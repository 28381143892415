import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../services/webpageStyle';
import { Base } from './mainContent/Base';





export interface BasePageContentProps {
  webpageStyle: WebpageStyleProps,
  doAction: Array<any>,
  resizeContentHeight: Array<any>
}





export const BasePageContent: FC<BasePageContentProps> = ({ webpageStyle, doAction, resizeContentHeight }) => {
  //

  const [contentHeight, setContentHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);

  const [mainContentHeight, setMainContentHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);
  const [sidebarHeight, setSidebarHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);


  const resizeMainContentHeight = (newMainContentHeight: number) => {
    setMainContentHeight(newMainContentHeight);
  }

  const resizeSidebarHeight = (newSidebarHeight: number) => {
    setSidebarHeight(newSidebarHeight);
  }


  useEffect(() => {
    setContentHeight(Math.max(mainContentHeight, sidebarHeight));
    resizeContentHeight[0](Math.max(mainContentHeight, sidebarHeight));
  }, [mainContentHeight, sidebarHeight]);


  return(
    // <div>
    //   BasePageContent
    // </div>
    <Base
      webpageStyle = { webpageStyle }
      resizeMainContentHeight = { [resizeMainContentHeight] }
    />
  )
}
