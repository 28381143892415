import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { BrowserRouter, Switch, Route, Redirect, useHistory, useParams } from 'react-router-dom';





export interface CheckPathAndSearchProps {
  recentPath: string,
  recentSearch: string,
  changePathAndSearch: Array<any>
}





export const CheckPathAndSearch: FC<CheckPathAndSearchProps> = ({ recentPath, recentSearch, changePathAndSearch }) => {
  // azért kell, hogy feldolgozza az aktuális path-t és search-öt
  // minden x-dik (200-dik) ezredmásodpercben újra és újra csekkoljuk, hátha történt változás
  // a probléma, hogy ehhez minden alkalommal újra és újra létre kell hozni egy csekkoló react komponenst

  const [isActive, setIsActive] = useState<boolean>(false);

  const [path, setPath] = useState<string>(useHistory().location.pathname);
  const [search, setSearch] = useState<string>(useHistory().location.search);

  const checkPathAndSearch = () => {
    if(recentPath !== path || recentSearch !== search) {
      changePathAndSearch[0](path, search);
    }
  }


  useEffect(() => {
    checkPathAndSearch();
  }, []);


  return(
    <div/>
  )
}
