import { UserData } from '../models/UserProps';

export function sortUsers(selectedUserInput: Array<UserData>, argument: string, order: string): Array<UserData> {
  //

  const sortedUsers: Array<UserData> = [];

  for(let i: number = 0; i < selectedUserInput.length; i++) {
    sortedUsers.push(selectedUserInput[i]);
  }

  let sortingIsAccomplished: boolean = false;
  while(sortingIsAccomplished !== true) {
    //

    sortingIsAccomplished = true;
    for(let i: number = 1; i < sortedUsers.length; i++) {
      //
      let a: string = "";
      let aa: Array<UserData> = [];
      let b: string = "";
      let bb: Array<UserData> = [];
      if(argument === "Id") {
        a = sortedUsers[i - 1].id.toString();
        b = sortedUsers[i].id.toString();
      }
      if(argument === "Name") {
        a = sortedUsers[i - 1].name;
        b = sortedUsers[i].name;
      }
      if(argument === "Email") {
        a = sortedUsers[i - 1].email;
        b = sortedUsers[i].email;
      }

      if(a === null) {
        a = "";
      }

      if(b === null) {
        b = "";
      }
      
      if(order === "Ascending") {
        let aa: UserData = sortedUsers[i - 1];
        if(argument === "Id") {
          if(Number(a) > Number(b)) {
            sortedUsers.splice((i - 1), 1);
            sortedUsers.splice(i, 0, aa);
            sortingIsAccomplished = false;
          }
        } else {
          if(a > b) {
            sortedUsers.splice((i - 1), 1);
            sortedUsers.splice(i, 0, aa);
            sortingIsAccomplished = false;
          }
        }
      }
      if(order === "Descending") {
        let bb: UserData = sortedUsers[i - 1];
        if(argument === "Id") {
          if(Number(a) < Number(b)) {
            sortedUsers.splice((i - 1), 1);
            sortedUsers.splice(i, 0, bb);
            sortingIsAccomplished = false;
          }
        } else {
          if(a < b) {
            sortedUsers.splice((i - 1), 1);
            sortedUsers.splice(i, 0, bb);
            sortingIsAccomplished = false;
          }
        }
      }
    }
  }

  return(sortedUsers);
}
