import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../../services/webpageStyle';
import { Horse, HorseData } from '../../../../../models/HorseProps';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../../03_baseComponents/Box';
import { ImageDisplay, ImageDisplayPrototypeDisplayParameters, ImageDisplayChangedDisplayParameters } from '../../../../03_baseComponents/ImageDisplay';
import { SingleRowText, SingleRowPrototypeDisplayParameters, SingleRowChangedDisplayParameters } from '../../../../03_baseComponents/SingleRowText';
import { KeyValueDisplay, KeyValueDisplayPrototypeDisplayParameters, KeyValueDisplayChangedDisplayParameters } from '../../../../03_baseComponents/KeyValueDisplay';
import { InterestButton, InterestButtonPrototypeDisplayParameters, InterestButtonBoxChangedDisplayParameters } from '../../../../03_baseComponents/InterestButton';
import { getHorsePageUrl } from '../../../../../services/RedirectionUrl';
import { getHorsePedigreeFromHorseTelex, HorsePedigree } from '../../../../../services/HorseTelexPedigree';
import { LoggedInUser } from '../../../../../models/UserProps';





export interface HorseCatalogGridElementProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  horse: Array<Horse>,
  initialHorseInterestState: boolean,
  interestFunction: Array<any>
}





export const HorseCatalogGridElement: FC<HorseCatalogGridElementProps> = ({ webpageStyle, loggedInUser, horse,
  initialHorseInterestState, interestFunction }) => {

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [horsePageUrl, setHorsePageUrl] = useState<Array<string>>([]);

  const [imageComponent, setImageComponent] = useState<Array<JSX.Element>>([<div/>]);
  const [imageBoxComponent, setImageBoxComponent] = useState<Array<JSX.Element>>([<div/>]);
  

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: 0,
    left: 0,
    height: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.boxHeight,
    width: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.boxOutlineColor
  });


  const [imageBoxPrototypeDisplayParameters, setImageBoxPrototypeDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.imageBoxTop,
    left: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.imageBoxLeft,
    height: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.imageBoxHeight,
    width: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.imageBoxWidth,
    backgroundColor: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.imageBoxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.imageBoxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.imageBoxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.imageBoxOutlineColor,
  });

  const [imagePrototypeDisplayParameters, setImagePrototypeDisplayParameters] = useState<ImageDisplayPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.imageTop,
    left: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.imageLeft,
    height: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.imageHeight,
    width: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.imageWidth
  });

  const [titlePrototypeDisplayParameters, setTitlePrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.titleTop,
    left: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.titleLeft,
    width: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.titleWidth,
    fontSize: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.titleFontSize,
    color: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.titleColor
  });

  const [firstDataTop, setFirstDataTop] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.gridElement.firstDataTop);
  const [dataTopDistance, setDataTopDistance] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.gridElement.dataTopDistance);
  const [connectedDataTopDistance, setConnectedDataTopDistance] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.gridElement.connectedDataTopDistance);

  const [keyValueDisplayPrototypeDisplayParameters, setKeyValueDisplayPrototypeDisplayParameters] = useState<KeyValueDisplayPrototypeDisplayParameters>({
    keyTop: firstDataTop,
    keyLeft: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.keyLeft,
    keyWidth: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.keyFontSize,
    keyColor: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.keyColor,
    valueTop: firstDataTop,
    valueLeft: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.valueLeft,
    valueWidth: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.valueWidth,
    valueFontSize: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.valueFontSize,
    valueColor: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.valueColor
  });

  const [horseBreedPrototypeDisplayParameters, setHorseBreedPrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: firstDataTop,
    left: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.valueLeft,
    width: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.valueWidth,
    fontSize: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.valueFontSize,
    color: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.valueColor
  });

  const [interestButtonPrototypeDisplayParameters, setInterestButtonPrototypeDisplayParameters] = useState<InterestButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.interestButtonTop,
    left: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.interestButtonLeft,
    size: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.interestButtonSize
  });

  const [firstCategoryLeft, setFirstCategoryLeft] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.gridElement.firstCategoryTop);
  const [categoryLeftDistance, setCategoryLeftDistance] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.gridElement.categoryTopDistance);

  const [categoryPrototypeDisplayParameters, setCategoryPrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.categoryLeft,
    left: firstCategoryLeft,
    width: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.categoryWidth,
    fontSize: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.categoryFontSize,
    color: webpageStyle.content.mainContent.horseCatalogCollection.gridElement.categoryColor
  });

  const [horseBreed, setHorseBreed] = useState<string>("");

  const [horseInterestState, setHorseInterestState] = useState<boolean>(initialHorseInterestState);

  const [priceCategory, setPriceCategory] = useState<string>("");

  const loadData = async () => {
    //

    const temporaryRedirectionUrl: string = getHorsePageUrl(horse[0].data.id);
    setHorsePageUrl([temporaryRedirectionUrl]);

    if(horse[0].data.mainImageUrl !== null) {
      if(horse[0].data.mainImageUrl.length !== 0) {
        setImageComponent([
          <ImageDisplay
            uploadedImage = { [] }
            sourceUrl = { [horse[0].data.mainImageUrl] }
            redirectionUrl = { temporaryRedirectionUrl }
            prototypeDisplayParameters = { imagePrototypeDisplayParameters }
          />
        ])
      }
    }

    setImageBoxComponent([
      <Box
        redirectionUrl = { temporaryRedirectionUrl }
        prototypeDisplayParameters = { imageBoxPrototypeDisplayParameters }
      />
    ])

    setHorseBreed(('(' + horse[0].data.horseBreed + ')'));

    setPriceCategory((horse[0].data.priceCategory + ' €'));

    setIsLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  const changeHorseInterest = () => {
    //

    // run API ...

    // feltételek ...

    setHorseInterestState(horseInterestState === true ? false : true);
  }

  if(isLoading === true) {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      >
        <div>
          Horse catalog list element is loading...
        </div>
      </Box>
    )
  } else {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      >
        <div>
          { imageBoxComponent[0] }

          { imageComponent[0] }

          <SingleRowText
            text = { horse[0].data.name }
            redirectionUrl = { horsePageUrl[0] }
            prototypeDisplayParameters = { titlePrototypeDisplayParameters }
          />

          <KeyValueDisplay
            keyText = { "Birth year" }
            valueText = { horse[0].data.birthYear.toString() }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 0 * dataTopDistance,
              valueTop: firstDataTop + 0 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Sex" }
            valueText = { horse[0].data.sex }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 1 * dataTopDistance,
              valueTop: firstDataTop + 1 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Origin" }
            valueText = { horse[0].data.origin }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 2 * dataTopDistance,
              valueTop: firstDataTop + 2 * dataTopDistance
            } }
          />

          <SingleRowText
            text = { horseBreed }
            prototypeDisplayParameters = { horseBreedPrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstDataTop + 2 * dataTopDistance + connectedDataTopDistance
            } }
          />

          { loggedInUser.role !== "administrator" && loggedInUser.role !== "account owner" ?
            <InterestButton
              value = { horseInterestState }
              userId = { -1 }
              horseId = { horse[0].data.id }
              functions = { [changeHorseInterest] }
              prototypeDisplayParameters = { interestButtonPrototypeDisplayParameters }
            /> : null
          }

          <SingleRowText
            text = { horse[0].data.tradingCategory }
            prototypeDisplayParameters = { categoryPrototypeDisplayParameters }
            changedDisplayParameters = { {
              left: firstCategoryLeft + 0 * categoryLeftDistance
            } }
          />

          <SingleRowText
            text = { priceCategory }
            prototypeDisplayParameters = { categoryPrototypeDisplayParameters }
            changedDisplayParameters = { {
              left: firstCategoryLeft + 1 * categoryLeftDistance
            } }
          />

        </div>
      </Box>
    )
  }
}
