import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import Popover from '@mui/material/Popover';





export interface PopUpWindowProps {
  message: Array<string>,
  children?: JSX.Element | JSX.Element[]
}





export const PopUpWindow: FC<PopUpWindowProps> = ({message, children}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return(
    <div>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={message.length !== 0 ? handlePopoverOpen : handlePopoverClose}
        onMouseLeave={handlePopoverClose}
      >
        { children }
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div>{message[0]}</div>
      </Popover>
    </div>
  )
}
