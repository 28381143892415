import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';





export interface customPaginationProps {
  numberOfFirstElements: number,
  numberOfMiddleElements: number,
  numberOfLastElements: number,
  sumOfPages: number,
  initialSelectedPageIndex: number,
  setPage: Array<any>,
  top: number,
  left: number,
  width: number,
  fontSize: number
}

export interface FilmCollectionProps {
  collectionTop: number,
  collectionLeft: number,
  elementHeight: number,
  elementWidth: number,
  paginationTop: number,
  paginationLeft: number,
  paginationWidth: number,
  paginationFontSize: number,
  selectedElementsAreChanged: number,
  children?: Array<JSX.Element>
}

export interface ListCollectionProps {
  collectionTop: number,
  collectionLeft: number,
  n: number, // height
  elementHeight: number,
  elementWidth: number,
  paginationTop: number,
  paginationLeft: number,
  paginationWidth: number,
  paginationFontSize: number,
  selectedElementsAreChanged: number,
  children?: Array<JSX.Element>
}

export interface GridCollectionProps {
  collectionTop: number,
  collectionLeft: number,
  n: number, // height
  m: number, // width
  elementHeight: number,
  elementWidth: number,
  paginationTop: number,
  paginationLeft: number,
  paginationWidth: number,
  paginationFontSize: number,
  selectedElementsAreChanged: number,
  children?: Array<JSX.Element>
}

export interface AdjustableCollectionProps {
  collectionType: string, // SingleElementPagination, ListPagination, GridPagination
  n: number, // height
  m: number, // width
  collectionTop: number,
  collectionLeft: number,
  elementHeight: number,
  elementWidth: number,
  paginationTop: number,
  paginationLeft: number,
  paginationWidth: number,
  paginationFontSize: number,
  selectedElementsAreChanged: number,
  children?: Array<JSX.Element>
}





const CustomPagination: FC<customPaginationProps> = ({ numberOfFirstElements, numberOfMiddleElements, numberOfLastElements,
  sumOfPages, initialSelectedPageIndex, setPage, top, left, width, fontSize }) => {
  //

  const [isLoadingElements, setIsLoadingElements] = useState<boolean>(true);

  const [elements, setElements] = useState<Array<JSX.Element>>([]);

  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(initialSelectedPageIndex);


  useEffect(() => {
    setIsLoadingElements(true);
    setSelectedPageIndex(initialSelectedPageIndex);
  }, [initialSelectedPageIndex, sumOfPages]);


  if(isLoadingElements === true) {
    let elementContents: Array<string> = [];
    let sumOfElements: number = 2 + Math.max(numberOfFirstElements, 1) + 1 + Math.max(numberOfMiddleElements, 1) + 1 + Math.max(numberOfLastElements, 1) + 2;
    let elementActiveStates: Array<boolean> = [];
    let elementSelectedStates: Array<boolean> = [];
    
    if(sumOfPages <= (sumOfElements - 4)) {
      // kioszt
      for(let i: number = 0; i < (sumOfPages + 4); i++) {
        elementContents.push("_");
        elementActiveStates.push(false);
        elementSelectedStates.push(false);
      }
      elementContents[0] = "<<";
      elementContents[1] = "<";
      elementContents[sumOfElements - 2] = ">";
      elementContents[sumOfElements - 1] = ">>";
  
      for(let i: number = 0; i < sumOfPages; i++) {
        elementContents[i + 2] = (i + 1).toString();
      }
  
      for(let i: number = 0; i < elementContents.length; i++) {
        if(elementContents[i] === "_") {
          elementContents[i] = "";
        }
      }
    } else {
      // feloszt
      // if(firstElements >= 1) {
      //   elementContents[2] = "1"
      // }
      for(let i: number = 0; i < sumOfElements; i++) {
        elementContents.push("_");
        elementActiveStates.push(false);
        elementSelectedStates.push(false);
      }
      elementContents[0] = "<<";
      elementContents[1] = "<";
      elementContents[sumOfElements - 2] = ">";
      elementContents[sumOfElements - 1] = ">>";
  
      if(selectedPageIndex <= (Math.max(numberOfFirstElements, 1) + (Math.max(numberOfMiddleElements, 1) + 1) /2)) {
        for(let i: number = 0; i < Math.max(numberOfFirstElements, 1); i++) {
          elementContents[i + 2] = (i + 1).toString();
        }
        for(let i: number = Math.max(numberOfFirstElements, 1); i < selectedPageIndex + ((Math.max(numberOfMiddleElements, 1) - 1) / 2); i++) {
          elementContents[i + 2] = (i + 1).toString();
        }
  
        for(let i: number = 0; i < Math.max(numberOfLastElements, 1); i++) {
          elementContents[sumOfElements - 3 - i] = (sumOfPages - i).toString();
        }
      } else {
        if((sumOfPages - selectedPageIndex) < (Math.max(numberOfLastElements, 1) + (Math.max(numberOfMiddleElements, 1) + 1) /2)) {
          for(let i: number = 0; i < Math.max(numberOfFirstElements, 1); i++) {
            elementContents[i + 2] = (i + 1).toString();
          }
  
          for(let i: number = 0; i < Math.max(numberOfLastElements, 1); i++) {
            elementContents[sumOfElements - 3 - i] = (sumOfPages - i).toString();
          }
          for(let i: number = Math.max(numberOfLastElements, 1); i < (sumOfPages - selectedPageIndex + 1) + ((Math.max(numberOfMiddleElements, 1) - 1) / 2); i++) {
            elementContents[sumOfElements - 3 - i] = (sumOfPages - i).toString();
          }
        } else {
          for(let i: number = 0; i < Math.max(numberOfFirstElements, 1); i++) {
            elementContents[i + 2] = (i + 1).toString();
          }
  
          for(let i: number = 0; i < Math.max(numberOfLastElements, 1); i++) {
            elementContents[sumOfElements - 3 - i] = (sumOfPages - i).toString();
          }
  
          for(let i: number = 0; i < Math.max(numberOfMiddleElements, 1); i++) {
            elementContents[((sumOfElements + 1) / 2) - 1 - ((Math.max(numberOfMiddleElements, 1) - 1) / 2) + i] = Math.floor((selectedPageIndex - ((Math.max(numberOfMiddleElements, 1) - 1) / 2) + i)).toString();
          }
        }
      }
    
      // if(lastElements >= 1) {
      //   elementContents[sumOfElements - 3] = sumOfPages.toString();
      // }
  
      for(let i: number = 0; i < elementContents.length; i++) {
        if(elementContents[i] === "_") {
          if((elementContents[i - 1] !== "_" && elementContents[i - 1] !== "..." && elementContents[i - 1] !== "") || (elementContents[i + 1] !== "_" && elementContents[i + 1] !== "..." && elementContents[i + 1] !== "")) {
            elementContents[i] = "...";
          } else {
            elementContents[i] = "";
          }
        }
      }
    }
  
    for(let i: number = 0; i < elementContents.length; i++) {
      if(elementContents[i] !== "" && elementContents[i] !== "..." && elementContents[i] !== selectedPageIndex.toString()) {
        elementActiveStates[i] = true;
      }
    }
  
    if(selectedPageIndex === 1) {
      elementActiveStates[0] = false;
      elementActiveStates[1] = false;
    }
  
    if(selectedPageIndex === sumOfPages) {
      elementActiveStates[sumOfElements - 2] = false;
      elementActiveStates[sumOfElements - 1] = false;
    }
  
    for(let i: number = 0; i < elementContents.length; i++) {
      if(elementContents[i] === selectedPageIndex.toString()) {
        elementSelectedStates[i] = true;
      } else {
        elementSelectedStates[i] = false;
      }
    }
  
    const changeIndex = (elementContent: string) => {
      //
  
      if(elementContent === "<<" || elementContent === "<" || elementContent === ">" || elementContent === ">>") {
        if(elementContent === "<<") {
         setSelectedPageIndex(1);
         setPage[0](1 - 1);
        }
        if(elementContent === "<") {
          if(selectedPageIndex !== 1) {
            setSelectedPageIndex(selectedPageIndex - 1);
            setPage[0](selectedPageIndex - 1 - 1);
          }
        }
        if(elementContent === ">") {
          if(selectedPageIndex !== sumOfPages) {
            setSelectedPageIndex(selectedPageIndex + 1);
            setPage[0](selectedPageIndex + 1 - 1);
          }
        }
        if(elementContent === ">>") {
          setSelectedPageIndex(sumOfPages);
          setPage[0](sumOfPages - 1);
        }
      } else {
        setSelectedPageIndex(Number(elementContent));
        setPage[0](Number(elementContent) - 1);
      }
    }
  
    const result: JSX.Element[] = [];
  
    for(let i: number = 0; i < sumOfElements; i++) {
      result.push(
        <div key = { i }>
          <div style = {{ position: "absolute", top: 0, left: (i * Math.floor((width / sumOfElements))), height: (fontSize * 2), width: (fontSize * 2), fontSize: fontSize, color: "black", backgroundColor: elementSelectedStates[i] === true ? "darkgrey" : "lightgrey" }}>
            { elementContents[i] }
          </div>
          <div style = {{ position: "absolute", top: 0, left: (i * Math.floor((width / sumOfElements))), height: (fontSize * 2), width: (fontSize * 2), backgroundColor: "transparent", cursor: elementActiveStates[i] === true ? "pointer" : "default" }} onClick = { () => { if(elementActiveStates[i] === true) { changeIndex(elementContents[i]) } } }/>
        </div>
      )
    }
  
    setElements(result);
    setIsLoadingElements(false);
  }


  return(
    <div style = {{ position: "absolute", top: top, left: left }}>
      { elements }
    </div>
  )
}

const FilmCollection: FC<FilmCollectionProps> = ({ collectionTop, collectionLeft, elementHeight, elementWidth, paginationTop,
  paginationLeft, paginationWidth, paginationFontSize, selectedElementsAreChanged, children }) => {
  const [index, setIndex] = useState<number>(0);

  if(children !== undefined) {
    return(
      <div style = {{ position: "absolute", top: collectionTop, left: collectionLeft }}>
        <div style = {{ height: (elementHeight + 50), width: elementWidth }}>
          { children[index] }
        </div>
        <div>
          {/* <Stack spacing={2}>
            <Pagination siblingCount={1} boundaryCount={1} count={ children.length } showFirstButton showLastButton onChange={(event: React.ChangeEvent<unknown>, page: number) => setIndex(page - 1)} />
          </Stack> */}
          <CustomPagination
            numberOfFirstElements = { 2 }
            numberOfMiddleElements = { 3 }
            numberOfLastElements = { 2 }
            sumOfPages = { children.length }
            initialSelectedPageIndex = { (index + 1) }
            setPage = { [setIndex] }
            top = { paginationTop }
            left = { paginationLeft }
            width = { paginationWidth }
            fontSize = { paginationFontSize }
          />
        </div>
      </div>
    )
  } else {
    return(
      <div>
        No elements
      </div>
    )
  }
}

const ListCollection: FC<ListCollectionProps> = ({ collectionTop, collectionLeft, elementHeight, n, elementWidth,
  paginationTop, paginationLeft, paginationWidth, paginationFontSize, selectedElementsAreChanged, children }) => {
  const [paginationIsLoading, setPaginationIsLoading] = useState<boolean>(true);
  const [index, setIndex] = useState<number>(0);

  const [paginationListElements, setPaginationListElements] = useState<JSX.Element[][]>([]);

  const getPaginationLists = () => {
    setIndex(0);
    if(children !== undefined) {
      const temporaryPaginationListElements: JSX.Element[][] = [];
      for(let i: number = 0; i < children.length; i = (i + n)) {
        //
  
        const temporaryPaginationPageList: JSX.Element[] = [];
        for(let j: number = i; (j < children.length && (j - i) < n); j++) {
          //
  
          temporaryPaginationPageList.push(children[j]);
        }
  
        temporaryPaginationListElements.push(temporaryPaginationPageList);
      }
      const lastListIndex: number = temporaryPaginationListElements.length - 1;
      for(let i: number = temporaryPaginationListElements[lastListIndex].length; i < n; i++) {
        temporaryPaginationListElements[lastListIndex].push(
          <div/>
        )
      }
      setPaginationListElements(temporaryPaginationListElements);
      setPaginationIsLoading(false);
    }
  }


  useEffect(() => {
    getPaginationLists();
  }, [selectedElementsAreChanged]);


  if(children !== undefined) {
    if(paginationIsLoading === true) {
      return(
        <div>
          Pagination is loading
        </div>
      )
    } else {
      return(
        <div style = {{ position: "absolute", top: collectionTop, left: collectionLeft }}>
          {
            paginationListElements[index].map((anElement, i) =>
              <div style = {{ position: "absolute", top: (elementHeight * i), height: elementHeight, width: elementWidth, backgroundColor: "grey" }} key = { "list element: " + i.toString() }>
                { anElement }
              </div>
            )
          }
          <div
            style = {{ height: (elementHeight * paginationListElements[index].length + 50), width: elementWidth }}
          />
          <div>
            {/* <Stack spacing={2}>
              <Pagination siblingCount={1} boundaryCount={1} count={ paginationListElements.length } showFirstButton showLastButton onChange={(event: React.ChangeEvent<unknown>, page: number) => setIndex(page - 1)} />
            </Stack> */}
            <CustomPagination
              numberOfFirstElements = { 2 }
              numberOfMiddleElements = { 3 }
              numberOfLastElements = { 2 }
              sumOfPages = { paginationListElements.length }
              initialSelectedPageIndex = { (index + 1) }
              setPage = { [setIndex] }
              top = { paginationTop }
              left = { paginationLeft }
              width = { paginationWidth }
              fontSize = { paginationFontSize }
            />
          </div>
        </div>
      )
    }
  } else {
    return(
      <div>
        No elements
      </div>
    )
  }
}

const GridCollection: FC<GridCollectionProps> = ({ collectionTop, collectionLeft, n, m, elementHeight, elementWidth,
  paginationTop, paginationLeft, paginationWidth, paginationFontSize, selectedElementsAreChanged, children }) => {
  const [paginationIsLoading, setPaginationIsLoading] = useState<boolean>(true);
  const [index, setIndex] = useState<number>(0);

  const [paginationGridElements, setPaginationGridElements] = useState<JSX.Element[][][]>([]);

  const getPaginationLists = () => {
    setIndex(0);
    if(children !== undefined) {
      const temporaryPaginationGridElements: JSX.Element[][][] = [];
      for(let i: number = 0; i < children.length; i = i + (n * m)) {
        const temporaryOnePageGridElements: JSX.Element[][] = [];
        for(let j: number = i; (j < children.length && (j - i) < (n * m)); j = (j + m)) {
          const temporaryOneRowGridElements: JSX.Element[] = [];
          for(let k: number = j; (k < children.length && (k - j) < m); k++) {
            temporaryOneRowGridElements.push(children[k]);
          }
          temporaryOnePageGridElements.push(temporaryOneRowGridElements);
        }
        temporaryPaginationGridElements.push(temporaryOnePageGridElements);
      }
      const lastGridIndex: number = temporaryPaginationGridElements.length - 1;
      const lastListIndex: number = temporaryPaginationGridElements[lastGridIndex].length - 1;
      for(let j: number = temporaryPaginationGridElements[lastGridIndex][lastListIndex].length; j < m; j++) {
        temporaryPaginationGridElements[lastGridIndex][lastListIndex].push(
          <div/>
        )
      }
      for(let i: number = temporaryPaginationGridElements[lastGridIndex].length; i < n; i++) {
        temporaryPaginationGridElements[lastGridIndex].push([]);

        for(let j: number = 0; j < m; j++) {
          temporaryPaginationGridElements[lastGridIndex][i].push(
            <div/>
          )
        }
      }
      setPaginationGridElements(temporaryPaginationGridElements);
      setPaginationIsLoading(false);
    }
  }


  useEffect(() => {
    getPaginationLists();
  }, [selectedElementsAreChanged]);


  if(children !== undefined) {
    if(paginationIsLoading === true) {
      return(
        <div>
          Pagination is loading
        </div>
      )
    } else {
      return(
        <div style = {{ position: "absolute", top: collectionTop, left: collectionLeft }}>
          {
            // paginationGridElements[0][0][0]
            paginationGridElements[index].map((aRow: JSX.Element[], i) =>
                aRow.map((anElement: JSX.Element, j) =>
                  <div style = {{ position: "absolute", top: (elementHeight * i), left: (elementWidth * j), height: elementHeight, width: elementWidth, backgroundColor: "grey" }} key = { ("grid element: " + i.toString() + '-' + j.toString()) }>
                    { anElement }
                  </div>
                )
            )
          }
          <div
            style = {{ height: (elementHeight * paginationGridElements[index].length + 50), width: elementWidth }}
          />
          <div>
            {/* <Stack spacing={2}>
              <Pagination siblingCount={1} boundaryCount={1} count={ paginationGridElements.length } showFirstButton showLastButton onChange={(event: React.ChangeEvent<unknown>, page: number) => setIndex(page - 1)} />
            </Stack> */}
            <CustomPagination
              numberOfFirstElements = { 2 }
              numberOfMiddleElements = { 3 }
              numberOfLastElements = { 2 }
              sumOfPages = { paginationGridElements.length }
              initialSelectedPageIndex = { (index + 1) }
              setPage = { [setIndex] }
              top = { paginationTop }
              left = { paginationLeft }
              width = { paginationWidth }
              fontSize = { paginationFontSize }
            />
          </div>
        </div>
      )
    }
  } else {
    return(
      <div>
        No elements
      </div>
    )
  }
}

export const AdjustableCollection: FC<AdjustableCollectionProps> = ({ collectionType, n, m, collectionTop, collectionLeft,
  elementHeight, elementWidth, paginationTop, paginationLeft, paginationWidth, paginationFontSize,
  selectedElementsAreChanged, children }) => {
  //

  if(children !== undefined) {
    if(children.length === 0) {
      return(
        <div style = {{ position: "absolute", top: collectionTop, left: collectionLeft }}>
          No elements
        </div>
      )
    }

    switch(collectionType) {
      case("FilmCollection"):
        return(
          <FilmCollection
            collectionTop = { collectionTop }
            collectionLeft = { collectionLeft }
            elementHeight = { elementHeight }
            elementWidth = { elementWidth }
            paginationTop = { paginationTop }
            paginationLeft = { paginationLeft }
            paginationWidth = { paginationWidth }
            paginationFontSize = { paginationFontSize }
            selectedElementsAreChanged = { selectedElementsAreChanged }
          >
            { children }
          </FilmCollection>
        )
        break;
      case("ListCollection"):
        return(
          <ListCollection
            collectionTop = { collectionTop }
            collectionLeft = { collectionLeft }
            n = { n }
            elementHeight = { elementHeight }
            elementWidth = { elementWidth }
            paginationTop = { paginationTop }
            paginationLeft = { paginationLeft }
            paginationWidth = { paginationWidth }
            paginationFontSize = { paginationFontSize }
            selectedElementsAreChanged = { selectedElementsAreChanged }
          >
            { children }
          </ListCollection>
        )
        break;
      case("GridCollection"):
        return(
          <GridCollection
            collectionTop = { collectionTop }
            collectionLeft = { collectionLeft }
            n = { n }
            m = { m }
            elementHeight = { elementHeight }
            elementWidth = { elementWidth }
            paginationTop = { paginationTop }
            paginationLeft = { paginationLeft }
            paginationWidth = { paginationWidth }
            paginationFontSize = { paginationFontSize }
            selectedElementsAreChanged = { selectedElementsAreChanged }
          >
            { children }
          </GridCollection>
        )
        break;
    }
  
    return(
      <div style = {{ position: "absolute", top: collectionTop, left: collectionLeft }}>
        No such collection type
      </div>
    )
  }

  return(
    <div style = {{ position: "absolute", top: collectionTop, left: collectionLeft }}>
      No collection element
    </div>
  )
}
