import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { UserData, EditUserDataRequest } from '../../../../models/UserProps';
import { getData, putData } from '../../../../services/API.services';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { KeyValueDisplay, KeyValueDisplayPrototypeDisplayParameters, KeyValueDisplayChangedDisplayParameters } from '../../../03_baseComponents/KeyValueDisplay';
import { KeyValueInput, KeyValueInputPrototypeDisplayParameters, KeyValueInputChangedDisplayParameters } from '../../../03_baseComponents/KeyValueInput';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../03_baseComponents/Button';
import { SingleRowText, SingleRowPrototypeDisplayParameters, SingleRowChangedDisplayParameters } from '../../../03_baseComponents/SingleRowText';
import { MultipleRowText, MultipleRowPrototypeDisplayParameters, MultipleRowChangedDisplayParameters } from '../../../03_baseComponents/MultipleRowText';
import { emailInputTest } from '../../../../services/Conditions';





export interface AdminEditUserDataProps {
  webpageStyle: WebpageStyleProps,
  selectedUserId: Array<number>,
  resetUsersData: Array<any>,
  resizeMainContentHeight: Array<any>
}





export const AdminEditUserData: FC<AdminEditUserDataProps> = ({ webpageStyle, selectedUserId,
  resetUsersData, resizeMainContentHeight }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [userData, setUserData] = useState<Array<UserData>>([]);

  const [initialName, setInitialName] = useState<Array<string>>([]);
  const [initialEmail2, setInitialEmail2] = useState<Array<string>>([]);
  const [initialTelephoneNumber1, setInitialTelephoneNumber1] = useState<Array<string>>([]);
  const [initialTelephoneNumber2, setInitialTelephoneNumber2] = useState<Array<string>>([]);
  const [initialLanguage1, setInitialLanguage1] = useState<Array<string>>([]);
  const [initialLanguage2, setInitialLanguage2] = useState<Array<string>>([]);
  const [initialShipmentContinent, setInitialShipmentContinent] = useState<Array<string>>([]);
  const [initialShipmentCountry, setInitialShipmentCountry] = useState<Array<string>>([]);
  const [initialShipmentState, setInitialShipmentState] = useState<Array<string>>([]);
  const [initialShipmentZipCode, setInitialShipmentZipCode] = useState<Array<string>>([]);
  const [initialShipmentCity, setInitialShipmentCity] = useState<Array<string>>([]);
  const [initialShipmentNameOfPublicArea, setInitialShipmentNameOfPublicArea] = useState<Array<string>>([]);
  const [initialShipmentTypeOfPublicArea, setInitialShipmentTypeOfPublicArea] = useState<Array<string>>([]);
  const [initialShipmentHouseNumber, setInitialShipmentHouseNumber] = useState<Array<string>>([]);
  const [initialShipmentFloor, setInitialShipmentFloor] = useState<Array<string>>([]);
  const [initialShipmentDoor, setInitialShipmentDoor] = useState<Array<string>>([]);
  const [initialInvoicingContinent, setInitialInvoicingContinent] = useState<Array<string>>([]);
  const [initialInvoicingCountry, setInitialInvoicingCountry] = useState<Array<string>>([]);
  const [initialInvoicingState, setInitialInvoicingState] = useState<Array<string>>([]);
  const [initialInvoicingZipCode, setInitialInvoicingZipCode] = useState<Array<string>>([]);
  const [initialInvoicingCity, setInitialInvoicingCity] = useState<Array<string>>([]);
  const [initialInvoicingNameOfPublicArea, setInitialInvoicingNameOfPublicArea] = useState<Array<string>>([]);
  const [initialInvoicingTypeOfPublicArea, setInitialInvoicingTypeOfPublicArea] = useState<Array<string>>([]);
  const [initialInvoicingHouseNumber, setInitialInvoicingHouseNumber] = useState<Array<string>>([]);
  const [initialInvoicingFloor, setInitialInvoicingFloor] = useState<Array<string>>([]);
  const [initialInvoicingDoor, setInitialInvoicingDoor] = useState<Array<string>>([]);
  const [initialBankName, setInitialBankName] = useState<Array<string>>([]);
  const [initialBankAccountNumber, setInitialBankAccountNumber] = useState<Array<string>>([]);

  const [name, setName] = useState<Array<string>>([]);
  const [email2, setEmail2] = useState<Array<string>>([]);
  const [telephoneNumber1, setTelephoneNumber1] = useState<Array<string>>([]);
  const [telephoneNumber2, setTelephoneNumber2] = useState<Array<string>>([]);
  const [language1, setLanguage1] = useState<Array<string>>([]);
  const [language2, setLanguage2] = useState<Array<string>>([]);
  const [shipmentContinent, setShipmentContinent] = useState<Array<string>>([]);
  const [shipmentCountry, setShipmentCountry] = useState<Array<string>>([]);
  const [shipmentState, setShipmentState] = useState<Array<string>>([]);
  const [shipmentZipCode, setShipmentZipCode] = useState<Array<string>>([]);
  const [shipmentCity, setShipmentCity] = useState<Array<string>>([]);
  const [shipmentNameOfPublicArea, setShipmentNameOfPublicArea] = useState<Array<string>>([]);
  const [shipmentTypeOfPublicArea, setShipmentTypeOfPublicArea] = useState<Array<string>>([]);
  const [shipmentHouseNumber, setShipmentHouseNumber] = useState<Array<string>>([]);
  const [shipmentFloor, setShipmentFloor] = useState<Array<string>>([]);
  const [shipmentDoor, setShipmentDoor] = useState<Array<string>>([]);
  const [invoicingContinent, setInvoicingContinent] = useState<Array<string>>([]);
  const [invoicingCountry, setInvoicingCountry] = useState<Array<string>>([]);
  const [invoicingState, setInvoicingState] = useState<Array<string>>([]);
  const [invoicingZipCode, setInvoicingZipCode] = useState<Array<string>>([]);
  const [invoicingCity, setInvoicingCity] = useState<Array<string>>([]);
  const [invoicingNameOfPublicArea, setInvoicingNameOfPublicArea] = useState<Array<string>>([]);
  const [invoicingTypeOfPublicArea, setInvoicingTypeOfPublicArea] = useState<Array<string>>([]);
  const [invoicingHouseNumber, setInvoicingHouseNumber] = useState<Array<string>>([]);
  const [invoicingFloor, setInvoicingFloor] = useState<Array<string>>([]);
  const [invoicingDoor, setInvoicingDoor] = useState<Array<string>>([]);
  const [bankName, setBankName] = useState<Array<string>>([]);
  const [bankAccountNumber, setBankAccountNumber] = useState<Array<string>>([]);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [statusMessage, setStatusMessage] = useState<string>("");

  const [changedValueMessage, setChangedValueMessage] = useState<string>("This value has been changed, but not saved.");

  const [somethingHasChanged, setSomethingHasChanged] = useState<boolean>(false);


  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.mainContent.adminEditUserData.boxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.boxTop,
    left: webpageStyle.content.mainContent.boxLeft,
    height: boxHeight,
    width: webpageStyle.content.mainContent.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.boxOutlineColor
  });


  const [firstInputTop, setFirstInputTop] = useState<number>(webpageStyle.content.mainContent.adminEditUserData.firstInputTop);
  const [inputTopDistance, setInputTopDistance] = useState<number>(webpageStyle.content.mainContent.adminEditUserData.inputTopDistance);

  const [keyValueDisplayPrototypeDisplayParameters, setKeyValueDisplayPrototypeDisplayParameters] = useState<KeyValueDisplayPrototypeDisplayParameters>({
    keyTop: firstInputTop,
    keyLeft: webpageStyle.content.mainContent.adminEditUserData.keyLeft,
    keyWidth: webpageStyle.content.mainContent.adminEditUserData.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.adminEditUserData.keyFontSize,
    keyColor: webpageStyle.content.mainContent.adminEditUserData.keyColor,
    valueTop: firstInputTop,
    valueLeft: webpageStyle.content.mainContent.adminEditUserData.fieldLeft,
    valueWidth: webpageStyle.content.mainContent.adminEditUserData.fieldWidth,
    valueFontSize: webpageStyle.content.mainContent.adminEditUserData.valueFontSize,
    valueColor: webpageStyle.content.mainContent.adminEditUserData.valueColor
  });

  const [keyValueInputPrototypeDisplayParameters, setKeyValueInputPrototypeDisplayParameters] = useState<KeyValueInputPrototypeDisplayParameters>({
    keyTop: firstInputTop,
    keyLeft: webpageStyle.content.mainContent.adminEditUserData.keyLeft,
    keyWidth: webpageStyle.content.mainContent.adminEditUserData.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.adminEditUserData.keyFontSize,
    keyColor: webpageStyle.content.mainContent.adminEditUserData.keyColor,
    fieldTop: firstInputTop,
    fieldLeft: webpageStyle.content.mainContent.adminEditUserData.fieldLeft,
    fieldHeight: webpageStyle.content.mainContent.adminEditUserData.fieldHeight,
    fieldWidth: webpageStyle.content.mainContent.adminEditUserData.fieldWidth,
    fieldBackgroundColor: webpageStyle.content.mainContent.adminEditUserData.fieldBackgroundColor,
    valueFontSize: webpageStyle.content.mainContent.adminEditUserData.valueFontSize,
    valueColor: webpageStyle.content.mainContent.adminEditUserData.valueColor,
  }
  );

  const [changingMessagePrototypeDisplayParameters, setChangingMessagePrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: firstInputTop,
    left: webpageStyle.content.mainContent.adminEditUserData.changingMessageLeft,
    width: webpageStyle.content.mainContent.adminEditUserData.changingMessageWidth,
    fontSize: webpageStyle.content.mainContent.adminEditUserData.changingMessageFontSize,
    color: webpageStyle.content.mainContent.adminEditUserData.changingMessageColor
  });

  const [saveButtonBackgroundColorActive, setSaveButtonBackgroundColorActive] = useState<string>(webpageStyle.content.mainContent.adminEditUserData.saveButtonBackgroundColorActive);
  const [saveButtonBackgroundColorInactive, setSaveButtonBackgroundColorInactive] = useState<string>(webpageStyle.content.mainContent.adminEditUserData.saveButtonBackgroundColorInactive);
  const [saveButtonContentColorActive, setSaveButtonContentColorActive] = useState<string>(webpageStyle.content.mainContent.adminEditUserData.saveButtonContentColorActive);
  const [saveButtonContentColorInactive, setSaveButtonContentColorInactive] = useState<string>(webpageStyle.content.mainContent.adminEditUserData.saveButtonContentColorInactive);

  const [saveButtonPrototypeDisplayParameters, setSaveButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.adminEditUserData.saveButtonTop,
    left: webpageStyle.content.mainContent.adminEditUserData.saveButtonLeft,
    height: webpageStyle.content.mainContent.adminEditUserData.saveButtonHeight,
    width: webpageStyle.content.mainContent.adminEditUserData.saveButtonWidth,
    backgroundColor: saveButtonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.adminEditUserData.saveButtonFontSize,
    contentColor: saveButtonContentColorActive
  });

  const [resetButtonBackgroundColorActive, setResetButtonBackgroundColorActive] = useState<string>(webpageStyle.content.mainContent.adminEditUserData.resetButtonBackgroundColorActive);
  const [resetButtonBackgroundColorInactive, setResetButtonBackgroundColorInactive] = useState<string>(webpageStyle.content.mainContent.adminEditUserData.resetButtonBackgroundColorInactive);
  const [resetButtonContentColorActive, setResetButtonContentColorActive] = useState<string>(webpageStyle.content.mainContent.adminEditUserData.resetButtonContentColorActive);
  const [resetButtonContentColorInactive, setResetButtonContentColorInactive] = useState<string>(webpageStyle.content.mainContent.adminEditUserData.resetButtonContentColorInactive);

  const [resetButtonPrototypeDisplayParameters, setResetButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.adminEditUserData.resetButtonTop,
    left: webpageStyle.content.mainContent.adminEditUserData.resetButtonLeft,
    height: webpageStyle.content.mainContent.adminEditUserData.resetButtonHeight,
    width: webpageStyle.content.mainContent.adminEditUserData.resetButtonWidth,
    backgroundColor: resetButtonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.adminEditUserData.resetButtonFontSize,
    contentColor: resetButtonContentColorActive
  });

  const [errorMessagePrototypeDisplayParameters, setErrorMessagePrototypeDisplayParameters] = useState<MultipleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.adminEditUserData.errorMessageTop,
    left: webpageStyle.content.mainContent.adminEditUserData.errorMessageLeft,
    height: webpageStyle.content.mainContent.adminEditUserData.errorMessageHeight,
    width: webpageStyle.content.mainContent.adminEditUserData.errorMessageWidth,
    fontSize: webpageStyle.content.mainContent.adminEditUserData.errorMessageFontSize,
    color: webpageStyle.content.mainContent.adminEditUserData.errorMessageColor
  });

  const [statusMessagePrototypeDisplayParameters, setStatusMessagePrototypeDisplayParameters] = useState<MultipleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.adminEditUserData.statusMessageTop,
    left: webpageStyle.content.mainContent.adminEditUserData.statusMessageLeft,
    height: webpageStyle.content.mainContent.adminEditUserData.statusMessageHeight,
    width: webpageStyle.content.mainContent.adminEditUserData.statusMessageWidth,
    fontSize: webpageStyle.content.mainContent.adminEditUserData.statusMessageFontSize,
    color: webpageStyle.content.mainContent.adminEditUserData.statusMessageColor
  });


  const saveInitialUserData = (userVariable: Array<UserData>) => {
    setUserData(userVariable);
  }

  const readUserData = async () => {
    if(selectedUserId[0] !== 0) {
      const result = await getData(`/api/users/single/read/admin/${ selectedUserId }`, true);
      if( !result || ( result as { status: number, message: string } )?.status >= 400 ) {
        if(result !== undefined) {
          if(result.message !== undefined) {
            console.log(result.message);
            setIsLoading(false);
          }
        }
      } else {
        //

        // console.log(result);

        if(result.length !== 0) {
          saveInitialUserData(result);
        }

        setIsLoading(false);
      }
    }
  }

  const testIfAnythingHasChanged = () => {
    let result: boolean = false;

    if(name[0] !== initialName[0]) {
      result = true;
    }

    if(email2[0] !== initialEmail2[0]) {
      result = true;
    }

    if(telephoneNumber1[0] !== initialTelephoneNumber1[0]) {
      result = true;
    }

    if(telephoneNumber2[0] !== initialTelephoneNumber2[0]) {
      result = true;
    }

    if(language1[0] !== initialLanguage1[0]) {
      result = true;
    }

    if(language2[0] !== initialLanguage2[0]) {
      result = true;
    }

    if(shipmentContinent[0] !== initialShipmentContinent[0]) {
      result = true;
    }

    if(shipmentCountry[0] !== initialShipmentCountry[0]) {
      result = true;
    }

    if(shipmentState[0] !== initialShipmentState[0]) {
      result = true;
    }

    if(shipmentZipCode[0] !== initialShipmentZipCode[0]) {
      result = true;
    }

    if(shipmentCity[0] !== initialShipmentCity[0]) {
      result = true;
    }

    if(shipmentNameOfPublicArea[0] !== initialShipmentNameOfPublicArea[0]) {
      result = true;
    }

    if(shipmentTypeOfPublicArea[0] !== initialShipmentTypeOfPublicArea[0]) {
      result = true;
    }

    if(shipmentHouseNumber[0] !== initialShipmentHouseNumber[0]) {
      result = true;
    }

    if(shipmentFloor[0] !== initialShipmentFloor[0]) {
      result = true;
    }

    if(shipmentDoor[0] !== initialShipmentDoor[0]) {
      result = true;
    }

    if(invoicingContinent[0] !== initialInvoicingContinent[0]) {
      result = true;
    }

    if(invoicingCountry[0] !== initialInvoicingCountry[0]) {
      result = true;
    }

    if(invoicingState[0] !== initialInvoicingState[0]) {
      result = true;
    }

    if(invoicingZipCode[0] !== initialInvoicingZipCode[0]) {
      result = true;
    }

    if(invoicingCity[0] !== initialInvoicingCity[0]) {
      result = true;
    }

    if(invoicingNameOfPublicArea[0] !== initialInvoicingNameOfPublicArea[0]) {
      result = true;
    }

    if(invoicingTypeOfPublicArea[0] !== initialInvoicingTypeOfPublicArea[0]) {
      result = true;
    }

    if(invoicingHouseNumber[0] !== initialInvoicingHouseNumber[0]) {
      result = true;
    }

    if(invoicingFloor[0] !== initialInvoicingFloor[0]) {
      result = true;
    }

    if(invoicingDoor[0] !== initialInvoicingDoor[0]) {
      result = true;
    }

    if(bankName[0] !== initialBankName[0]) {
      result = true;
    }

    if(bankAccountNumber[0] !== initialBankAccountNumber[0]) {
      result = true;
    }

    setSomethingHasChanged(result);
  }

  const saveUserData = async () => {
    //

    const test: boolean = testConditions();

    if(test === true) {
      const editUserDataRequest: EditUserDataRequest = {
        name: name.length!== 0 ? name[0] : "",
        email2: email2.length !== 0 ? email2[0] : "",
        telephoneNumber1: telephoneNumber1.length !== 0 ? telephoneNumber1[0] : "",
        telephoneNumber2: telephoneNumber2.length !== 0 ? telephoneNumber2[0] : "",
        language1: language1.length !== 0 ? language1[0] : "",
        language2: language2.length !== 0 ? language2[0] : "",
        shipmentContinent: shipmentContinent.length !== 0 ? shipmentContinent[0] : "",
        shipmentCountry: shipmentCountry.length !== 0 ? shipmentCountry[0] : "",
        shipmentState: shipmentState.length !== 0 ? shipmentState[0] : "",
        shipmentZipCode: shipmentZipCode.length !== 0 ? shipmentZipCode[0] : "",
        shipmentCity: shipmentCity.length !== 0 ? shipmentCity[0] : "",
        shipmentNameOfPublicArea: shipmentNameOfPublicArea.length !== 0 ? shipmentNameOfPublicArea[0] : "",
        shipmentTypeOfPublicArea: shipmentTypeOfPublicArea.length !== 0 ? shipmentTypeOfPublicArea[0] : "",
        shipmentHouseNumber: shipmentHouseNumber.length !== 0 ? shipmentHouseNumber[0] : "",
        shipmentFloor: shipmentFloor.length !== 0 ? shipmentFloor[0] : "",
        shipmentDoor: shipmentDoor.length !== 0 ? shipmentDoor[0] : "",
        invoicingContinent: invoicingContinent.length !== 0 ? invoicingContinent[0] : "",
        invoicingCountry: invoicingCountry.length !== 0 ? invoicingCountry[0] : "",
        invoicingState: invoicingState.length !== 0 ? invoicingState[0] : "",
        invoicingZipCode: invoicingZipCode.length !== 0 ? invoicingZipCode[0] : "",
        invoicingCity: invoicingCity.length !== 0 ? invoicingCity[0] : "",
        invoicingNameOfPublicArea: invoicingNameOfPublicArea.length !== 0 ? invoicingNameOfPublicArea[0] : "",
        invoicingTypeOfPublicArea: invoicingTypeOfPublicArea.length !== 0 ? invoicingTypeOfPublicArea[0] : "",
        invoicingHouseNumber: invoicingHouseNumber.length !== 0 ? invoicingHouseNumber[0] : "",
        invoicingFloor: invoicingFloor.length !== 0 ? invoicingFloor[0] : "",
        invoicingDoor: invoicingDoor.length !== 0 ? invoicingDoor[0] : "",
        bankName: bankName.length !== 0 ? bankName[0] : "",
        bankAccountNumber: bankAccountNumber.length !== 0 ? bankAccountNumber[0] : ""
      }
      const editUserDataResult = await putData(`/api/users/single/edit/admin/${ selectedUserId }`, editUserDataRequest, true);
      if (!editUserDataResult || (editUserDataResult as { status: number, message: string })?.status >= 400) {
        setErrorMessage(editUserDataResult.message);
      } else {
        setErrorMessage("");
        setStatusMessage("User data has been edited.");

        readUserData();
        resetUsersData[0]();
        
        testIfAnythingHasChanged();
      }
    }

    // ide, hogy mentse el

    // majd vagy tovább az adatlapra, vagy itt marad, de az initial értékeket felülírja
  }

  const testConditions = (): boolean => {
    //

    if(email2.length !== 0) {
      if(email2[0] === userData[0].email) {
        setErrorMessage("The secondary email equals to the primer email.");
          return(false);
      }
      const testEmail = emailInputTest(email2);
      if(testEmail.length !== 0) {
        if(testEmail[0] !== true) {
          setErrorMessage("The secondary email is not a valid email address.");
          return(false);
        }
      }
    }

    setErrorMessage("");
    return(true);
  }

  const resetFields = () => {
    setName(initialName);
    setEmail2(initialEmail2);
    setTelephoneNumber1(initialTelephoneNumber1);
    setTelephoneNumber2(initialTelephoneNumber2);
    setLanguage1(initialLanguage1);
    setLanguage2(initialLanguage2);
    setShipmentContinent(initialShipmentContinent);
    setShipmentCountry(initialShipmentCountry);
    setShipmentState(initialShipmentState);
    setShipmentZipCode(initialShipmentZipCode);
    setShipmentCity(initialShipmentCity);
    setShipmentNameOfPublicArea(initialShipmentNameOfPublicArea);
    setShipmentTypeOfPublicArea(initialShipmentTypeOfPublicArea);
    setShipmentHouseNumber(initialShipmentHouseNumber);
    setShipmentFloor(initialShipmentFloor);
    setShipmentDoor(initialShipmentDoor);
    setInvoicingContinent(initialInvoicingContinent);
    setInvoicingCountry(initialInvoicingCountry);
    setInvoicingState(initialInvoicingState);
    setInvoicingZipCode(initialInvoicingZipCode);
    setInvoicingCity(initialInvoicingCity);
    setInvoicingNameOfPublicArea(initialInvoicingNameOfPublicArea);
    setInvoicingTypeOfPublicArea(initialInvoicingTypeOfPublicArea);
    setInvoicingHouseNumber(initialInvoicingHouseNumber);
    setInvoicingFloor(initialInvoicingFloor);
    setInvoicingDoor(initialInvoicingDoor);
    setBankName(initialBankName);
    setBankAccountNumber(initialBankAccountNumber);
    setErrorMessage("");
    setStatusMessage("");
  }


  useEffect(() => {
    resizeMainContentHeight[0](boxHeight);
  }, []);

  useEffect(() => {
    readUserData();
  }, []);

  useEffect(() => {
    if(userData.length !== 0) {
      setInitialName(userData[0].name !== null && userData[0].name !== "" ? [userData[0].name] : []);
      setName(userData[0].name !== null && userData[0].name !== "" ? [userData[0].name] : []);
      setInitialEmail2(userData[0].email2 !== null && userData[0].email2 !== "" ? [userData[0].email2] : []);
      setEmail2(userData[0].email2 !== null && userData[0].email2 !== "" ? [userData[0].email2] : []);
      setInitialTelephoneNumber1(userData[0].telephoneNumber1 !== null && userData[0].telephoneNumber1 !== "" ? [userData[0].telephoneNumber1] : []);
      setTelephoneNumber1(userData[0].telephoneNumber1 !== null && userData[0].telephoneNumber1 !== "" ? [userData[0].telephoneNumber1] : []);
      setInitialTelephoneNumber2(userData[0].telephoneNumber2 !== null && userData[0].telephoneNumber2 !== "" ? [userData[0].telephoneNumber2] : []);
      setTelephoneNumber2(userData[0].telephoneNumber2 !== null && userData[0].telephoneNumber2 !== "" ? [userData[0].telephoneNumber2] : []);
      setInitialLanguage1(userData[0].language1 !== null && userData[0].language1 !== "" ? [userData[0].language1] : []);
      setLanguage1(userData[0].language1 !== null && userData[0].language1 !== "" ? [userData[0].language1] : []);
      setInitialLanguage2(userData[0].language2 !== null && userData[0].language2 !== "" ? [userData[0].language2] : []);
      setLanguage2(userData[0].language2 !== null && userData[0].language2 !== "" ? [userData[0].language2] : []);
      setInitialShipmentContinent(userData[0].shipmentContinent !== null && userData[0].shipmentContinent !== "" ? [userData[0].shipmentContinent] : []);
      setShipmentContinent(userData[0].shipmentContinent !== null && userData[0].shipmentContinent !== "" ? [userData[0].shipmentContinent] : []);
      setInitialShipmentCountry(userData[0].shipmentCountry !== null && userData[0].shipmentCountry !== "" ? [userData[0].shipmentCountry] : []);
      setShipmentCountry(userData[0].shipmentCountry !== null && userData[0].shipmentCountry !== "" ? [userData[0].shipmentCountry] : []);
      setInitialShipmentState(userData[0].shipmentState !== null && userData[0].shipmentState !== "" ? [userData[0].shipmentState] : []);
      setShipmentState(userData[0].shipmentState !== null && userData[0].shipmentState !== "" ? [userData[0].shipmentState] : []);
      setInitialShipmentZipCode(userData[0].shipmentZipCode !== null && userData[0].shipmentZipCode !== "" ? [userData[0].shipmentZipCode] : []);
      setShipmentZipCode(userData[0].shipmentZipCode !== null && userData[0].shipmentZipCode !== "" ? [userData[0].shipmentZipCode] : []);
      setInitialShipmentCity(userData[0].shipmentCity !== null && userData[0].shipmentCity !== "" ? [userData[0].shipmentCity] : []);
      setShipmentCity(userData[0].shipmentCity !== null && userData[0].shipmentCity !== "" ? [userData[0].shipmentCity] : []);
      setInitialShipmentNameOfPublicArea(userData[0].shipmentNameOfPublicArea !== null && userData[0].shipmentNameOfPublicArea !== "" ? [userData[0].shipmentNameOfPublicArea] : []);
      setShipmentNameOfPublicArea(userData[0].shipmentNameOfPublicArea !== null && userData[0].shipmentNameOfPublicArea !== "" ? [userData[0].shipmentNameOfPublicArea] : []);
      setInitialShipmentTypeOfPublicArea(userData[0].shipmentTypeOfPublicArea !== null && userData[0].shipmentTypeOfPublicArea !== "" ? [userData[0].shipmentTypeOfPublicArea] : []);
      setShipmentTypeOfPublicArea(userData[0].shipmentTypeOfPublicArea !== null && userData[0].shipmentTypeOfPublicArea !== "" ? [userData[0].shipmentTypeOfPublicArea] : []);
      setInitialShipmentHouseNumber(userData[0].shipmentHouseNumber !== null && userData[0].shipmentHouseNumber !== "" ? [userData[0].shipmentHouseNumber] : []);
      setShipmentHouseNumber(userData[0].shipmentHouseNumber !== null && userData[0].shipmentHouseNumber !== "" ? [userData[0].shipmentHouseNumber] : []);
      setInitialShipmentFloor(userData[0].shipmentFloor !== null && userData[0].shipmentFloor !== "" ? [userData[0].shipmentFloor] : []);
      setShipmentFloor(userData[0].shipmentFloor !== null && userData[0].shipmentFloor !== "" ? [userData[0].shipmentFloor] : []);
      setInitialShipmentDoor(userData[0].shipmentDoor !== null && userData[0].shipmentDoor !== "" ? [userData[0].shipmentDoor] : []);
      setShipmentDoor(userData[0].shipmentDoor !== null && userData[0].shipmentDoor !== "" ? [userData[0].shipmentDoor] : []);
      setInitialInvoicingContinent(userData[0].invoicingContinent !== null && userData[0].invoicingContinent !== "" ? [userData[0].invoicingContinent] : []);
      setInvoicingContinent(userData[0].invoicingContinent !== null && userData[0].invoicingContinent !== "" ? [userData[0].invoicingContinent] : []);
      setInitialInvoicingCountry(userData[0].invoicingCountry !== null && userData[0].invoicingCountry !== "" ? [userData[0].invoicingCountry] : []);
      setInvoicingCountry(userData[0].invoicingCountry !== null && userData[0].invoicingCountry !== "" ? [userData[0].invoicingCountry] : []);
      setInitialInvoicingState(userData[0].invoicingState !== null && userData[0].invoicingState !== "" ? [userData[0].invoicingState] : []);
      setInvoicingState(userData[0].invoicingState !== null && userData[0].invoicingState !== "" ? [userData[0].invoicingState] : []);
      setInitialInvoicingZipCode(userData[0].invoicingZipCode !== null && userData[0].invoicingZipCode !== "" ? [userData[0].invoicingZipCode] : []);
      setInvoicingZipCode(userData[0].invoicingZipCode !== null && userData[0].invoicingZipCode !== "" ? [userData[0].invoicingZipCode] : []);
      setInitialInvoicingCity(userData[0].invoicingCity !== null && userData[0].invoicingCity !== "" ? [userData[0].invoicingCity] : []);
      setInvoicingCity(userData[0].invoicingCity !== null && userData[0].invoicingCity !== "" ? [userData[0].invoicingCity] : []);
      setInitialInvoicingNameOfPublicArea(userData[0].invoicingNameOfPublicArea !== null && userData[0].invoicingNameOfPublicArea !== "" ? [userData[0].invoicingNameOfPublicArea] : []);
      setInvoicingNameOfPublicArea(userData[0].invoicingNameOfPublicArea !== null && userData[0].invoicingNameOfPublicArea !== "" ? [userData[0].invoicingNameOfPublicArea] : []);
      setInitialInvoicingTypeOfPublicArea(userData[0].invoicingTypeOfPublicArea !== null && userData[0].invoicingTypeOfPublicArea !== "" ? [userData[0].invoicingTypeOfPublicArea] : []);
      setInvoicingTypeOfPublicArea(userData[0].invoicingTypeOfPublicArea !== null && userData[0].invoicingTypeOfPublicArea !== "" ? [userData[0].invoicingTypeOfPublicArea] : []);
      setInitialInvoicingHouseNumber(userData[0].invoicingHouseNumber !== null && userData[0].invoicingHouseNumber !== "" ? [userData[0].invoicingHouseNumber] : []);
      setInvoicingHouseNumber(userData[0].invoicingHouseNumber !== null && userData[0].invoicingHouseNumber !== "" ? [userData[0].invoicingHouseNumber] : []);
      setInitialInvoicingFloor(userData[0].invoicingFloor !== null && userData[0].invoicingFloor !== "" ? [userData[0].invoicingFloor] : []);
      setInvoicingFloor(userData[0].invoicingFloor !== null && userData[0].invoicingFloor !== "" ? [userData[0].invoicingFloor] : []);
      setInitialInvoicingDoor(userData[0].invoicingDoor !== null && userData[0].invoicingDoor !== "" ? [userData[0].invoicingDoor] : []);
      setInvoicingDoor(userData[0].invoicingDoor !== null && userData[0].invoicingDoor !== "" ? [userData[0].invoicingDoor] : []);
      setInitialBankName(userData[0].bankName !== null && userData[0].bankName !== "" ? [userData[0].bankName] : []);
      setBankName(userData[0].bankName !== null && userData[0].bankName !== "" ? [userData[0].bankName] : []);
      setInitialBankAccountNumber(userData[0].bankAccountNumber !== null && userData[0].bankAccountNumber !== "" ? [userData[0].bankAccountNumber] : []);
      setBankAccountNumber(userData[0].bankAccountNumber !== null && userData[0].bankAccountNumber !== "" ? [userData[0].bankAccountNumber] : []);
    }
  }, [userData]);

  useEffect(() => {
    testIfAnythingHasChanged();
  }, [name, email2, telephoneNumber1, telephoneNumber2, language1, language2, shipmentContinent, shipmentCountry, shipmentState,
    shipmentZipCode, shipmentCity, shipmentNameOfPublicArea, shipmentTypeOfPublicArea, shipmentHouseNumber, shipmentFloor,
    shipmentDoor, invoicingContinent, invoicingCountry, invoicingState, invoicingZipCode, invoicingCity,
    invoicingNameOfPublicArea, invoicingTypeOfPublicArea, invoicingHouseNumber, invoicingFloor, invoicingDoor,
    bankName, bankAccountNumber]);


  if(isLoading !== true) {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      >
        <KeyValueDisplay
          keyText = { "Id" }
          valueText = { userData[0].id.toString() }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 0 * inputTopDistance,
            valueTop: firstInputTop + 0 * inputTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Email" }
          valueText = { userData[0].email }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 2 * inputTopDistance,
            valueTop: firstInputTop + 2 * inputTopDistance
          } }
        />

        <KeyValueInput
          keyText = { "Name" }
          initialValueString = { name }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setName, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 3 * inputTopDistance,
            fieldTop: firstInputTop + 3 * inputTopDistance
          } }
        />

        { (name[0] !== initialName[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 3 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Email 2" }
          initialValueString = { email2 }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setEmail2, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 4 * inputTopDistance,
            fieldTop: firstInputTop + 4 * inputTopDistance
          } }
        />

        { (email2[0] !== initialEmail2[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 4 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Telephone number 1" }
          initialValueString = { telephoneNumber1 }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setTelephoneNumber1, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 5 * inputTopDistance,
            fieldTop: firstInputTop + 5 * inputTopDistance
          } }
        />

        { (telephoneNumber1[0] !== initialTelephoneNumber1[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 5 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Telephone number 2" }
          initialValueString = { telephoneNumber2 }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setTelephoneNumber2, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 6 * inputTopDistance,
            fieldTop: firstInputTop + 6 * inputTopDistance
          } }
        />

        { (telephoneNumber2[0] !== initialTelephoneNumber2[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 6 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Language 1" }
          initialValueString = { language1 }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setLanguage1, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 7 * inputTopDistance,
            fieldTop: firstInputTop + 7 * inputTopDistance
          } }
        />

        { (language1[0] !== initialLanguage1[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 7 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Language 2" }
          initialValueString = { language2 }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setLanguage2, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 8 * inputTopDistance,
            fieldTop: firstInputTop + 8 * inputTopDistance
          } }
        />

        { (language2[0] !== initialLanguage2[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 8 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Shipment continent" }
          initialValueString = { shipmentContinent }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setShipmentContinent, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 10 * inputTopDistance,
            fieldTop: firstInputTop + 10 * inputTopDistance
          } }
        />

        { (shipmentContinent[0] !== initialShipmentContinent[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 10 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Shipment country" }
          initialValueString = { shipmentCountry }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setShipmentCountry, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 11 * inputTopDistance,
            fieldTop: firstInputTop + 11 * inputTopDistance
          } }
        />

        { (shipmentCountry[0] !== initialShipmentCountry[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 11 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Shipment state" }
          initialValueString = { shipmentState }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setShipmentState, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 12 * inputTopDistance,
            fieldTop: firstInputTop + 12 * inputTopDistance
          } }
        />

        { (shipmentState[0] !== initialShipmentState[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 12 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Shipment ZIP code" }
          initialValueString = { shipmentZipCode }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setShipmentZipCode, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 13 * inputTopDistance,
            fieldTop: firstInputTop + 13 * inputTopDistance
          } }
        />

        { (shipmentZipCode[0] !== initialShipmentZipCode[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 13 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Shipment city" }
          initialValueString = { shipmentCity }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setShipmentCity, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 14 * inputTopDistance,
            fieldTop: firstInputTop + 14 * inputTopDistance
          } }
        />

        { (shipmentCity[0] !== initialShipmentCity[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 14 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Shipment name of public area" }
          initialValueString = { shipmentNameOfPublicArea }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setShipmentNameOfPublicArea, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 15 * inputTopDistance,
            fieldTop: firstInputTop + 15 * inputTopDistance
          } }
        />

        { (shipmentNameOfPublicArea[0] !== initialShipmentNameOfPublicArea[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 15 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Shipment type of public area" }
          initialValueString = { shipmentTypeOfPublicArea }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setShipmentTypeOfPublicArea, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 16 * inputTopDistance,
            fieldTop: firstInputTop + 16 * inputTopDistance
          } }
        />

        { (shipmentTypeOfPublicArea[0] !== initialShipmentTypeOfPublicArea[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 16 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Shipment house number" }
          initialValueString = { shipmentHouseNumber }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setShipmentHouseNumber, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 17 * inputTopDistance,
            fieldTop: firstInputTop + 17 * inputTopDistance
          } }
        />

        { (shipmentHouseNumber[0] !== initialShipmentHouseNumber[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 17 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Shipment floor" }
          initialValueString = { shipmentFloor }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setShipmentFloor, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 18 * inputTopDistance,
            fieldTop: firstInputTop + 18 * inputTopDistance
          } }
        />

        { (shipmentFloor[0] !== initialShipmentFloor[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 18 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Shipment door" }
          initialValueString = { shipmentDoor }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setShipmentDoor, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 19 * inputTopDistance,
            fieldTop: firstInputTop + 19 * inputTopDistance
          } }
        />

        { (shipmentDoor[0] !== initialShipmentDoor[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 19 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Invoicing continent" }
          initialValueString = { invoicingContinent }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setInvoicingContinent, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 21 * inputTopDistance,
            fieldTop: firstInputTop + 21 * inputTopDistance
          } }
        />

        { (invoicingContinent[0] !== initialInvoicingContinent[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 21 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Invoicing country" }
          initialValueString = { invoicingCountry }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setInvoicingCountry, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 22 * inputTopDistance,
            fieldTop: firstInputTop + 22 * inputTopDistance
          } }
        />

        { (invoicingCountry[0] !== initialInvoicingCountry[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 22 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Invoicing state" }
          initialValueString = { invoicingState }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setInvoicingState, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 23 * inputTopDistance,
            fieldTop: firstInputTop + 23 * inputTopDistance
          } }
        />

        { (invoicingState[0] !== initialInvoicingState[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 23 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Invoicing ZIP code" }
          initialValueString = { invoicingZipCode }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setInvoicingZipCode, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 24 * inputTopDistance,
            fieldTop: firstInputTop + 24 * inputTopDistance
          } }
        />

        { (invoicingZipCode[0] !== initialInvoicingZipCode[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 24 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Invoicing city" }
          initialValueString = { invoicingCity }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setInvoicingCity, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 25 * inputTopDistance,
            fieldTop: firstInputTop + 25 * inputTopDistance
          } }
        />

        { (invoicingCity[0] !== initialInvoicingCity[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 25 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Invoicing name of public area" }
          initialValueString = { invoicingNameOfPublicArea }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setInvoicingNameOfPublicArea, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 26 * inputTopDistance,
            fieldTop: firstInputTop + 26 * inputTopDistance
          } }
        />

        { (invoicingNameOfPublicArea[0] !== initialInvoicingNameOfPublicArea[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 26 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Invoicing type of public area" }
          initialValueString = { invoicingTypeOfPublicArea }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setInvoicingTypeOfPublicArea, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 27 * inputTopDistance,
            fieldTop: firstInputTop + 27 * inputTopDistance
          } }
        />

        { (invoicingTypeOfPublicArea[0] !== initialInvoicingTypeOfPublicArea[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 27 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Invoicing house number" }
          initialValueString = { invoicingHouseNumber }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setInvoicingHouseNumber, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 28 * inputTopDistance,
            fieldTop: firstInputTop + 28 * inputTopDistance
          } }
        />

        { (invoicingHouseNumber[0] !== initialInvoicingHouseNumber[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 28 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Invoicing floor" }
          initialValueString = { invoicingFloor }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setInvoicingFloor, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 29 * inputTopDistance,
            fieldTop: firstInputTop + 29 * inputTopDistance
          } }
        />

        { (invoicingFloor[0] !== initialInvoicingFloor[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 29 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Invoicing door" }
          initialValueString = { invoicingDoor }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setInvoicingDoor, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 30 * inputTopDistance,
            fieldTop: firstInputTop + 30 * inputTopDistance
          } }
        />

        { (invoicingDoor[0] !== initialInvoicingDoor[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 30 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Bank name" }
          initialValueString = { bankName }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setBankName, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 32 * inputTopDistance,
            fieldTop: firstInputTop + 32 * inputTopDistance
          } }
        />

        { (bankName[0] !== initialBankName[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 32 * inputTopDistance
            } }
          /> : <div/>
        }

        <KeyValueInput
          keyText = { "Bank account number" }
          initialValueString = { bankAccountNumber }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setBankAccountNumber, () => {
            setErrorMessage("");
            setStatusMessage("");
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 33 * inputTopDistance,
            fieldTop: firstInputTop + 33 * inputTopDistance
          } }
        />

        { (bankAccountNumber[0] !== initialBankAccountNumber[0]) ?
          <SingleRowText
            text = { changedValueMessage }
            prototypeDisplayParameters = { changingMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 33 * inputTopDistance
            } }
          /> : <div/>
        }

        {/* <KeyValueInput
          keyText = { "" } //*
          initialValueString = {  } //*
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [] } //*
          properInputDisplay = { false }
          conditions = { [] }
          conditionTestFunctions = { [] }
          conditionResultFunctions = { [] }
          hidden = { [] }
          disabled = { false }
          keyTop = { firstInputTop + _ * inputTopDistance } //*
          keyLeft = { keyLeft }
          keyWidth = { keyWidth }
          keyFontSize = { keyFontSize }
          keyColor = {keyColor }
          fieldTop = { firstInputTop + _ * inputTopDistance } //*
          fieldLeft = { fieldLeft }
          fieldHeight = { fieldHeight }
          fieldWidth = { fieldWidth }
          fieldBackgroundColor = { fieldBackgroundColor }
          valueFontSize = { valueFontSize }
          valueColor = { valueColor }
          properInputIndicatorTop = { [] }
          properInputIndicatorLeft = { [] }
          properInputIndicatorSize = { [] }
        />

        { () ? //*
          <SingleRowText
            text = { changedValueMessage }
            redirectionUrl = { [] }
            message = { [] }
            top = { firstInputTop + _ * inputTopDistance } //*
            left = { changingMessageLeft }
            width = { changingMessageWidth }
            fontSize = { changingMessageFontSize }
            color = { changingMessageColor }
          /> : <div/>
        } */}

        <Button
          buttonText = { "Save" }
          actionFunctions = { [saveUserData] }
          disabled = { somethingHasChanged !== false ? false : true }
          prototypeDisplayParameters = { saveButtonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            backgroundColor: somethingHasChanged !== false ? saveButtonBackgroundColorActive : saveButtonBackgroundColorInactive,
            contentColor: somethingHasChanged !== false ? saveButtonContentColorActive : saveButtonContentColorInactive
          } }
        />

        <Button
          buttonText = { "Reset" }
          actionFunctions = { [resetFields] }
          disabled = { somethingHasChanged !== false ? false : true }
          prototypeDisplayParameters = { resetButtonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            backgroundColor: somethingHasChanged !== false ? resetButtonBackgroundColorActive : resetButtonBackgroundColorInactive,
            contentColor: somethingHasChanged !== false ? resetButtonContentColorActive : resetButtonContentColorInactive
          } }
        />

        { errorMessage.length !== 0 ?
          <MultipleRowText
            text = { errorMessage }
            prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
          /> : <div/>
        }
        
        { statusMessage.length !== 0 ?
          <MultipleRowText
            text = { statusMessage }
            prototypeDisplayParameters = { statusMessagePrototypeDisplayParameters }
          /> : <div/>
        }
      </Box>
    )
  } else {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      >
        <div>
          Selected user is loading...
        </div>
      </Box>
    )
  }
}
