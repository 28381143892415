import React, { FC, useEffect, useState, ChangeEvent } from "react";
import { WebpageStyleProps } from "../../../../../services/webpageStyle";
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from "../../../../03_baseComponents/Box";
import { KeyValueInput, KeyValueInputPrototypeDisplayParameters, KeyValueInputChangedDisplayParameters } from "../../../../03_baseComponents/KeyValueInput";
import { SingleRowText, SingleRowPrototypeDisplayParameters, SingleRowChangedDisplayParameters } from '../../../../03_baseComponents/SingleRowText';
import { KeyValueDisplay, KeyValueDisplayPrototypeDisplayParameters, KeyValueDisplayChangedDisplayParameters } from '../../../../03_baseComponents/KeyValueDisplay';





export interface DataFormData {
  horseId: Array<number>,
  name: Array<string>,
  stud: Array<string>,
  birthYear: Array<number>,
  sex: Array<string>,
  origin: Array<string>,
  horseBreed: Array<string>,
  color: Array<string>,
  description: Array<string>,
  horseTelexId: Array<number>,
  dijugratasId: Array<number>,
  tradingCategory: Array<string>,
  priceCategory: Array<string>,
  breedingCategory: Array<string>,
  accessibility: Array<string>
}

export interface AdminCreateAndEditHorseDataFormProps {
  webpageStyle: WebpageStyleProps,
  existingData?: DataFormData,
  updateDataForm: Array<any>,
  showErrorMessages: boolean
}





export const AdminCreateAndEditHorseDataForm: FC<AdminCreateAndEditHorseDataFormProps> = ({ webpageStyle,
  existingData, updateDataForm, showErrorMessages }) => {

  const [horseId, setHorseId] = useState<Array<string>>([]);
  const [name, setName] = useState<Array<string>>([]);
  const [stud, setStud] = useState<Array<string>>([]);
  const [birthYear, setBirthYear] = useState<Array<number>>([]);
  const [sex, setSex] = useState<Array<string>>([]);
  const [origin, setOrigin] = useState<Array<string>>([]);
  const [horseBreed, setHorseBreed] = useState<Array<string>>([]);
  const [color, setColor] = useState<Array<string>>([]);
  const [description, setDescription] = useState<Array<string>>([]);
  const [horseTelexId, setHorseTelexId] = useState<Array<number>>([]);
  const [dijugratasId, setDijugratasId] = useState<Array<number>>([]);
  const [tradingCategory, setTradingCategory] = useState<Array<string>>([]);
  const [priceCategory, setPriceCategory] = useState<Array<string>>([]);
  const [breedingCategory, setBreedingCategory] = useState<Array<string>>([]);
  const [accessibility, setAccessibility] = useState<Array<string>>([]);

  const [sexOptions, setSexOptions] = useState<Array<string>>([" ", "stallion", "mare", "gelding"]);
  const [colorOptions, setColorOptions] = useState<Array<string>>([" ", "sorrel", "dapple grey", "bay", "blue dun", "grey", "flaxen chestnut", "skew bald", "blue eyed cream", "black", "dun", "liver chestnut", "red roan", "palomino", "appaloosa", "pie bald", "buckskin"]);
  const [tradingCategoryOptions, setTradingCategoryOptions] = useState<Array<string>>([" ", "for sale", "sold", "not for sale", "owned by others"]);
  const [priceCategoryOptions, setPriceCategoryOptions] = useState<Array<string>>([" ", "N/A", "0", "1-5000", "5001-10000", "10001-15000", "15001-20000", "20001-"]);
  const [breedingCategoryOptions, setBreedingCategoryOptions] = useState<Array<string>>([" ", "broodstallion", "broodmare", "not a breeder", "no information"]);
  const [accessibilityOptions, setAccessibilityOptions] = useState<Array<string>>([" ", "public", "private"]);

  // const [nameOk, setNameOk] = useState<boolean>(false);
  // const [studOk, setStudOk] = useState<boolean>(false);
  // const [birthYearOk, setBirthYearOk] = useState<boolean>(false);
  // const [sexOk, setSexOk] = useState<boolean>(false);
  // const [originOk, setOriginOk] = useState<boolean>(false);
  // const [horseBreedOk, setHorseBreedOk] = useState<boolean>(false);
  // const [colorOk, setColorOk] = useState<boolean>(false);
  // const [descriptionOk, setDescriptionOk] = useState<boolean>(false);
  // const [horseTelexIdOk, setHorseTelexIdOk] = useState<boolean>(false);
  // const [dijugratasIdOk, setDijugratasIdOk] = useState<boolean>(false);
  // const [tradingCategoryOk, setTradingCategoryOk] = useState<boolean>(false);
  // const [priceCategoryOk, setPriceCategoryOk] = useState<boolean>(false);
  // const [breedingCategoryOk, setBreedingCategoryOk] = useState<boolean>(false);
  // const [accessibilityOk, setAccessibilityOk] = useState<boolean>(false);

  const [nameErrorMessage, setNameErrorMessage] = useState<Array<string>>(["Missing horse name"]); // ha hiányzik
  const [studErrorMessage, setStudErrorMessage] = useState<Array<string>>(["Missing stud name"]); // ha hiányzik
  const [birthYearErrorMessage, setBirthYearErrorMessage] = useState<Array<string>>(["Missing birth year"]); // ha hiányzik, vagy ha a jövőben született, vagy ha túl régen született
  const [sexErrorMessage, setSexErrorMessage] = useState<Array<string>>(["Missing sex"]); // ha hiányzik
  const [originErrorMessage, setOriginErrorMessage] = useState<Array<string>>(["Missing origin"]); // ha hiányzik
  const [horseBreedErrorMessage, setHorseBreedErrorMessage] = useState<Array<string>>(["Missing horse breed"]); // ha hiányzik
  const [colorErrorMessage, setColorErrorMessage] = useState<Array<string>>(["Missing color"]); // ha hiányzik
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState<Array<string>>([]); // csak ha túl hosszú
  const [horseTelexIdErrorMessage, setHorseTelexIdErrorMessage] = useState<Array<string>>([]); // nem nézzük
  const [dijugratasIdErrorMessage, setDijugratasIdErrorMessage] = useState<Array<string>>([]); // nem nézzük
  const [tradingCategoryErrorMessage, setTradingCategoryErrorMessage] = useState<Array<string>>(["Missing trading category"]); // ha hiányzik
  const [priceCategoryErrorMessage, setPriceCategoryErrorMessage] = useState<Array<string>>(["Missing price category"]); // ha hiányzik
  const [breedingCategoryErrorMessage, setBreedingCategoryErrorMessage] = useState<Array<string>>(["Missing breeding category"]); // ha hiányzik
  const [accessibilityErrorMessage, setAccessibilityErrorMessage] = useState<Array<string>>(["Missing accessibility"]); // ha hiányzik
  

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.adminCreateHorse.dataForm.boxTop,
    left: webpageStyle.content.mainContent.adminCreateHorse.dataForm.boxLeft,
    height: webpageStyle.content.mainContent.adminCreateHorse.dataForm.boxHeight,
    width: webpageStyle.content.mainContent.adminCreateHorse.dataForm.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.adminCreateHorse.dataForm.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.adminCreateHorse.dataForm.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.adminCreateHorse.dataForm.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.adminCreateHorse.dataForm.boxOutlineColor
  });


  const [firstInputTop, setFirstInputTop] = useState<number>(webpageStyle.content.mainContent.adminCreateHorse.dataForm.firstInputTop);
  const [inputTopDistance, setInputTopDistance] = useState<number>(webpageStyle.content.mainContent.adminCreateHorse.dataForm.inputTopDistance);

  const [keyValueDisplayPrototypeDisplayParameters, setKeyValueDisplayPrototypeDisplayParameters] = useState<KeyValueDisplayPrototypeDisplayParameters>({
    keyTop: firstInputTop,
    keyLeft: webpageStyle.content.mainContent.adminCreateHorse.dataForm.keyLeft,
    keyWidth: webpageStyle.content.mainContent.adminCreateHorse.dataForm.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.adminCreateHorse.dataForm.keyFontSize,
    keyColor: webpageStyle.content.mainContent.adminCreateHorse.dataForm.keyColor,
    valueTop: firstInputTop,
    valueLeft: webpageStyle.content.mainContent.adminCreateHorse.dataForm.fieldLeft,
    valueWidth: webpageStyle.content.mainContent.adminCreateHorse.dataForm.fieldWidth,
    valueFontSize: webpageStyle.content.mainContent.adminCreateHorse.dataForm.valueFontSize,
    valueColor: webpageStyle.content.mainContent.adminCreateHorse.dataForm.valueColor
  });

  const [descriptionFieldHeight, setDescriptionFieldHeight] = useState<number>(webpageStyle.content.mainContent.adminCreateHorse.dataForm.descriptionFieldHeight);

  const [keyValueInputPrototypeDisplayParameters, setKeyValueInputPrototypeDisplayParameters] = useState<KeyValueInputPrototypeDisplayParameters>({
    keyTop: firstInputTop,
    keyLeft: webpageStyle.content.mainContent.adminCreateHorse.dataForm.keyLeft,
    keyWidth: webpageStyle.content.mainContent.adminCreateHorse.dataForm.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.adminCreateHorse.dataForm.keyFontSize,
    keyColor: webpageStyle.content.mainContent.adminCreateHorse.dataForm.keyColor,
    fieldTop: firstInputTop,
    fieldLeft: webpageStyle.content.mainContent.adminCreateHorse.dataForm.fieldLeft,
    fieldHeight: webpageStyle.content.mainContent.adminCreateHorse.dataForm.fieldHeight,
    fieldWidth: webpageStyle.content.mainContent.adminCreateHorse.dataForm.fieldWidth,
    fieldBackgroundColor: webpageStyle.content.mainContent.adminCreateHorse.dataForm.fieldBackgroundColor,
    valueFontSize: webpageStyle.content.mainContent.adminCreateHorse.dataForm.valueFontSize,
    valueColor: webpageStyle.content.mainContent.adminCreateHorse.dataForm.valueColor,
  });

  const [errorMessagePrototypeDisplayParameters, setErrorMessagePrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: firstInputTop,
    left: webpageStyle.content.mainContent.adminCreateHorse.dataForm.errorMessageLeft,
    width: webpageStyle.content.mainContent.adminCreateHorse.dataForm.errorMessageWidth,
    fontSize: webpageStyle.content.mainContent.adminCreateHorse.dataForm.errorMessageFontSize,
    color: webpageStyle.content.mainContent.adminCreateHorse.dataForm.errorMessageColor
  });


  // itt kéne tesztelni, úgy hogy kap egy értéket, amire válaszol, vagy mindig küldi, hogy jók-e az értékek (az updateDataForm[0]() függvénnyel)


  // useEffect(() => {
  //   updateDataForm[0](); // kell majd valami bemenet
  // }, [name, stud, birthYear, sex, origin, horseBreed, color, description,
  //   horseTelexId, dijugratasId, tradingCategory, priceCategory, breedingCategory, accessibility]);


  const changeInput = (modifiedValueType: string, newValue: any) => {
    let newErrorMessage: Array<string> = [];
    switch(modifiedValueType) {
      case("name"):
        setName(newValue);
        if(newValue === undefined) {
          newErrorMessage = ["Missing horse name"];
        } else {
          if(newValue.length === 0) {
            newErrorMessage = ["Missing horse name"];
          }
        }
        setNameErrorMessage(newErrorMessage);
        updateDataForm[0]("name", newValue, newErrorMessage);
        break;
      case("stud"):
        setStud(newValue);
        if(newValue === undefined) {
          newErrorMessage = ["Missing stud name"];
        } else {
          if(newValue.length === 0) {
            newErrorMessage = ["Missing stud name"];
          }
        }
        setStudErrorMessage(newErrorMessage);
        updateDataForm[0]("stud", newValue, newErrorMessage);
        break;
      case("birthYear"):
        setBirthYear(newValue);
        if(newValue === undefined) {
          newErrorMessage = ["Missing birth year"];
        } else {
          if(newValue.length === 0) {
            newErrorMessage = ["Missing birth year"];
          } else {
            if(typeof newValue[0] !== "number") {
              newErrorMessage = ["Missing birth year"];
            } else {
              if(newValue[0] < 1970) {
                newErrorMessage = ["Birth year from the past"];
              }
              const currentYear = new Date().getFullYear();
              if(newValue[0] > currentYear) {
                newErrorMessage = ["Birth year from the future"];
              }
            }
          }
        }
        setBirthYearErrorMessage(newErrorMessage);
        updateDataForm[0]("birthYear", newValue, newErrorMessage);
        break;
      case("sex"):
        setSex(newValue);
        if(newValue === undefined) {
          newErrorMessage = ["Missing horse sex"];
        } else {
          if(newValue.length === 0) {
            newErrorMessage = ["Missing horse sex"];
          } else {
            if(newValue[0] === " ") {
              newErrorMessage = ["Missing horse sex"];
            } else {
              if(newValue[0] !== "stallion" && newValue[0] !== "mare" && newValue[0] !== "gelding") {
                newErrorMessage = ["Wrong horse sex"];
              }
            }
          }
        }
        setSexErrorMessage(newErrorMessage);
        updateDataForm[0]("sex", newValue, newErrorMessage);
        break;
      case("origin"):
        setOrigin(newValue);
        if(newValue === undefined) {
          newErrorMessage = ["Missing horse origin"];
        } else {
          if(newValue.length === 0) {
            newErrorMessage = ["Missing horse origin"];
          }
        }
        setOriginErrorMessage(newErrorMessage);
        updateDataForm[0]("origin", newValue, newErrorMessage);
        break;
      case("horseBreed"):
        setHorseBreed(newValue);
        if(newValue === undefined) {
          newErrorMessage = ["Missing horse breed"];
        } else {
          if(newValue.length === 0) {
            newErrorMessage = ["Missing horse breed"];
          }
        }
        setHorseBreedErrorMessage(newErrorMessage);
        updateDataForm[0]("horseBreed", newValue, newErrorMessage);
        break;
      case("color"):
        setColor(newValue);
        if(newValue === undefined) {
          newErrorMessage = ["Missing horse color"];
        } else {
          if(newValue.length === 0) {
            newErrorMessage = ["Missing horse color"];
          } else {
            if(newValue[0] === " ") {
              newErrorMessage = ["Missing horse color"];
            } else {
              if(newValue[0] !== "sorrel" && newValue[0] !== "dapple grey" && newValue[0] !== "bay" && newValue[0] !== "blue dun" && newValue[0] !== "grey" && newValue[0] !== "flaxen chestnut" && newValue[0] !== "skew bald" && newValue[0] !== "blue eyed cream" && newValue[0] !== "black" && newValue[0] !== "dun" && newValue[0] !== "liver chestnut" && newValue[0] !== "red roan" && newValue[0] !== "palomino" && newValue[0] !== "appaloosa" && newValue[0] !== "pie bald" && newValue[0] !== "buckskin") {
                newErrorMessage = ["Wrong horse color"];
              }
            }
          }
        }
        setColorErrorMessage(newErrorMessage);
        updateDataForm[0]("color", newValue, newErrorMessage);
        break;
      case("description"):
        setDescription(newValue);
        if(newValue === undefined) {
          newErrorMessage = ["Missing horse description"];
        } else {
          // if(newValue.length === 0) {
          //   newErrorMessage = ["Missing horse name"];
          // }
          if(newValue.length !== 0) {
            if(newValue[0].length > 1000) {
              newErrorMessage = ["Horse description with more than 1000 characters"];
            }
          }
        }
        setDescriptionErrorMessage(newErrorMessage);
        updateDataForm[0]("description", newValue, newErrorMessage);
        break;
      case("horseTelexId"):
        setHorseTelexId(newValue);
        if(newValue === undefined) {
          newErrorMessage = ["Missing Horse Telex ID"];
        } else {
          // if(newValue.length === 0) {
          //   newErrorMessage = ["Missing Horse Telex ID"];
          // }
        }
        setHorseTelexIdErrorMessage(newErrorMessage);
        updateDataForm[0]("horseTelexId", newValue, newErrorMessage);
        break;
      case("dijugratasId"):
        setDijugratasId(newValue);
        if(newValue === undefined) {
          newErrorMessage = ["Missing Dijugratas ID"];
        } else {
          // if(newValue.length === 0) {
          //   newErrorMessage = ["Missing Dijugratas ID"];
          // }
        }
        setDijugratasIdErrorMessage(newErrorMessage);
        updateDataForm[0]("dijugratasId", newValue, newErrorMessage);
        break;
      case("tradingCategory"):
        setTradingCategory(newValue);
        if(newValue === undefined) {
          newErrorMessage = ["Missing trading category"];
        } else {
          if(newValue.length === 0) {
            newErrorMessage = ["Missing trading category"];
          } else {
            if(newValue[0] === " ") {
              newErrorMessage = ["Missing trading category"];
            } else {
              if(newValue[0] !== "for sale" && newValue[0] !== "sold" && newValue[0] !== "not for sale" && newValue[0] !== "owned by others") {
                newErrorMessage = ["Wrong trading category"];
              }
            }
          }
        }
        setTradingCategoryErrorMessage(newErrorMessage);
        updateDataForm[0]("tradingCategory", newValue, newErrorMessage);
        break;
      case("priceCategory"):
        setPriceCategory(newValue);
        if(newValue === undefined) {
          newErrorMessage = ["Missing price category"];
        } else {
          if(newValue.length === 0) {
            newErrorMessage = ["Missing price category"];
          } else {
            if(newValue[0] === " ") {
              newErrorMessage = ["Missing price category"];
            } else {
              if(newValue[0] !== "N/A" && newValue[0] !== "0" && newValue[0] !== "1-5000" && newValue[0] !== "5001-10000" && newValue[0] !== "10001-15000" && newValue[0] !== "15001-20000" && newValue[0] !== "20001-") {
                newErrorMessage = ["Wrong price category"];
              }
            }
          }
        }
        setPriceCategoryErrorMessage(newErrorMessage);
        updateDataForm[0]("priceCategory", newValue, newErrorMessage);
        break;
      case("breedingCategory"):
        setBreedingCategory(newValue);
        if(newValue === undefined) {
          newErrorMessage = ["Missing breeding category"];
        } else {
          if(newValue.length === 0) {
            newErrorMessage = ["Missing breeding category"];
          } else {
            if(newValue[0] === " ") {
              newErrorMessage = ["Missing breeding category"];
            } else {
              if(newValue[0] !== "broodstallion" && newValue[0] !== "broodmare" && newValue[0] !== "not a breeder" && newValue[0] !== "no information") {
                newErrorMessage = ["Wrong breeding category"];
              }
            }
          }
        }
        setBreedingCategoryErrorMessage(newErrorMessage);
        updateDataForm[0]("breedingCategory", newValue, newErrorMessage);
        break;
      case("accessibility"):
        setAccessibility(newValue);
        if(newValue === undefined) {
          newErrorMessage = ["Missing accessibility"];
        } else {
          if(newValue.length === 0) {
            newErrorMessage = ["Missing accessibility"];
          } else {
            if(newValue[0] === " ") {
              newErrorMessage = ["Missing accessibility"];
            } else {
              if(newValue[0] !== "public" && newValue !== "private") {
                newErrorMessage = ["Wrong accessibility"];
              }
            }
          }
        }
        setAccessibilityErrorMessage(newErrorMessage);
        updateDataForm[0]("accessibility", newValue, newErrorMessage);
        break;
    }
  }

  const testSexBreedingCategoryConflict = () => {
    if(breedingCategory.length !== 0 && breedingCategory[0] !== " " && sex.length !== 0 && sex[0] !== " ") {
      let newSexErrorMessage: Array<string> = [];
      let newBreedingCategoryErrorMessage: Array<string> = [];
      if(sex[0] === "stallion" && breedingCategory[0] === "broodmare") {
        newSexErrorMessage = ["Horse sex - breeding category conflict"];
        newBreedingCategoryErrorMessage = ["Horse sex - breeding category conflict"];
      }
      if(sex[0] === "mare" && breedingCategory[0] === "broodstallion") {
        newSexErrorMessage = ["Horse sex - breeding category conflict"];
        newBreedingCategoryErrorMessage = ["Horse sex - breeding category conflict"];
      }
      if(sex[0] === "gelding" && breedingCategory[0] === "broodmare") {
        newSexErrorMessage = ["Horse sex - breeding category conflict"];
        newBreedingCategoryErrorMessage = ["Horse sex - breeding category conflict"];
      }
      if(sex[0] === "gelding" && breedingCategory[0] === "broodstallion") {
        newSexErrorMessage = ["Horse sex - breeding category conflict"];
        newBreedingCategoryErrorMessage = ["Horse sex - breeding category conflict"];
      }
      if(sex[0] === "gelding" && breedingCategory[0] === "no information") {
        newSexErrorMessage = ["Horse sex - breeding category conflict"];
        newBreedingCategoryErrorMessage = ["Horse sex - breeding category conflict"];
      }
      if(sex[0] === "gelding" && breedingCategory[0] === " ") {
        newSexErrorMessage = ["Horse sex - breeding category conflict"];
        newBreedingCategoryErrorMessage = ["Horse sex - breeding category conflict"];
      }
      if(newSexErrorMessage.length !== 0) {
        setSexErrorMessage(newSexErrorMessage);
        updateDataForm[0]("sex", sex, newSexErrorMessage);
      } else {
        setSexErrorMessage([]);
        updateDataForm[0]("sex", sex, []);
      }
      if(newBreedingCategoryErrorMessage.length !== 0) {
        setBreedingCategoryErrorMessage(newBreedingCategoryErrorMessage);
        updateDataForm[0]("breedingCategory", breedingCategory, newBreedingCategoryErrorMessage);
      } else {
        setBreedingCategoryErrorMessage([]);
        updateDataForm[0]("breedingCategory", breedingCategory, []);
      }
    }
  }


  useEffect(() => {
    if(existingData !== undefined) {
      if(existingData.horseId.length !== null) {
        setHorseId([existingData.horseId[0].toString()]);
      }
      if(existingData.name.length !== null) {
        changeInput("name", existingData.name);
      } else {
        changeInput("name", []);
      }
      if(existingData.stud.length !== null) {
        changeInput("stud", existingData.stud);
      } else {
        changeInput("stud", []);
      }
      if(existingData.birthYear.length !== null) {
        changeInput("birthYear", existingData.birthYear);
      } else {
        changeInput("birthYear", []);
      }
      if(existingData.sex.length !== null) {
        changeInput("sex", existingData.sex);
      } else {
        changeInput("sex", []);
      }
      if(existingData.origin.length !== null) {
        changeInput("origin", existingData.origin);
      } else {
        changeInput("origin", []);
      }
      if(existingData.horseBreed.length !== null) {
        changeInput("horseBreed", existingData.horseBreed);
      } else {
        changeInput("horseBreed", []);
      }
      if(existingData.color.length !== null) {
        changeInput("color", existingData.color);
      } else {
        changeInput("color", []);
      }
      if(existingData.description.length !== null) {
        changeInput("description", existingData.description);
      } else {
        changeInput("description", []);
      }
      if(existingData.horseTelexId.length !== null) {
        changeInput("horseTelexId", existingData.horseTelexId);
      } else {
        changeInput("horseTelexId", []);
      }
      if(existingData.dijugratasId.length !== null) {
        changeInput("dijugratasId", existingData.dijugratasId);
      } else {
        changeInput("dijugratasId", []);
      }
      if(existingData.tradingCategory.length !== null) {
        changeInput("tradingCategory", existingData.tradingCategory);
      } else {
        changeInput("tradingCategory", []);
      }
      if(existingData.priceCategory.length !== null) {
        changeInput("priceCategory", existingData.priceCategory);
      } else {
        changeInput("priceCategory", []);
      }
      if(existingData.breedingCategory.length !== null) {
        changeInput("breedingCategory", existingData.breedingCategory);
      } else {
        changeInput("breedingCategory", []);
      }
      if(existingData.accessibility.length !== null) {
        changeInput("accessibility", existingData.accessibility);
      } else {
        changeInput("accessibility", []);
      }
    } else {
      //
    }
  }, []);

  useEffect(() => {
    testSexBreedingCategoryConflict();
  }, [sex, breedingCategory]);


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <div>
        <KeyValueDisplay
          keyText = { "Horse ID" }
          valueText = { horseId.length !== 0 ? horseId[0] : "" }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 0 * inputTopDistance,
            valueTop: firstInputTop + 0 * inputTopDistance
          } }
        />

        <KeyValueInput
          keyText = { "Name" }
          initialValueString = { name }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [(newValue: any) => {
            changeInput("name", newValue);
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 1 * inputTopDistance,
            fieldTop: firstInputTop + 1 * inputTopDistance
          } }
        />

        { showErrorMessages ?
          <SingleRowText
            text = { nameErrorMessage.length !== 0 ? nameErrorMessage[0] : "" }
            prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 1 * inputTopDistance
            } }
          /> : null
        }

        <KeyValueInput
          keyText = { "Stud" }
          initialValueString = { stud }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [(newValue: any) => {
            changeInput("stud", newValue);
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 2 * inputTopDistance,
            fieldTop: firstInputTop + 2 * inputTopDistance
          } }
        />

        { showErrorMessages ?
          <SingleRowText
            text = { studErrorMessage.length !== 0 ? studErrorMessage[0] : "" }
            prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 2 * inputTopDistance
            } }
          /> : null
        }

        <KeyValueInput
          keyText = { "Birth year" }
          initialValueString = { [] }
          initialValueNumber = { birthYear }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "IntegerInput" }
          modificationFunctions = { [(newValue: any) => {
            changeInput("birthYear", newValue);
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 3 * inputTopDistance,
            fieldTop: firstInputTop + 3 * inputTopDistance
          } }
        />

        { showErrorMessages ?
          <SingleRowText
            text = { birthYearErrorMessage.length !== 0 ? birthYearErrorMessage[0] : "" }
            prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 3 * inputTopDistance
            } }
          /> : null
        }

        <KeyValueInput
          keyText = { "Sex" }
          initialValueString = { sex }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { sexOptions }
          inputType = { "SingleSelectInput" }
          modificationFunctions = { [(newValue: any) => {
            changeInput("sex", newValue);
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 4 * inputTopDistance,
            fieldTop: firstInputTop + 4 * inputTopDistance
          } }
        />

        { showErrorMessages ?
          <SingleRowText
            text = { sexErrorMessage.length !== 0 ? sexErrorMessage[0] : "" }
            prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 4 * inputTopDistance
            } }
          /> : null
        }

        <KeyValueInput
          keyText = { "Origin" }
          initialValueString = { origin }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [(newValue: any) => {
            changeInput("origin", newValue);
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 5 * inputTopDistance,
            fieldTop: firstInputTop + 5 * inputTopDistance
          } }
        />

        { showErrorMessages ?
          <SingleRowText
            text = { originErrorMessage.length !== 0 ? originErrorMessage[0] : "" }
            prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 5 * inputTopDistance
            } }
          /> : null
        }

        <KeyValueInput
          keyText = { "Horse breed" }
          initialValueString = { horseBreed }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [(newValue: any) => {
            changeInput("horseBreed", newValue);
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 6 * inputTopDistance,
            fieldTop: firstInputTop + 6 * inputTopDistance
          } }
        />

        { showErrorMessages ?
          <SingleRowText
            text = { horseBreedErrorMessage.length !== 0 ? horseBreedErrorMessage[0] : "" }
            prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 6 * inputTopDistance
            } }
          /> : null
        }

        <KeyValueInput
          keyText = { "Color" }
          initialValueString = { color }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { colorOptions }
          inputType = { "SingleSelectInput" }
          modificationFunctions = { [(newValue: any) => {
            changeInput("color", newValue);
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 7 * inputTopDistance,
            fieldTop: firstInputTop + 7 * inputTopDistance
          } }
        />

        { showErrorMessages ?
          <SingleRowText
            text = { colorErrorMessage.length !== 0 ? colorErrorMessage[0] : "" }
            prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 7 * inputTopDistance
            } }
          /> : null
        }

        <KeyValueInput
          keyText = { "Horse Telex ID" }
          initialValueString = { [] }
          initialValueNumber = { horseTelexId }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "IntegerInput" }
          modificationFunctions = { [(newValue: any) => {
            changeInput("horseTelexId", newValue);
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 10 * inputTopDistance,
            fieldTop: firstInputTop + 10 * inputTopDistance
          } }
        />

        { showErrorMessages ?
          <SingleRowText
            text = { horseTelexIdErrorMessage.length !== 0 ? horseTelexIdErrorMessage[0] : "" }
            prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 10 * inputTopDistance
            } }
          /> : null
        }

        <KeyValueInput
          keyText = { "Dijugratas ID" }
          initialValueString = { [] }
          initialValueNumber = { dijugratasId }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "IntegerInput" }
          modificationFunctions = { [(newValue: any) => {
            changeInput("dijugratasId", newValue);
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 11 * inputTopDistance,
            fieldTop: firstInputTop + 11 * inputTopDistance
          } }
        />

        { showErrorMessages ?
          <SingleRowText
            text = { dijugratasIdErrorMessage.length !== 0 ? dijugratasIdErrorMessage[0] : "" }
            prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 11 * inputTopDistance
            } }
          /> : null
        }

        <KeyValueInput
          keyText = { "Trading category" }
          initialValueString = { tradingCategory }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { tradingCategoryOptions }
          inputType = { "SingleSelectInput" }
          modificationFunctions = { [(newValue: any) => {
            changeInput("tradingCategory", newValue);
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 12 * inputTopDistance,
            fieldTop: firstInputTop + 12 * inputTopDistance
          } }
        />

        { showErrorMessages ?
          <SingleRowText
            text = { tradingCategoryErrorMessage.length !== 0 ? tradingCategoryErrorMessage[0] : "" }
            prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 12 * inputTopDistance
            } }
          /> : null
        }

        <KeyValueInput
          keyText = { "Price category" }
          initialValueString = { priceCategory }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { priceCategoryOptions }
          inputType = { "SingleSelectInput" }
          modificationFunctions = { [(newValue: any) => {
            changeInput("priceCategory", newValue);
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 13 * inputTopDistance,
            fieldTop: firstInputTop + 13 * inputTopDistance
          } }
        />

        { showErrorMessages ?
          <SingleRowText
            text = { priceCategoryErrorMessage.length !== 0 ? priceCategoryErrorMessage[0] : "" }
            prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 13 * inputTopDistance
            } }
          /> : null
        }

        <KeyValueInput
          keyText = { "Breeding category" }
          initialValueString = { breedingCategory }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { breedingCategoryOptions }
          inputType = { "SingleSelectInput" }
          modificationFunctions = { [(newValue: any) => {
            changeInput("breedingCategory", newValue);
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 14 * inputTopDistance,
            fieldTop: firstInputTop + 14 * inputTopDistance
          } }
        />

        { showErrorMessages ?
          <SingleRowText
            text = { breedingCategoryErrorMessage.length !== 0 ? breedingCategoryErrorMessage[0] : "" }
            prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 14 * inputTopDistance
            } }
          /> : null
        }

        <KeyValueInput
          keyText = { "Accessibility" }
          initialValueString = { accessibility }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { accessibilityOptions }
          inputType = { "SingleSelectInput" }
          modificationFunctions = { [(newValue: any) => {
            changeInput("accessibility", newValue);
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 15 * inputTopDistance,
            fieldTop: firstInputTop + 15 * inputTopDistance
          } }
        />

        { showErrorMessages ?
          <SingleRowText
            text = { accessibilityErrorMessage.length !== 0 ? accessibilityErrorMessage[0] : "" }
            prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 15 * inputTopDistance
            } }
          /> : null
        }

        <KeyValueInput
          keyText = { "Description" }
          initialValueString = { description }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "MultipleRowTextInput" }
          modificationFunctions = { [(newValue: any) => {
            changeInput("description", newValue);
          }] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 8 * inputTopDistance,
            fieldTop: firstInputTop + 8 * inputTopDistance,
            fieldHeight: descriptionFieldHeight
          } }
        />

        { showErrorMessages ?
          <SingleRowText
            text = { descriptionErrorMessage.length !== 0 ? descriptionErrorMessage[0] : "" }
            prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstInputTop + 8 * inputTopDistance
            } }
          /> : null
        }
      </div>
    </Box>
  )
}
