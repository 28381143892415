import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { AdjustableBox } from '../04_subComponents/Box';





export interface BoxPrototypeDisplayParameters {
  top: number,
  left: number,
  height: number,
  width: number,
  backgroundColor: string,
  outlineStyle: string,
  outlineWidth: number,
  outlineColor: string,
}

export interface BoxChangedDisplayParameters {
  top?: number,
  left?: number,
  height?: number,
  width?: number,
  backgroundColor?: string,
  outlineStyle?: string,
  outlineWidth?: number,
  outlineColor?: string,
}

export interface BoxProps {
  redirectionUrl?: string,
  prototypeDisplayParameters: BoxPrototypeDisplayParameters,
  changedDisplayParameters?: BoxChangedDisplayParameters,
  children?: JSX.Element | JSX.Element[]
}





export const Box: FC<BoxProps> = ({ redirectionUrl, prototypeDisplayParameters, changedDisplayParameters , children }) => {
  //

  const [top, setTop] = useState<number>(prototypeDisplayParameters.top);
  const [left, setLeft] = useState<number>(prototypeDisplayParameters.left);
  const [height, setHeight] = useState<number>(prototypeDisplayParameters.height);
  const [width, setWidth] = useState<number>(prototypeDisplayParameters.width);
  const [backgroundColor, setBackgroundColor] = useState<string>(prototypeDisplayParameters.backgroundColor);
  const [outlineStyle, setOutlineStyle] = useState<string>(prototypeDisplayParameters.outlineStyle);
  const [outlineWidth, setOutlineWidth] = useState<number>(prototypeDisplayParameters.outlineWidth);
  const [outlineColor, setOutlineColor] = useState<string>(prototypeDisplayParameters.outlineColor);


  useEffect(() => {
    if(changedDisplayParameters !== undefined) {
      if(changedDisplayParameters.top !== undefined) {
        setTop(changedDisplayParameters.top);
      }
      if(changedDisplayParameters.left !== undefined) {
        setLeft(changedDisplayParameters.left);
      }
      if(changedDisplayParameters.height !== undefined) {
        setHeight(changedDisplayParameters.height);
      }
      if(changedDisplayParameters.width !== undefined) {
        setWidth(changedDisplayParameters.width);
      }
      if(changedDisplayParameters.backgroundColor !== undefined) {
        setBackgroundColor(changedDisplayParameters.backgroundColor);
      }
      if(changedDisplayParameters.outlineStyle !== undefined) {
        setOutlineStyle(changedDisplayParameters.outlineStyle);
      }
      if(changedDisplayParameters.outlineWidth !== undefined) {
        setOutlineWidth(changedDisplayParameters.outlineWidth);
      }
      if(changedDisplayParameters.outlineColor !== undefined) {
        setOutlineColor(changedDisplayParameters.outlineColor);
      }
    }
  }, [changedDisplayParameters]);


  return(
    <AdjustableBox
      redirectionUrl = { redirectionUrl !== undefined ? [redirectionUrl] : [] }
      top = { top }
      left = { left }
      height = { height }
      width = { width }
      backgroundColor = { backgroundColor }
      outlineStyle = { outlineStyle }
      outlineWidth = { outlineWidth }
      outlineColor = { outlineColor }
    >
      { children }
    </AdjustableBox>
  )
}
