import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { KeyValueInput, KeyValueInputPrototypeDisplayParameters, KeyValueInputChangedDisplayParameters } from '../../../03_baseComponents/KeyValueInput';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../03_baseComponents/Button';
import { emailInputTest, numberOfCharactersGreaterThanOrEqual, equalCondition } from '../../../../services/Conditions';
import { SingleRowText } from '../../../03_baseComponents/SingleRowText';
import { MultipleRowText, MultipleRowPrototypeDisplayParameters, MultipleRowChangedDisplayParameters } from '../../../03_baseComponents/MultipleRowText';
import dotenv from "dotenv";
import { postData } from "../../../../services/API.services";
import { LoginRequest, UserFromToken } from '../../../../models/UserProps';
import jwtDecode from "jwt-decode";
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { resetActionsDone } from '../../../../App';





export interface LoginProps {
  webpageStyle: WebpageStyleProps,
  changeLoggedInUser: Array<any>,
  resizeMainContentHeight: Array<any>
}





export const Login: FC<LoginProps> = ({ webpageStyle, changeLoggedInUser, resizeMainContentHeight }) => {
  //

  const [email, setEmail] = useState<Array<string>>(["lmn@op.hu"]);
  const [password, setPassword] = useState<Array<string>>(["abcdefgh"]);
  const [confirmPassword, setConfirmPassword] = useState<Array<string>>([]);

  const [errorMessage, setErrorMessage] = useState<string>("");


  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.mainContent.login.boxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.boxTop,
    left: webpageStyle.content.mainContent.boxLeft,
    height: boxHeight,
    width: webpageStyle.content.mainContent.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.boxOutlineColor
  });


  const [firstFieldTop, setFirstFieldTop] = useState<number>(webpageStyle.content.mainContent.login.firstFieldTop);
  const [topDistance, setTopDistance] = useState<number>(webpageStyle.content.mainContent.login.topDistance);

  const [keyLeft, setKeyLeft] = useState<number>();
  const [keyWidth, setKeyWidth] = useState<number>();
  const [keyFontSize, setKeyFontSize] = useState<number>();
  const [keyColor, setKeyColor] = useState<string>();

  const [fieldLeft, setFieldLeft] = useState<number>();
  const [fieldHeight, setFieldHeight] = useState<number>();
  const [fieldWidth, setFieldWidth] = useState<number>();
  const [fieldBackgroundColor, setFieldBackgroundColor] = useState<string>();
  const [valueFontSize, setValueFontSize] = useState<number>();
  const [valueColor, setValueColor] = useState<string>();

  const [keyValueInputPrototypeDisplayParameters, setKeyValueInputPrototypeDisplayParameters] = useState<KeyValueInputPrototypeDisplayParameters>({
    keyTop: firstFieldTop,
    keyLeft: webpageStyle.content.mainContent.login.keyLeft,
    keyWidth: webpageStyle.content.mainContent.login.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.login.keyFontSize,
    keyColor: webpageStyle.content.mainContent.login.keyColor,
    fieldTop: firstFieldTop,
    fieldLeft: webpageStyle.content.mainContent.login.fieldLeft,
    fieldHeight: webpageStyle.content.mainContent.login.fieldHeight,
    fieldWidth: webpageStyle.content.mainContent.login.fieldWidth,
    fieldBackgroundColor: webpageStyle.content.mainContent.login.fieldBackgroundColor,
    valueFontSize: webpageStyle.content.mainContent.login.valueFontSize,
    valueColor: webpageStyle.content.mainContent.login.valueColor,
  });

  const [loginButtonPrototypeDisplayParameters, setLoginButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.login.loginButtonTop,
    left: webpageStyle.content.mainContent.login.loginButtonLeft,
    height: webpageStyle.content.mainContent.login.loginButtonHeight,
    width: webpageStyle.content.mainContent.login.loginButtonWidth,
    backgroundColor: webpageStyle.content.mainContent.login.loginButtonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.login.loginButtonFontSize,
    contentColor: webpageStyle.content.mainContent.login.loginButtonContentColorActive
  });

  const [forgotPasswordButtonPrototypeDisplayParameters, setForgotPasswordButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.login.forgotPasswordButtonTop,
    left: webpageStyle.content.mainContent.login.forgotPasswordButtonLeft,
    height: webpageStyle.content.mainContent.login.forgotPasswordButtonHeight,
    width: webpageStyle.content.mainContent.login.forgotPasswordButtonWidth,
    backgroundColor: webpageStyle.content.mainContent.login.forgotPasswordButtonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.login.forgotPasswordButtonFontSize,
    contentColor: webpageStyle.content.mainContent.login.forgotPasswordButtonContentColorActive
  });

  const [errorMessagePrototypeDisplayParameters, setErrorMessagePrototypeDisplayParameters] = useState<MultipleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.login.errorMessageTop,
    left: webpageStyle.content.mainContent.login.errorMessageLeft,
    height: webpageStyle.content.mainContent.login.errorMessageHeight,
    width: webpageStyle.content.mainContent.login.errorMessageWidth,
    fontSize: webpageStyle.content.mainContent.login.errorMessageFontSize,
    color: webpageStyle.content.mainContent.login.errorMessageColor
  });

  
  let history = useHistory();
  
  const login = async () => {
    //

    const test: boolean = testConditions();

    if(test === true) {
      const loginUserRequest: LoginRequest = {
        email: email[0],
        password: password[0]
      }
      const result = await postData(`/api/users/single/login`, loginUserRequest);
      if (!result || (result as { status: number, message: string })?.status >= 400) {
        setErrorMessage(result.message);
      } else {
        resetActionsDone();
        localStorage.setItem("user", JSON.stringify(result.token));
        setErrorMessage("");
        // console.log(result);
        let userFromToken: UserFromToken = jwtDecode<UserFromToken>(result.token as string);
        // let userFromToken: UserFromToken = jwtDecode<UserFromToken>(localStorage.getItem("user") as string);
        changeLoggedInUser[0](userFromToken.id, userFromToken.email,userFromToken.role);
        history.push("/");
      }
    }
  }

  const testConditions = (): boolean => {
    //

    const testEmail = emailInputTest(email);

    if(email.length === 0) {
      setErrorMessage("No email entered");
        return(false);
    }

    if(testEmail.length !== 0) {
      if(testEmail[0] !== true) {
        setErrorMessage("Not valid email address");
        return(false);
      }
    }

    if(password.length === 0) {
      setErrorMessage("No password entered");
        return(false);
    }

    setErrorMessage("");
    return(true);
  }


  useEffect(() => {
    resizeMainContentHeight[0](boxHeight);
  }, []);


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <KeyValueInput
        keyText = { "Email" }
        initialValueString = { email }
        // initialValueString = { [] }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { [] }
        inputType = { "EmailInput" }
        modificationFunctions = { [setEmail, () => { setErrorMessage("") }] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstFieldTop + 0 * topDistance,
          fieldTop: firstFieldTop + 0 * topDistance
        } }
      />

      <KeyValueInput
        keyText = { "Password" }
        initialValueString = { password }
        // initialValueString = { [] }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { [] }
        inputType = { "PasswordInputPrimer" }
        modificationFunctions = { [setPassword, () => { setErrorMessage("") }] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstFieldTop + 1 * topDistance,
          fieldTop: firstFieldTop + 1 * topDistance
        } }
      />

      <Button
        buttonText = { "Login" }
        actionFunctions = { [login] }
        disabled = { false }
        prototypeDisplayParameters = { loginButtonPrototypeDisplayParameters }
      />

      <Button
        buttonText = { "Forgot password" }
        redirectionUrl = { "/forgotpasswordrequest" }
        disabled = { false }
        prototypeDisplayParameters = { forgotPasswordButtonPrototypeDisplayParameters }
      />

      {/* <SingleRowText
        text = { errorMessage }
        redirectionUrl = { [] }
        message = { [] }
        top = { errorMessageTop }
        left = { errorMessageLeft }
        width = { errorMessageWidth }
        fontSize = { errorMessageFontSize }
        color = { errorMessageColor }
      /> */}

      <MultipleRowText
        text = { errorMessage }
        prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
      />
    </Box>
  )
}
