import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { KeyValueInput, KeyValueInputPrototypeDisplayParameters, KeyValueInputChangedDisplayParameters } from '../../../03_baseComponents/KeyValueInput';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../03_baseComponents/Button';
import { MultipleRowText, MultipleRowPrototypeDisplayParameters, MultipleRowChangedDisplayParameters } from '../../../03_baseComponents/MultipleRowText';
import { postData } from "../../../../services/API.services";
import { emailInputTest, numberOfCharactersGreaterThanOrEqual, equalCondition } from '../../../../services/Conditions';
import { InvitationRequest } from '../../../../models/UserProps';





export interface AdminInviteUserProps {
  webpageStyle: WebpageStyleProps,
  reloadUsers: Array<any>,
  resizeMainContentHeight: Array<any>
}





export const AdminInviteUser: FC<AdminInviteUserProps> = ({ webpageStyle, reloadUsers, resizeMainContentHeight }) => {
  //

  const [email, setEmail] = useState<Array<string>>([]);
  const [role, setRole] = useState<Array<string>>([]);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [roleOptions, setRoleOptions] = useState<Array<string>>([" ", "customer", "administrator", "account owner"]);


  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.mainContent.adminInviteUser.boxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.boxTop,
    left: webpageStyle.content.mainContent.boxLeft,
    height: boxHeight,
    width: webpageStyle.content.mainContent.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.boxOutlineColor
  });


  const [firstInputTop, setFirstInputTop] = useState<number>(webpageStyle.content.mainContent.adminInviteUser.firstInputTop);
  const [inputTopDistance, setInputTopDistance] = useState<number>(webpageStyle.content.mainContent.adminInviteUser.inputTopDistance);

  const [keyValueInputPrototypeDisplayParameters, setKeyValueInputPrototypeDisplayParameters] = useState<KeyValueInputPrototypeDisplayParameters>({
    keyTop: firstInputTop,
    keyLeft: webpageStyle.content.mainContent.adminInviteUser.keyLeft,
    keyWidth: webpageStyle.content.mainContent.adminInviteUser.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.adminInviteUser.keyFontSize,
    keyColor: webpageStyle.content.mainContent.adminInviteUser.keyColor,
    fieldTop: firstInputTop,
    fieldLeft: webpageStyle.content.mainContent.adminInviteUser.fieldLeft,
    fieldHeight: webpageStyle.content.mainContent.adminInviteUser.fieldHeight,
    fieldWidth: webpageStyle.content.mainContent.adminInviteUser.fieldWidth,
    fieldBackgroundColor: webpageStyle.content.mainContent.adminInviteUser.fieldBackgroundColor,
    valueFontSize: webpageStyle.content.mainContent.adminInviteUser.valueFontSize,
    valueColor: webpageStyle.content.mainContent.adminInviteUser.valueColor,
  });

  const [inviteButtonPrototypeDisplayParameters, setInviteButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.adminInviteUser.inviteButtonTop,
    left: webpageStyle.content.mainContent.adminInviteUser.inviteButtonLeft,
    height: webpageStyle.content.mainContent.adminInviteUser.inviteButtonHeight,
    width: webpageStyle.content.mainContent.adminInviteUser.inviteButtonWidth,
    backgroundColor: webpageStyle.content.mainContent.adminInviteUser.inviteButtonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.adminInviteUser.inviteButtonFontSize,
    contentColor: webpageStyle.content.mainContent.adminInviteUser.inviteButtonContentColorActive
  });

  const [errorMessageDisplayParameters, setErrorMessageDisplayParameters] = useState<MultipleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.adminInviteUser.errorMessageTop,
    left: webpageStyle.content.mainContent.adminInviteUser.errorMessageLeft,
    height: webpageStyle.content.mainContent.adminInviteUser.errorMessageHeight,
    width: webpageStyle.content.mainContent.adminInviteUser.errorMessageWidth,
    fontSize: webpageStyle.content.mainContent.adminInviteUser.errorMessageFontSize,
    color: webpageStyle.content.mainContent.adminInviteUser.errorMessageColor
  });


  const invite = async () => {
    //

    const test: boolean = testConditions();

    if(test === true) {
      const loginUserRequest: InvitationRequest = {
        email: email[0],
        role: role[0]
      }
      const invitationResult = await postData('/api/users/single/invite/admin', loginUserRequest, true);
      if (!invitationResult || (invitationResult as { status: number, message: string })?.status >= 400) {
        setErrorMessage(invitationResult.message);
      } else {
        reloadUsers[0]();
      }
    }
  }

  const testConditions = (): boolean => {
    //

    if(email.length === 0) {
      setErrorMessage("No email entered");
        return(false);
    }

    const testEmail = emailInputTest(email);

    if(testEmail.length !== 0) {
      if(testEmail[0] !== true) {
        setErrorMessage("Not valid email address");
        return(false);
      }
    }

    if(role[0] !== "customer" && role[0] !== "administrator" && role[0] !== "account owner") {
      setErrorMessage("No role entered");
        return(false);
    }

    setErrorMessage("");
    return(true);
  }

  const changeRoleValue = (newParameter: Array<string>) => {
    if(newParameter.length !== 0) {
      if(newParameter[0] !== " ") {
        setRole(newParameter);
      } else {
        setRole([]);
      }
    }
  }


  useEffect(() => {
    resizeMainContentHeight[0](boxHeight);
  }, []);


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <KeyValueInput
        keyText = { "Email" }
        initialValueString = { [] }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { [] }
        inputType = { "EmailInput" }
        modificationFunctions = { [setEmail, () => { setErrorMessage("") }] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstInputTop + 0 * inputTopDistance,
          fieldTop: firstInputTop + 0 * inputTopDistance
        } }
      />

      <KeyValueInput
        keyText = { "User role" }
        initialValueString = { role }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { roleOptions }
        inputType = { "SingleSelectInput" }
        modificationFunctions = { [changeRoleValue, () => { setErrorMessage("") }] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstInputTop + 1 * inputTopDistance,
          fieldTop: firstInputTop + 1 * inputTopDistance
        } }
      />

      <Button
        buttonText = { "Invite" }
        actionFunctions = { [invite] }
        disabled = { false }
        prototypeDisplayParameters = { inviteButtonPrototypeDisplayParameters }
      />

      <MultipleRowText
        text = { errorMessage }
        prototypeDisplayParameters = { errorMessageDisplayParameters }
      />
    </Box>
  )
}
