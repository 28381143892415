import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { Button } from '@mui/material';
import { PopUpWindow } from './PopUpWindow';
import { AdjustableIcon } from '../04_subComponents/Icon';
import Link from '@mui/material/Link';
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { incrementActionsDone } from '../../App';





export interface AdjustableButtonProps {
  buttonText: Array<string>,
  buttonIcon: Array<string>,
  actionFunctions: Array<any>,
  redirectionUrl: Array<string>,
  conditionFunction: Array<any>, // if the output is true -> do the actionFunction, if false -> do nothing, if no conditionFunction -> do the actionFunction
  message: Array<string>,
  disabled: boolean,
  top: number,
  left: number,
  height: number,
  width: number,
  backgroundColor: string,
  fontSize: number,
  contentColor: string
}





export const AdjustableButton: FC<AdjustableButtonProps> = ({ buttonText, buttonIcon, actionFunctions, redirectionUrl, conditionFunction, message, disabled, top, left, height, width, backgroundColor, fontSize, contentColor }) => {
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(true);
  const [buttonContent, setButtonContent] = useState<Array<JSX.Element>>([]);

  const getButtonContent = () => {
    if(buttonText.length > 0) {
      setButtonContent(
        [<div style = {{ fontSize: fontSize, color: contentColor }}>
          { buttonText }
        </div>]
      )
    } else {
      if(buttonIcon.length > 0) {
        setButtonContent(
          [<AdjustableIcon
            iconType = { buttonIcon[0] }
            message = { [] }
            onClickFunctions = { [] }
            top = { ((height / 2) - fontSize) }
            left = { ((width / 2) - fontSize) }
            size = { fontSize }
            color = { contentColor }
          />]
        )
      } else {
        setButtonContent(
          [<div/>]
        )
      }
    }
    setButtonIsLoading(false);
  }

  useEffect(() => {
    getButtonContent();
  }, []);

  let history = useHistory();

  if(buttonIsLoading === true) {
    return(
      <div style = {{ position: "absolute", top: top, left: left }}>
        Button is loading
      </div>
    )
  } else {
    return(
      <div style = {{ position: "absolute", top: top, left: left }}>
        <PopUpWindow
          message = { message }
        >
          <Button
            style = {{ height: height, width: width, backgroundColor: backgroundColor }}
            disabled = { disabled }
            onClick = {() => {
              incrementActionsDone();
              let conditionTest: boolean = true;
              if(conditionFunction.length !== 0) {
                conditionTest = conditionFunction[0]();
              }
              if(conditionTest === true) {
                // if(actionFunction.length !== 0) {
                //   actionFunction[0]();
                // }
                for(let i: number = 0; i < actionFunctions.length; i++) {
                  actionFunctions[i]();
                }
                if(redirectionUrl.length !== 0) {
                  history.push(redirectionUrl[0]);
                }
              }
            }}
          >
            { buttonContent[0] }
          </Button>
        </PopUpWindow>
      </div>
    )
  }
}
