import React, { FC, useEffect, useState, Component } from "react";
import dotenv from "dotenv";
import "./App.css";
import { WebpageStyleProps, getWebpageStyle } from './services/webpageStyle';
import { Logo } from './components_uj/01_pageSections/Logo';
import { Header } from './components_uj/01_pageSections/Header';
import { Navigation } from './components_uj/01_pageSections/Navigation';
import { Content } from './components_uj/01_pageSections/Content';
import { Footer } from './components_uj/01_pageSections/Footer';
import { BrowserRouter, Switch, Route, Redirect, useHistory, useParams } from 'react-router-dom';
import { url } from "inspector";
import { HorseCatalogSettings, HorseCatalogFilteringSettings, HorseCatalogSortingSettings } from './models/HorseCatalogSettings';
import { CheckPathAndSearch } from './components_uj/01_pageSections/CheckPathAndSearch';
import { getData, postData } from "./services/API.services";
import { LoggedInUser, UserFromToken } from './models/UserProps';
import jwtDecode from "jwt-decode";

dotenv.config();

// export var globalVariable: number = 100;

export var actionsDone: number = 0;

export function incrementActionsDone(): void {
  actionsDone++;
}

export function resetActionsDone(): void {
  actionsDone = 0;
}

export var passwordLength: Array<number> = [8];

const App: React.FC = () => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [loggedInUser, setLoggedInUser] = useState<LoggedInUser>({
    id: 0,
    email: "",
    role: ""
  });
  const [loggedInUserId, setLoggedInUserId] = useState<number>(0);
  const [loggedInUserEmail, setLoggedInUserEmail] = useState<string>("");
  const [loggedInUserRole, setLoggedInUserRole] = useState<string>(""); // visitor vagy customer vagy administrator
  const [accumulatedActions, setAccumulatedActions] = useState<number>(0);
  const [profileUpdated, setProfileUpdated] = useState<boolean>(false);

  const [webpageStyle, setWebpageStyle] = useState<WebpageStyleProps>(getWebpageStyle("test"));

  const [contentMinimumHeight, setContentMinimumHeight] = useState<number>(500);
  const [footerBoxTop, setFooterBoxTop] = useState<number>(800);

  const [isContentLoading, seIsContentLoading] = useState<boolean>(false);

  const [path, setPath] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const [recentPath, setRecentPath] = useState<string>(path);
  const [recentSearch, setRecentSearch] = useState<string>(search);

  const [runCheckPathAndSearch, setRunCheckPathAndSearch] = useState<boolean>(false);

  const [recentHorseCatalogSettings, setRecentHorseCatalogSettings] = useState<HorseCatalogSettings>({
    filteringSettings: {
      keyWords: [],
      name: [],
      sex: [],
      birthYear: [],
      color: [],
      stud: [],
      origin: [],
      horseBreed: [],
      tradingCategory: [],
      // accessibility: loggedInUserType !== "administrator" ? ["public"] : ["private"]
      // accessibility: ["public"]
    },
    sortingSettings: {
      arguments: ["Name"],
      orders: ["Ascending"]
  }});

  // const getUser = () => {
  //   if (localStorage.getItem("user")) {
  //     return jwtDecode<UserFromToken>(localStorage.getItem("user") as string);
  //   } else {
  //     return undefined;
  //   }
  // };
  // const [user, setUser] = useState<UserFromToken | undefined>(() => {
  //   return getUser();
  // });

  // const fetchUpdatedProfile = async () => {
  //   const result = await postData(`/api/user`, { id: user?.id }, true);
  //   if (!result || (result as { status: number, message: string })?.status >= 400) {
  //     setUser(undefined);
  //   } else {
  //     localStorage.setItem("user", JSON.stringify(result.token));
  //     setUser(getUser());
  //   }
  // }

  // useEffect(() => {
  //   fetchUpdatedProfile();
  // }, [profileUpdated]);

  const checkToken = async () => {
    let userToken = localStorage.getItem("user");
    if(userToken) {
      // const result = await postData(`/api/users/single/tokencontrol`, {
      //   userToken: userToken
      // }, true);
      const result = await getData(`/api/users/single/refetch`, true);
      if (!result || (result as { status: number, message: string })?.status >= 400) {
        changeLoggedInUser(0, "", "visitor");
        // setLoggedInUserRole("visitor");
        localStorage.clear();
      } else {
        if(result.length === 0) {
          changeLoggedInUser(0, "", "visitor");
          // setLoggedInUserRole("visitor");
        } else {
          // changeLoggedInUser(result.id, result.email, result.role);
          localStorage.setItem("user", JSON.stringify(result.token));
          let userFromToken: UserFromToken = jwtDecode<UserFromToken>(result.token as string);
          changeLoggedInUser(userFromToken.id, userFromToken.email, userFromToken.role);
        }
        resetActionsDone();
      }
    } else {
      changeLoggedInUser(0, "", "visitor");
      // setLoggedInUserRole("visitor");
    }
    setIsLoading(false);
  }

  const triggerRefetch = (): void => { setProfileUpdated(!profileUpdated) };

  const actionDone = () => {
    //

    // setLoggedInUserType("visitor");

    if((accumulatedActions + 1) === 10) {
      triggerRefetch();
      setAccumulatedActions(0);
    } else {
      setAccumulatedActions(accumulatedActions + 1);
    }
  }

  const resizeContentHeightInTheApp = (newContentHeight: number) => {
    setFooterBoxTop(webpageStyle.webpageMargin + webpageStyle.logo.boxHeight + (newContentHeight >= contentMinimumHeight ? newContentHeight : contentMinimumHeight));
  }

  const changeContent = () => {
    seIsContentLoading(true);
    setTimeout(() => seIsContentLoading(false), 100);
  }

  const savePath = (newPath: string) => {
    setPath(newPath);
  }

  const changeLoggedInUser = (newUserId: number, newUserEmail: string, newUserRole: string) => {
    let role: string = "";
    if(newUserRole === "administrator" || newUserRole === "account owner") {
      role = "administrator";
    }
    if(newUserRole === "customer") {
      role = "customer";
    }
    if(newUserRole === "visitor") {
      role = "visitor";
    }
    setLoggedInUser({
      id: newUserId,
      email: newUserEmail,
      role: role
    })
    setLoggedInUserId(newUserId);
    setLoggedInUserEmail(newUserEmail);
    setLoggedInUserRole(role);
  }

  const changeHorseCatalogSettings = (newHorseCatalogSettings: HorseCatalogSettings) => {
    setRecentHorseCatalogSettings(newHorseCatalogSettings);
  }

  const changePathAndSearch = (newPath: string, newSearch: string) => {
    setPath(newPath);
    setSearch(newSearch);
  }

  if(runCheckPathAndSearch === false) {
    setTimeout(() => {
      if(path !== recentPath) {
        setRecentPath(path);
      }
      if(search !== recentSearch) {
        setRecentSearch(search);
      }
      setRunCheckPathAndSearch(true);
    }, 100);
  }

  const refetchUser = async () => {
    //

    const result = await getData(`/api/users/single/refetch`, true);

    // console.log(result);

    if (!result || (result as { status: number, message: string })?.status >= 400) {
      changeLoggedInUser(0, "", "visitor");
      // setLoggedInUserRole("visitor");
      localStorage.clear();
    } else {
      localStorage.setItem("user", JSON.stringify(result.token));
      let userFromToken: UserFromToken = jwtDecode<UserFromToken>(result.token as string);
      changeLoggedInUser(userFromToken.id, userFromToken.email, userFromToken.role);
    }
  }

  if(loggedInUserRole === "customer" || loggedInUserRole === "administrator") {
    if(actionsDone >= 10) {
      actionsDone = 0;
      refetchUser();
    }
  }


  useEffect(() => {
    setTimeout(() => setRunCheckPathAndSearch(!runCheckPathAndSearch), 100);
  }, [runCheckPathAndSearch]);

  useEffect(() => {
    checkToken();
  }, []);


  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path="/">
            <div>
              { runCheckPathAndSearch === true ?
                <CheckPathAndSearch
                  recentPath = { recentPath }
                  recentSearch = { recentSearch }
                  changePathAndSearch = { [changePathAndSearch] }
                /> : <div/>
              }

              <Logo
                webpageStyle = { webpageStyle }
                path = { path }
                doAction = { [actionDone] }
              />

              <Header
                webpageStyle = { webpageStyle }
                loggedInUser = { loggedInUser }
                path = { path }
                changeLoggedInUser = { [changeLoggedInUser] }
                changeContent = { [changeContent] }
                doAction = { [actionDone] }
              />

              <Navigation
                webpageStyle = { webpageStyle }
                loggedInUser = { loggedInUser }
                path = { path }
                changeContent = { [changeContent] }
                doAction = { [actionDone] }
              />

              { isLoading === false ?
                <Content
                  webpageStyle = { webpageStyle }
                  loggedInUser = { loggedInUser }
                  recentHorseCatalogSettings = { recentHorseCatalogSettings }
                  path = { path }
                  search = { search }
                  changeLoggedInUser = { [changeLoggedInUser] }
                  changeHorseCatalogSettings = { [changeHorseCatalogSettings] } // ki kell még dolgozni
                  changeContent = { [changeContent] }
                  doAction = { [actionDone] }
                  resizeContentHeightInTheApp = { [resizeContentHeightInTheApp] }
                /> : null
              }
              
              {/* { isContentLoading === false ?
                <Content
                  webpageStyle = { webpageStyle }
                  loggedInUserType = { loggedInUserType }
                  recentHorseCatalogSettings = { recentHorseCatalogSettings }
                  changeHorseCatalogSettings = { [changeHorseCatalogSettings] } // ki kell még dolgozni
                  savePath = { [savePath] }
                  changeContent = { [changeContent] }
                  doAction = { [actionDone] }
                  resizeContentHeightInTheApp = { [resizeContentHeightInTheApp] }
                /> : null
              } */}

              <Footer
                webpageStyle = { webpageStyle }
                doAction = { [actionDone] }
                footerBoxTop = { footerBoxTop }
              />
            </div>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

// const formData = new FormData();

// console.log(formData.get('myFile')?.slice());

export default App;
