import React, { FC, useEffect, useState, ChangeEvent } from 'react';





export interface UploadedFile {
  title: string,
  fileExtension: string,
  content: string
}

export interface AdjustableFileInputProps {
  uploadFunction: Array<any>,
  top: number,
  left: number
}





export const AdjustableFileInput: FC<AdjustableFileInputProps> = ({ uploadFunction, top, left }) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);

  const resetFile = () => {
    setSelectedFile(null);
  }

  const onFileUpload = () => {
	
    const formData = new FormData();

    formData.append(
      "myFile",
      selectedFile,
      selectedFile.name
    );
    
    async function a(formData: any) {
      var arrayBuffer = await formData.get('myFile').arrayBuffer(100);
      // console.log(arrayBuffer.byteLength);


      let dataView = new DataView(arrayBuffer, 0, arrayBuffer.byteLength);
      let text = "";
      for(let i = 0; i < arrayBuffer.byteLength; i++) {
        let charCodeNumber = dataView.getUint8(i);
        let character = String.fromCharCode(charCodeNumber);
        text = text + character;
      }

      const buffer = Buffer.from(arrayBuffer);

      const base64String = buffer.toString('base64');

      // console.log(base64String);

      // console.log(formData.getAll('myFile')[0].name);
      const body = {
        title: formData.getAll('myFile')[0].name,
        text: base64String,
      };
      // await postData(`/api/ftp`, body, true);

      const fileName: string[] = formData.getAll('myFile')[0].name.split(".");
      const fileExtension: string = fileName.length === 2 ? fileName[1] : "";
      const title: string = fileName[0];
      
      const newUploadedImage: UploadedFile = {
        title: title,
        fileExtension: fileExtension,
        content: base64String
      }

      uploadFunction[0](newUploadedImage);
    }

	  a(formData);
	}

  return(
    <div style ={{ position: "absolute", top: top, left: left }}>
      <input
        type="file"
        onChange={
          (e: ChangeEvent<HTMLInputElement>) => {
            if(e.target.files !== null) {
              setSelectedFile(e.target.files[0]);
              // waitForAWhile();
            }
          }
        }
      />
      <button
        disabled = { selectedFile !== null ? false : true } //
        onClick={() => {
            if(selectedFile !== null) {
              onFileUpload();
            }
          }
        }>
			  Upload!
			</button>
      {/* <button
        disabled = { false } //
        onClick={() => {
          resetFile();
        }}>
        reset
      </button> */}
    </div>
  )
}
