import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { incrementActionsDone } from '../../App';





export interface AdjustableBoxProps {
  redirectionUrl: Array<string>,
  top: number,
  left: number,
  height: number,
  width: number,
  backgroundColor: string,
  outlineStyle: string,
  outlineWidth: number,
  outlineColor: string,
  children?: JSX.Element | JSX.Element[]
}





export const AdjustableBox: FC<AdjustableBoxProps> = ({ redirectionUrl, top, left, height, width, backgroundColor, outlineStyle, outlineWidth, outlineColor, children }) => {
  //

  let history = useHistory();

  return(
    <div
      style = {{ position: "absolute", top: top, left: left, height: height, width: width, backgroundColor: (backgroundColor !== "obscured" ? backgroundColor : "white"), outlineStyle: outlineStyle, outlineWidth: outlineWidth, outlineColor: outlineColor, cursor: redirectionUrl.length === 0 ? "auto" : "pointer", opacity: (backgroundColor !== "obscured" ? 1 : 0.25) }}
      onClick = { () => {
        if(redirectionUrl.length > 0) {
          incrementActionsDone();
          history.push(redirectionUrl[0]);
        }
      }}
    >
      { children }
    </div>
  )
}
