import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';





export interface AdminProposalListProps {
  webpageStyle: WebpageStyleProps,
  resizeMainContentHeight: Array<any>
}





export const AdminProposalList: FC<AdminProposalListProps> = ({ webpageStyle, resizeMainContentHeight }) => {
  //

  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.mainContent.adminCreateHorse.boxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.boxTop,
    left: webpageStyle.content.mainContent.boxLeft,
    height: boxHeight,
    width: webpageStyle.content.mainContent.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.boxOutlineColor
  });


  useEffect(() => {
    resizeMainContentHeight[0](boxHeight);
  }, []);


  return(
    <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <div>
        Admin proposal list
      </div>
    </Box>
  )
}
