import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../../services/webpageStyle';
import { Horse, HorseData } from '../../../../../models/HorseProps';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../../03_baseComponents/Box';
import { KeyValueDisplay, KeyValueDisplayPrototypeDisplayParameters, KeyValueDisplayChangedDisplayParameters } from '../../../../03_baseComponents/KeyValueDisplay';
import { getHorsePedigreeFromHorseTelex, HorsePedigree } from '../../../../../services/HorseTelexPedigree';
import { SingleRowText } from '../../../../03_baseComponents/SingleRowText';





export interface HorseDataSheetProps {
  webpageStyle: WebpageStyleProps,
  horse: Array<Horse>
}





export const HorseDataSheet: FC<HorseDataSheetProps> = ({ webpageStyle, horse }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [origin, setOrigin] = useState<string>("");
  const [pedigree, setPedigree] = useState<string>("N/A - N/A - N/A");
  
  const [priceCategory, setPriceCategory] = useState<string>("");
  const [breedingCategory, setBreedingCategory] = useState<string>("");


  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.horseProfile.horseDataSheet.boxTop,
    left: webpageStyle.content.mainContent.horseProfile.horseDataSheet.boxLeft,
    height: webpageStyle.content.mainContent.horseProfile.horseDataSheet.boxHeight,
    width: webpageStyle.content.mainContent.horseProfile.horseDataSheet.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.horseProfile.horseDataSheet.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.horseProfile.horseDataSheet.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.horseProfile.horseDataSheet.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.horseProfile.horseDataSheet.boxOutlineColor
  });


  const [firstDataTop, setFirstDataTop] = useState<number>(webpageStyle.content.mainContent.horseProfile.horseDataSheet.firstDataTop);
  const [dataTopDistance, setDataTopDistance] = useState<number>(webpageStyle.content.mainContent.horseProfile.horseDataSheet.dataTopDistance);

  const [keyValueDisplayPrototypeDisplayParameters, setKeyValueDisplayPrototypeDisplayParameters] = useState<KeyValueDisplayPrototypeDisplayParameters>({
    keyTop: firstDataTop,
    keyLeft: webpageStyle.content.mainContent.horseProfile.horseDataSheet.keyLeft,
    keyWidth: webpageStyle.content.mainContent.horseProfile.horseDataSheet.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.horseProfile.horseDataSheet.keyFontSize,
    keyColor: webpageStyle.content.mainContent.horseProfile.horseDataSheet.keyFontColor,
    valueTop: firstDataTop,
    valueLeft: webpageStyle.content.mainContent.horseProfile.horseDataSheet.valueLeft,
    valueWidth: webpageStyle.content.mainContent.horseProfile.horseDataSheet.valueWidth,
    valueFontSize: webpageStyle.content.mainContent.horseProfile.horseDataSheet.valueFontSize,
    valueColor: webpageStyle.content.mainContent.horseProfile.horseDataSheet.valueFontColor
  });


  const loadData = async () => {
    setOrigin((horse[0].data.origin + " (" + horse[0].data.horseBreed + ")"));

    let horsePedigree: HorsePedigree | undefined = undefined;

    if(horse[0].data.horseTelexId !== null) {
      horsePedigree = await getHorsePedigreeFromHorseTelex(horse[0].data.horseTelexId);
    }

    if(horsePedigree !== undefined) {
      const fathersName: string = horsePedigree.fathersName.length !== 0 ? horsePedigree.fathersName[0] : "N/A";
      const mothersFathersName: string = horsePedigree.mothersFathersName.length !== 0 ? horsePedigree.mothersFathersName[0] : "N/A";
      const mothersMothersFathersName: string = horsePedigree.mothersMothersFathersName.length !== 0 ? horsePedigree.mothersMothersFathersName[0] : "N/A";

      setPedigree(( fathersName + " - " + mothersFathersName + " - " + mothersMothersFathersName ));
    }

    setPriceCategory((horse[0].data.priceCategory + ' €'));
    if(horse[0].data.breedingCategory !== "no information") {
      setBreedingCategory(horse[0].data.breedingCategory);
    } else {
      setBreedingCategory("N/A");
    }

    setIsLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);


  if(isLoading === true) {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      >
        <div>
          Horse catalog list element is loading...
        </div>
      </Box>
      
    )
  } else {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      >
        <div>
          <KeyValueDisplay
            keyText = { "Stud" }
            valueText = { horse[0].data.stud }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 0 * dataTopDistance,
              valueTop: firstDataTop + 0 * dataTopDistance
            } }
          />
  
          <KeyValueDisplay
            keyText = { "Birth year" }
            valueText = { horse[0].data.birthYear.toString() }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 1 * dataTopDistance,
              valueTop: firstDataTop + 1 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Sex" }
            valueText = { horse[0].data.sex }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 2 * dataTopDistance,
              valueTop: firstDataTop + 2 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Color" }
            valueText = { horse[0].data.color }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 3 * dataTopDistance,
              valueTop: firstDataTop + 3 * dataTopDistance
            } }
          />
  
          <KeyValueDisplay
            keyText = { "Origin" }
            valueText = { origin }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 4 * dataTopDistance,
              valueTop: firstDataTop + 4 * dataTopDistance
            } }
          />
  
          <KeyValueDisplay
            keyText = { "Pedigree" }
            valueText = { pedigree }
            message = { "Father - Mother's Father - Mother's Mother's Father" }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 5 * dataTopDistance,
              valueTop: firstDataTop + 5 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Trading category" }
            valueText = { horse[0].data.tradingCategory }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 6 * dataTopDistance,
              valueTop: firstDataTop + 6 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Price category" }
            valueText = { priceCategory }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 7 * dataTopDistance,
              valueTop: firstDataTop + 7 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Breeding category" }
            valueText = { breedingCategory }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 8 * dataTopDistance,
              valueTop: firstDataTop + 8 * dataTopDistance
            } }
          />
        </div>
      </Box>
    )
  }
}
