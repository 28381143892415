import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { KeyValueInput, KeyValueInputPrototypeDisplayParameters, KeyValueInputChangedDisplayParameters } from '../../../03_baseComponents/KeyValueInput';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../03_baseComponents/Button';
import { emailInputTest, numberOfCharactersGreaterThanOrEqual, equalCondition } from '../../../../services/Conditions';
import { SingleRowText } from '../../../03_baseComponents/SingleRowText';
import { MultipleRowText, MultipleRowPrototypeDisplayParameters, MultipleRowChangedDisplayParameters } from '../../../03_baseComponents/MultipleRowText';
import { postData } from "../../../../services/API.services";
import { LoginRequest, RegistrationRequest, UserFromToken } from '../../../../models/UserProps';
import jwtDecode from "jwt-decode";
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { resetActionsDone, passwordLength } from '../../../../App';





export interface SignUpProps {
  webpageStyle: WebpageStyleProps,
  changeLoggedInUser: Array<any>,
  resizeMainContentHeight: Array<any>
}





export const SignUp: FC<SignUpProps> = ({ webpageStyle, changeLoggedInUser, resizeMainContentHeight }) => {
  //

  // const [passwordLength, setPasswordLength] = useState<Array<number>>([8]);

  const [email, setEmail] = useState<Array<string>>([]);
  const [password, setPassword] = useState<Array<string>>([]);
  const [confirmPassword, setConfirmPassword] = useState<Array<string>>([]);

  // const [confirmPasswordIsLoading, setConfirmPasswordIsLoading] = useState<boolean>(false);

  // const [emailIsProper, setEmailIsProper] = useState<boolean>(false);
  // const [passwordIsProper, setPasswordIsProper] = useState<boolean>(false);
  // const [confirmPasswordIsProper, setConfirmPasswordIsProper] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>("");

  
  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.mainContent.signUp.boxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.boxTop,
    left: webpageStyle.content.mainContent.boxLeft,
    height: boxHeight,
    width: webpageStyle.content.mainContent.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.boxOutlineColor
  });


  const [firstFieldTop, setFirstFieldTop] = useState<number>(webpageStyle.content.mainContent.signUp.firstFieldTop);
  const [topDistance, setTopDistance] = useState<number>(webpageStyle.content.mainContent.signUp.topDistance);

  const [keyValueInputPrototypeDisplayParameters, setKeyValueInputPrototypeDisplayParameters] = useState<KeyValueInputPrototypeDisplayParameters>({
    keyTop: firstFieldTop,
    keyLeft: webpageStyle.content.mainContent.signUp.keyLeft,
    keyWidth: webpageStyle.content.mainContent.signUp.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.signUp.keyFontSize,
    keyColor: webpageStyle.content.mainContent.signUp.keyColor,
    fieldTop: firstFieldTop,
    fieldLeft: webpageStyle.content.mainContent.signUp.fieldLeft,
    fieldHeight: webpageStyle.content.mainContent.signUp.fieldHeight,
    fieldWidth: webpageStyle.content.mainContent.signUp.fieldWidth,
    fieldBackgroundColor: webpageStyle.content.mainContent.signUp.fieldBackgroundColor,
    valueFontSize: webpageStyle.content.mainContent.signUp.valueFontSize,
    valueColor: webpageStyle.content.mainContent.signUp.valueColor,
  });

  const [signUpButtonPrototypeDisplayParameters, setSignUpButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.signUp.signUpButtonTop,
    left: webpageStyle.content.mainContent.signUp.signUpButtonLeft,
    height: webpageStyle.content.mainContent.signUp.signUpButtonHeight,
    width: webpageStyle.content.mainContent.signUp.signUpButtonWidth,
    backgroundColor: webpageStyle.content.mainContent.signUp.signUpButtonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.signUp.signUpButtonFontSize,
    contentColor: webpageStyle.content.mainContent.signUp.signUpButtonContentColorActive
  });

  const [errorMessagePrototypeDisplayParameters, setErrorMessagePrototypeDisplayParameters] = useState<MultipleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.signUp.errorMessageTop,
    left: webpageStyle.content.mainContent.signUp.errorMessageLeft,
    height: webpageStyle.content.mainContent.signUp.errorMessageHeight,
    width: webpageStyle.content.mainContent.signUp.errorMessageWidth,
    fontSize: webpageStyle.content.mainContent.signUp.errorMessageFontSize,
    color: webpageStyle.content.mainContent.signUp.errorMessageColor
  });


  let history = useHistory();

  // if(confirmPasswordIsLoading === true) {
  //   setTimeout(() => setConfirmPasswordIsLoading(false), 1);
  // }

  const signUp = async () => {
    //

    const test: boolean = testConditions();

    if(test === true) {
      const registrationRequest: RegistrationRequest = {
        email: email[0],
        password: password[0]
      }
      const registrationResult = await postData('/api/users/single/registration', registrationRequest);
      if (!registrationResult || (registrationResult as { status: number, message: string })?.status >= 400) {
        setErrorMessage(registrationResult.message);
      } else {
        const loginUserRequest: LoginRequest = {
          email: email[0],
          password: password[0]
        }
        const loginResult = await postData(`/api/users/single/login`, loginUserRequest);
        if (!loginResult || (loginResult as { status: number, message: string })?.status >= 400) {
          setErrorMessage(loginResult.message);
        } else {
          resetActionsDone();
          localStorage.setItem("user", JSON.stringify(loginResult.token));
          setErrorMessage("");
          let userFromToken: UserFromToken = jwtDecode<UserFromToken>(loginResult.token as string);
          changeLoggedInUser[0](userFromToken.id, userFromToken.email, userFromToken.role);
          history.push("/");
        }
      }
    }
  }

  const testConditions = (): boolean => {
    //

    const testEmail = emailInputTest(email);
    const testPasswordLength = numberOfCharactersGreaterThanOrEqual(password, passwordLength);
    const testPasswordsEquality = equalCondition(password, confirmPassword);

    if(email.length === 0) {
      setErrorMessage("No email entered");
        return(false);
    }

    if(testEmail.length !== 0) {
      if(testEmail[0] !== true) {
        setErrorMessage("Not valid email address");
        return(false);
      }
    }

    if(testPasswordLength.length !== 0) {
      if(testPasswordLength[0] !== true) {
        setErrorMessage(`The password length is less than ${ passwordLength }`);
        return(false);
      }
    }

    if(testPasswordsEquality.length !== 0) {
      if(testPasswordsEquality[0] !== true) {
        setErrorMessage("The password doesn't equal the confirm password");
        return(false);
      }
    }

    setErrorMessage("");
    return(true);
  }


  useEffect(() => {
    resizeMainContentHeight[0](boxHeight);
  }, []);


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <KeyValueInput
        keyText = { "Email" }
        initialValueString = { [] }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { [] }
        inputType = { "EmailInput" }
        modificationFunctions = { [setEmail, () => { setErrorMessage("") }] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstFieldTop + 0 * topDistance,
          fieldTop: firstFieldTop + 0 * topDistance
        } }
      />

      <KeyValueInput
        keyText = { "Password" }
        initialValueString = { [] }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { [] }
        inputType = { "PasswordInputPrimer" }
        // modificationFunctions = { [setPassword, () => { setConfirmPasswordIsLoading(true) }] }
        modificationFunctions = { [setPassword, () => { setErrorMessage("") }] }
        properInputDisplay = { false }
        // conditions = { [{
        //   conditionType: "numberOfCharactersGreaterThanOrEqual",
        //   conditionArgument: [5],
        //   errorMessage: ["The password contains less than five characters"]
        // }] }
        // conditionResultFunctions = { [setPasswordIsProper] }
        disabled = { false }
        prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstFieldTop + 1 * topDistance,
          fieldTop: firstFieldTop + 1 * topDistance
        } }
      />

      {/* { confirmPasswordIsLoading === false ?
        <KeyValueInput
          keyText = { "Confirm password" }
          initialValueString = { confirmPassword }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "PasswordInputSecunder" }
          modificationFunctions = { [setConfirmPassword] }
          properInputDisplay = { false }
          conditions = { [] }
          conditionTestFunctions = { [] }
          conditionResultFunctions = { [] }
          hidden = { [] } // erre a mezőre talán nincs is szükség ???
          disabled = { false }
          keyTop = { (FirstFieldTop + 2 * topDistance) }
          keyLeft = { keyLeft }
          keyWidth = { keyWidth }
          keyFontSize = { keyFontSize }
          keyColor = { keyColor }
          fieldTop = { (FirstFieldTop + 2 * topDistance) }
          fieldLeft = { fieldLeft }
          fieldHeight = { fieldHeight }
          fieldWidth = { fieldWidth }
          fieldBackgroundColor = { fieldBackgroundColor }
          valueFontSize = { valueFontSize }
          valueColor = { valueColor }
          properInputIndicatorTop = { [] }
          properInputIndicatorLeft = { [] }
          properInputIndicatorSize = { [] }
        /> : <div/>
      } */}

      <KeyValueInput
        keyText = { "Confirm password" }
        initialValueString = { confirmPassword }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { [] }
        inputType = { "PasswordInputSecunder" }
        modificationFunctions = { [setConfirmPassword, () => { setErrorMessage("") }] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstFieldTop + 2 * topDistance,
          fieldTop: firstFieldTop + 2 * topDistance
        } }
      />

      <Button
        buttonText = { "Sign up" }
        actionFunctions = { [signUp] }
        disabled = { false }
        prototypeDisplayParameters = { signUpButtonPrototypeDisplayParameters }
      />

      {/* <SingleRowText
        text = { errorMessage }
        redirectionUrl = { [] }
        message = { [] }
        top = { errorMessageTop }
        left = { errorMessageLeft }
        width = { errorMessageWidth }
        fontSize = { errorMessageFontSize }
        color = { errorMessageColor }
      /> */}

      <MultipleRowText
        text = { errorMessage }
        prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
      />
    </Box>
  )
}
