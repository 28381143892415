import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../../03_baseComponents/Box';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../../03_baseComponents/Button';





export interface AdminHorsesNavigationProps {
  webpageStyle: WebpageStyleProps,
  adminHorseListPageContentType: string,
  changeAdminHorseListPageContentType: Array<any>
}





export const AdminHorsesNavigation: FC<AdminHorsesNavigationProps> = ({ webpageStyle, adminHorseListPageContentType,
  changeAdminHorseListPageContentType }) => {
  //

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.adminHorses.navigation.boxTop,
    left: webpageStyle.content.sidebar.navigation.boxLeft,
    height: webpageStyle.content.sidebar.adminHorses.navigation.boxHeight,
    width: webpageStyle.content.sidebar.navigation.boxWidth,
    backgroundColor: webpageStyle.content.sidebar.navigation.boxBackgroundColor,
    outlineStyle: webpageStyle.content.sidebar.navigation.boxOutlineStyle,
    outlineWidth: webpageStyle.content.sidebar.navigation.boxOutlineWidth,
    outlineColor: webpageStyle.content.sidebar.navigation.boxOutlineColor
  });


  const [firstButtonTop, setFirstButtonTop] = useState<number>(webpageStyle.content.sidebar.navigation.firstButtonTop);
  const [buttonTopDistance, setButtonTopDistance] = useState<number>(webpageStyle.content.sidebar.navigation.buttonTopDistance);

  const [buttonBackgroundColorActive, setButtonBackgroundColorActive] = useState<string>(webpageStyle.content.sidebar.navigation.buttonBackgroundColorActive);
  const [buttonContentColorActive, setButtonContentColorActive] = useState<string>(webpageStyle.content.sidebar.navigation.buttonContentColorActive);
  const [buttonBackgroundColorHighlighted, setButtonBackgroundColorHighlighted] = useState<string>(webpageStyle.content.sidebar.navigation.buttonBackgroundColorHighlighted);
  const [buttonContentColorHighlighted, setButtonContentColorHighlighted] = useState<string>(webpageStyle.content.sidebar.navigation.buttonContentColorHighlighted);

  const [buttonPrototypeDisplayParameters, setButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.navigation.firstButtonTop,
    left: webpageStyle.content.sidebar.navigation.buttonLeft,
    height: webpageStyle.content.sidebar.navigation.buttonHeight,
    width: webpageStyle.content.sidebar.navigation.buttonWidth,
    backgroundColor: buttonBackgroundColorActive,
    fontSize: webpageStyle.content.sidebar.navigation.buttonFontSize,
    contentColor: buttonContentColorActive
  });


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <div>
        <Button
          buttonText = { "Horse list" }
          actionFunctions = { [() => changeAdminHorseListPageContentType[0]("horseList")] }
          conditionFunction = { [() => {
            if(adminHorseListPageContentType !== "horseList") {
              return(true);
            } else {
              return(false);
            }
          }] }
          disabled = { false }
          prototypeDisplayParameters = { buttonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            top: firstButtonTop + 0 * buttonTopDistance,
            backgroundColor: adminHorseListPageContentType !== "horseList" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
            contentColor: adminHorseListPageContentType !== "horseList" ?  buttonContentColorActive : buttonContentColorHighlighted
          } }
        />

        <Button
          buttonText = { "Create horse" }
          actionFunctions = { [() => changeAdminHorseListPageContentType[0]("createHorse")] }
          conditionFunction = { [() => {
            if(adminHorseListPageContentType !== "createHorse") {
              return(true);
            } else {
              return(false);
            }
          }] }
          disabled = { false }
          prototypeDisplayParameters = { buttonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            top: firstButtonTop + 1 * buttonTopDistance,
            backgroundColor: adminHorseListPageContentType !== "createHorse" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
            contentColor: adminHorseListPageContentType !== "createHorse" ?  buttonContentColorActive : buttonContentColorHighlighted
          } }
        />
      </div>
    </Box>
  )
}
