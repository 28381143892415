import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../../services/webpageStyle';
import { Horse } from '../../../../../models/HorseProps';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../../03_baseComponents/Box';
import { InterestButton, InterestButtonPrototypeDisplayParameters, InterestButtonBoxChangedDisplayParameters } from '../../../../03_baseComponents/InterestButton';





export interface HorseInterestPanelProps {
  webpageStyle: WebpageStyleProps,
  horse: Array<Horse>,
  // initialHorseInterestState: boolean,
  interestFunction: Array<any>
}





export const HorseInterestPanel: FC<HorseInterestPanelProps> = ({ webpageStyle, horse, interestFunction }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [horseInterestState, setHorseInterestState] = useState<boolean>(horse[0].interest);


  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.horseProfile.horseInterestPanel.boxTop,
    left: webpageStyle.content.mainContent.horseProfile.horseInterestPanel.boxLeft,
    height: webpageStyle.content.mainContent.horseProfile.horseInterestPanel.boxHeight,
    width: webpageStyle.content.mainContent.horseProfile.horseInterestPanel.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.horseProfile.horseInterestPanel.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.horseProfile.horseInterestPanel.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.horseProfile.horseInterestPanel.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.horseProfile.horseInterestPanel.boxOutlineColor
  });


  const [interestButtonPrototypeDisplayParameters, setInterestButtonPrototypeDisplayParameters] = useState<InterestButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.horseProfile.horseInterestPanel.interestButtonTop,
    left: webpageStyle.content.mainContent.horseProfile.horseInterestPanel.interestButtonLeft,
    size: webpageStyle.content.mainContent.horseProfile.horseInterestPanel.interestButtonSize
  });


  const changeHorseInterest = () => {
    //

    // run API ...

    // feltételek ...

    setHorseInterestState(horseInterestState === true ? false : true);
  }


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <InterestButton
        value = { horseInterestState }
        userId = { -1 }
        horseId = { horse[0].data.id }
        functions = { [changeHorseInterest] }
        prototypeDisplayParameters = { interestButtonPrototypeDisplayParameters }
      />
    </Box>
  )
}
