import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TableRowsIcon from '@mui/icons-material/TableRows';
import AppsIcon from '@mui/icons-material/Apps';
import { PopUpWindow } from './PopUpWindow';
import { Button } from "@mui/material";







export interface IconDisplayProps {
  iconType: string,
  size: number,
  color: string
}

export interface IconDisplayAndOnMouseOverFunctionProps {
  iconType: string,
  message: Array<string>,
  size: number,
  color: string
}

export interface IconDisplayAndOnClickFunctionProps {
  iconType: string,
  message: Array<string>,
  onClickFunctions: Array<any>,
  size: number,
  color: string
}

export interface AdjustableIconProps {
  iconType: string,
  message: Array<string>, // a felugró ablakra adjon szöveget
  onClickFunctions: Array<any>, // rákattintásra reagáljon
  top: number,
  left: number,
  size: number,
  color: string
}





const IconDisplay: FC<IconDisplayProps> = ({iconType, size, color}) => {
  switch(iconType) {
    case ("StarIcon"):
      return(
        <StarIcon
          style = {{ fontSize: size, color: color }}
        />
      )
      break;
    case ("StarBorderIcon"):
      return(
        <StarBorderIcon
          style = {{ fontSize: size, color: color }}
        />
      )
      break;
    case ("FemaleIcon"):
      return(
        <FemaleIcon
          style = {{ fontSize: size, color: color }}
        />
      )
      break;
    case ("MaleIcon"):
      return(
        <MaleIcon
          style = {{ fontSize: size, color: color }}
        />
      )
      break;
    case ("CheckCircleOutlineIcon"):
      return(
        <CheckCircleOutlineIcon
          style = {{ fontSize: size, color: color }}
        />
      )
      break;
    case ("ErrorOutlineIcon"):
      return(
        <ErrorOutlineIcon
          style = {{ fontSize: size, color: color }}
        />
      )
      break;
    case ("VisibilityIcon"):
      return(
        <VisibilityIcon
          style = {{ fontSize: size, color: color }}
        />
      )
      break;
    case ("VisibilityOffIcon"):
      return(
        <VisibilityOffIcon
          style = {{ fontSize: size, color: color }}
        />
      )
      break;
    case ("TableRowsIcon"): // list element
      return(
        <TableRowsIcon
          style = {{ fontSize: size, color: color }}
        />
      )
      break;
    case ("AppsIcon"): // grid element
      return(
        <AppsIcon
          style = {{ fontSize: size, color: color }}
        />
      )
      break;
  }

  return(
    <div>
      No icon
    </div>
  )
}

const IconDisplayAndOnMouseOverFunction: FC<IconDisplayAndOnMouseOverFunctionProps> = ({iconType, size, color, message}) => {
  //

  return(
    <PopUpWindow
      message = { message }
    >
      <IconDisplay
        iconType = { iconType }
        size = { size }
        color = { color }
      />
    </PopUpWindow>
  )
}

const IconDisplayAndOnClickFunction: FC<IconDisplayAndOnClickFunctionProps> = ({iconType, message, onClickFunctions, size, color}) => {
  const runOnClickFunctions = () => {
    if(onClickFunctions.length !== 0) {
      onClickFunctions[0]();
    }
  }

  if(onClickFunctions.length !== 0) {
    return(
      <Button
        variant="text"
        startIcon={
          <IconDisplayAndOnMouseOverFunction
            iconType = { iconType }
            size = { size }
            color = { color }
            message = { message }
          />
        }
        onClick={ () => runOnClickFunctions() }
        style={{ color: "black", padding: "1px" }}
      />
    )
  } else {
    return(
      <IconDisplayAndOnMouseOverFunction
        iconType = { iconType }
        size = { size }
        color = { color }
        message = { message }
      />
    )
  }
}

export const AdjustableIcon: FC<AdjustableIconProps> = ({iconType, message, onClickFunctions, top, left, size, color}) => {
  return(
    <div style = {{ position: "absolute", top: top, left: left }}>
      <IconDisplayAndOnClickFunction
        iconType = { iconType }
        message = { message }
        onClickFunctions = { onClickFunctions }
        size = { size }
        color = { color }
      />
    </div>
  )
}
