import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { Collection } from '../../../03_baseComponents/Collection';
import { KeyValueDisplay, KeyValueDisplayPrototypeDisplayParameters, KeyValueDisplayChangedDisplayParameters } from '../../../03_baseComponents/KeyValueDisplay';
import { KeyValueInput, KeyValueInputPrototypeDisplayParameters, KeyValueInputChangedDisplayParameters } from '../../../03_baseComponents/KeyValueInput';
import { Horse, HorseData } from '../../../../models/HorseProps';
import { HorseCatalogListElement } from './HorseCatalogCollection/HorseCatalogListElement';
import { HorseCatalogGridElement } from './HorseCatalogCollection/HorseCatalogGridElement';
import { LoggedInUser } from '../../../../models/UserProps';





export interface HorseCatalogCollectionProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  selectedAndSortedHorses: Array<Horse>,
  selectedAndSortedHorsesAreChanged: number,
  resizeMainContentHeight: Array<any>
}





export const HorseCatalogCollection: FC<HorseCatalogCollectionProps> = ({ webpageStyle, loggedInUser, selectedAndSortedHorses,
  selectedAndSortedHorsesAreChanged, resizeMainContentHeight }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [collectionType, setCollectionType] = useState<string>("list");

  const [listElements, setListElements] = useState<Array<JSX.Element>>([]);
  const [gridElements, setGridElements] = useState<Array<JSX.Element>>([]);

  const [selectedHorsesAreChangedInCollection, setSelectedHorsesAreChangedInCollection] = useState<number>(0);


  const [listCollectionReferenceBoxHeight, setListCollectionReferenceBoxHeight] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.listCollectionReferenceBoxHeight);
  const [gridCollectionReferenceBoxHeight, setGridCollectionReferenceBoxHeight] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.gridCollectionReferenceBoxHeight);

  const [boxHeight, setBoxHeight] = useState<number>(collectionType === "list" ? webpageStyle.content.mainContent.horseCatalogCollection.listCollectionReferenceBoxHeight : webpageStyle.content.mainContent.horseCatalogCollection.gridCollectionReferenceBoxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.boxTop,
    left: webpageStyle.content.mainContent.boxLeft,
    height: boxHeight,
    width: webpageStyle.content.mainContent.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.boxOutlineColor
  });

  
  const [catalogListCollectionTop, setCatalogListCollectionTop] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogListCollectionTop);
  const [catalogListCollectionLeft, setCatalogListCollectionLeft] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogListCollectionLeft);
  const [catalogListCollectionN, setCatalogListCollectionN] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogListCollectionNumberOfRows);
  const [catalogListCollectionM, setCatalogListCollectionM] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogListCollectionNumberOfColumns);
  const [catalogListElementHeight, setCatalogListElementHeight] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogListElementHeight);
  const [catalogListElementWidth, setCatalogListElementWidth] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogListElementWidth);

  const [catalogListPaginationTop, setCatalogListPaginationTop] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogListPaginationTop);
  const [catalogListPaginationLeft, setCatalogListPaginationLeft] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogListPaginationLeft);
  const [catalogListPaginationWidth, setCatalogListPaginationWidth] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogListPaginationWidth);
  const [catalogListPaginationFontSize, setCatalogListPaginationFontSize] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogListPaginationFontSize);

  const [catalogGridCollectionTop, setCatalogGridCollectionTop] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogGridCollectionTop);
  const [catalogGridCollectionLeft, setCatalogGridCollectionLeft] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogGridCollectionLeft);
  const [catalogGridCollectionN, setCatalogGridCollectionN] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogGridCollectionNumberOfRows);
  const [catalogGridCollectionM, setCatalogGridCollectionM] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogGridCollectionNumberOfColumns);
  const [catalogGridElementHeight, setCatalogGridElementHeight] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogGridElementHeight);
  const [catalogGridElementWidth, setCatalogGridElementWidth] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogGridElementWidth);

  const [catalogGridPaginationTop, setCatalogGridPaginationTop] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogGridPaginationTop);
  const [catalogGridPaginationLeft, setCatalogGridPaginationLeft] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogGridPaginationLeft);
  const [catalogGridPaginationWidth, setCatalogGridPaginationWidth] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogGridPaginationWidth);
  const [catalogGridPaginationFontSize, setCatalogGridPaginationFontSize] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.catalogGridPaginationFontSize);

  const [sumOfSelectedHorsesPrototypeDisplayParameters, setSumOfSelectedHorsesPrototypeDisplayParameters] = useState<KeyValueDisplayPrototypeDisplayParameters>({
    keyTop: webpageStyle.content.mainContent.horseCatalogCollection.sumOfSelectedHorsesKeyTop,
    keyLeft: webpageStyle.content.mainContent.horseCatalogCollection.sumOfSelectedHorsesKeyLeft,
    keyWidth: webpageStyle.content.mainContent.horseCatalogCollection.sumOfSelectedHorsesKeyWidth,
    keyFontSize: webpageStyle.content.mainContent.horseCatalogCollection.sumOfSelectedHorsesKeyFontSize,
    keyColor: webpageStyle.content.mainContent.horseCatalogCollection.sumOfSelectedHorsesKeyColor,
    valueTop: webpageStyle.content.mainContent.horseCatalogCollection.sumOfSelectedHorsesValueTop,
    valueLeft: webpageStyle.content.mainContent.horseCatalogCollection.sumOfSelectedHorsesValueLeft,
    valueWidth: webpageStyle.content.mainContent.horseCatalogCollection.sumOfSelectedHorsesValueWidth,
    valueFontSize: webpageStyle.content.mainContent.horseCatalogCollection.sumOfSelectedHorsesValueFontSize,
    valueColor: webpageStyle.content.mainContent.horseCatalogCollection.sumOfSelectedHorsesValueColor
  });

  const [collectionTypeSingleSelectPrototypeDisplayParameters, setCollectionTypeSingleSelectPrototypeDisplayParameters] = useState<KeyValueInputPrototypeDisplayParameters>({
    keyTop: webpageStyle.content.mainContent.horseCatalogCollection.collectionTypeSingleSelectKeyTop,
    keyLeft: webpageStyle.content.mainContent.horseCatalogCollection.collectionTypeSingleSelectKeyLeft,
    keyWidth: webpageStyle.content.mainContent.horseCatalogCollection.collectionTypeSingleSelectKeyWidth,
    keyFontSize: webpageStyle.content.mainContent.horseCatalogCollection.collectionTypeSingleSelectKeyFontSize,
    keyColor: webpageStyle.content.mainContent.horseCatalogCollection.collectionTypeSingleSelectKeyColor,
    fieldTop: webpageStyle.content.mainContent.horseCatalogCollection.collectionTypeSingleSelectFieldTop,
    fieldLeft: webpageStyle.content.mainContent.horseCatalogCollection.collectionTypeSingleSelectFieldLeft,
    fieldHeight: webpageStyle.content.mainContent.horseCatalogCollection.collectionTypeSingleSelectFieldHeight,
    fieldWidth: webpageStyle.content.mainContent.horseCatalogCollection.collectionTypeSingleSelectFieldWidth,
    fieldBackgroundColor: webpageStyle.content.mainContent.horseCatalogCollection.collectionTypeSingleSelectFieldBackgroundColor,
    valueFontSize: webpageStyle.content.mainContent.horseCatalogCollection.collectionTypeSingleSelectValueFontSize,
    valueColor: webpageStyle.content.mainContent.horseCatalogCollection.collectionTypeSingleSelectValueColor,
  });


  const getStarted = () =>{
    //

    // console.log(selectedHorses);

    const temporaryListElements: Array<JSX.Element> = [];
    const temporaryGridElements: Array<JSX.Element> = [];
    const temporaryAdminHorseListElements: Array<JSX.Element> = [];
    for(let i: number = 0; i < selectedAndSortedHorses.length; i++) {
      temporaryListElements.push(
        <HorseCatalogListElement
          webpageStyle = { webpageStyle }
          loggedInUser = { loggedInUser }
          horse = { [selectedAndSortedHorses[i]] }
          initialHorseInterestState = { selectedAndSortedHorses[i].interest }
          interestFunction = { [] }
          key = { selectedAndSortedHorses[i].id.toString() }
        />
      )
      temporaryGridElements.push(
        <HorseCatalogGridElement
          webpageStyle = { webpageStyle }
          loggedInUser = { loggedInUser }
          horse = { [selectedAndSortedHorses[i]] }
          initialHorseInterestState = { selectedAndSortedHorses[i].interest }
          interestFunction = { [] }
          key = { selectedAndSortedHorses[i].id.toString() }
        />
      )
    }
    setListElements(temporaryListElements);
    setGridElements(temporaryGridElements);

    setIsLoading(false);

    setSelectedHorsesAreChangedInCollection(selectedHorsesAreChangedInCollection + 1);
  }

  const changeCollectionType = (newValue: Array<string>) => {
    //

    if(newValue.length !== 0) {
      setCollectionType(newValue[0]);
    }

    if(newValue.length !== 0) {
      if(newValue[0] === "list") {
        setBoxHeight(listCollectionReferenceBoxHeight);
        resizeMainContentHeight[0](listCollectionReferenceBoxHeight);
      }

      if(newValue[0] === "grid") {
        setBoxHeight(gridCollectionReferenceBoxHeight);
        resizeMainContentHeight[0](gridCollectionReferenceBoxHeight);
      }
    }
  }


  useEffect(() => {
    resizeMainContentHeight[0](boxHeight);
  }, []);

  useEffect(() => {
    getStarted();
  }, [selectedAndSortedHorsesAreChanged]);


  // console.log(isLoading);

  if(isLoading === false) {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
        changedDisplayParameters = { {
          height: boxHeight
        } }
      >
        <div>
          { collectionType === "list" ?
            <Collection
              collectionType = { "ListCollection" }
              collectionTop = { catalogListCollectionTop }
              collectionLeft = { catalogListCollectionLeft }
              n = { catalogListCollectionN }
              m = { catalogListCollectionM }
              elementHeight = { catalogListElementHeight }
              elementWidth = { catalogListElementWidth }
              paginationTop = { catalogListPaginationTop }
              paginationLeft = { catalogListPaginationLeft }
              paginationWidth = { catalogListPaginationWidth }
              paginationFontSize = { catalogListPaginationFontSize }
              selectedElementsAreChanged = { selectedHorsesAreChangedInCollection }
            >
              { listElements }
            </Collection> : null
          }
    
          { collectionType === "grid" ?
            <Collection
              collectionType = { "GridCollection" }
              collectionTop = { catalogGridCollectionTop }
              collectionLeft = { catalogGridCollectionLeft }
              n = { catalogGridCollectionN }
              m = { catalogGridCollectionM }
              elementHeight = { catalogGridElementHeight }
              elementWidth = { catalogGridElementWidth }
              paginationTop = { catalogGridPaginationTop }
              paginationLeft = { catalogGridPaginationLeft }
              paginationWidth = { catalogGridPaginationWidth }
              paginationFontSize = { catalogGridPaginationFontSize }
              selectedElementsAreChanged = { selectedHorsesAreChangedInCollection }
            >
              { gridElements }
            </Collection> : null
          }
  
          <KeyValueDisplay
            keyText = { "Number of selected horses" }
            valueText = { selectedAndSortedHorses.length.toString() }
            prototypeDisplayParameters = { sumOfSelectedHorsesPrototypeDisplayParameters }
          />
  
          <KeyValueInput
            keyText = { "Collection type" }
            initialValueString = { [collectionType] }
            initialValueNumber = { [] }
            initialValueBoolean = { [] }
            optionsString = { ["list", "grid"] }
            inputType = { "SingleSelectInput" }
            modificationFunctions = { [changeCollectionType] }
            properInputDisplay = { false }
            disabled = { false }
            prototypeDisplayParameters = { collectionTypeSingleSelectPrototypeDisplayParameters }
          />
        </div>
      </Box>
    )
  } else {
    return(
      <div>
        Loading...
      </div>
    )
  }
}
