import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../services/webpageStyle';
import { getData } from '../../../services/API.services';
import { AdminProposalsNavigation } from './sidebar/AdminProposalsNavigation';
import { AdminProposalList } from './mainContent/AdminProposalList';





export interface AdminProposalsPageContentProps {
  webpageStyle: WebpageStyleProps,
  doAction: Array<any>,
  resizeContentHeight: Array<any>
}





export const AdminProposalsPageContent: FC<AdminProposalsPageContentProps> = ({ webpageStyle, doAction, resizeContentHeight }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [adminProposalsPageContentType, setAdminProposalsPageContentType] = useState<string>("proposalList");

  //

  const [contentHeight, setContentHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);

  const [mainContentHeight, setMainContentHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);
  const [sidebarHeight, setSidebarHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);

  const resizeMainContentHeight = (newMainContentHeight: number) => {
    setMainContentHeight(newMainContentHeight);
  }

  const resizeSidebarHeight = (newSidebarHeight: number) => {
    setSidebarHeight(newSidebarHeight);
  }

  useEffect(() => {
    setContentHeight(Math.max(mainContentHeight, sidebarHeight));
    resizeContentHeight[0](Math.max(mainContentHeight, sidebarHeight));
  }, [mainContentHeight, sidebarHeight]);

  return(
    <div>
      <AdminProposalsNavigation
        webpageStyle = { webpageStyle }
        adminProposalsPageContentType = { adminProposalsPageContentType }
        changeAdminProposalsPageContentType = { [setAdminProposalsPageContentType] }
        resizeSidebarHeight = { [resizeSidebarHeight] }
      />

      { (/*isLoading === false &&*/ adminProposalsPageContentType === "proposalList") ?
        <AdminProposalList
          webpageStyle = { webpageStyle }
          resizeMainContentHeight = { [resizeMainContentHeight] }
        /> : null
      }
    </div>
  )
}
