import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../services/webpageStyle';
import { getData } from '../../../services/API.services';
import { AdminUsersNavigationAndFilteringAndSorting } from './sidebar/AdminUsersNavigationAndFilteringAndSorting';
import { AdminUserList } from './mainContent/AdminUserList';
import { AdminReadUserData } from './mainContent/AdminReadUserData';
import { AdminEditUserData } from './mainContent/AdminEditUserData';
import { AdminInviteUser } from './mainContent/AdminInviteUser';
import { UserData } from '../../../models/UserProps';
import { UserListSettings, UserListFilteringSettings, UserListSortingSettings } from '../../../models/UserListSettings';
import { sortUsers } from '../../../services/SortUsers';
import { LoggedInUser } from '../../../models/UserProps';





export interface AdminUsersPageContentProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  doAction: Array<any>,
  resizeContentHeight: Array<any>
}





export const AdminUsersPageContent: FC<AdminUsersPageContentProps> = ({ webpageStyle, loggedInUser,
  doAction, resizeContentHeight }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [adminUserListPageContentType, setAdminUserListPageContentType] = useState<string>("userList");

  const [allUsers, setAllUsers] = useState<Array<UserData>>([]);

  const [selectedAndSortedUsersAreChanged, setSelectedAndSortedUsersChanged] = useState<number>(0); // ? sorted -> van sorting?
  const [selectedAndSortedUsers, setSelectedAndSortedUsers] = useState<Array<UserData>>([]); // ? sorted -> van sorting?

  const [selectedUserId, setSelectedUserId] = useState<Array<number>>([]);

  const [recentUserListSettings, setRecentUserListSettings] = useState<UserListSettings>({
    filteringSettings: {
      keyWords: [],
      role: [],
      status: []
    },
    sortingSettings: {
      arguments: ["Id"],
      orders: ["Ascending"]
  }});


  const [contentHeight, setContentHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);

  const [mainContentHeight, setMainContentHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);
  const [sidebarHeight, setSidebarHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);


  const saveUsers = async (usersVariable: Array<UserData>) => {
    setAllUsers(usersVariable);
    changeSelectedUsers(usersVariable, recentUserListSettings);
  }

  const getUsers = async () => {
    //

    const result = await getData("/api/users/all/read/admin", true);
    if( !result || ( result as { status: number, message: string } )?.status >= 400 ) {
      if(result !== undefined) {
        if(result.message !== undefined) {
          console.log(result.message);
        }
      }
    } else {
      //

      // console.log(result);

      saveUsers(result);
    }
  }

  const changeFilteringOrSortingSettings = (newUserListSettings: UserListSettings) => {
    changeSelectedUsers(allUsers, newUserListSettings);
    setRecentUserListSettings(newUserListSettings);
  }

  const changeSelectedUsers = (usersVariable: Array<UserData>, newUserListSettings: UserListSettings) => {
    //
    
    const newUserListFilterSettings: UserListFilteringSettings = newUserListSettings.filteringSettings;
    // const newHorseCatalogSortSettings: HorseCatalogSortSettings = newHorseCatalogSettings.horseCatalogSortSettings;

    // // console.log(horsesVariable);

    let temporarySelectedUsers: Array<UserData> = [];

    if(newUserListFilterSettings.keyWords.length !== 0) {
      const usersDataWordsArray: Array<Array<string>> = [];
      for(let i: number = 0; i < usersVariable.length; i++) {
        const temporaryUsersDataWordsArray: Array<Array<string>> = [];
        if(usersVariable[i].name !== null) {
          temporaryUsersDataWordsArray.push(usersVariable[i].name.split(" "));
        }
        if(usersVariable[i].email !== null) {
          temporaryUsersDataWordsArray.push(usersVariable[i].email.split(" "));
        }
        for(let j: number = 0; j < temporaryUsersDataWordsArray.length; j++) {
          usersDataWordsArray.push([]);
          for(let k: number = 0; k < temporaryUsersDataWordsArray[j].length; k++) {
            usersDataWordsArray[i].push(temporaryUsersDataWordsArray[j][k]);
          }
        }
      }
      
      for(let i: number = 0; i < usersVariable.length; i++) {
        let selectHorseByKeywords: boolean = true;
        const temporaryHorseDataWords: Array<string> = usersDataWordsArray[i];
        const temporaryKeyWords: Array<string> = newUserListFilterSettings.keyWords;
        for(let j: number = 0; j < temporaryKeyWords.length; j++) {
          let keyWordMatches: boolean = false;
          for(let k: number = 0; k < temporaryHorseDataWords.length; k++) {
            if(temporaryKeyWords[j].toLowerCase() === temporaryHorseDataWords[k].toLowerCase()) {
              temporaryHorseDataWords.splice(k, 1);
              keyWordMatches = true;
              k = temporaryHorseDataWords.length;
            }
          }
          if(keyWordMatches === false) {
            selectHorseByKeywords = false;
            j = temporaryKeyWords.length;
          }
        }
        if(selectHorseByKeywords === true) {
          temporarySelectedUsers.push(usersVariable[i]);
        }
      }
    } else {
      for(let i: number = 0; i < usersVariable.length; i++) {
        temporarySelectedUsers.push(usersVariable[i]);
      }
    }

    if(newUserListFilterSettings.role.length !== 0) {
      for(let i: number = 0; i < temporarySelectedUsers.length; i++) {
        if(temporarySelectedUsers[i].role !== newUserListFilterSettings.role[0]) {
          temporarySelectedUsers.splice(i, 1);
          i--;
        }
      }
    }

    if(newUserListFilterSettings.status.length !== 0) {
      for(let i: number = 0; i < temporarySelectedUsers.length; i++) {
        if(temporarySelectedUsers[i].status !== newUserListFilterSettings.status[0]) {
          temporarySelectedUsers.splice(i, 1);
          i--;
        }
      }
    }

    // // if(accessibility.length !== 0) {
    // //   for(let i: number = 0; i < temporarySelectedHorses.length; i++) {
    // //     if(temporarySelectedHorses[i].data.accessibility.toLowerCase() !== accessibility[0].toLowerCase()) {
    // //       temporarySelectedHorses.splice(i, 1);
    // //       i--;
    // //     }
    // //   }
    // // }

    // console.log(newUserListSettings);

    if(newUserListSettings.sortingSettings.arguments.length >= 3 &&
      newUserListSettings.sortingSettings.orders.length >= 3) {
      temporarySelectedUsers = sortUsers(temporarySelectedUsers,
        newUserListSettings.sortingSettings.arguments[2],
        newUserListSettings.sortingSettings.orders[2]);
    }

    if(newUserListSettings.sortingSettings.arguments.length >= 2 &&
      newUserListSettings.sortingSettings.orders.length >= 2) {
      temporarySelectedUsers = sortUsers(temporarySelectedUsers,
        newUserListSettings.sortingSettings.arguments[1],
        newUserListSettings.sortingSettings.orders[1]);
    }

    if(newUserListSettings.sortingSettings.arguments.length >= 1 &&
      newUserListSettings.sortingSettings.orders.length >= 1) {
      temporarySelectedUsers = sortUsers(temporarySelectedUsers,
        newUserListSettings.sortingSettings.arguments[0],
        newUserListSettings.sortingSettings.orders[0]);
    } else {
      temporarySelectedUsers = sortUsers(temporarySelectedUsers, "Id", "Ascending");
    }

    // setSelectedAndSortedHorses(temporarySelectedHorses);
    // // setSelectedAndSortedHorses(sortHorses(temporarySelectedHorses, sortingArgument[0], sortingOrder[0]));

    // setSelectedAndSortedHorsesChanged(selectedAndSortedHorsesAreChanged + 1);

    // setIsLoading(false);

    setSelectedAndSortedUsers(temporarySelectedUsers);
    
    setSelectedAndSortedUsersChanged(selectedAndSortedUsersAreChanged + 1);

    setIsLoading(false);
  }

  const resizeMainContentHeight = (newMainContentHeight: number) => {
    setMainContentHeight(newMainContentHeight);
  }

  const resizeSidebarHeight = (newSidebarHeight: number) => {
    setSidebarHeight(newSidebarHeight);
  }

  const reloadUsers = async () => {
    await getUsers();
    setAdminUserListPageContentType("userList");
  }

  const overwriteDeletedUser = (userId: number) => {
    const temporaryAllUsers: Array<UserData> = allUsers;
    const temporarySelectedAndSortedUsers: Array<UserData> = selectedAndSortedUsers;

    for(let i: number = 0; i < temporaryAllUsers.length; i++) {
      if(temporaryAllUsers[i].id === userId) {
        temporaryAllUsers[i].status = "deleted";
      }
    }

    for(let i: number = 0; i < temporarySelectedAndSortedUsers.length; i++) {
      if(temporarySelectedAndSortedUsers[i].id === userId) {
        temporarySelectedAndSortedUsers[i].status = "deleted";
      }
    }

    setAllUsers(temporaryAllUsers);
    setSelectedAndSortedUsers(temporarySelectedAndSortedUsers);
  }


  useEffect(() => {
    setContentHeight(Math.max(mainContentHeight, sidebarHeight));
    resizeContentHeight[0](Math.max(mainContentHeight, sidebarHeight));
  }, [mainContentHeight, sidebarHeight]);

  useEffect(() => {
    getUsers();
  }, []);


  return(
    <div>
      {isLoading === false ?
        <AdminUsersNavigationAndFilteringAndSorting
          webpageStyle = { webpageStyle }
          adminUsersPageContentType = { adminUserListPageContentType }
          recentUserListSettings = { recentUserListSettings }
          changeAdminUserListPageContentType = { [setAdminUserListPageContentType] }
          changeFilteringOrSortingSettings = { [changeFilteringOrSortingSettings] }
          resizeSidebarHeight = { [resizeSidebarHeight] }
        /> : null
      }

      { (isLoading === false && adminUserListPageContentType === "userList") ?
        <AdminUserList
          webpageStyle = { webpageStyle }
          loggedInUser = { loggedInUser }
          selectedAndSortedUsers = { selectedAndSortedUsers }
          selectedAndSortedUsersAreChanged = { selectedAndSortedUsersAreChanged }
          changeAdminUserListPageContentType = { [setAdminUserListPageContentType] }
          overwriteDeletedUser = { [overwriteDeletedUser] }
          changeSelectedUserId = { [setSelectedUserId] }
          resizeMainContentHeight = { [resizeMainContentHeight] }
        /> : null
      }

      { (isLoading === false && adminUserListPageContentType === "readUserData") ?
        <AdminReadUserData
          webpageStyle = { webpageStyle }
          selectedUserId = { selectedUserId }
          resizeMainContentHeight = { [resizeMainContentHeight] }
        /> : null
      }

      { (isLoading === false && adminUserListPageContentType === "editUserData") ?
        <AdminEditUserData
          webpageStyle = { webpageStyle }
          selectedUserId = { selectedUserId }
          resetUsersData = { [getUsers] }
          resizeMainContentHeight = { [resizeMainContentHeight] }
        /> : null
      }

      { (isLoading === false && adminUserListPageContentType === "inviteUser") ?
        <AdminInviteUser
          webpageStyle = { webpageStyle }
          reloadUsers = { [reloadUsers] }
          resizeMainContentHeight = { [resizeMainContentHeight] }
        /> : null
      }
    </div>
  )
}
