import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../Box';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../Button';
import { KeyValueInput, KeyValueInputPrototypeDisplayParameters, KeyValueInputChangedDisplayParameters } from '../KeyValueInput';
import { UploadedFile, AdjustableFileInput } from '../../04_subComponents/FileInput';
import { Image, UploadedImageElement } from '../ImageUploader/UploadedImageElement';
import { Video, UploadedVideoElement } from '../VideoUploader/UploadedVideoElement';





export interface ImageAndVideoInputProps {
  webpageStyle: WebpageStyleProps,
  uploadImage: Array<any>,
  uploadVideo: Array<any>
}





export const ImageAndVideoInput: FC<ImageAndVideoInputProps> = ({ webpageStyle, uploadImage, uploadVideo }) => {
  //

  const [mode, setMode] = useState<string>("image");

  const [youtubeUrl, setYoutubeUrl] = useState<Array<string>>([]);


  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.boxTop,
    left: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.boxLeft,
    height: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.boxHeight,
    width: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.boxWidth,
    backgroundColor: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.boxBackgroundColor,
    outlineStyle: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.boxOutlineStyle,
    outlineWidth: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.boxOutlineWidth,
    outlineColor: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.boxOutlineColor
  });

  const [modeButtonBackgroundColorActive, setModeButtonBackgroundColorActive] = useState<string>(webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.modeButtonBackgroundColorActive);
  const [modeButtonBackgroundColorInactive, setModeButtonBackgroundColorInactive] = useState<string>(webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.modeButtonBackgroundColorInactive);
  const [modeButtonContentColorActive, setModeButtonContentColorActive] = useState<string>(webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.modeButtonContentColorActive);
  const [modeButtonContentColorInactive, setModeButtonContentColorInactive] = useState<string>(webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.modeButtonContentColorInactive);

  const [imageModePrototypeDisplayParameters, setImageModePrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.imageModeButtonTop,
    left: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.imageModeButtonLeft,
    height: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.imageModeButtonHeight,
    width: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.imageModeButtonWidth,
    fontSize: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.imageModeButtonFontSize,
    backgroundColor: modeButtonBackgroundColorActive,
    contentColor: modeButtonContentColorActive
  });

  const [videoModePrototypeDisplayParameters, setVideoModePrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.videoModeButtonTop,
    left: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.videoModeButtonLeft,
    height: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.videoModeButtonHeight,
    width: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.videoModeButtonWidth,
    fontSize: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.videoModeButtonFontSize,
    backgroundColor: modeButtonBackgroundColorActive,
    contentColor: modeButtonContentColorActive
  });

  const [fileUploadTop, setFileUploadTop] = useState<number>(webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.fileUploadTop);
  const [fileUploadLeft, setFileUploadLeft] = useState<number>(webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.fileUploadLeft);

  const [keyValueInputPrototypeDisplayParameters, setKeyValueInputPrototypeDisplayParameters] = useState<KeyValueInputPrototypeDisplayParameters>({
    keyTop: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.keyValueInputTop,
    keyLeft: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.keyLeft,
    keyWidth: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.keyWidth,
    keyFontSize: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.keyFontSize,
    keyColor: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.keyColor,
    fieldTop: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.keyValueInputTop,
    fieldLeft: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.fieldLeft,
    fieldHeight: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.fieldHeight,
    fieldWidth: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.fieldWidth,
    fieldBackgroundColor: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.fieldBackgroundColor,
    valueFontSize: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.valueFontSize,
    valueColor: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.valueColor,
  });

  const [videoUploadButtonPrototypeDisplayParameters, setVideoUploadButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.videoUploadButtonTop,
    left: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.videoUploadButtonLeft,
    height: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.videoUploadButtonHeight,
    width: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.videoUploadButtonWidth,
    backgroundColor: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.videoUploadButtonBackgroundColorActive,
    fontSize: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.videoUploadButtonFontSize,
    contentColor: webpageStyle.baseComponent.imageAndVideoUploader.imageAndVideoInput.videoUploadButtonContentColorActive
  });


  const uploadNewImage = (uploadedFile: UploadedFile) => {
    if(uploadedFile.fileExtension === "jpg" || uploadedFile.fileExtension === "jpeg" || uploadedFile.fileExtension === "png") {
      const newlyUploadedImage: Image = {
        uploadedImage: [uploadedFile],
        sourceUrl: [],
        oldTitle: uploadedFile.title,
        title: uploadedFile.title,
        fileExtension: uploadedFile.fileExtension
      }
      uploadImage[0](newlyUploadedImage);
    }
  }

  const uploadNewVideo = () => {
    const newlyUploadedVideo: Video = {
      sourceUrl: youtubeUrl,
      oldTitle: "",
      title: ""
    };
    uploadVideo[0](newlyUploadedVideo);
  }


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <div>
        <Button
          buttonText = { "Image" }
          actionFunctions = { [() => setMode("image"), () => setYoutubeUrl([])] }
          disabled = { (mode === "image") }
          prototypeDisplayParameters = { imageModePrototypeDisplayParameters }
          changedDisplayParameters = { {
            backgroundColor: mode !== "image" ? modeButtonBackgroundColorActive : modeButtonBackgroundColorInactive,
            contentColor: mode !== "image" ? modeButtonBackgroundColorActive : modeButtonBackgroundColorInactive
          } }
        />

        <Button
          buttonText = { "Video" }
          actionFunctions = { [() => setMode("video")] }
          disabled = { mode === "video" }
          prototypeDisplayParameters = { videoModePrototypeDisplayParameters }
          changedDisplayParameters = { {
            backgroundColor: mode !== "video" ? modeButtonBackgroundColorActive : modeButtonBackgroundColorInactive,
            contentColor: mode !== "video" ? modeButtonBackgroundColorActive : modeButtonBackgroundColorInactive
          } }
        />

        { mode === "image" ?
          <AdjustableFileInput
            uploadFunction = { [uploadNewImage] }
            top = { fileUploadTop }
            left = { fileUploadLeft }
          /> : null
        }

        { mode === "video" ?
          <div>
            <KeyValueInput
              keyText = { "Youtube URL" }
              initialValueString = { [] }
              initialValueNumber = { [] }
              initialValueBoolean = { [] }
              optionsString = { [] }
              inputType = { "SingleRowTextInput" }
              modificationFunctions = { [setYoutubeUrl] }
              properInputDisplay = { false }
              disabled = { false }
              prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
            />

            <Button
              buttonText = { "Upload" }
              actionFunctions = { [() => uploadNewVideo()] }
              disabled = { false }
              prototypeDisplayParameters = { videoUploadButtonPrototypeDisplayParameters }
            />
          </div> : null
        }
      </div>
    </Box>
  )
}
