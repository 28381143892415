import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { Collection } from '../../../03_baseComponents/Collection';
import { KeyValueDisplay, KeyValueDisplayPrototypeDisplayParameters, KeyValueDisplayChangedDisplayParameters } from '../../../03_baseComponents/KeyValueDisplay';
import { UserData } from '../../../../models/UserProps';
import { AdminUserListElement } from './AdminUserList/AdminUserListElement';
import { LoggedInUser } from '../../../../models/UserProps';





export interface AdminUserListProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  selectedAndSortedUsers: Array<UserData>,
  selectedAndSortedUsersAreChanged: number,
  changeAdminUserListPageContentType: Array<any>,
  overwriteDeletedUser: Array<any>,
  changeSelectedUserId: Array<any>,
  resizeMainContentHeight: Array<any>
}





export const AdminUserList: FC<AdminUserListProps> = ({ webpageStyle, loggedInUser, selectedAndSortedUsers,
  selectedAndSortedUsersAreChanged, changeAdminUserListPageContentType, changeSelectedUserId, overwriteDeletedUser,
  resizeMainContentHeight }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listElements, setListElements] = useState<Array<JSX.Element>>([]);

  const [selectedUsersAreChangedInCollection, setSelectedUsersAreChangedInCollection] = useState<number>(0);


  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.mainContent.adminUserList.boxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.boxTop,
    left: webpageStyle.content.mainContent.boxLeft,
    height: boxHeight,
    width: webpageStyle.content.mainContent.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.boxOutlineColor
  });
  

  const [userListCollectionTop, setUserListCollectionTop] = useState<number>(webpageStyle.content.mainContent.adminUserList.userListCollectionTop);
  const [userListCollectionLeft, setUserListCollectionLeft] = useState<number>(webpageStyle.content.mainContent.adminUserList.userListCollectionLeft);
  const [userListCollectionN, setUserListCollectionN] = useState<number>(webpageStyle.content.mainContent.adminUserList.userListCollectionNumberOfRows);
  const [userListCollectionM, setUserListCollectionM] = useState<number>(webpageStyle.content.mainContent.adminUserList.userListCollectionNumberOfColumns);
  const [userListElementHeight, setUserListElementHeight] = useState<number>(webpageStyle.content.mainContent.adminUserList.userListElementHeight);
  const [userListElementWidth, setUserListElementWidth] = useState<number>(webpageStyle.content.mainContent.adminUserList.userListElementWidth);

  const [userListPaginationTop, setUserListPaginationTop] = useState<number>(webpageStyle.content.mainContent.adminUserList.userListPaginationTop);
  const [userListPaginationLeft, setUserListPaginationLeft] = useState<number>(webpageStyle.content.mainContent.adminUserList.userListPaginationLeft);
  const [userListPaginationWidth, setUserListPaginationWidth] = useState<number>(webpageStyle.content.mainContent.adminUserList.userListPaginationWidth);
  const [userListPaginationFontSize, setUserListPaginationFontSize] = useState<number>(webpageStyle.content.mainContent.adminUserList.userListPaginationFontSize);

  const [sumOfSelectedUsersPrototypeDisplayParameters, setSumOfSelectedUsersPrototypeDisplayParameters] = useState<KeyValueDisplayPrototypeDisplayParameters>({
    keyTop: webpageStyle.content.mainContent.adminUserList.sumOfSelectedUsersKeyTop,
    keyLeft: webpageStyle.content.mainContent.adminUserList.sumOfSelectedUsersKeyLeft,
    keyWidth: webpageStyle.content.mainContent.adminUserList.sumOfSelectedUsersKeyWidth,
    keyFontSize: webpageStyle.content.mainContent.adminUserList.sumOfSelectedUsersKeyFontSize,
    keyColor: webpageStyle.content.mainContent.adminUserList.sumOfSelectedUsersKeyColor,
    valueTop: webpageStyle.content.mainContent.adminUserList.sumOfSelectedUsersValueTop,
    valueLeft: webpageStyle.content.mainContent.adminUserList.sumOfSelectedUsersValueLeft,
    valueWidth: webpageStyle.content.mainContent.adminUserList.sumOfSelectedUsersValueWidth,
    valueFontSize: webpageStyle.content.mainContent.adminUserList.sumOfSelectedUsersValueFontSize,
    valueColor: webpageStyle.content.mainContent.adminUserList.sumOfSelectedUsersValueColor
  });


  const getStarted = () => {
    const temporaryListElements: Array<JSX.Element> = [];
    const temporaryAdminHorseListElements: Array<JSX.Element> = [];
    for(let i: number = 0; i < selectedAndSortedUsers.length; i++) {
      temporaryListElements.push(
        <AdminUserListElement
          webpageStyle = { webpageStyle }
          loggedInUser = { loggedInUser }
          initialUser = { [selectedAndSortedUsers[i]] }
          changeAdminUserListPageContentType = { changeAdminUserListPageContentType }
          overwriteDeletedUser = { overwriteDeletedUser }
          changeSelectedUserId = { changeSelectedUserId }
          key = { selectedAndSortedUsers[i].id.toString() }
        />
      )
    }
    setListElements(temporaryListElements);

    setIsLoading(false);

    setSelectedUsersAreChangedInCollection(selectedUsersAreChangedInCollection + 1);
  }


  useEffect(() => {
    resizeMainContentHeight[0](boxHeight);
  }, []);

  useEffect(() => {
    getStarted();
  }, [selectedAndSortedUsersAreChanged]);


  if(isLoading === false) {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      >
        <div>
          <Collection
            collectionType = { "ListCollection" }
            collectionTop = { userListCollectionTop }
            collectionLeft = { userListCollectionLeft }
            n = { userListCollectionN }
            m = { userListCollectionM }
            elementHeight = { userListElementHeight }
            elementWidth = { userListElementWidth }
            paginationTop = { userListPaginationTop }
            paginationLeft = { userListPaginationLeft }
            paginationWidth = { userListPaginationWidth }
            paginationFontSize = { userListPaginationFontSize }
            selectedElementsAreChanged = { selectedUsersAreChangedInCollection }
          >
            { listElements }
          </Collection>
  
          <KeyValueDisplay
            keyText = { "Number of selected users" }
            valueText = { selectedAndSortedUsers.length.toString() }
            prototypeDisplayParameters = { sumOfSelectedUsersPrototypeDisplayParameters }
          />
        </div>
      </Box>
    )
  } else {
    return(
      <div>
        Loading...
      </div>
    )
  }
}
