import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../03_baseComponents/Button';





export interface UserProfileNavigationProps {
  webpageStyle: WebpageStyleProps,
  userProfilePageContentType: string,
  changeUserProfilePageContentType: Array<any>,
  resizeSidebarHeight: Array<any>
}





export const UserProfileNavigation: FC<UserProfileNavigationProps> = ({ webpageStyle, userProfilePageContentType, changeUserProfilePageContentType, resizeSidebarHeight }) => {
  //

  const [sidebarHeight, setSidebarHeight] = useState<number>(webpageStyle.content.sidebar.userProfile.navigation.boxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.userProfile.navigation.boxTop,
    left: webpageStyle.content.sidebar.navigation.boxLeft,
    height: sidebarHeight,
    width: webpageStyle.content.sidebar.navigation.boxWidth,
    backgroundColor: webpageStyle.content.sidebar.navigation.boxBackgroundColor,
    outlineStyle: webpageStyle.content.sidebar.navigation.boxOutlineStyle,
    outlineWidth: webpageStyle.content.sidebar.navigation.boxOutlineWidth,
    outlineColor: webpageStyle.content.sidebar.navigation.boxOutlineColor
  });


  const [firstButtonTop, setFirstButtonTop] = useState<number>(webpageStyle.content.sidebar.navigation.firstButtonTop);
  const [buttonTopDistance, setButtonTopDistance] = useState<number>(webpageStyle.content.sidebar.navigation.buttonTopDistance);

  const [buttonBackgroundColorActive, setButtonBackgroundColorActive] = useState<string>(webpageStyle.content.sidebar.navigation.buttonBackgroundColorActive);
  const [buttonContentColorActive, setButtonContentColorActive] = useState<string>(webpageStyle.content.sidebar.navigation.buttonContentColorActive);
  const [buttonBackgroundColorHighlighted, setButtonBackgroundColorHighlighted] = useState<string>(webpageStyle.content.sidebar.navigation.buttonBackgroundColorHighlighted);
  const [buttonContentColorHighlighted, setButtonContentColorHighlighted] = useState<string>(webpageStyle.content.sidebar.navigation.buttonContentColorHighlighted);

  const [buttonPrototypeDisplayParameters, setButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.navigation.firstButtonTop,
    left: webpageStyle.content.sidebar.navigation.buttonLeft,
    height: webpageStyle.content.sidebar.navigation.buttonHeight,
    width: webpageStyle.content.sidebar.navigation.buttonWidth,
    backgroundColor: buttonBackgroundColorActive,
    fontSize: webpageStyle.content.sidebar.navigation.buttonFontSize,
    contentColor: buttonContentColorActive
  });


  useEffect(() => {
    resizeSidebarHeight[0](sidebarHeight);
  }, []);


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <div>
        <Button
          buttonText = { "Read user data" }
          actionFunctions = { [() => changeUserProfilePageContentType[0]("readUserData")] }
          conditionFunction = { [() => {
            if(userProfilePageContentType !== "readUserData") {
              return(true);
            } else {
              return(false);
            }
          }] }
          disabled = { false }
          prototypeDisplayParameters = { buttonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            top: firstButtonTop + 0 * buttonTopDistance,
            backgroundColor: userProfilePageContentType !== "readUserData" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
            contentColor: userProfilePageContentType !== "readUserData" ?  buttonContentColorActive : buttonContentColorHighlighted
          } }
        />

        <Button
          buttonText = { "Edit user data" }
          actionFunctions = { [() => changeUserProfilePageContentType[0]("editUserData")] }
          conditionFunction = { [() => {
            if(userProfilePageContentType !== "editUserData") {
              return(true);
            } else {
              return(false);
            }
          }] }
          disabled = { false }
          prototypeDisplayParameters = { buttonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            top: firstButtonTop + 1 * buttonTopDistance,
            backgroundColor: userProfilePageContentType !== "editUserData" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
            contentColor: userProfilePageContentType !== "editUserData" ?  buttonContentColorActive : buttonContentColorHighlighted
          } }
        />

        <Button
          buttonText = { "Change email" }
          actionFunctions = { [() => changeUserProfilePageContentType[0]("changeUserEmail")] }
          conditionFunction = { [() => {
            if(userProfilePageContentType !== "changeUserEmail") {
              return(true);
            } else {
              return(false);
            }
          }] }
          disabled = { false }
          prototypeDisplayParameters = { buttonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            top: firstButtonTop + 2 * buttonTopDistance,
            backgroundColor: userProfilePageContentType !== "changeUserEmail" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
            contentColor: userProfilePageContentType !== "changeUserEmail" ?  buttonContentColorActive : buttonContentColorHighlighted
          } }
        />

        <Button
          buttonText = { "Change password" }
          actionFunctions = { [() => changeUserProfilePageContentType[0]("changeUserPassword")] }
          conditionFunction = { [() => {
            if(userProfilePageContentType !== "changeUserPassword") {
              return(true);
            } else {
              return(false);
            }
          }] }
          disabled = { false }
          prototypeDisplayParameters = { buttonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            top: firstButtonTop + 3 * buttonTopDistance,
            backgroundColor: userProfilePageContentType !== "changeUserPassword" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
            contentColor: userProfilePageContentType !== "changeUserPassword" ?  buttonContentColorActive : buttonContentColorHighlighted
          } }
        />
      </div>
    </Box>
  )
}
