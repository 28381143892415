import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../services/webpageStyle';
import { Horse, HorseData } from '../../../models/HorseProps';
import { getData } from '../../../services/API.services';
import { AdminHorsesNavigationAndFilteringAndSorting } from './sidebar/AdminHorsesNavigationAndFilteringAndSorting';
import { AdminHorseList } from './mainContent/AdminHorseList';
import { AdminCreateHorse } from './mainContent/AdminCreateHorse';
import { sortHorses } from '../../../services/SortHorses';
import { HorseCatalogSettings, HorseCatalogFilteringSettings, HorseCatalogSortingSettings } from '../../../models/HorseCatalogSettings';
import { AdminEditHorse } from './mainContent/AdminEditHorse';





export interface AdminHorsesPageContentProps {
  webpageStyle: WebpageStyleProps,
  recentHorseCatalogSettings: HorseCatalogSettings,
  changeHorseCatalogSettings: Array<any>,
  doAction: Array<any>,
  resizeContentHeight: Array<any>
}





export const AdminHorsesPageContent: FC<AdminHorsesPageContentProps> = ({ webpageStyle, recentHorseCatalogSettings,
  changeHorseCatalogSettings, doAction, resizeContentHeight }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [adminHorseListPageContentType, setAdminHorseListPageContentType] = useState<string>("horseList");

  const [allHorses, setAllHorses] = useState<Array<Horse>>([]);

  const [selectedAndSortedHorsesAreChanged, setSelectedAndSortedHorsesChanged] = useState<number>(0);
  const [selectedAndSortedHorses, setSelectedAndSortedHorses] = useState<Array<Horse>>([]);

  const [selectedHorseId, setSelectedHorseId] = useState<Array<number>>([]);

  const [sortingArgument, setSortingArgument] = useState<Array<string>>(["Name"]);
  const [sortingOrder, setSortingOrder] = useState<Array<string>>(["Ascending"]);

  const [contentHeight, setContentHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);

  const [mainContentHeight, setMainContentHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);
  const [sidebarHeight, setSidebarHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);


  const saveHorses = async (horsesVariable: Array<Horse>) => {
    setAllHorses(horsesVariable);
    // setSelectedAndSortedHorses(horsesVariable);
     changeSelectedHorses(horsesVariable, recentHorseCatalogSettings);
    //  setSelectedAndSortedHorses(sortHorses(horsesVariable, sortingArgument[0], sortingOrder[0]));
  }

  const getHorses = async () => {
    //

    const result = await getData("/api/horses/all/read/admin", true);
    if( !result || ( result as { status: number, message: string } )?.status >= 400 ) {
      if(result !== undefined) {
        if(result.message !== undefined) {
          console.log(result.message);
        }
      }
    } else {
      //

      // console.log(result);

      saveHorses(result);
    }
  }

  const changeFilteringOrSortingSettings = (newHorseCatalogSettings: HorseCatalogSettings) => {
    changeSelectedHorses(allHorses, newHorseCatalogSettings);
  }

  const changeSelectedHorses = (horsesVariable: Array<Horse>, newHorseCatalogSettings: HorseCatalogSettings) => {
    //
    
    const newHorseCatalogFilterSettings: HorseCatalogFilteringSettings = newHorseCatalogSettings.filteringSettings;
    const newHorseCatalogSortSettings: HorseCatalogSortingSettings = newHorseCatalogSettings.sortingSettings;

    // console.log(horsesVariable);

    let temporarySelectedHorses: Array<Horse> = [];

    if(newHorseCatalogFilterSettings.keyWords.length !== 0) {
      const horsesDataWordsArray: Array<Array<string>> = [];
      for(let i: number = 0; i < horsesVariable.length; i++) {
        const temporaryHorseDataWordsArray: Array<Array<string>> = [];
        if(horsesVariable[i].data.name !== null) {
          temporaryHorseDataWordsArray.push(horsesVariable[i].data.name.split(" "));
        }
        if(horsesVariable[i].data.sex !== null) {
          temporaryHorseDataWordsArray.push(horsesVariable[i].data.sex.split(" "));
        }
        if(horsesVariable[i].data.birthYear !== null) {
          temporaryHorseDataWordsArray.push(horsesVariable[i].data.birthYear.toString().split(" "));
        }
        if(horsesVariable[i].data.color !== null) {
          temporaryHorseDataWordsArray.push(horsesVariable[i].data.color.split(" "));
        }
        if(horsesVariable[i].data.stud !== null) {
          temporaryHorseDataWordsArray.push(horsesVariable[i].data.stud.split(" "));
        }
        if(horsesVariable[i].data.origin !== null) {
          temporaryHorseDataWordsArray.push(horsesVariable[i].data.origin.split(" "));
        }
        if(horsesVariable[i].data.horseBreed !== null) {
          temporaryHorseDataWordsArray.push(horsesVariable[i].data.horseBreed.split(" "));
        }
        if(horsesVariable[i].data.tradingCategory !== null) {
          temporaryHorseDataWordsArray.push(horsesVariable[i].data.tradingCategory.split(" "));
        }
        for(let j: number = 0; j < temporaryHorseDataWordsArray.length; j++) {
          horsesDataWordsArray.push([]);
          for(let k: number = 0; k < temporaryHorseDataWordsArray[j].length; k++) {
            horsesDataWordsArray[i].push(temporaryHorseDataWordsArray[j][k]);
          }
        }
      }
      
      for(let i: number = 0; i < horsesVariable.length; i++) {
        let selectHorseByKeywords: boolean = true;
        const temporaryHorseDataWords: Array<string> = horsesDataWordsArray[i];
        const temporaryKeyWords: Array<string> = newHorseCatalogFilterSettings.keyWords;
        for(let j: number = 0; j < temporaryKeyWords.length; j++) {
          let keyWordMatches: boolean = false;
          for(let k: number = 0; k < temporaryHorseDataWords.length; k++) {
            if(temporaryKeyWords[j].toLowerCase() === temporaryHorseDataWords[k].toLowerCase()) {
              temporaryHorseDataWords.splice(k, 1);
              keyWordMatches = true;
              k = temporaryHorseDataWords.length;
            }
          }
          if(keyWordMatches === false) {
            selectHorseByKeywords = false;
            j = temporaryKeyWords.length;
          }
        }
        if(selectHorseByKeywords === true) {
          temporarySelectedHorses.push(horsesVariable[i]);
        }
      }
    } else {
      for(let i: number = 0; i < horsesVariable.length; i++) {
        temporarySelectedHorses.push(horsesVariable[i]);
      }
    }

    if(newHorseCatalogFilterSettings.name.length !== 0) {
      for(let i: number = 0; i < temporarySelectedHorses.length; i++) {
        let nameIsOk: boolean = false;
        if(temporarySelectedHorses[i].data.name.toLowerCase() !== newHorseCatalogFilterSettings.name[0].toLowerCase()) {
          temporarySelectedHorses.splice(i, 1);
          i--;
        }
      }
    }

    if(newHorseCatalogFilterSettings.sex.length !== 0) {
      for(let i: number = 0; i < temporarySelectedHorses.length; i++) {
        if(temporarySelectedHorses[i].data.sex.toLowerCase() !== newHorseCatalogFilterSettings.sex[0].toLowerCase()) {
          temporarySelectedHorses.splice(i, 1);
          i--;
        }
      }
    }

    if(newHorseCatalogFilterSettings.birthYear.length !== 0) {
      for(let i: number = 0; i < temporarySelectedHorses.length; i++) {
        if(temporarySelectedHorses[i].data.birthYear !== newHorseCatalogFilterSettings.birthYear[0]) {
          temporarySelectedHorses.splice(i, 1);
          i--;
        }
      }
    }

    if(newHorseCatalogFilterSettings.color.length !== 0) {
      for(let i: number = 0; i < temporarySelectedHorses.length; i++) {
        if(temporarySelectedHorses[i].data.color.toLowerCase() !== newHorseCatalogFilterSettings.color[0].toLowerCase()) {
          temporarySelectedHorses.splice(i, 1);
          i--;
        }
      }
    }

    if(newHorseCatalogFilterSettings.stud.length !== 0) {
      for(let i: number = 0; i < temporarySelectedHorses.length; i++) {
        if(temporarySelectedHorses[i].data.stud.toLowerCase() !== newHorseCatalogFilterSettings.stud[0].toLowerCase()) {
          temporarySelectedHorses.splice(i, 1);
          i--;
        }
      }
    }

    if(newHorseCatalogFilterSettings.origin.length !== 0) {
      for(let i: number = 0; i < temporarySelectedHorses.length; i++) {
        if(temporarySelectedHorses[i].data.origin.toLowerCase() !== newHorseCatalogFilterSettings.origin[0].toLowerCase()) {
          temporarySelectedHorses.splice(i, 1);
          i--;
        }
      }
    }

    if(newHorseCatalogFilterSettings.horseBreed.length !== 0) {
      for(let i: number = 0; i < temporarySelectedHorses.length; i++) {
        if(temporarySelectedHorses[i].data.horseBreed.toLowerCase() !== newHorseCatalogFilterSettings.horseBreed[0].toLowerCase()) {
          temporarySelectedHorses.splice(i, 1);
          i--;
        }
      }
    }

    if(newHorseCatalogFilterSettings.tradingCategory.length !== 0) {
      for(let i: number = 0; i < temporarySelectedHorses.length; i++) {
        if(temporarySelectedHorses[i].data.tradingCategory.toLowerCase() !== newHorseCatalogFilterSettings.tradingCategory[0].toLowerCase()) {
          temporarySelectedHorses.splice(i, 1);
          i--;
        }
      }
    }

    // if(accessibility.length !== 0) {
    //   for(let i: number = 0; i < temporarySelectedHorses.length; i++) {
    //     if(temporarySelectedHorses[i].data.accessibility.toLowerCase() !== accessibility[0].toLowerCase()) {
    //       temporarySelectedHorses.splice(i, 1);
    //       i--;
    //     }
    //   }
    // }

    if(newHorseCatalogSettings.sortingSettings.arguments.length >= 5 &&
      newHorseCatalogSettings.sortingSettings.orders.length >= 5) {
      temporarySelectedHorses = sortHorses(temporarySelectedHorses,
        newHorseCatalogSettings.sortingSettings.arguments[4],
        newHorseCatalogSettings.sortingSettings.orders[4]);
    }

    if(newHorseCatalogSettings.sortingSettings.arguments.length >= 4 &&
      newHorseCatalogSettings.sortingSettings.orders.length >= 4) {
      temporarySelectedHorses = sortHorses(temporarySelectedHorses,
        newHorseCatalogSettings.sortingSettings.arguments[3],
        newHorseCatalogSettings.sortingSettings.orders[3]);
    }

    if(newHorseCatalogSettings.sortingSettings.arguments.length >= 3 &&
      newHorseCatalogSettings.sortingSettings.orders.length >= 3) {
      temporarySelectedHorses = sortHorses(temporarySelectedHorses,
        newHorseCatalogSettings.sortingSettings.arguments[2],
        newHorseCatalogSettings.sortingSettings.orders[2]);
    }

    if(newHorseCatalogSettings.sortingSettings.arguments.length >= 2 &&
      newHorseCatalogSettings.sortingSettings.orders.length >= 2) {
      temporarySelectedHorses = sortHorses(temporarySelectedHorses,
        newHorseCatalogSettings.sortingSettings.arguments[1],
        newHorseCatalogSettings.sortingSettings.orders[1]);
    }

    if(newHorseCatalogSettings.sortingSettings.arguments.length >= 1 &&
      newHorseCatalogSettings.sortingSettings.orders.length >= 1) {
      temporarySelectedHorses = sortHorses(temporarySelectedHorses,
        newHorseCatalogSettings.sortingSettings.arguments[0],
        newHorseCatalogSettings.sortingSettings.orders[0]);
    } else {
      temporarySelectedHorses = sortHorses(temporarySelectedHorses, "Name", "Ascending");
    }

    setSelectedAndSortedHorses(temporarySelectedHorses);
    // setSelectedAndSortedHorses(sortHorses(temporarySelectedHorses, sortingArgument[0], sortingOrder[0]));

    setSelectedAndSortedHorsesChanged(selectedAndSortedHorsesAreChanged + 1);

    setIsLoading(false);
  }

  const resizeMainContentHeight = (newMainContentHeight: number) => {
    setMainContentHeight(newMainContentHeight);
  }

  const resizeSidebarHeight = (newSidebarHeight: number) => {
    setSidebarHeight(newSidebarHeight);
  }

  const changeAdminHorsesPageContentType = (newAdminHorsesPageContentType: string) => {
    setAdminHorseListPageContentType(newAdminHorsesPageContentType);
  }

  const overwriteDeletedHorse = (horseId: number) => {
    const temporaryAllHorses: Array<Horse> = allHorses;
    const temporarySelectedAndSortedHorses: Array<Horse> = selectedAndSortedHorses;

    for(let i: number = 0; i < temporaryAllHorses.length; i++) {
      if(temporaryAllHorses[i].id === horseId) {
        temporaryAllHorses[i].data.accessibility = "deleted";
      }
    }

    for(let i: number = 0; i < temporarySelectedAndSortedHorses.length; i++) {
      if(temporarySelectedAndSortedHorses[i].id === horseId) {
        temporarySelectedAndSortedHorses[i].data.accessibility = "deleted";
      }
    }

    setAllHorses(temporaryAllHorses);
    setSelectedAndSortedHorses(temporarySelectedAndSortedHorses);
  }


  useEffect(() => {
    setContentHeight(Math.max(mainContentHeight, sidebarHeight));
    resizeContentHeight[0](Math.max(mainContentHeight, sidebarHeight));
  }, [mainContentHeight, sidebarHeight]);

  useEffect(() => {
    getHorses();
  }, []);


  return(
    <div>
      <AdminHorsesNavigationAndFilteringAndSorting
        webpageStyle = { webpageStyle }
        adminHorseListPageContentType = { adminHorseListPageContentType }
        recentHorseCatalogSettings = { recentHorseCatalogSettings }
        changeHorseCatalogSettings = { changeHorseCatalogSettings }
        changeFilteringOrSortingSettings = { [changeFilteringOrSortingSettings] }
        changeAdminHorseListPageContentType = { [changeAdminHorsesPageContentType] }
        resizeSidebarHeight = { [resizeSidebarHeight] }
      />

      { (/*isLoading === false &&*/ adminHorseListPageContentType === "horseList") ?
        <AdminHorseList
          webpageStyle = { webpageStyle }
          selectedAndSortedHorses = { selectedAndSortedHorses }
          selectedAndSortedHorsesAreChanged = { selectedAndSortedHorsesAreChanged }
          changeAdminHorseListPageContentType = { [setAdminHorseListPageContentType] }
          overwriteDeletedHorse = { [overwriteDeletedHorse] }
          changeSelectedHorseId = { [setSelectedHorseId] }
          resizeMainContentHeight = { [resizeMainContentHeight] }
        /> : null
      }

      { (/*isLoading === false &&*/ adminHorseListPageContentType === "createHorse") ?
        <AdminCreateHorse
          webpageStyle = { webpageStyle }
          resizeMainContentHeight = { [resizeMainContentHeight] }
        /> : null
      }

      {(/*isLoading === false &&*/ adminHorseListPageContentType === "editHorseData") ?
        <AdminEditHorse
          webpageStyle = { webpageStyle }
          resizeMainContentHeight = { [resizeMainContentHeight] }
          selectedHorseId = { selectedHorseId }
        /> : null
      }
    </div>
  )
}
