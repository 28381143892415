//

export interface HorsePedigree {
  fathersName: Array<string>,
  mothersFathersName: Array<string>,
  mothersMothersFathersName: Array<string>
}

export const getHorsePedigreeFromHorseTelex = async (horseTelexId: number) => {
  if(horseTelexId !== undefined && horseTelexId !== null) {
    if(horseTelexId !== -1) {
      try {
        const fetchURL = ("https://www.horsetelex.com/pedigree/pedigrees/family-tree?id=" + horseTelexId.toString() + "&generations=3");
    
        const customParams = {
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
        };
    
        const response = await fetch(fetchURL, customParams);
        const data: any = await response.json();
    
        let fathersName: Array<string> = [];
        let mothersGrandfathersName: Array<string> = [];
        let mothersGrandmothersGreatGrandfathersName: Array<string> = [];
    
        const horsePedigree: HorsePedigree = {
          fathersName: [],
          mothersFathersName: [],
          mothersMothersFathersName: []
        }
    
        if(data !== null) {
          if(data.pedigree !== null) {
            if(data.pedigree.father !== null) {
              horsePedigree.fathersName = [data.pedigree.father.name];
            }
            if(data.pedigree.mother !== null) {
              if(data.pedigree.mother.father !== null) {
                horsePedigree.mothersFathersName = [data.pedigree.mother.father.name];
              }
              if(data.pedigree.mother.mother !== null) {
                if(data.pedigree.mother.mother.father !== null) {
                  horsePedigree.mothersMothersFathersName = [data.pedigree.mother.mother.father.name];
                }
              }
            }
          }
        }
        
        return horsePedigree;
    
      } catch (err) {
        console.error(err);
      }
    }
  }
};
