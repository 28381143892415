import React, { FC, useEffect, useState, ChangeEvent } from 'react';





export interface AdjustableSingleRowTextInputProps {
  value: string,
  modificationFunction: Array<any>,
  disabled: boolean,
  top: number,
	left: number,
	height: number,
	width: number,
	backgroundColor: string,
	fontSize: number,
	color: string
}

export interface AdjustableMultipleRowTextInputProps {
  value: string,
  modificationFunction: Array<any>,
  disabled: boolean,
  top: number,
	left: number,
	height: number,
	width: number,
	backgroundColor: string,
	fontSize: number,
	color: string
}

export interface AdjustableSingleSelectInputProps {
  value: string,
  options: Array<string>,
  modificationFunction: Array<any>,
  disabled: boolean,
  top: number,
	left: number,
	height: number,
	width: number,
	backgroundColor: string,
	fontSize: number,
	color: string
}

export interface AdjustablePasswordInputProps {
  value: string,
  modificationFunction: Array<any>,
  hidden: boolean,
  disabled: boolean,
  top: number,
	left: number,
	height: number,
	width: number,
	backgroundColor: string,
	fontSize: number,
	color: string
}

export interface AdjustableDateInputProps {
  value: string,
  modificationFunction: Array<any>,
  disabled: boolean,
  top: number,
	left: number,
	height: number,
	width: number,
	backgroundColor: string,
	fontSize: number,
	color: string
}

export interface AdjustableIntegerInputProps {
  valueImage: string,
  modificationFunction: Array<any>,
  disabled: boolean,
  top: number,
	left: number,
	height: number,
	width: number,
	backgroundColor: string,
	fontSize: number,
	color: string
}

export interface AdjustableNumberInputProps {
  valueImage: string,
  modificationFunction: Array<any>,
  disabled: boolean,
  top: number,
	left: number,
	height: number,
	width: number,
	backgroundColor: string,
	fontSize: number,
	color: string
}

export interface AdjustableCheckboxInputProps {
  value: boolean,
  modificationFunction: Array<any>,
  disabled: boolean,
  top: number,
  left: number,
  size: number
}





export const AdjustableSingleRowTextInput: FC<AdjustableSingleRowTextInputProps> = ({ value, modificationFunction, disabled, top, left, height, width, backgroundColor, fontSize, color }) => {
  const provideValue = (v: string) => {
    modificationFunction[0](v);
  }

  // useEffect(() => {
  //   getInputFieldComponent();
  // }, [value]);

  return(
    <input
      style= {{ position: "absolute", top: top, left: left, height: height, width: width, backgroundColor: backgroundColor, fontSize: fontSize, color: color }}
      type="text"
      value = { value }
      disabled = { disabled }
      onChange = {
        (e: ChangeEvent<HTMLInputElement>) => {
          provideValue(e.target.value);
        }
      }
    />
  )
}

export const AdjustableMultipleRowTextInput: FC<AdjustableMultipleRowTextInputProps> = ({ value, modificationFunction, disabled, top, left, height, width, backgroundColor, fontSize, color }) => {
  const provideValue = (v: string) => {
    modificationFunction[0](v);
  }

  return(
    <textarea
      style= {{ position: "absolute", top: top, left: left, height: height, width: width, backgroundColor: backgroundColor, fontSize: fontSize, color: color }}
      value = { value }
      disabled = { disabled }
      onChange = {
        (e: ChangeEvent<HTMLTextAreaElement>) => {
          provideValue(e.target.value);
        }
      }
    />
  )
}

export const AdjustableSingleSelectInput: FC<AdjustableSingleSelectInputProps> = ({ value, options, modificationFunction, disabled, top, left, height, width, backgroundColor, fontSize, color }) => {
  const provideValue = (v: string) => {
    modificationFunction[0](v);
  }

  return(
    <select
      required
      style= {{ position: "absolute", top: top, left: left, height: height, width: width, backgroundColor: backgroundColor, fontSize: fontSize, color: color }}
      value = { value }
      disabled = { disabled }
      onChange={
        (e: ChangeEvent<HTMLSelectElement>) =>
        {
          provideValue(e.target.value);
        }
      }
    >
      {
        options.map((option) =>
          { return(option !== "" ? <option value={ option } key = { option }>{ option }</option> : <option value="" key = { option }>Please choose an option--</option>) }
          )
      }
    </select>
  )
}

export const AdjustablePasswordInput: FC<AdjustablePasswordInputProps> = ({ value, modificationFunction, hidden, disabled, top, left, height, width, backgroundColor, fontSize, color }) => {
  const provideValue = (v: string) => {
    modificationFunction[0](v);
  }

  if(hidden === true) {
    return(
      <input
        style= {{ position: "absolute", top: top, left: left, height: height, width: width, backgroundColor: backgroundColor, fontSize: fontSize, color: color }}
        type="password"
        value = { value }
        disabled = { disabled }
        onChange = {
          (e: ChangeEvent<HTMLInputElement>) => {
            provideValue(e.target.value);
          }
        }
      />
    )
  } else {
    return(
      <input
        style= {{ position: "absolute", top: top, left: left, height: height, width: width, backgroundColor: backgroundColor, fontSize: fontSize, color: color }}
        type="text"
        value = { value }
        disabled = { disabled }
        onChange = {
          (e: ChangeEvent<HTMLInputElement>) => {
            provideValue(e.target.value);
          }
        }
      />
    )
  }
  
}

export const AdjustableDateInput: FC<AdjustableDateInputProps> = ({ value, modificationFunction, disabled, top, left, height, width, backgroundColor, fontSize, color }) => {
  const provideValue = (v: string) => {
    modificationFunction[0](v);
  }

  return(
    <input
      style= {{ position: "absolute", top: top, left: left, height: height, width: width, backgroundColor: backgroundColor, fontSize: fontSize, color: color }}
      type="date"
      value = { value }
      disabled = { disabled }
      onChange = {
        (e: ChangeEvent<HTMLInputElement>) => {
          provideValue(e.target.value);
        }
      }
    />
  )
}

export const AdjustableIntegerInput: FC<AdjustableIntegerInputProps> = ({ valueImage, modificationFunction, disabled, top, left, height, width, backgroundColor, fontSize, color }) => {
  const provideValue = (vImage: string) => {
    if(vImage.length === 0) {
      modificationFunction[0]([], "");
      return;
    }
    if(vImage[(vImage.length - 1)] === '.') {
      return;
    }
    if(vImage.length === 1) {
      if(vImage === '-') {
        modificationFunction[0]([], '-');
        return;
      }
      let vNumber = Number(vImage);
      if(vNumber >= 0 || vNumber < 0) {
        modificationFunction[0]([vNumber], vImage);
        return;
      }
    }
    if(vImage.length === 2) {
      let vImage0: string = vImage[0];
      let vImage1: string = vImage[1];
      if(vImage0 === "0") {
        let vNumber1 = Number(vImage1);
        if(vNumber1 >= 0) {
          modificationFunction[0]([vNumber1], vImage1);
          return;
        }
      }
      if(vImage0 === '-') {
        let vNumber1 = Number(vImage1);
        if(vNumber1 >= 0) {
          modificationFunction[0]([((-1) * vNumber1)], vImage);
          return;
        }
      }
      let vNumber = Number(vImage);
      if(vNumber >= 0 || vNumber < 0) {
        modificationFunction[0]([vNumber], vImage);
        return;
      }
    }
    if(vImage.length === 3) {
      if(vImage[0] === '-' && vImage[1] === '0') {
        let vNumber2 = Number(vImage[2]);
        if(vNumber2 >= 0) {
          modificationFunction[0]([((-1) * vNumber2)], ('-' + vImage[2]));
          return;
        }
      }
    }
    if(vImage.length >= 3) {
      let vNumber = Number(vImage);
      if(vNumber > 0 || vNumber < 0) {
        modificationFunction[0]([vNumber], vImage);
        return;
      }
      if(vNumber === 0) {
        modificationFunction[0]([], vImage);
        return;
      }
    }
  }

  return(
    <input
      style= {{ position: "absolute", top: top, left: left, height: height, width: width, backgroundColor: backgroundColor, fontSize: fontSize, color: color }}
      type="text"
      value = { valueImage }
      disabled = { disabled }
      onChange = {
        (e: ChangeEvent<HTMLInputElement>) => {
          provideValue(e.target.value);
        }
      }
    />
  )
}

export const AdjustableNumberInput: FC<AdjustableNumberInputProps> = ({ valueImage, modificationFunction, disabled, top, left, height, width, backgroundColor, fontSize, color }) => {
  const provideValue = (vImage: string) => {
    if(vImage.length === 0) {
      modificationFunction[0]([], "");
      return;
    }
    if(vImage.length > 0) {
      if(vImage[(vImage.length - 1)] === '.') {
        let thereIsAnotherPoint: boolean = false;
        for(let i: number = 0; i < vImage.length - 1; i++) {
          if(vImage[i] === '.') {
            thereIsAnotherPoint = true;
          }
        }
        if(thereIsAnotherPoint) {
          return;
        } else {
          let vNumber = Number(vImage);
          if(vNumber >= 0 || vNumber < 0) {
            modificationFunction[0]([], vImage);
            return;
          }
        }
      }
    }
    if(vImage.length === 1) {
      if(vImage === '-') {
        modificationFunction[0]([], '-');
        return;
      }
      if(vImage === '.') {
        modificationFunction[0]([], "0.");
        return;
      }
      let vNumber = Number(vImage);
      if(vNumber >= 0 || vNumber < 0) {
        modificationFunction[0]([vNumber], vImage);
        return;
      }
    }
    if(vImage.length >= 2) {
      let thereIsAPoint: boolean = false;
      let indexOfPoint: number = -1;
      for(let i: number = 0; i < vImage.length; i++) {
        if(vImage[i] === '.') {
          thereIsAPoint = true;
          indexOfPoint = i;
          i = vImage.length;
        }
      }
      if(thereIsAPoint === true) {
        let thereIsAnotherNumberThanZeroAfterThePoint: boolean = false;
        for(let i: number = (indexOfPoint + 1); i < vImage.length; i++) {
          if(vImage[i] !== '0') {
            thereIsAnotherNumberThanZeroAfterThePoint = true;
            i = vImage.length;
          }
        }
        if(thereIsAnotherNumberThanZeroAfterThePoint === false) {
          let vNumber = Number(vImage);
          if(vNumber >= 0 || vNumber < 0) {
            modificationFunction[0]([], vImage);
            return;
          }
        }
      }
    }
    if(vImage.length === 2) {
      let vImage0: string = vImage[0];
      let vImage1: string = vImage[1];
      if(vImage0 === "0") {
        let vNumber1 = Number(vImage1);
        if(vNumber1 >= 0) {
          modificationFunction[0]([vNumber1], vImage1);
          return;
        }
      }
      if(vImage0 === '-') {
        if(vImage1 === '.') {
          modificationFunction[0]([], "-0.");
          return;
        }
        let vNumber1 = Number(vImage1);
        if(vNumber1 >= 0) {
          modificationFunction[0]([((-1) * vNumber1)], vImage);
          return;
        }
      }
      if(vImage0 === '.') {
        let vNumber1 = Number(vImage1);
        if(vNumber1 >= 0) {
          modificationFunction[0]([(vNumber1 / 10)], ('0.' + vImage1));
          return;
        }
      }
      let vNumber = Number(vImage);
      if(vNumber >= 0 || vNumber < 0) {
        modificationFunction[0]([vNumber], vImage);
        return;
      }
    }
    if(vImage.length === 3) {
      if(vImage[0] === '-' && vImage[1] === '0') {
        let vNumber2 = Number(vImage[2]);
        if(vNumber2 >= 0) {
          modificationFunction[0]([((-1) * vNumber2)], ('-' + vImage[2]));
          return;
        }
      }
    }
    if(vImage.length >= 3) {
      let vNumber = Number(vImage);
      if(vNumber > 0 || vNumber < 0) {
        modificationFunction[0]([vNumber], vImage);
        return;
      }
      if(vNumber === 0) {
        modificationFunction[0]([], vImage);
        return;
      }
    }
  }

  return(
    <input
      style= {{ position: "absolute", top: top, left: left, height: height, width: width, backgroundColor: backgroundColor, fontSize: fontSize, color: color }}
      type="text"
      value = { valueImage }
      disabled = { disabled }
      onChange = {
        (e: ChangeEvent<HTMLInputElement>) => {
          provideValue(e.target.value);
        }
      }
    />
  )
}

export const AdjustableCheckboxInput: FC<AdjustableCheckboxInputProps> = ({ value, modificationFunction, disabled, top, left, size }) => {
  const provideValue = (v: boolean) => {
    modificationFunction[0](v);
  }
  
  return(
    <input
      style= {{ position: "absolute", top: top, left: left, height: size, width: size }}
      type="checkbox"
      checked = { value }
      disabled = { disabled }
      onChange = {
        (e: ChangeEvent<HTMLInputElement>) => {
          provideValue(e.target.checked);
        }
      }
    />
  )
}
