import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Horse } from '../../../../models/HorseProps';
import { Box } from '../../../03_baseComponents/Box';
import { AdminUsersNavigation } from './AdminUsersNavigationAndFilteringAndSorting/AdminUsersNavigation';
import { AdminUsersFiltering } from './AdminUsersNavigationAndFilteringAndSorting/AdminUsersFiltering';
import { AdminUsersSorting } from './AdminUsersNavigationAndFilteringAndSorting/AdminUsersSorting';
import { UserListSettings, UserListFilteringSettings, UserListSortingSettings } from '../../../../models/UserListSettings';
import { LoggedInUser } from '../../../../models/UserProps';





export interface AdminUsersNavigationAndFilteringAndSortingProps {
  webpageStyle: WebpageStyleProps,
  adminUsersPageContentType: string,
  recentUserListSettings: UserListSettings,
  changeAdminUserListPageContentType: Array<any>,
  changeFilteringOrSortingSettings: Array<any>,
  resizeSidebarHeight: Array<any>
}





export const AdminUsersNavigationAndFilteringAndSorting: FC<AdminUsersNavigationAndFilteringAndSortingProps> = ({ webpageStyle,
  adminUsersPageContentType, recentUserListSettings,
  changeAdminUserListPageContentType, changeFilteringOrSortingSettings, resizeSidebarHeight }) => {
  //

  // sidebarHeight: nem túl szép megoldás, meg is kellene változtatni
  const [sidebarHeight, setSidebarHeight] = useState<number>(700);


  const changeUserListFilteringSettings = (newUserListFilterSettings: UserListFilteringSettings) => {
    const newUserListSettings: UserListSettings = {
      filteringSettings: newUserListFilterSettings,
      sortingSettings: recentUserListSettings.sortingSettings
    }
    changeFilteringOrSortingSettings[0](newUserListSettings);
  }

  const changeHorseCatalogSortingSettings = (newUserListSortSettings: UserListSortingSettings) => {
    const newUserListSettings: UserListSettings = {
      filteringSettings: recentUserListSettings.filteringSettings,
      sortingSettings: newUserListSortSettings
    }
    changeFilteringOrSortingSettings[0](newUserListSettings);
  }


  useEffect(() => {
    if(adminUsersPageContentType === "userList") {
      resizeSidebarHeight[0](webpageStyle.content.sidebar.adminUserList.navigation.boxTop + webpageStyle.content.sidebar.adminUserList.navigation.boxHeight + 50 + webpageStyle.content.sidebar.adminUserList.sorting.boxHeight);
    } else {
      resizeSidebarHeight[0](webpageStyle.content.sidebar.adminUserList.navigation.boxTop + webpageStyle.content.sidebar.adminUserList.navigation.boxHeight);
    }
  }, [adminUsersPageContentType]);


  return(
    <div>
      <AdminUsersNavigation
        webpageStyle = { webpageStyle }
        adminUsersPageContentType = { adminUsersPageContentType }
        changeAdminUserListPageContentType = { changeAdminUserListPageContentType }
      />
      { adminUsersPageContentType === "userList" ?
        <AdminUsersFiltering
          webpageStyle = { webpageStyle }
          recentUserListFilteringSettings = { recentUserListSettings.filteringSettings }
          changeUserListFilteringSettings = { [changeUserListFilteringSettings] }
        /> : null
      }
      { adminUsersPageContentType === "userList" ?
        <AdminUsersSorting
          webpageStyle = { webpageStyle }
          changeHorseCatalogSortingSettings = { [changeHorseCatalogSortingSettings] }
        /> : null
      }
    </div>
  )
}
