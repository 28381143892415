import { Horse } from '../models/HorseProps';

export function sortHorses(selectedHorsesInput: Array<Horse>, argument: string, order: string): Array<Horse> {
  //

  const sortedHorses: Array<Horse> = [];

  for(let i: number = 0; i < selectedHorsesInput.length; i++) {
    sortedHorses.push(selectedHorsesInput[i]);
  }

  let sortingIsAccomplished: boolean = false;
  while(sortingIsAccomplished !== true) {
    //

    sortingIsAccomplished = true;
    for(let i: number = 1; i < sortedHorses.length; i++) {
      //
      let a: string = "";
      let aa: Array<Horse> = [];
      let b: string = "";
      let bb: Array<Horse> = [];
      if(argument === "Id") {
        a = sortedHorses[i - 1].id.toString();
        b = sortedHorses[i].id.toString();
      }
      if(argument === "Name") {
        a = sortedHorses[i - 1].data.name;
        b = sortedHorses[i].data.name;
      }
      if(argument === "Birth year") {
        a = sortedHorses[i - 1].data.birthYear.toString();
        b = sortedHorses[i].data.birthYear.toString();
      }
      if(argument === "Stud") {
        a = sortedHorses[i - 1].data.stud;
        b = sortedHorses[i].data.stud;
      }
      if(argument === "Origin") {
        a = sortedHorses[i - 1].data.origin;
        b = sortedHorses[i].data.origin;
      }
      if(argument === "Horse breed") {
        a = sortedHorses[i - 1].data.horseBreed;
        b = sortedHorses[i].data.horseBreed;
      }
      
      if(order === "Ascending") {
        let aa: Horse = sortedHorses[i - 1];
        if(argument === "Id") {
          if(Number(a) > Number(b)) {
            sortedHorses.splice((i - 1), 1);
            sortedHorses.splice(i, 0, aa);
            sortingIsAccomplished = false;
          }
        } else {
          if(a > b) {
            sortedHorses.splice((i - 1), 1);
            sortedHorses.splice(i, 0, aa);
            sortingIsAccomplished = false;
          }
        }
      }
      if(order === "Descending") {
        let bb: Horse = sortedHorses[i - 1];
        if(argument === "Id") {
          if(Number(a) < Number(b)) {
            sortedHorses.splice((i - 1), 1);
            sortedHorses.splice(i, 0, bb);
            sortingIsAccomplished = false;
          }
        } else {
          if(a < b) {
            sortedHorses.splice((i - 1), 1);
            sortedHorses.splice(i, 0, bb);
            sortingIsAccomplished = false;
          }
        }
      }
    }
  }

  return(sortedHorses);
}
