import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../Box';
import { SingleRowText, SingleRowPrototypeDisplayParameters, SingleRowChangedDisplayParameters } from '../SingleRowText';
import { KeyValueInput, KeyValueInputPrototypeDisplayParameters, KeyValueInputChangedDisplayParameters } from '../KeyValueInput';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../Button';
import { Image, UploadedImageElement } from '../ImageUploader/UploadedImageElement';
import { Video, UploadedVideoElement } from '../VideoUploader/UploadedVideoElement';
import { UploadedFile } from '../../04_subComponents/FileInput';
import { ImageDisplay, ImageDisplayPrototypeDisplayParameters, ImageDisplayChangedDisplayParameters } from '../ImageDisplay';
import { VideoDisplay, VideoDisplayPrototypeDisplayParameters, VideoDisplayChangedDisplayParameters } from '../VideoDisplay';
import { KeyValueDisplay, KeyValueDisplayPrototypeDisplayParameters, KeyValueDisplayChangedDisplayParameters } from '../KeyValueDisplay';





export interface ImageOrVideo {
  oldTitle: string,
  image: Array<Image>,
  video: Array<Video>
}

export interface UploadedImageOrVideoElementProps {
  webpageStyle: WebpageStyleProps,
  top: number,
  left: number,
  imageOrVideo: ImageOrVideo,
  index: number,
  removeImageOrVideo: Array<any>,
  changeImageOrVideoTitle: Array<any>
}





export const UploadedImageOrVideoElement: FC<UploadedImageOrVideoElementProps> = ({ webpageStyle, top, left,
  imageOrVideo, index, removeImageOrVideo, changeImageOrVideoTitle }) => {
  //

  const [image, setImage] = useState<Array<Image>>(imageOrVideo.image);
  const [video, setVideo] = useState<Array<Video>>(imageOrVideo.video);

  const [newName, setNewName] = useState<Array<string>>([imageOrVideo.oldTitle]);


  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: top,
    left: left,
    height: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.boxHeight,
    width: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.boxWidth,
    backgroundColor: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.boxBackgroundColor,
    outlineStyle: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.boxOutlineStyle,
    outlineWidth: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.boxOutlineWidth,
    outlineColor: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.boxOutlineColor
  });


  const [imagePrototypeDisplayParameters, setImagePrototypeDisplayParameters] = useState<ImageDisplayPrototypeDisplayParameters>({
    top: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.imageOrVideoTop,
    left: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.imageOrVideoLeft,
    height: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.imageOrVideoHeight,
    width: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.imageOrVideoWidth
  });

  const [videoPrototypeDisplayParameters, setVideoPrototypeDisplayParameters] = useState<VideoDisplayPrototypeDisplayParameters>({
    top: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.imageOrVideoTop,
    left: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.imageOrVideoLeft,
    height: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.imageOrVideoHeight,
    width: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.imageOrVideoWidth
  });
  
  const [firstInputTop, setFirstInputTop] = useState<number>(webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.firstInputTop);
  const [inputTopDistance, setInputTopDistance] = useState<number>(webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.inputTopDistance);

  const [keyValueDisplayPrototypeDisplayParameters, setKeyValueDisplayPrototypeDisplayParameters] = useState<KeyValueDisplayPrototypeDisplayParameters>({
    keyTop: firstInputTop,
    keyLeft: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.keyLeft,
    keyWidth: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.keyWidth,
    keyFontSize: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.keyFontSize,
    keyColor: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.keyColor,
    valueTop: firstInputTop,
    valueLeft: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.valueAndFieldLeft,
    valueWidth: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.valueAndFieldWidth,
    valueFontSize: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.valueFontSize,
    valueColor: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.valueColor
  });

  const [keyValueInputPrototypeDisplayParameters, setKeyValueInputPrototypeDisplayParameters] = useState<KeyValueInputPrototypeDisplayParameters>({
    keyTop: firstInputTop,
    keyLeft: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.keyLeft,
    keyWidth: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.keyWidth,
    keyFontSize: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.keyFontSize,
    keyColor: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.keyColor,
    fieldTop: firstInputTop,
    fieldLeft: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.valueAndFieldLeft,
    fieldHeight: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.fieldHeight,
    fieldWidth: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.valueAndFieldWidth,
    fieldBackgroundColor: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.fieldBackgroundColor,
    valueFontSize: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.valueFontSize,
    valueColor: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.valueColor,
  });

  const [extensionPrototypeDisplayParameters, setExtensionPrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: firstInputTop,
    left: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.extensionLeft,
    width: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.extensionWidth,
    fontSize: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.valueFontSize,
    color: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.valueColor
  });

  const [removeButtonPrototypeDisplayParameters, setRemoveButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.removeButtonTop,
    left: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.removeButtonLeft,
    height: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.removeButtonHeight,
    width: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.removeButtonWidth,
    backgroundColor: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.removeButtonBackgroundColorActive,
    fontSize: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.removeButtonFontSize,
    contentColor: webpageStyle.baseComponent.imageAndVideoUploader.uploadedImageAndVideoElement.removeButtonContentColorActive
  });


  const initializeImageAndVideo = () => {
    //

    // érdekes hogy így is működhetne:
    // function determineIfIsImageOrVideo_image(toBeDetermined: ImageOrVideo): toBeDetermined is Image {
    //   if((toBeDetermined as Image).uploadedImage){
    //     return true;
    //   }
    //   return false;
    // }

    //
  }

  function removeUploadedImage(): void {
    removeImageOrVideo[0](imageOrVideo);
  }

  useEffect(() => {
    // initializeImageAndVideo();
    // updateAndTestImages(images);
  }, []);

  // console.log(image);

  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <div>
        { (image.length !== 0 && video.length === 0) ?
          <div>
            <ImageDisplay
              uploadedImage = { image[0].uploadedImage }
              sourceUrl = { image[0].sourceUrl }
              prototypeDisplayParameters = { imagePrototypeDisplayParameters }
            />

            <KeyValueDisplay
              keyText = { "Old title" }
              valueText = { `${ image[0].oldTitle }.${ image[0].fileExtension }` }
              prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
              changedDisplayParameters = { {
                keyTop: firstInputTop + 0 * inputTopDistance,
                valueTop: firstInputTop + 0 * inputTopDistance
              } }
            />

            <KeyValueInput
              keyText = { "New title" }
              initialValueString = { newName }
              initialValueNumber = { [] }
              initialValueBoolean = { [] }
              optionsString = { [] }
              inputType = { "SingleRowTextInput" }
              modificationFunctions = { [(newTitle: Array<string>) => {
                changeImageOrVideoTitle[0](newTitle, index);
              }] }
              properInputDisplay = { false }
              disabled = { false }
              prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
              changedDisplayParameters = { {
                keyTop: firstInputTop + 1 * inputTopDistance,
                fieldTop: firstInputTop + 1 * inputTopDistance
              } }
            />

            <SingleRowText
              text = { `.${image[0].fileExtension}` }
              prototypeDisplayParameters = { extensionPrototypeDisplayParameters }
              changedDisplayParameters = { {
                top: firstInputTop + 1 * inputTopDistance
              } }
            />

            <Button
              buttonText = { "Remove" }
              actionFunctions = { [() => removeUploadedImage()] }
              disabled = { false }
              prototypeDisplayParameters = { removeButtonPrototypeDisplayParameters }
            />
          </div> : null
        }

        { (video.length !== 0 && image.length === 0) ?
          <div>
            <VideoDisplay
              sourceUrl = { video[0].sourceUrl[0] }
              prototypeDisplayParameters = { videoPrototypeDisplayParameters }
            />

            <KeyValueDisplay
              keyText = { "Old title" }
              valueText = { `${ video[0].oldTitle }` }
              prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
              changedDisplayParameters = { {
                keyTop: firstInputTop + 0 * inputTopDistance,
                valueTop: firstInputTop + 0 * inputTopDistance
              } }
            />

            <KeyValueInput
              keyText = { "New title" }
              initialValueString = { newName }
              initialValueNumber = { [] }
              initialValueBoolean = { [] }
              optionsString = { [] }
              inputType = { "SingleRowTextInput" }
              modificationFunctions = { [(newTitle: Array<string>) => {
                changeImageOrVideoTitle[0](newTitle, index);
              }] }
              properInputDisplay = { false }
              disabled = { false }
              prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
              changedDisplayParameters = { {
                keyTop: firstInputTop + 1 * inputTopDistance,
                fieldTop: firstInputTop + 1 * inputTopDistance
              } }
            />

            <Button
              buttonText = { "Remove" }
              actionFunctions = { [() => removeUploadedImage()] }
              disabled = { false }
              prototypeDisplayParameters = { removeButtonPrototypeDisplayParameters }
            />
          </div> : null
        }
      </div>
    </Box>
  )
}
