import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { HorseCatalogFiltering } from './HorseCatalogFilteringAndSorting/HorseCatalogFiltering';
import { HorseCatalogSorting } from './HorseCatalogFilteringAndSorting/HorseCatalogSorting';
import { HorseCatalogSettings, HorseCatalogFilteringSettings, HorseCatalogSortingSettings } from '../../../../models/HorseCatalogSettings';
import { LoggedInUser } from '../../../../models/UserProps';





export interface HorseCatalogFilteringAndSortingProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  recentHorseCatalogSettings: HorseCatalogSettings,
  changeHorseCatalogSettings: Array<any>,
  changeFilteringOrSortingSettings: Array<any>,
  resizeSidebarHeight: Array<any>
}





export const HorseCatalogFilteringAndSorting: FC<HorseCatalogFilteringAndSortingProps> = ({ webpageStyle, loggedInUser,
  recentHorseCatalogSettings, changeHorseCatalogSettings,
  changeFilteringOrSortingSettings, resizeSidebarHeight }) => {
  //

  const [sidebarHeight, setSidebarHeight] = useState<number>(700);


  const changeHorseCatalogFilterSettings = (newHorseCatalogFilteringSettings: HorseCatalogFilteringSettings) => {
    const newHorseCatalogSettings: HorseCatalogSettings = {
      filteringSettings: newHorseCatalogFilteringSettings,
      sortingSettings: recentHorseCatalogSettings.sortingSettings
    }
    changeHorseCatalogSettings[0](newHorseCatalogSettings);
    changeFilteringOrSortingSettings[0](newHorseCatalogSettings);
  }

  const changeHorseCatalogSortSettings = (newHorseCatalogSortingSettings: HorseCatalogSortingSettings) => {
    const newHorseCatalogSettings: HorseCatalogSettings = {
      filteringSettings: recentHorseCatalogSettings.filteringSettings,
      sortingSettings: newHorseCatalogSortingSettings
    }
    changeHorseCatalogSettings[0](newHorseCatalogSettings);
    changeFilteringOrSortingSettings[0](newHorseCatalogSettings);
  }


  useEffect(() => {
    resizeSidebarHeight[0](sidebarHeight);
  }, []);


  return(
    <div>
      <HorseCatalogFiltering
        webpageStyle = { webpageStyle }
        loggedInUser = { loggedInUser }
        recentHorseCatalogFilteringSettings = { recentHorseCatalogSettings.filteringSettings }
        changeHorseCatalogFilteringSettings = { [changeHorseCatalogFilterSettings] }
      />

      <HorseCatalogSorting
        webpageStyle = { webpageStyle }
        recentHorseCatalogSortingSettings = { recentHorseCatalogSettings.sortingSettings }
        changeHorseCatalogSortingSettings = { [changeHorseCatalogSortSettings] }
      />
    </div>
  )
}
