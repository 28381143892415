import { authHeader } from '../authorization-header/auth-header';

export const getData = async (URI: string, isAuth: boolean = false) => {
  try {
    const fetchURL = process.env.REACT_APP_LOCAL_HOST + URI;

    let header = {
      "Content-type": "application/json",
    }

    let customParams = {
      method: "GET",
      headers: header
    };

    if(isAuth === true) {
      const token: string = await JSON.parse(localStorage.getItem("user") as string);

      const headerWithAuth = {
        ...header,
        "authorization": token? `Bearer ${token}` : ''
      }

      customParams = {
        method: "GET",
        headers: headerWithAuth,
      };
    }

    const response = await fetch(fetchURL, customParams);
    const data: any = await response.json();
    return data;

  } catch (err) {
    console.error(err);
  }
};

export const postData = async (URI: string, body: any, isAuth: boolean = false) => {
  try {
    const fetchURL = process.env.REACT_APP_LOCAL_HOST + URI;

    let header = {
      "Content-type": "application/json",
    }

    let customParams = {
      method: "POST",
      headers: header,
      body: JSON.stringify(body)
    };

    if(isAuth === true) {
      const token: string = await JSON.parse(localStorage.getItem("user") as string);

      const headerWithAuth = {
        ...header,
        "authorization": token? `Bearer ${token}` : ''
      }

      customParams = {
        method: "POST",
        headers: headerWithAuth,
        body: JSON.stringify(body)
      };
    }

    const response = await fetch(fetchURL, customParams);
    const data: any = await response.json();

    return data;
  } catch (err) {
    console.error(err);
  }
};

export const putData = async (URI: string, body: any, isAuth: boolean = false) => {
  try {
    const fetchURL = process.env.REACT_APP_LOCAL_HOST + URI;

    let header = {
      "Content-type": "application/json",
    }

    let customParams = {
      method: "PUT",
      headers: header,
      body: JSON.stringify(body)
    };

    if(isAuth === true) {
      const token: string = await JSON.parse(localStorage.getItem("user") as string);

      const headerWithAuth = {
        ...header,
        "authorization": token? `Bearer ${token}` : ''
      }

      customParams = {
        method: "PUT",
        headers: headerWithAuth,
        body: JSON.stringify(body)
      };
    }

    const response = await fetch(fetchURL, customParams);
    const data: any = await response.json();

    return data;
  } catch (err) {
    console.error(err);
  }
};

export const patchData = async (URI: string, body: any, isAuth: boolean = false) => {
  try {
    const fetchURL = process.env.REACT_APP_LOCAL_HOST + URI;

    let header = {
      "Content-type": "application/json",
    }

    let customParams = {
      method: "PATCH",
      headers: header,
      body: JSON.stringify(body)
    };

    if(isAuth === true) {
      const token: string = await JSON.parse(localStorage.getItem("user") as string);

      const headerWithAuth = {
        ...header,
        "authorization": token? `Bearer ${token}` : ''
      }

      customParams = {
        method: "PATCH",
        headers: headerWithAuth,
        body: JSON.stringify(body)
      };
    }

    const response = await fetch(fetchURL, customParams);
    const data: any = await response.json();
    
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const deleteData = async (URI: string, body: any, isAuth: boolean = false) => {
  try {
    const fetchURL = process.env.REACT_APP_LOCAL_HOST + URI;

    let header = {
      "Content-type": "application/json",
    }

    let customParams = {
      method: "DELETE",
      headers: header,
      body: JSON.stringify(body)
    };

    if(isAuth === true) {
      const token: string = await JSON.parse(localStorage.getItem("user") as string);

      const headerWithAuth = {
        ...header,
        "authorization": token? `Bearer ${token}` : ''
      }

      customParams = {
        method: "DELETE",
        headers: headerWithAuth,
        body: JSON.stringify(body)
      };
    }

    const response = await fetch(fetchURL, customParams);
    const data: any = await response.json();

    return data;
  } catch (err) {
    console.error(err);
  }
};


// import { authHeader } from '../authorization-header/auth-header';

// const header = {
//   "Content-type": "application/json",
// }
// const headerWithAuth = {
//   ...header,
//   "authorization": authHeader()
// }

// export const getData = async (URI: string, isAuth: boolean = false) => {
//   try {
//     const fetchURL = process.env.REACT_APP_LOCAL_HOST + URI;

//     const customParams = {
//       method: "GET",
//       headers: isAuth ? headerWithAuth : header,
//     };

//     const response = await fetch(fetchURL, customParams);
//     const data: any = await response.json();
//     return data;

//   } catch (err) {
//     console.error(err);
//   }
// };

// export const patchData = async (URI: string, body: any, isAuth: boolean = false) => {
//   try {
//     const fetchURL = process.env.REACT_APP_LOCAL_HOST + URI;

//     const customParams = {
//       method: "PATCH",
//       headers: isAuth ? headerWithAuth : header,
//       body: JSON.stringify(body)
//     };

//     const response = await fetch(fetchURL, customParams);
//     const data: any = await response.json();
//     return data;
//   } catch (err) {
//     console.error(err);
//   }
// };

// export const postData = async (URI: string, body: any, isAuth: boolean = false) => {
//   try {
//     const fetchURL = process.env.REACT_APP_LOCAL_HOST + URI;

//     const customParams = {
//       method: "POST",
//       headers: isAuth ? headerWithAuth : header,
//       body: JSON.stringify(body),
//     };

//     const response = await fetch(fetchURL, customParams);
//     const data: any = await response.json();

//     return data;
//   } catch (err) {
//     console.error(err);
//   }
// };
