import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { KeyValueInput, KeyValueInputPrototypeDisplayParameters, KeyValueInputChangedDisplayParameters } from '../../../03_baseComponents/KeyValueInput';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../03_baseComponents/Button';
import { emailInputTest, numberOfCharactersGreaterThanOrEqual, equalCondition } from '../../../../services/Conditions';
import { SingleRowText } from '../../../03_baseComponents/SingleRowText';
import { MultipleRowText, MultipleRowPrototypeDisplayParameters, MultipleRowChangedDisplayParameters } from '../../../03_baseComponents/MultipleRowText';
import { ForgotPasswordRequestRequest } from '../../../../models/UserProps';
import { postData } from "../../../../services/API.services";
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';





export interface ForgotPasswordRequestProps {
  webpageStyle: WebpageStyleProps,
  resizeMainContentHeight: Array<any>
}





export const ForgotPasswordRequest: FC<ForgotPasswordRequestProps> = ({ webpageStyle, resizeMainContentHeight }) => {
  //

  const [email, setEmail] = useState<Array<string>>([]);

  const [errorMessage, setErrorMessage] = useState<string>("");

  
  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.mainContent.forgotPasswordRequest.boxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.boxTop,
    left: webpageStyle.content.mainContent.boxLeft,
    height: boxHeight,
    width: webpageStyle.content.mainContent.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.boxOutlineColor
  });


  const [firstFieldTop, setFirstFieldTop] = useState<number>(webpageStyle.content.mainContent.forgotPasswordRequest.firstFieldTop);
  const [topDistance, setTopDistance] = useState<number>(webpageStyle.content.mainContent.forgotPasswordRequest.topDistance);

  const [keyValueInputPrototypeDisplayParameters, setKeyValueInputPrototypeDisplayParameters] = useState<KeyValueInputPrototypeDisplayParameters>({
    keyTop: firstFieldTop,
    keyLeft: webpageStyle.content.mainContent.forgotPasswordRequest.keyLeft,
    keyWidth: webpageStyle.content.mainContent.forgotPasswordRequest.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.forgotPasswordRequest.keyFontSize,
    keyColor: webpageStyle.content.mainContent.forgotPasswordRequest.keyColor,
    fieldTop: firstFieldTop,
    fieldLeft: webpageStyle.content.mainContent.forgotPasswordRequest.fieldLeft,
    fieldHeight: webpageStyle.content.mainContent.forgotPasswordRequest.fieldHeight,
    fieldWidth: webpageStyle.content.mainContent.forgotPasswordRequest.fieldWidth,
    fieldBackgroundColor: webpageStyle.content.mainContent.forgotPasswordRequest.fieldBackgroundColor,
    valueFontSize: webpageStyle.content.mainContent.forgotPasswordRequest.valueFontSize,
    valueColor: webpageStyle.content.mainContent.forgotPasswordRequest.valueColor,
  });

  const [forgotPasswordRequestButtonPrototypeDisplayParameters, setForgotPasswordRequestButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.forgotPasswordRequest.forgotPasswordRequestButtonTop,
    left: webpageStyle.content.mainContent.forgotPasswordRequest.forgotPasswordRequestButtonLeft,
    height: webpageStyle.content.mainContent.forgotPasswordRequest.forgotPasswordRequestButtonHeight,
    width: webpageStyle.content.mainContent.forgotPasswordRequest.forgotPasswordRequestButtonWidth,
    backgroundColor: webpageStyle.content.mainContent.forgotPasswordRequest.forgotPasswordRequestButtonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.forgotPasswordRequest.forgotPasswordRequestButtonFontSize,
    contentColor: webpageStyle.content.mainContent.forgotPasswordRequest.forgotPasswordRequestButtonContentColorActive
  });

  const [errorMessagePrototypeDisplayParameters, setErrorMessagePrototypeDisplayParameters] = useState<MultipleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.forgotPasswordRequest.errorMessageTop,
    left: webpageStyle.content.mainContent.forgotPasswordRequest.errorMessageLeft,
    height: webpageStyle.content.mainContent.forgotPasswordRequest.errorMessageHeight,
    width: webpageStyle.content.mainContent.forgotPasswordRequest.errorMessageWidth,
    fontSize: webpageStyle.content.mainContent.forgotPasswordRequest.errorMessageFontSize,
    color: webpageStyle.content.mainContent.forgotPasswordRequest.errorMessageColor
  });


  let history = useHistory();

  const forgotPasswordRequest = async () => {
    //

    const test : boolean = testConditions();

    if(test === true) {
      const forgotPasswordRequestRequest: ForgotPasswordRequestRequest = {
        email: email[0]
      }
      const result = await postData(`/api/users/single/forgotpasswordrequest`, forgotPasswordRequestRequest);
      if (!result || (result as { status: number, message: string })?.status >= 400) {
        setErrorMessage(result.message);
      } else {
        setErrorMessage("");
        history.push("/login");
      }
    }
  }

  const testConditions = (): boolean => {
    //

    const testEmail = emailInputTest(email);

    if(testEmail.length !== 0) {
      if(testEmail[0] !== true) {
        setErrorMessage("Not valid email address");
        return(false);
      }
    }

    setErrorMessage("");
    return(true);
  }


  useEffect(() => {
    resizeMainContentHeight[0](boxHeight);
  }, []);


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <KeyValueInput
        keyText = { "Email" }
        initialValueString = { [] }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { [] }
        inputType = { "EmailInput" }
        modificationFunctions = { [setEmail, () => { setErrorMessage("") }] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstFieldTop + 0 * topDistance,
          fieldTop: firstFieldTop + 0 * topDistance
        } }
      />

      <Button
        buttonText = { "Password request" }
        actionFunctions = { [forgotPasswordRequest] }
        disabled = { false }
        prototypeDisplayParameters = { forgotPasswordRequestButtonPrototypeDisplayParameters }
      />

      {/* <SingleRowText
        text = { errorMessage }
        redirectionUrl = { [] }
        message = { [] }
        top = { errorMessageTop }
        left = { errorMessageLeft }
        width = { errorMessageWidth }
        fontSize = { errorMessageFontSize }
        color = { errorMessageColor }
      /> */}

      <MultipleRowText
        text = { errorMessage }
        prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
      />
    </Box>
  )
}
