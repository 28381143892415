import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../../services/webpageStyle';
import { Horse } from '../../../../../models/HorseProps';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../../03_baseComponents/Box';
import { ImagesAndVideos } from '../../../../03_baseComponents/ImagesAndVideos';
import { ImageOrVideo } from '../../../../03_baseComponents/ImageAndVideoUploader/UploadedImageAndVideoElement';
import { AttachedFile } from '../../../../../models/AttachedFile';
import { sortImagesAndVideos } from '../../../../../services/SortImagesAndVideos';





export interface HorseImagesAndVideosProps {
  webpageStyle: WebpageStyleProps,
  horse: Array<Horse>
}





export const HorseImagesAndVideos: FC<HorseImagesAndVideosProps> = ({ webpageStyle, horse }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [imagesAndVideos, setImagesAndVideos] = useState<Array<ImageOrVideo>>([]);
  

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.boxTop,
    left: webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.boxLeft,
    height: webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.boxHeight,
    width: webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.boxOutlineColor
  });


  const [imagesAndVideosTop, setImagesAndVideosTop] = useState<number>(webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.imagesAndVideosTop);
  const [imagesAndVideosLeft, setImagesAndVideosLeft] = useState<number>(webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.imagesAndVideosLeft);
  const [imagesAndVideosHeight, setImagesAndVideosHeight] = useState<number>(webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.imagesAndVideosHeight);
  const [imagesAndVideosWidth, setImagesAndVideosWidth] = useState<number>(webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.imagesAndVideosWidth);
  const [imagesAndVideosPaginationTop, setImagesAndVideosPaginationTop] = useState<number>(webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.imagesAndVideosPaginationTop);
  const [imagesAndVideosPaginationLeft, setImagesAndVideosPaginationLeft] = useState<number>(webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.imagesAndVideosPaginationLeft);
  const [imagesAndVideosPaginationWidth, setImagesAndVideosPaginationWidth] = useState<number>(webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.imagesAndVideosPaginationWidth);
  const [imagesAndVideosPaginationFontSize, setImagesAndVideosPaginationFontSize] = useState<number>(webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.imagesAndVideosPaginationFontSize);

  const [imageBoxTop, setImageBoxTop] = useState<number>(webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.imageBoxTop);
  const [imageBoxLeft, setImageBoxLeft] = useState<number>(webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.imageBoxLeft);
  const [imageBoxHeight, setImageBoxHeight] = useState<number>(webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.imageBoxHeight);
  const [imageBoxWidth, setImageBoxWidth] = useState<number>(webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.imageBoxWidth);
  const [imageBoxBackgroundColor, setImageBoxBackgroundColor] = useState<string>(webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.imageBoxBackgroundColor);
  const [imageBoxOutlineStyle, setImageBoxOutlineStyle] = useState<string>(webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.imageBoxOutlineStyle);
  const [imageBoxOutlineWidth, setImageBoxOutlineWidth] = useState<number>(webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.imageBoxOutlineWidth);
  const [imageBoxOutlineColor, setImageBoxOutlineColor] = useState<string>(webpageStyle.content.mainContent.horseProfile.horseImagesAndVideos.imageBoxOutlineColor);


  const loadImagesAndVideos = (horseVariable: Array<Horse>) => {
    // const images: Array<AttachedFile> = [];
    // const videos: Array<AttachedFile> = [];

    // for(let i: number = 0; i < horseVariable[0].images.length; i++) {
    //   images.push(horseVariable[0].images[i]);
    // }

    // for(let i: number = 0; i < horseVariable[0].videos.length; i++) {
    //   videos.push(horseVariable[0].videos[i]);
    // }

    const temporaryImagesAndVideos: Array<ImageOrVideo> = sortImagesAndVideos(horseVariable[0].images, horseVariable[0].videos);

    // for(let i: number = 0; i < (horseVariable[0].images.length + horseVariable[0].videos.length); i++) {
    //   let minSequenceNumber = -1;
    //   let imageIndexOfMinSequenceNumber: number = -1;
    //   let videoIndexOfMinSequenceNumber: number = -1;

    //   for(let j: number = 0; j < images.length; j++) {
    //     if(minSequenceNumber !== -1) {
    //       if(images[j].sequenceNumber < minSequenceNumber) {
    //         minSequenceNumber = images[j].sequenceNumber;
    //         imageIndexOfMinSequenceNumber = j;
    //         videoIndexOfMinSequenceNumber = -1;
    //       }
    //     } else {
    //       minSequenceNumber = images[j].sequenceNumber;
    //       imageIndexOfMinSequenceNumber = j;
    //     }
    //   }

    //   for(let j: number = 0; j < videos.length; j++) {
    //     if(minSequenceNumber !== -1) {
    //       if(videos[j].sequenceNumber < minSequenceNumber) {
    //         minSequenceNumber = videos[j].sequenceNumber;
    //         videoIndexOfMinSequenceNumber = j;
    //         imageIndexOfMinSequenceNumber = -1;
    //       }
    //     } else {
    //       minSequenceNumber = videos[j].sequenceNumber;
    //       videoIndexOfMinSequenceNumber = j;
    //     }
    //   }

    //   if(imageIndexOfMinSequenceNumber !== -1) {
    //     temporaryImagesAndVideos.push({
    //       oldTitle: images[imageIndexOfMinSequenceNumber].title,
    //       image: [{
    //         uploadedImage: [],
    //         sourceUrl: [images[imageIndexOfMinSequenceNumber].url],
    //         oldTitle: images[imageIndexOfMinSequenceNumber].title,
    //         title: images[imageIndexOfMinSequenceNumber].title,
    //         fileExtension: images[imageIndexOfMinSequenceNumber].type,
    //         id: images[imageIndexOfMinSequenceNumber].id
    //       }],
    //       video: []
    //     });

    //     images.splice(imageIndexOfMinSequenceNumber, 1);
    //   }

    //   if(videoIndexOfMinSequenceNumber !== -1 ) {
    //     temporaryImagesAndVideos.push({
    //       oldTitle: videos[videoIndexOfMinSequenceNumber].title,
    //       image: [],
    //       video: [{
    //         sourceUrl: [videos[videoIndexOfMinSequenceNumber].url],
    //         oldTitle: videos[videoIndexOfMinSequenceNumber].title,
    //         title: videos[videoIndexOfMinSequenceNumber].title,
    //         id: videos[videoIndexOfMinSequenceNumber].id
    //       }]
    //     });

    //     videos.splice(videoIndexOfMinSequenceNumber, 1);
    //   }
    // }

    // for(let i: number = 0; i < horseVariable[0].images.length; i++) {
    //   const temporaryImageOrVideo: ImageOrVideo = {
    //     oldTitle: horseVariable[0].images[i].title,
    //     image: [{
    //       uploadedImage: [],
    //       sourceUrl: [horseVariable[0].images[i].url],
    //       oldTitle: horseVariable[0].images[i].title,
    //       title: horseVariable[0].images[i].title,
    //       fileExtension: horseVariable[0].images[i].type,
    //       id: horseVariable[0].images[i].id
    //     }],
    //     video: []
    //   }
    //   temporaryImagesAndVideos.push(temporaryImageOrVideo);
    // }

    // for(let i: number = 0; i < horseVariable[0].videos.length; i++) {
    //   const temporaryImageOrVideo: ImageOrVideo = {
    //     oldTitle: horseVariable[0].videos[i].title,
    //     image: [],
    //     video: [{
    //       sourceUrl: [horseVariable[0].videos[i].url],
    //       oldTitle: horseVariable[0].videos[i].title,
    //       title: horseVariable[0].videos[i].title,
    //       id: horseVariable[0].videos[i].id
    //     }]
    //   }
    //   temporaryImagesAndVideos.push(temporaryImageOrVideo);
    // }

    setImagesAndVideos(temporaryImagesAndVideos);

    setIsLoading(false);
  }


  useEffect(() => {
    loadImagesAndVideos(horse);
  }, []);


  if(isLoading === true) {
    return(
      <div>
        Horse images and videos are loading...
      </div>
    )
  } else {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      >
        <Box
          prototypeDisplayParameters = { {
            top: imageBoxTop,
            left: imageBoxLeft,
            height: imageBoxHeight,
            width: imageBoxWidth,
            backgroundColor: imageBoxBackgroundColor,
            outlineStyle: imageBoxOutlineStyle,
            outlineWidth: imageBoxOutlineWidth,
            outlineColor: imageBoxOutlineColor
          } }
        />
        
        <ImagesAndVideos
          imagesAndVideos = { imagesAndVideos }
          collectionTop = { imagesAndVideosTop }
          collectionLeft = { imagesAndVideosLeft }
          elementHeight = { imagesAndVideosHeight }
          elementWidth = { imagesAndVideosWidth }
          paginationTop = { imagesAndVideosPaginationTop }
          paginationLeft = { imagesAndVideosPaginationLeft }
          paginationWidth = { imagesAndVideosPaginationWidth }
          paginationFontSize = { imagesAndVideosPaginationFontSize }
        />
      </Box>
    )
  }
}
