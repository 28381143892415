import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../../03_baseComponents/Box';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../../03_baseComponents/Button';





export interface AdminUsersNavigationProps {
  webpageStyle: WebpageStyleProps,
  adminUsersPageContentType: string,
  changeAdminUserListPageContentType: Array<any>
}





export const AdminUsersNavigation: FC<AdminUsersNavigationProps> = ({ webpageStyle, adminUsersPageContentType,
  changeAdminUserListPageContentType }) => {
  //

  const [sidebarHeight, setSidebarHeight] = useState<number>(webpageStyle.content.sidebar.adminUserList.navigation.boxHeight);

  const [boxTop, setBoxTop] = useState<number>();
  const [boxLeft, setBoxLeft] = useState<number>();
  const [boxHeight, setBoxHeight] = useState<number>(sidebarHeight);
  const [boxWidth, setBoxWidth] = useState<number>();
  const [boxBackgroundColor, setBoxBackgroundColor] = useState<string>();
  const [boxOutlineStyle, setBoxOutlineStyle] = useState<string>();
  const [boxOutlineWidth, setBoxOutlineWidth] = useState<number>();
  const [boxOutlineColor, setBoxOutlineColor] = useState<string>();

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.adminUserList.navigation.boxTop,
    left: webpageStyle.content.sidebar.navigation.boxLeft,
    height: sidebarHeight,
    width: webpageStyle.content.sidebar.navigation.boxWidth,
    backgroundColor: webpageStyle.content.sidebar.navigation.boxBackgroundColor,
    outlineStyle: webpageStyle.content.sidebar.navigation.boxOutlineStyle,
    outlineWidth: webpageStyle.content.sidebar.navigation.boxOutlineWidth,
    outlineColor: webpageStyle.content.sidebar.navigation.boxOutlineColor
  });


  const [firstButtonTop, setFirstButtonTop] = useState<number>(webpageStyle.content.sidebar.navigation.firstButtonTop);
  const [buttonTopDistance, setButtonTopDistance] = useState<number>(webpageStyle.content.sidebar.navigation.buttonTopDistance);

  const [buttonBackgroundColorActive, setButtonBackgroundColorActive] = useState<string>(webpageStyle.content.sidebar.navigation.buttonBackgroundColorActive);
  const [buttonContentColorActive, setButtonContentColorActive] = useState<string>(webpageStyle.content.sidebar.navigation.buttonContentColorActive);
  const [buttonBackgroundColorHighlighted, setButtonBackgroundColorHighlighted] = useState<string>(webpageStyle.content.sidebar.navigation.buttonBackgroundColorHighlighted);
  const [buttonContentColorHighlighted, setButtonContentColorHighlighted] = useState<string>(webpageStyle.content.sidebar.navigation.buttonContentColorHighlighted);

  const [buttonPrototypeDisplayParameters, setButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.navigation.firstButtonTop,
    left: webpageStyle.content.sidebar.navigation.buttonLeft,
    height: webpageStyle.content.sidebar.navigation.buttonHeight,
    width: webpageStyle.content.sidebar.navigation.buttonWidth,
    backgroundColor: buttonBackgroundColorActive,
    fontSize: webpageStyle.content.sidebar.navigation.buttonFontSize,
    contentColor: buttonContentColorActive
  });


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <div>
        <Button
          buttonText = { "User list" }
          actionFunctions = { [() => changeAdminUserListPageContentType[0]("userList")] }
          conditionFunction = { [() => {
            if(adminUsersPageContentType !== "userList") {
              return(true);
            } else {
              return(false);
            }
          }] }
          disabled = { false }
          prototypeDisplayParameters = { buttonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            top: firstButtonTop + 0 * buttonTopDistance,
            backgroundColor: adminUsersPageContentType !== "userList" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
            contentColor: adminUsersPageContentType !== "userList" ?  buttonContentColorActive : buttonContentColorHighlighted
          } }
        />

        <Button
          buttonText = { "Invite user" }
          actionFunctions = { [() => changeAdminUserListPageContentType[0]("inviteUser")] }
          conditionFunction = { [() => {
            if(adminUsersPageContentType !== "inviteUser") {
              return(true);
            } else {
              return(false);
            }
          }] }
          disabled = { false }
          prototypeDisplayParameters = { buttonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            top: firstButtonTop + 1 * buttonTopDistance,
            backgroundColor: adminUsersPageContentType !== "inviteUser" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
            contentColor: adminUsersPageContentType !== "inviteUser" ?  buttonContentColorActive : buttonContentColorHighlighted
          } }
        />
      </div>
    </Box>
  )
}
