import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { UserData } from '../../../../models/UserProps';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { KeyValueDisplay, KeyValueDisplayPrototypeDisplayParameters, KeyValueDisplayChangedDisplayParameters } from '../../../03_baseComponents/KeyValueDisplay';





export interface ReadUserDataProps {
  webpageStyle: WebpageStyleProps,
  userData: Array<UserData>,
  resizeMainContentHeight: Array<any>
}





export const ReadUserData: FC<ReadUserDataProps> = ({ webpageStyle, userData, resizeMainContentHeight }) => {
  //
  
  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.mainContent.readUserData.boxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.boxTop,
    left: webpageStyle.content.mainContent.boxLeft,
    height: boxHeight,
    width: webpageStyle.content.mainContent.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.boxOutlineColor
  });


  const [firstDataTop, setFirstDataTop] = useState<number>(webpageStyle.content.mainContent.readUserData.firstDataTop);
  const [dataTopDistance, setDataTopDistance] = useState<number>(webpageStyle.content.mainContent.readUserData.dataTopDistance);

  const [keyValueDisplayPrototypeDisplayParameters, setKeyValueDisplayPrototypeDisplayParameters] = useState<KeyValueDisplayPrototypeDisplayParameters>({
    keyTop: firstDataTop,
    keyLeft: webpageStyle.content.mainContent.readUserData.keyLeft,
    keyWidth: webpageStyle.content.mainContent.readUserData.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.readUserData.keyFontSize,
    keyColor: webpageStyle.content.mainContent.readUserData.keyColor,
    valueTop: firstDataTop,
    valueLeft: webpageStyle.content.mainContent.readUserData.valueLeft,
    valueWidth: webpageStyle.content.mainContent.readUserData.valueWidth,
    valueFontSize: webpageStyle.content.mainContent.readUserData.valueFontSize,
    valueColor: webpageStyle.content.mainContent.readUserData.valueColor
  });


  useEffect(() => {
    resizeMainContentHeight[0](boxHeight);
  }, []);


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <KeyValueDisplay
        keyText = { "Email" }
        valueText = { userData[0].email }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 0 * dataTopDistance,
          valueTop: firstDataTop + 0 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Name" }
        valueText = { userData[0].name }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 1 * dataTopDistance,
          valueTop: firstDataTop + 1 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Email 2" }
        valueText = { userData[0].email2 }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 2 * dataTopDistance,
          valueTop: firstDataTop + 2 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Telephone number 1" }
        valueText = { userData[0].telephoneNumber1 }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 3 * dataTopDistance,
          valueTop: firstDataTop + 3 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Telephone number 2" }
        valueText = { userData[0].telephoneNumber2 }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 4 * dataTopDistance,
          valueTop: firstDataTop + 4 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Language 1" }
        valueText = { userData[0].language1 }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 5 * dataTopDistance,
          valueTop: firstDataTop + 5 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Language 2" }
        valueText = { userData[0].language2 }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 6 * dataTopDistance,
          valueTop: firstDataTop + 6 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Shipment continent" }
        valueText = { userData[0].shipmentContinent }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 8 * dataTopDistance,
          valueTop: firstDataTop + 8 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Shipment country" }
        valueText = { userData[0].shipmentCountry }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 9 * dataTopDistance,
          valueTop: firstDataTop + 9 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Shipment state" }
        valueText = { userData[0].shipmentState }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 10 * dataTopDistance,
          valueTop: firstDataTop + 10 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Shipment ZIP code" }
        valueText = { userData[0].shipmentZipCode }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 11 * dataTopDistance,
          valueTop: firstDataTop + 11 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Shipment city" }
        valueText = { userData[0].shipmentCity }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 12 * dataTopDistance,
          valueTop: firstDataTop + 12 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Shipment name of public area" }
        valueText = { userData[0].shipmentNameOfPublicArea }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 13 * dataTopDistance,
          valueTop: firstDataTop + 13 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Shipment type of public area" }
        valueText = { userData[0].shipmentTypeOfPublicArea }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 14 * dataTopDistance,
          valueTop: firstDataTop + 14 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Shipment house number" }
        valueText = { userData[0].shipmentHouseNumber }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 15 * dataTopDistance,
          valueTop: firstDataTop + 15 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Shipment floor" }
        valueText = { userData[0].shipmentFloor }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 16 * dataTopDistance,
          valueTop: firstDataTop + 16 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Shipment door" }
        valueText = { userData[0].shipmentDoor }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 17 * dataTopDistance,
          valueTop: firstDataTop + 17 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Invoicing continent" }
        valueText = { userData[0].invoicingContinent }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 19 * dataTopDistance,
          valueTop: firstDataTop + 19 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Invoicing country" }
        valueText = { userData[0].invoicingCountry }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 20 * dataTopDistance,
          valueTop: firstDataTop + 20 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Invoicing state" }
        valueText = { userData[0].invoicingState }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 21 * dataTopDistance,
          valueTop: firstDataTop + 21 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Invoicing ZIP code" }
        valueText = { userData[0].invoicingZipCode }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 22 * dataTopDistance,
          valueTop: firstDataTop + 22 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Invoicing city" }
        valueText = { userData[0].invoicingCity }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 23 * dataTopDistance,
          valueTop: firstDataTop + 23 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Invoicing name of public area" }
        valueText = { userData[0].invoicingNameOfPublicArea }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 24 * dataTopDistance,
          valueTop: firstDataTop + 24 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Invoicing type of public area" }
        valueText = { userData[0].invoicingTypeOfPublicArea }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 25 * dataTopDistance,
          valueTop: firstDataTop + 25 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Invoicing house number" }
        valueText = { userData[0].invoicingHouseNumber }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 26 * dataTopDistance,
          valueTop: firstDataTop + 26 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Invoicing floor" }
        valueText = { userData[0].invoicingFloor }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 27 * dataTopDistance,
          valueTop: firstDataTop + 27 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Invoicing door" }
        valueText = { userData[0].invoicingDoor }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 28 * dataTopDistance,
          valueTop: firstDataTop + 28 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Bank name" }
        valueText = { userData[0].bankName }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 30 * dataTopDistance,
          valueTop: firstDataTop + 30 * dataTopDistance
        } }
      />

      <KeyValueDisplay
        keyText = { "Bank account number" }
        valueText = { userData[0].bankAccountNumber }
        prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstDataTop + 31 * dataTopDistance,
          valueTop: firstDataTop + 31 * dataTopDistance
        } }
      />

      {/* <KeyValueDisplay
        keyText = { "" }
        valueText = { user[0]. }
        redirectionUrl = { [] }
        message = { [] }
        keyTop = { (firstDataTop + _ * dataTopDistance) }
        keyLeft = { keyLeft }
        keyWidth = { keyWidth }
        keyFontSize = { keyFontSize }
        keyColor = { keyColor }
        valueTop = { (firstDataTop + _ * dataTopDistance) }
        valueLeft = { valueLeft }
        valueWidth = { valueWidth }
        valueFontSize = { valueFontSize }
        valueColor = { valueColor }
      /> */}
    </Box>
  )
}
