//

export function textWidth(inputText: string, fontSize: number): number {
  // az értékek 100 darab 20-as méretű karakterekkel lettek tesztelve a default betűtípussal

  let result: number = 0;

  for(let i: number = 0; i < inputText.length; i++) {
    switch(inputText[i]) {
      case(" "):
        result += 550;
        break;
      case("a"):
        result += 1020;
        break;
      case("á"):
        result += 1020;
        break;
      case("b"):
        result += 1180;
        break;
      case("c"):
        result += 920;
        break;
      case("d"):
        result += 1180;
        break;
      case("e"):
        result += 1050;
        break;
      case("é"):
        result += 1050;
        break;
      case("f"):
        result += 630;
        break;
      case("g"):
        result += 1180;
        break;
      case("h"):
        result += 1130;
        break;
      case("i"):
        result += 490;
        break;
      case("í"):
        result += 490;
        break;
      case("j"):
        result += 490;
        break;
      case("k"):
        result += 1000;
        break;
      case("l"):
        result += 490;
        break;
      case("m"):
        result += 1720;
        break;
      case("n"):
        result += 1130;
        break;
      case("o"):
        result += 1170;
        break;
      case("ó"):
        result += 1170;
        break;
      case("ö"):
        result += 1170;
        break;
      case("ő"):
        result += 1170;
        break;
      case("p"):
        result += 1180;
        break;
      case("q"):
        result += 1180;
        break;
      case("r"):
        result += 700;
        break;
      case("s"):
        result += 850;
        break;
      case("t"):
        result += 680;
        break;
      case("u"):
        result += 1130;
        break;
      case("ú"):
        result += 1130;
        break;
      case("ü"):
        result += 1130;
        break;
      case("ű"):
        result += 1130;
        break;
      case("v"):
        result += 960;
        break;
      case("w"):
        result += 1450;
        break;
      case("x"):
        result += 920;
        break;
      case("y"):
        result += 970;
        break;
      case("z"):
        result += 910;
        break;
      case("A"):
        result += 1290;
        break;
      case("Á"):
        result += 1290;
        break;
      case("B"):
        result += 1140;
        break;
      case("C"):
        result += 1190;
        break;
      case("D"):
        result += 1400;
        break;
      case("E"):
        result += 1010;
        break;
      case("É"):
        result += 1010;
        break;
      case("F"):
        result += 980;
        break;
      case("G"):
        result += 1370;
        break;
      case("H"):
        result += 1420;
        break;
      case("I"):
        result += 530;
        break;
      case("Í"):
        result += 530;
        break;
      case("J"):
        result += 630;
        break;
      case("K"):
        result += 1160;
        break;
      case("L"):
        result += 940;
        break;
      case("M"):
        result += 1800;
        break;
      case("N"):
        result += 1500;
        break;
      case("O"):
        result += 1510;
        break;
      case("Ó"):
        result += 1510;
        break;
      case("Ö"):
        result += 1510;
        break;
      case("Ő"):
        result += 1510;
        break;
      case("P"):
        result += 1120;
        break;
      case("Q"):
        result += 1510;
        break;
      case("R"):
        result += 1200;
        break;
      case("S"):
        result += 1170;
        break;
      case("T"):
        result += 1090;
        break;
      case("U"):
        result += 1380;
        break;
      case("V"):
        result += 1240;
        break;
      case("W"):
        result += 1870;
        break;
      case("X"):
        result += 1180;
        break;
      case("Y"):
        result += 1110;
        break;
      case("Z"):
        result += 1140;
        break;
      case("0"):
        result += 1080;
        break;
      case("1"):
        result += 1080;
        break;
      case("2"):
        result += 1080;
        break;
      case("3"):
        result += 1080;
        break;
      case("4"):
        result += 1080;
        break;
      case("5"):
        result += 1080;
        break;
      case("6"):
        result += 1080;
        break;
      case("7"):
        result += 1080;
        break;
      case("8"):
        result += 1080;
        break;
      case("9"):
        result += 1080;
        break;
      case("."):
        result += 430;
        break;
      case(","):
        result += 430;
        break;
      case(";"):
        result += 430;
        break;
      case("!"):
        result += 570;
        break;
      case("?"):
        result += 900;
        break;
      case("+"):
        result += 1360;
        break;
      case("-"):
        result += 800;
        break;
      case("–"):
        result += 1000;
        break;
      case("="):
        result += 1370;
        break;
      case("/"):
        result += 780;
        break;
      case("%"):
        result += 1650;
        break;
      case("("):
        result += 610;
        break;
      case(")"):
        result += 610;
        break;
      case("{"):
        result += 610;
        break;
      case("}"):
        result += 610;
        break;
      case("["):
        result += 610;
        break;
      case("]"):
        result += 610;
        break;
      case("&"):
        result += 1600;
        break;
      case("@"):
        result += 1910;
        break;
      case("#"):
        result += 1180;
        break;
      case("<"):
        result += 1370;
        break;
      case(">"):
        result += 1370;
        break;
      case("$"):
        result += 1080;
        break;
      case("€"):
        result += 1080;
        break;
      case('"'):
        result += 790;
        break;
      case("'"):
        result += 460;
        break;
      case("_"):
        result += 830;
        break;
      case("|"):
        result += 490;
        break;
      case(""):
        result += 0;
        break;
      default:
        result+= 1200;
        break;
    }
  }

  let fontSizeRatio: number = 1;

  if(fontSize !== 10) {
    fontSizeRatio = fontSize / 20;
  } else {
    // valami react-os bug következtében 11-es betűméretnek felel meg
    fontSizeRatio = 11 / 20;
  }

  result *= fontSizeRatio;
  result /= 100;

  return(result);
}
