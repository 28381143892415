import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Horse, HorseData } from '../../../../models/HorseProps';
import { getData } from '../../../../services/API.services';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { HorseTitle } from './HorseProfile/HorseTitle';
import { HorseImagesAndVideos } from './HorseProfile/HorseImagesAndVideos';
import { HorseDataSheet } from './HorseProfile/HorseDataSheet';
import { HorseDescription } from './HorseProfile/HorseDescription';
import { HorseInterestPanel } from './HorseProfile/HorseInterestPanel';
import { LoggedInUser } from '../../../../models/UserProps';





export interface HorseProfileProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  horse: Array<Horse>,
  resizeMainContentHeight: Array<any>
}





export const HorseProfile: FC<HorseProfileProps> = ({ webpageStyle, loggedInUser, horse, resizeMainContentHeight }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(false); // true

  // const [horse, setHorse] = useState<Array<Horse>>([]);

  
  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.mainContent.horseProfile.boxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.boxTop,
    left: webpageStyle.content.mainContent.boxLeft,
    height: boxHeight,
    width: webpageStyle.content.mainContent.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.boxOutlineColor
  });


  // const saveHorse = (horseVariable: Array<Horse>) => {
  //   setHorse(horseVariable);
  // }

  // const getHorse = async () => {
  //   let horseId: string = "1";

  //   const result = await getData(`/api/horses/single/visitor/${ horseId }`);
  //   if( !result || ( result as { status: number, message: string } )?.status >= 400 ) {
  //     if(result !== undefined) {
  //       if(result.message !== undefined) {
  //         console.log(result.message);
  //         setIsLoading(false);
  //       }
  //     }
  //   } else {
  //     //

  //     // console.log(result);

  //     if(result.length !== 0) {
  //       saveHorse(result);
  //     }

  //     setIsLoading(false);
  //   }
  // }


  useEffect(() => {
    resizeMainContentHeight[0](boxHeight);
    // getHorse();
  }, []);


  if(isLoading === true) {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      >
        <div>
          Horse page is loading...
        </div>
      </Box>
    )
  } else {
    if(horse.length !== 0) {
      return(
        <Box
          prototypeDisplayParameters = { boxPrototypeDisplayParameters }
        >
          <div>
            <HorseTitle
              webpageStyle = { webpageStyle }
              horse = { horse }
            />

            <HorseImagesAndVideos
              webpageStyle = { webpageStyle }
              horse = { horse }
            />

            <HorseDataSheet
              webpageStyle = { webpageStyle }
              horse = { horse }
            />

            <HorseDescription
              webpageStyle = { webpageStyle }
              horse = { horse }
            />

            { loggedInUser.role !== "administrator" && loggedInUser.role !== "account owner" ?
              <HorseInterestPanel
                webpageStyle = { webpageStyle }
                horse = { horse }
                interestFunction = { [] }
              /> : null
            }
          </div>
        </Box>
      )
    } else {
      return(
        <Box
          prototypeDisplayParameters = { boxPrototypeDisplayParameters }
        >
          <div>
            Horse page is unable to load...
          </div>
        </Box>
      )
    }
  }
}
