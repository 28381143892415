import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../03_baseComponents/Box';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../03_baseComponents/Button';
import { SingleRowText, SingleRowPrototypeDisplayParameters, SingleRowChangedDisplayParameters } from '../03_baseComponents/SingleRowText';
import { resetActionsDone } from '../../App';
import { LoggedInUser } from '../../models/UserProps';





export interface HeaderProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  path: string,
  changeLoggedInUser: Array<any>,
  changeContent: Array<any>,
  doAction: Array<any>
}





export const Header: FC<HeaderProps> = ({ webpageStyle, loggedInUser, path,
  changeLoggedInUser, changeContent, doAction }) => {
  //

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.header.boxTop + webpageStyle.general.webpageMargin,
    left: webpageStyle.header.boxLeft + webpageStyle.general.webpageMargin,
    height: webpageStyle.header.boxHeight,
    width: webpageStyle.header.boxWidth,
    backgroundColor: webpageStyle.header.boxBackgroundColor,
    outlineStyle: webpageStyle.header.boxOutlineStyle,
    outlineWidth: webpageStyle.header.boxOutlineWidth,
    outlineColor: webpageStyle.header.boxOutlineColor
  });


  const [buttonHeight, setButtonHeight] = useState<number>(webpageStyle.header.buttonHeight);
  const [buttonWidth, setButtonWidth] = useState<number>(webpageStyle.header.buttonWidth);
  const [buttonFontSize, setButtonFontSize] = useState<number>(webpageStyle.header.buttonFontSize);
  const [buttonBackgroundColorActive, setButtonBackgroundColorActive] = useState<string>(webpageStyle.header.buttonBackgroundColorActive);
  const [buttonContentColorActive, setButtonContentColorActive] = useState<string>(webpageStyle.header.buttonContentColorActive);
  const [buttonBackgroundColorHighlighted, setButtonBackgroundColorHighlighted] = useState<string>(webpageStyle.header.buttonBackgroundColorHighlighted);
  const [buttonContentColorHighlighted, setButtonContentColorHighlighted] = useState<string>(webpageStyle.header.buttonContentColorHighlighted);

  const [informationAndContactButtonPrototypeDisplayParameters, setInformationAndContactButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.header.positionA.top,
    left: webpageStyle.header.positionA.left,
    height: buttonHeight,
    width: buttonWidth,
    backgroundColor: buttonBackgroundColorActive,
    fontSize: buttonFontSize,
    contentColor: buttonContentColorActive
  });

  const [registrationButtonPrototypeDisplayParameters, setRegistrationButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.header.positionB.top,
    left: webpageStyle.header.positionB.left,
    height: buttonHeight,
    width: buttonWidth,
    backgroundColor: buttonBackgroundColorActive,
    fontSize: buttonFontSize,
    contentColor: buttonContentColorActive
  });

  const [loginButtonPrototypeDisplayParameters, setLoginButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.header.positionC.top,
    left: webpageStyle.header.positionC.left,
    height: buttonHeight,
    width: buttonWidth,
    backgroundColor: buttonBackgroundColorActive,
    fontSize: buttonFontSize,
    contentColor: buttonContentColorActive
  });

  const [logoutButtonPrototypeDisplayParameters, set] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.header.positionC.top,
    left: webpageStyle.header.positionC.left,
    height: buttonHeight,
    width: buttonWidth,
    backgroundColor: buttonBackgroundColorActive,
    fontSize: buttonFontSize,
    contentColor: buttonContentColorActive
  });

  const [loggedInUserEmailPrototypeDisplayParameters, setLoggedInUserEmailPrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: webpageStyle.header.displayTop,
    left: webpageStyle.header.displayLeft,
    width: webpageStyle.header.displayWidth,
    fontSize: webpageStyle.header.textFontSize,
    color: webpageStyle.header.textFontColor
  });


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <div>
        { (loggedInUser.role === "visitor" || loggedInUser.role === "customer" || loggedInUser.role === "administrator") ? // adminnak is kell?
          <Button
            buttonText = { "Information and Contact" }
            actionFunctions = { [changeContent[0]] } // ezt ki kell dolgozni
            redirectionUrl = { "/informationandcontact" }
            conditionFunction = { [() => {
              if(path !== "/informationandcontact") {
                return(true);
              } else {
                return(false);
              }
            }] }
            disabled = { false }
            prototypeDisplayParameters = { informationAndContactButtonPrototypeDisplayParameters }
            changedDisplayParameters = { {
              backgroundColor: path !== "/informationandcontact" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
              contentColor: path !== "/informationandcontact" ? buttonContentColorActive : buttonContentColorHighlighted
            } }
          /> : null
        }

        { loggedInUser.role === "visitor" ?
          <Button
            buttonText = { "Registration" }
            actionFunctions = { [changeContent[0]] } // ezt ki kell dolgozni
            redirectionUrl = { "/registration" }
            conditionFunction = { [() => {
              if(path !== "/registration") {
                return(true);
              } else {
                return(false);
              }
            }] }
            disabled = { false }
            prototypeDisplayParameters = { registrationButtonPrototypeDisplayParameters }
            changedDisplayParameters = { {
              backgroundColor: path !== "/registration" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
              contentColor: path !== "/registration" ? buttonContentColorActive : buttonContentColorHighlighted
            } }
          /> : null
        }

        { loggedInUser.role === "visitor" ?
          <Button
            buttonText = { "Login" }
            actionFunctions = { [changeContent[0]] } // ezt ki kell dolgozni
            redirectionUrl = { "/login" }
            conditionFunction = { [() => {
              if(path !== "/login") {
                return(true);
              } else {
                return(false);
              }
            }] }
            disabled = { false }
            prototypeDisplayParameters = { loginButtonPrototypeDisplayParameters }
            changedDisplayParameters = { {
              backgroundColor: path !== "/login" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
              contentColor: path !== "/login" ? buttonContentColorActive : buttonContentColorHighlighted
            } }
          /> : null
        }

        { (loggedInUser.role === "customer" || loggedInUser.role === "administrator") ?
          <Button
            buttonText = { "Logout" }
            actionFunctions = { [changeContent[0], () => { changeLoggedInUser[0](0, "", "visitor") },
              () => { localStorage.clear() }, () => resetActionsDone()] } // ezt ki kell dolgozni
            redirectionUrl = { "/" } // ez egy picit bonyolultabb lesz
            disabled = { false }
            prototypeDisplayParameters = { logoutButtonPrototypeDisplayParameters }
          /> : null
        }

        { loggedInUser.role === "customer" || loggedInUser.role === "administrator" ?
          <SingleRowText
            text = { `Logged in user email: ${ loggedInUser.email }${ loggedInUser.role === "administrator" ? " (admin)" : "" }` }
            prototypeDisplayParameters = { loggedInUserEmailPrototypeDisplayParameters }
          /> : null
        }
      </div>
    </Box>
  )
}
