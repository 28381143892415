import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { AdjustableButton } from '../04_subComponents/Button';





export interface ButtonPrototypeDisplayParameters {
  top: number,
  left: number,
  height: number,
  width: number,
  backgroundColor: string,
  fontSize: number,
  contentColor: string
}

export interface ButtonChangedDisplayParameters {
  top?: number,
  left?: number,
  height?: number,
  width?: number,
  backgroundColor?: string,
  fontSize?: number,
  contentColor?: string
}

export interface ButtonProps {
  buttonText?: string,
  buttonIcon?: string,
  actionFunctions?: Array<any>,
  redirectionUrl?: string,
  conditionFunction?: Array<any>, // if the output is true -> do the actionFunction, if false -> do nothing, if no conditionFunction -> do the actionFunction
  message?: string,
  disabled: boolean,
  prototypeDisplayParameters: ButtonPrototypeDisplayParameters,
  changedDisplayParameters?: ButtonChangedDisplayParameters
}





export const Button: FC<ButtonProps> = ({ buttonText, buttonIcon, actionFunctions, redirectionUrl, conditionFunction,
  message, disabled, prototypeDisplayParameters, changedDisplayParameters }) => {
  //

  const [top, setTop] = useState<number>(prototypeDisplayParameters.top);
  const [left, setLeft] = useState<number>(prototypeDisplayParameters.left);
  const [height, setHeight] = useState<number>(prototypeDisplayParameters.height);
  const [width, setWidth] = useState<number>(prototypeDisplayParameters.width);
  const [backgroundColor, setBackgroundColor] = useState<string>(prototypeDisplayParameters.backgroundColor);
  const [fontSize, setFontSize] = useState<number>(prototypeDisplayParameters.fontSize);
  const [contentColor, setContentColor] = useState<string>(prototypeDisplayParameters.contentColor);

  
  useEffect(() => {
    if(changedDisplayParameters !== undefined) {
      if(changedDisplayParameters.top !== undefined) {
        setTop(changedDisplayParameters.top);
      }
      if(changedDisplayParameters.left !== undefined) {
        setLeft(changedDisplayParameters.left);
      }
      if(changedDisplayParameters.height !== undefined) {
        setHeight(changedDisplayParameters.height);
      }
      if(changedDisplayParameters.width !== undefined) {
        setWidth(changedDisplayParameters.width);
      }
      if(changedDisplayParameters.backgroundColor !== undefined) {
        setBackgroundColor(changedDisplayParameters.backgroundColor);
      }
      if(changedDisplayParameters.fontSize !== undefined) {
        setFontSize(changedDisplayParameters.fontSize);
      }
      if(changedDisplayParameters.contentColor !== undefined) {
        setContentColor(changedDisplayParameters.contentColor);
      }
    }
  }, [changedDisplayParameters]);


  return(
    <AdjustableButton
      buttonText = { buttonText !== undefined ? [buttonText] : [] }
      buttonIcon = { buttonIcon !== undefined ? [buttonIcon] : [] }
      redirectionUrl = { redirectionUrl !== undefined ? [redirectionUrl] : [] }
      actionFunctions = { actionFunctions !== undefined ? actionFunctions : [] }
      conditionFunction = { conditionFunction !== undefined ? conditionFunction : [] }
      message = { message !== undefined ? [message] : [] }
      disabled = { disabled }
      top = { top }
      left = { left }
      height = { height }
      width = { width }
      backgroundColor = { backgroundColor }
      fontSize = { fontSize }
      contentColor = { contentColor }
    />
  )
}
