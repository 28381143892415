import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../services/webpageStyle';
import { UserData } from '../../../models/UserProps';
import { getData } from '../../../services/API.services';
import { AdminProfileNavigation } from './sidebar/AdminProfileNavigation';
import { ReadUserData } from '../UserProfilePageContent/mainContent/ReadUserData';
import { EditUserData } from '../UserProfilePageContent/mainContent/EditUserData';
import { ChangeUserEmail } from '../UserProfilePageContent/mainContent/ChangeUserEmail';
import { ChangeUserPassword } from '../UserProfilePageContent/mainContent/ChangeUserPassword';
import { LoggedInUser } from '../../../models/UserProps';





export interface AdminProfilePageContentProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  changeLoggedInUser: Array<any>,
  doAction: Array<any>,
  resizeContentHeight: Array<any>
}





export const AdminProfilePageContent: FC<AdminProfilePageContentProps> = ({ webpageStyle, loggedInUser, changeLoggedInUser, doAction, resizeContentHeight }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [adminProfilePageContentType, setAdminProfilePageContentType] = useState<string>("readAdminData");

  const [userData, setUserData] = useState<Array<UserData>>([]);


  const [contentHeight, setContentHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);

  const [mainContentHeight, setMainContentHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);
  const [sidebarHeight, setSidebarHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);

  const resizeMainContentHeight = (newMainContentHeight: number) => {
    setMainContentHeight(newMainContentHeight);
  }

  const resizeSidebarHeight = (newSidebarHeight: number) => {
    setSidebarHeight(newSidebarHeight);
  }

  const saveUserData = (userVariable: Array<UserData>) => {
    setUserData(userVariable);
  }

  const readUserData = async () => {
    if(loggedInUser.id !== 0) {
      const result = await getData(`/api/users/single/read`, true);
      if( !result || ( result as { status: number, message: string } )?.status >= 400 ) {
        if(result !== undefined) {
          if(result.message !== undefined) {
            console.log(result.message);
            setIsLoading(false);
          }
        }
      } else {
        //

        // console.log(result);

        if(result.length !== 0) {
          saveUserData(result);
        }

        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    setContentHeight(Math.max(mainContentHeight, sidebarHeight));
    resizeContentHeight[0](Math.max(mainContentHeight, sidebarHeight));
  }, [mainContentHeight, sidebarHeight]);

  useEffect(() => {
    readUserData();
  }, []);

  return(
    <div>
      <AdminProfileNavigation
        webpageStyle = { webpageStyle }
        adminProfilePageContentType = { adminProfilePageContentType }
        changeAdminProfilePageContentType = { [setAdminProfilePageContentType] }
        resizeSidebarHeight = { [resizeSidebarHeight] }
      />

      { (isLoading === false && adminProfilePageContentType === "readAdminData") ?
        <ReadUserData
          webpageStyle = { webpageStyle }
          userData = { userData }
          resizeMainContentHeight = { [resizeMainContentHeight] }
        /> : null
      }

      { (isLoading === false && adminProfilePageContentType === "editAdminData") ?
        <EditUserData
          webpageStyle = { webpageStyle }
          loggedInUser = { loggedInUser }
          userData = { userData }
          readUserData = { [readUserData] }
          resizeMainContentHeight = { [resizeMainContentHeight] }
        /> : null
      }

      { (isLoading === false && adminProfilePageContentType === "changeAdminEmail") ?
        <ChangeUserEmail
          webpageStyle = { webpageStyle }
          loggedInUser = { loggedInUser }
          changeLoggedInUser = { changeLoggedInUser }
          readUserData = { [readUserData] }
          resizeMainContentHeight = { [resizeMainContentHeight] }
        /> : null
      }

      { (isLoading === false && adminProfilePageContentType === "changeAdminPassword") ?
        <ChangeUserPassword
          webpageStyle = { webpageStyle }
          loggedInUser = { loggedInUser }
          resizeMainContentHeight = { [resizeMainContentHeight] }
        /> : null
      }
    </div>
  )
}
