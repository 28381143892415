import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { AdjustableMultipleRowTextDisplay } from '../04_subComponents/TextDisplay';





export interface MultipleRowPrototypeDisplayParameters {
  top: number,
  left: number,
  height: number,
  width: number,
  fontSize: number,
  color: string
}

export interface MultipleRowChangedDisplayParameters {
  top?: number,
  left?: number,
  height?: number,
  width?: number,
  fontSize?: number,
  color?: string
}

export interface MultipleRowTextProps {
  text: string,
  redirectionUrl?: string,
  message?: string,
  prototypeDisplayParameters: MultipleRowPrototypeDisplayParameters,
  changedDisplayParameters?: MultipleRowChangedDisplayParameters
}





export const MultipleRowText: FC<MultipleRowTextProps> = ({ text, redirectionUrl, message,
  prototypeDisplayParameters, changedDisplayParameters }) => {
  //

  const [top, setTop] = useState<number>(prototypeDisplayParameters.top);
  const [left, setLeft] = useState<number>(prototypeDisplayParameters.left);
  const [height, setHeight] = useState<number>(prototypeDisplayParameters.height);
  const [width, setWidth] = useState<number>(prototypeDisplayParameters.width)
  const [fontSize, setFontSize] = useState<number>(prototypeDisplayParameters.fontSize);
  const [color, setColor] = useState<string>(prototypeDisplayParameters.color);


  useEffect(() => {
    if(changedDisplayParameters !== undefined) {
      if(changedDisplayParameters.top !== undefined) {
        setTop(changedDisplayParameters.top);
      }
      if(changedDisplayParameters.left !== undefined) {
        setLeft(changedDisplayParameters.left);
      }
      if(changedDisplayParameters.height !== undefined) {
        setHeight(changedDisplayParameters.height);
      }
      if(changedDisplayParameters.width !== undefined) {
        setWidth(changedDisplayParameters.width);
      }
      if(changedDisplayParameters.fontSize !== undefined) {
        setFontSize(changedDisplayParameters.fontSize);
      }
      if(changedDisplayParameters.color !== undefined) {
        setColor(changedDisplayParameters.color);
      }
    }
  }, [changedDisplayParameters]);


  return(
    <AdjustableMultipleRowTextDisplay
      text = { text }
      redirectionUrl = { redirectionUrl !== undefined ? [redirectionUrl] : [] }
      message = { message !== undefined ? [message] : [] }
      top = { top }
      left = { left }
      height = { height }
      width = { width }
      fontSize = { fontSize }
      color = { color }
    />
  )
}
