import React, { FC, useEffect, useState, ChangeEvent } from 'react';

export function equalCondition(value: Array<string | number | boolean>, argument: Array<string | number | boolean>): Array<boolean> {
  if(value.length === 0 && argument.length === 0) {
    return([true]);
  }
  
  // if(value.length === 0) {
  //   return([]);
  // }

  // if(argument.length === 0) {
  //   return([]);
  // }

  // let typeOfValue = typeof(value[0]);
  // let typeOfArgument = typeof(argument[0]);

  // if(typeOfValue !== typeOfArgument) {
  //   return([]);
  // }

  if(value[0] === argument[0]) {
    return([true]);
  } else {
    return([false]);
  }
}

export function notEqualCondition(value: Array<string | number | boolean>, argument: Array<string | number | boolean>): Array<boolean> {
  if(value.length === 0) {
    return([]);
  }

  if(argument.length === 0) {
    return([]);
  }

  let typeOfValue = typeof(value[0]);
  let typeOfArgument = typeof(argument[0]);

  if(typeOfValue !== typeOfArgument) {
    return([]);
  }

  if(value[0] !== argument[0]) {
    return([true]);
  } else {
    return([false]);
  }
}

export function blankCondition(value: Array<string | number | boolean>): Array<boolean> {
  if(value.length === 0) {
    return([true]);
  }

  let typeOfValue = typeof(value[0]);

  if(typeOfValue !== "string" && typeOfValue !== "number" && typeOfValue !== "boolean") {
    return([]);
  } else {
    return([false]);
  }
}

export function notBlankCondition(value: Array<string | number | boolean>): Array<boolean> {
  if(value.length === 0) {
    return([false]);
  }

  let typeOfValue = typeof(value[0]);

  if(typeOfValue !== "string" && typeOfValue !== "number" && typeOfValue !== "boolean") {
    return([]);
  } else {
    return([false]);
  }
}

export function greaterThanCondition(value: Array<string | number | boolean>, argument: Array<string | number | boolean>): Array<boolean> {
  if(value.length === 0) {
    return([]);
  }

  if(argument.length === 0) {
    return([]);
  }

  let typeOfValue = typeof(value[0]);
  let typeOfArgument = typeof(argument[0]);

  if(typeOfValue !== typeOfArgument) {
    return([]);
  }

  if(value[0] > argument[0]) {
    return([true]);
  } else {
    return([false]);
  }
}

export function lessThanCondition(value: Array<string | number | boolean>, argument: Array<string | number | boolean>): Array<boolean> {
  if(value.length === 0) {
    return([]);
  }

  if(argument.length === 0) {
    return([]);
  }

  let typeOfValue = typeof(value[0]);
  let typeOfArgument = typeof(argument[0]);

  if(typeOfValue !== typeOfArgument) {
    return([]);
  }

  if(value[0] < argument[0]) {
    return([true]);
  } else {
    return([false]);
  }
}

export function greaterThanOrEqualCondition(value: Array<string | number | boolean>, argument: Array<string | number | boolean>): Array<boolean> {
  if(value.length === 0) {
    return([]);
  }

  if(argument.length === 0) {
    return([]);
  }

  let typeOfValue = typeof(value[0]);
  let typeOfArgument = typeof(argument[0]);

  if(typeOfValue !== typeOfArgument) {
    return([]);
  }

  if(value[0] >= argument[0]) {
    return([true]);
  } else {
    return([false]);
  }
}

export function lessThanOrEqualCondition(value: Array<string | number | boolean>, argument: Array<string | number | boolean>): Array<boolean> {
  if(value.length === 0) {
    return([]);
  }

  if(argument.length === 0) {
    return([]);
  }

  let typeOfValue = typeof(value[0]);
  let typeOfArgument = typeof(argument[0]);

  if(typeOfValue !== typeOfArgument) {
    return([]);
  }

  if(value[0] <= argument[0]) {
    return([true]);
  } else {
    return([false]);
  }
}

export function containCondition(value: Array<string | number | boolean>, argument: Array<string | number | boolean>): Array<boolean> {
  if(value.length === 0) {
    return([]);
  }

  if(argument.length === 0) {
    return([]);
  }

  let typeOfValue = typeof(value[0]);
  let typeOfArgument = typeof(argument[0]);

  if(typeOfValue !== "string" || typeOfArgument !== "string") {
    return([]);
  }

  let valueString: string = "";
  if(typeof(value[0]) === "string") {
    valueString = value[0];
  }
  let argumentString: string = "";
  if(typeof(argument[0]) === "string") {
    argumentString = argument[0];
  }

  let testResult: string[] = valueString.split(argumentString);

  if(testResult.length > 1) {
    return([true]);
  } else {
    return([false]);
  }
}

export function notContainCondition(value: Array<string | number | boolean>, argument: Array<string | number | boolean>): Array<boolean> {
  if(value.length === 0) {
    return([]);
  }

  if(argument.length === 0) {
    return([]);
  }

  let typeOfValue = typeof(value[0]);
  let typeOfArgument = typeof(argument[0]);

  if(typeOfValue !== "string" || typeOfArgument !== "string") {
    return([]);
  }

  let valueString: string = "";
  if(typeof(value[0]) === "string") {
    valueString = value[0];
  }
  let argumentString: string = "";
  if(typeof(argument[0]) === "string") {
    argumentString = argument[0];
  }

  let testResult: string[] = valueString.split(argumentString);

  if(testResult.length > 1) {
    return([false]);
  } else {
    return([true]);
  }
}

export function partOfCondition(value: Array<string | number | boolean>, argument: Array<string | number | boolean>): Array<boolean> {
  if(value.length === 0) {
    return([]);
  }

  if(argument.length === 0) {
    return([]);
  }

  let typeOfValue = typeof(value[0]);
  let typeOfArgument = typeof(argument[0]);

  if(typeOfValue !== "string" || typeOfArgument !== "string") {
    return([]);
  }

  let valueString: string = "";
  if(typeof(value[0]) === "string") {
    valueString = value[0];
  }
  let argumentString: string = "";
  if(typeof(argument[0]) === "string") {
    argumentString = argument[0];
  }

  let testResult: string[] = argumentString.split(valueString);

  if(testResult.length > 1) {
    return([true]);
  } else {
    return([false]);
  }
}

export function notPartOfCondition(value: Array<string | number | boolean>, argument: Array<string | number | boolean>): Array<boolean> {
  if(value.length === 0) {
    return([]);
  }

  if(argument.length === 0) {
    return([]);
  }

  let typeOfValue = typeof(value[0]);
  let typeOfArgument = typeof(argument[0]);

  if(typeOfValue !== "string" || typeOfArgument !== "string") {
    return([]);
  }

  let valueString: string = "";
  if(typeof(value[0]) === "string") {
    valueString = value[0];
  }
  let argumentString: string = "";
  if(typeof(argument[0]) === "string") {
    argumentString = argument[0];
  }

  let testResult: string[] = argumentString.split(valueString);

  if(testResult.length > 1) {
    return([false]);
  } else {
    return([true]);
  }
}

export function numberOfCharactersGreaterThanOrEqual(value: Array<string | number | boolean>, argument: Array<string | number | boolean>): Array<boolean> {
  // if(value.length === 0) {
  //   return([]);
  // }

  if(argument.length === 0) {
    return([]);
  }

  let typeOfValue = typeof(value[0]);
  let typeOfArgument = typeof(argument[0]);

  if(value.length !== 0) {
    if(typeOfValue !== "string") {
      return([]);
    }
  }
  if(typeOfArgument !== "number") {
    return([]);
  }

  let valueString: string = "";
  if(typeof(value[0]) === "string") {
    valueString = value[0];
  }
  let argumentNumber: number = 0;
  if(typeof(argument[0]) === "number") {
    argumentNumber = argument[0];
  }

  if(valueString.length >= argumentNumber) {
    return([true]);
  } else {
    return([false]);
  }
}

export function numberOfCharactersLessThanOrEqual(value: Array<string | number | boolean>, argument: Array<string | number | boolean>): Array<boolean> {
  // if(value.length === 0) {
  //   return([]);
  // }

  if(argument.length === 0) {
    return([]);
  }

  let typeOfValue = typeof(value[0]);
  let typeOfArgument = typeof(argument[0]);

  if(value.length !== 0) {
    if(typeOfValue !== "string") {
      return([]);
    }
  }
  if(typeOfArgument !== "number") {
    return([]);
  }

  let valueString: string = "";
  if(typeof(value[0]) === "string") {
    valueString = value[0];
  }
  let argumentNumber: number = 0;
  if(typeof(argument[0]) === "number") {
    argumentNumber = argument[0];
  }

  if(valueString.length <= argumentNumber) {
    return([true]);
  } else {
    return([false]);
  }
}

export function emailInputTest(value: Array<string>): Array<boolean> {
  //

  let typeOfValue = typeof(value[0]);

  if(typeOfValue !== "string") {
    return([false]);
  }

  // ezt még bőven tovább lehet majd fejleszteni!

  if(value.length < 1) {
    return([false]);
  }

  let sectionOneTest: boolean = false;
  let sectionTwoTest: boolean = false;
  let sectionThreeTest: boolean = false;
  let sectionFourTest: boolean = false;
  let sectionFiveTest: boolean = false;
  
  for(let i: number = 0; i < value[0].length; i++) {
    if(sectionOneTest === false) {
      if(value[0][i] !== '@' && value[0][i] !== '.') {
        sectionOneTest = true;
      } else {
        return([false]);
      }
    } else {
      if(sectionTwoTest === false) {
        if(value[0][i] === '@') {
          sectionTwoTest = true;
        }
      } else {
        if(sectionThreeTest === false) {
          if(value[0][i] !== '@' && value[0][i] !== '.') {
            sectionThreeTest = true;
          } else {
            return([false]);
          }
        } else {
          if(sectionFourTest === false) {
            if(value[0][i] === '@') {
              return([false]);
            }
            if(value[0][i] === '.') {
              sectionFourTest = true;
            }
          } else {
            if(sectionFiveTest === false) {
              if(value[0][i] !== '@' && value[0][i] !== '.') {
                sectionFiveTest = true;
              } else {
                return([false]);
              }
            } else {
              if(value[0][i] === '@' || value[0][i] === '.') {
                return([false]);
              }
            }
          }
        }
      }
    }
  }

  if(sectionOneTest === true && sectionTwoTest === true && sectionThreeTest === true && sectionFourTest === true && sectionFiveTest === true) {
    return([true]);
  } else {
    return([false]);
  }
}
