import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../../services/webpageStyle';
import { Horse } from '../../../../../models/HorseProps';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../../03_baseComponents/Box';
import { SingleRowText, SingleRowPrototypeDisplayParameters, SingleRowChangedDisplayParameters } from '../../../../03_baseComponents/SingleRowText';





export interface HorseTitleProps {
  webpageStyle: WebpageStyleProps,
  horse: Array<Horse>
}





export const HorseTitle: FC<HorseTitleProps> = ({ webpageStyle, horse }) => {
  //

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.horseProfile.horseTitle.boxTop,
    left: webpageStyle.content.mainContent.horseProfile.horseTitle.boxLeft,
    height: webpageStyle.content.mainContent.horseProfile.horseTitle.boxHeight,
    width: webpageStyle.content.mainContent.horseProfile.horseTitle.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.horseProfile.horseTitle.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.horseProfile.horseTitle.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.horseProfile.horseTitle.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.horseProfile.horseTitle.boxOutlineColor
  });


  const [titlePrototypeDisplayParameters, setTitlePrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.horseProfile.horseTitle.titleTop,
    left: webpageStyle.content.mainContent.horseProfile.horseTitle.titleLeft,
    width: webpageStyle.content.mainContent.horseProfile.horseTitle.titleWidth,
    fontSize: webpageStyle.content.mainContent.horseProfile.horseTitle.titleFontSize,
    color: webpageStyle.content.mainContent.horseProfile.horseTitle.titleFontColor
  });

  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <SingleRowText
        text = { horse[0].data.name }
        prototypeDisplayParameters = { titlePrototypeDisplayParameters }
      />
    </Box>
  )
}
