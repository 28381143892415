import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { UserData } from '../../../../models/UserProps';
import { getData } from '../../../../services/API.services';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { KeyValueDisplay, KeyValueDisplayPrototypeDisplayParameters, KeyValueDisplayChangedDisplayParameters } from '../../../03_baseComponents/KeyValueDisplay';





export interface AdminReadUserDataProps {
  webpageStyle: WebpageStyleProps,
  selectedUserId: Array<number>,
  resizeMainContentHeight: Array<any>
}





export const AdminReadUserData: FC<AdminReadUserDataProps> = ({ webpageStyle, selectedUserId, resizeMainContentHeight }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [userData, setUserData] = useState<Array<UserData>>([]);

  
  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.mainContent.adminReadUserData.boxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.boxTop,
    left: webpageStyle.content.mainContent.boxLeft,
    height: boxHeight,
    width: webpageStyle.content.mainContent.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.boxOutlineColor
  });


  const [firstDataTop, setFirstDataTop] = useState<number>(webpageStyle.content.mainContent.adminReadUserData.firstDataTop);
  const [dataTopDistance, setDataTopDistance] = useState<number>(webpageStyle.content.mainContent.adminReadUserData.dataTopDistance);

  const [keyValueDisplayPrototypeDisplayParameters, setKeyValueDisplayPrototypeDisplayParameters] = useState<KeyValueDisplayPrototypeDisplayParameters>({
    keyTop: firstDataTop,
    keyLeft: webpageStyle.content.mainContent.adminReadUserData.keyLeft,
    keyWidth: webpageStyle.content.mainContent.adminReadUserData.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.adminReadUserData.keyFontSize,
    keyColor: webpageStyle.content.mainContent.adminReadUserData.keyColor,
    valueTop: firstDataTop,
    valueLeft: webpageStyle.content.mainContent.adminReadUserData.valueLeft,
    valueWidth: webpageStyle.content.mainContent.adminReadUserData.valueWidth,
    valueFontSize: webpageStyle.content.mainContent.adminReadUserData.valueFontSize,
    valueColor: webpageStyle.content.mainContent.adminReadUserData.valueColor
  });


  const saveUserData = (userVariable: Array<UserData>) => {
    setUserData(userVariable);
  }

  const readUserData = async () => {
    if(selectedUserId[0] !== 0) {
      const result = await getData(`/api/users/single/read/admin/${ selectedUserId }`, true);
      if( !result || ( result as { status: number, message: string } )?.status >= 400 ) {
        if(result !== undefined) {
          if(result.message !== undefined) {
            console.log(result.message);
            setIsLoading(false);
          }
        }
      } else {
        //

        // console.log(result);

        if(result.length !== 0) {
          saveUserData(result);
        }

        setIsLoading(false);
      }
    }
  }


  useEffect(() => {
    resizeMainContentHeight[0](boxHeight);
  }, []);

  useEffect(() => {
    readUserData();
  }, []);


  if(isLoading !== true) {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      >
        <KeyValueDisplay
          keyText = { "Id" }
          valueText = { userData[0].id.toString() }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 0 * dataTopDistance,
            valueTop: firstDataTop + 0 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Email" }
          valueText = { userData[0].email }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 2 * dataTopDistance,
            valueTop: firstDataTop + 2 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Name" }
          valueText = { userData[0].name }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 3 * dataTopDistance,
            valueTop: firstDataTop + 3 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Email 2" }
          valueText = { userData[0].email2 }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 4 * dataTopDistance,
            valueTop: firstDataTop + 4 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Telephone number 1" }
          valueText = { userData[0].telephoneNumber1 }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 5 * dataTopDistance,
            valueTop: firstDataTop + 5 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Telephone number 2" }
          valueText = { userData[0].telephoneNumber2 }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 6 * dataTopDistance,
            valueTop: firstDataTop + 6 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Language 1" }
          valueText = { userData[0].language1 }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 7 * dataTopDistance,
            valueTop: firstDataTop + 7 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Language 2" }
          valueText = { userData[0].language2 }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 8 * dataTopDistance,
            valueTop: firstDataTop + 8 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Shipment continent" }
          valueText = { userData[0].shipmentContinent }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 10 * dataTopDistance,
            valueTop: firstDataTop + 10 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Shipment country" }
          valueText = { userData[0].shipmentCountry }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 11 * dataTopDistance,
            valueTop: firstDataTop + 11 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Shipment state" }
          valueText = { userData[0].shipmentState }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 12 * dataTopDistance,
            valueTop: firstDataTop + 12 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Shipment ZIP code" }
          valueText = { userData[0].shipmentZipCode }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 13 * dataTopDistance,
            valueTop: firstDataTop + 13 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Shipment city" }
          valueText = { userData[0].shipmentCity }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 14 * dataTopDistance,
            valueTop: firstDataTop + 14 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Shipment name of public area" }
          valueText = { userData[0].shipmentNameOfPublicArea }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 15 * dataTopDistance,
            valueTop: firstDataTop + 15 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Shipment type of public area" }
          valueText = { userData[0].shipmentTypeOfPublicArea }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 16 * dataTopDistance,
            valueTop: firstDataTop + 16 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Shipment house number" }
          valueText = { userData[0].shipmentHouseNumber }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 17 * dataTopDistance,
            valueTop: firstDataTop + 17 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Shipment floor" }
          valueText = { userData[0].shipmentFloor }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 18 * dataTopDistance,
            valueTop: firstDataTop + 18 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Shipment door" }
          valueText = { userData[0].shipmentDoor }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 19 * dataTopDistance,
            valueTop: firstDataTop + 19 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Invoicing continent" }
          valueText = { userData[0].invoicingContinent }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 21 * dataTopDistance,
            valueTop: firstDataTop + 21 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Invoicing country" }
          valueText = { userData[0].invoicingCountry }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 22 * dataTopDistance,
            valueTop: firstDataTop + 22 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Invoicing state" }
          valueText = { userData[0].invoicingState }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 23 * dataTopDistance,
            valueTop: firstDataTop + 23 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Invoicing ZIP code" }
          valueText = { userData[0].invoicingZipCode }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 24 * dataTopDistance,
            valueTop: firstDataTop + 24 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Invoicing city" }
          valueText = { userData[0].invoicingCity }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 25 * dataTopDistance,
            valueTop: firstDataTop + 25 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Invoicing name of public area" }
          valueText = { userData[0].invoicingNameOfPublicArea }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 26 * dataTopDistance,
            valueTop: firstDataTop + 26 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Invoicing type of public area" }
          valueText = { userData[0].invoicingTypeOfPublicArea }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 27 * dataTopDistance,
            valueTop: firstDataTop + 27 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Invoicing house number" }
          valueText = { userData[0].invoicingHouseNumber }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 28 * dataTopDistance,
            valueTop: firstDataTop + 28 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Invoicing floor" }
          valueText = { userData[0].invoicingFloor }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 29 * dataTopDistance,
            valueTop: firstDataTop + 29 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Invoicing door" }
          valueText = { userData[0].invoicingDoor }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 30 * dataTopDistance,
            valueTop: firstDataTop + 30 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Bank name" }
          valueText = { userData[0].bankName }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 32 * dataTopDistance,
            valueTop: firstDataTop + 32 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Bank account number" }
          valueText = { userData[0].bankAccountNumber }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 33 * dataTopDistance,
            valueTop: firstDataTop + 33 * dataTopDistance
          } }
        />
      </Box>
    )
  } else {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      >
        <div>
          Selected user is loading...
        </div>
      </Box>
    )
  }
}
