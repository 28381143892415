import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../../services/webpageStyle';
import { Horse } from '../../../../../models/HorseProps';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../../03_baseComponents/Box';
import { MultipleRowText, MultipleRowPrototypeDisplayParameters, MultipleRowChangedDisplayParameters } from '../../../../03_baseComponents/MultipleRowText';





export interface HorseDescriptionProps {
  webpageStyle: WebpageStyleProps,
  horse: Array<Horse>
}





export const HorseDescription: FC<HorseDescriptionProps> = ({ webpageStyle, horse }) => {
  //

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.horseProfile.horseDescription.boxTop,
    left: webpageStyle.content.mainContent.horseProfile.horseDescription.boxLeft,
    height: webpageStyle.content.mainContent.horseProfile.horseDescription.boxHeight,
    width: webpageStyle.content.mainContent.horseProfile.horseDescription.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.horseProfile.horseDescription.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.horseProfile.horseDescription.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.horseProfile.horseDescription.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.horseProfile.horseDescription.boxOutlineColor
  });
  

  const [descriptionPrototypeDisplayParameters, setDescriptionPrototypeDisplayParameters] = useState<MultipleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.horseProfile.horseDescription.descriptionTop,
    left: webpageStyle.content.mainContent.horseProfile.horseDescription.descriptionLeft,
    height: webpageStyle.content.mainContent.horseProfile.horseDescription.descriptionHeight,
    width: webpageStyle.content.mainContent.horseProfile.horseDescription.descriptionWidth,
    fontSize: webpageStyle.content.mainContent.horseProfile.horseDescription.descriptionFontSize,
    color: webpageStyle.content.mainContent.horseProfile.horseDescription.descriptionFontColor
  });


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <div>
        <MultipleRowText
          text = { horse[0].data.description }
          prototypeDisplayParameters = { descriptionPrototypeDisplayParameters }
        />
      </div>
    </Box>
  )
}
