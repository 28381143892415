import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../03_baseComponents/Box';
import { BrowserRouter, Switch, Route, Redirect, useHistory, useParams } from 'react-router-dom';
import { BasePageContent } from '../02_pageContents/BasePageContent/BasePageContent';
import { InformationAndContactPageContent } from '../02_pageContents/InformationAndContactPageContent/InformationAndContactPageContent';
import { HorseCatalogPageContent } from '../02_pageContents/HorseCatalogPageContent/HorseCatalogPageContent';
import { HorseProfilePageContent } from '../02_pageContents/HorseProfilePageContent/HorseProfilePageContent';
import { RegistrationPageContent } from '../02_pageContents/RegistrationPageContent/RegistrationPageContent';
import { LoginPageContent } from '../02_pageContents/LoginPageContent/LoginPageContent';
import { ForgotPasswordRequestPageContent } from '../02_pageContents/ForgotPasswordRequestPageContent/ForgotPasswordRequestPageContent';
import { ForgotPasswordResetPageContent } from '../02_pageContents/ForgotPasswordResetPageContent/ForgotPasswordResetPageContent';
import { UserInterestingHorsesPageContent } from '../02_pageContents/UserInterestingHorsesPageContent/UserInterestingHorsesPageContent';
import { UserRequestedProposalsPageContent } from '../02_pageContents/UserRequestedProposalsPageContent/UserRequestedProposalsPageContent';
import { UserProfilePageContent } from '../02_pageContents/UserProfilePageContent/UserProfilePageContent';
import { AdminHorsesPageContent } from '../02_pageContents/AdminHorsesPageContent/AdminHorsesPageContent';
import { AdminUsersPageContent } from '../02_pageContents/AdminUsersPageContent/AdminUsersPageContent';
import { AdminProposalsPageContent } from '../02_pageContents/AdminProposalsPageContent/AdminProposalsPageContent';
import { AdminWebDataPageContent } from '../02_pageContents/AdminWebDataPageContent/AdminWebDataPageContent';
import { AdminProfilePageContent } from '../02_pageContents/AdminProfilePageContent/AdminProfilePageContent';
import { HorseCatalogSettings } from '../../models/HorseCatalogSettings';
import { LoggedInUser } from '../../models/UserProps';





export interface ContentProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  recentHorseCatalogSettings: HorseCatalogSettings,
  path: string,
  search: string,
  changeLoggedInUser: Array<any>,
  changeHorseCatalogSettings: Array<any>,
  changeContent: Array<any>,
  doAction: Array<any>,
  resizeContentHeightInTheApp: Array<any>
}





export const Content: FC<ContentProps> = ({ webpageStyle, loggedInUser, recentHorseCatalogSettings,
  path, search, changeLoggedInUser, changeHorseCatalogSettings, doAction, changeContent, resizeContentHeightInTheApp }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // console.log(useHistory().location.pathname); // ezt kell majd használni
  // const { id } = useParams<{ id : string }>(); // ezt is lehet, hogy majd kell valahol használni

  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.boxMinimumHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.boxTop + webpageStyle.general.webpageMargin,
    left: webpageStyle.content.boxLeft + webpageStyle.general.webpageMargin,
    height: webpageStyle.content.boxMinimumHeight,
    width: webpageStyle.content.boxWidth,
    backgroundColor: webpageStyle.content.boxBackgroundColor,
    outlineStyle: webpageStyle.content.boxOutlineStyle,
    outlineWidth: webpageStyle.content.boxOutlineWidth,
    outlineColor: webpageStyle.content.boxOutlineColor
  });

  let recentPath: string = path;
  let recentSearch: string = search;

  // setTimeout(() => resizeContentHeight[0](400), 1000);

  // console.log(path);

  const resizeContentHeight = (newContentHeight: number) => {
    resizeContentHeightInTheApp[0](newContentHeight);
    setBoxHeight(newContentHeight);
  }

  // const newPathOrNewSearch = () => {
  //   let newPath: string = useHistory().location.pathname;
  //   let newSearch: string = useHistory().location.search;

  //   setPath(newPath);
  //   setSearch(newSearch);
  //   savePath[0](newPath);
  // }

  // setTimeout(() => setPath(useHistory().location.pathname), 1000);

  // setTimeout(() => {
  //   recentPath = path;
  //   recentSearch = search;
  //   setRunReloadPathAndSearch(!runReloadPathAndSearch)
  // }, 1000);

  // setTimeout(() => setRunReloadPathAndSearch(!runReloadPathAndSearch), 1000);


  // useEffect(() => {
  //   savePath[0](path);
  // }, []);

  useEffect(() => {
    // newPathOrNewSearch();
    // console.log("*");
  }, [useHistory().location.pathname, useHistory().location.search]);

  // const reloadPathAndSearch = () => {
  //   setPath(useGetPath);
  //   setSearch(useGetSearch);
  // }

  // useEffect(() => {
  //   setTimeout(() => {
  //     setRunReloadPathAndSearch(!runReloadPathAndSearch);
  //     reloadPathAndSearch();
  //   }, 250);
  // }, [runReloadPathAndSearch]);

  // function useGetPath() {
  //   const [actualPath, setActualPath] = useState<string>(useHistory().location.pathname);
  //   return(actualPath);
  // }

  // function useGetSearch() {
  //   const [actualSearch, setActualSearch] = useState<string>(useHistory().location.search);
  //   return(actualSearch);
  // }


  // console.log(path);
  // console.log(search);


  // console.log(globalVariable);
  // globalVariable = globalVariable + 1;


  if(isLoading === true) {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
        changedDisplayParameters = { {
          height: boxHeight
        } }
      >
        <div>
          Loading the content...
        </div>
      </Box>
    )
  } else {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
        changedDisplayParameters = { {
          height: boxHeight
        } }
      >
        <div>
          { path === "/" ?
            <BasePageContent
              webpageStyle = { webpageStyle }
              doAction = { doAction }
              resizeContentHeight = { [resizeContentHeight] }
            /> : null
          }
          { path === "/informationandcontact" ?
            <InformationAndContactPageContent
              webpageStyle = { webpageStyle }
              doAction = { doAction }
              resizeContentHeight = { [resizeContentHeight] }
            /> : null
          }
          { path === "/horsecatalog" ?
            <HorseCatalogPageContent
              webpageStyle = { webpageStyle }
              loggedInUser = { loggedInUser }
              recentHorseCatalogSettings = { recentHorseCatalogSettings }
              changeHorseCatalogSettings = { changeHorseCatalogSettings }
              doAction = { doAction }
              resizeContentHeight = { [resizeContentHeight] }
            /> : null
          }
          { path === "/horseprofile" ? // ezt még ki kell dolgozni
            <HorseProfilePageContent
                webpageStyle = { webpageStyle }
                loggedInUser = { loggedInUser }
                search = { search }
                doAction = { doAction }
                resizeContentHeight = { [resizeContentHeight] }
              /> : null
          }
          { path === "/registration" ?
            <RegistrationPageContent
              webpageStyle = { webpageStyle }
              loggedInUser = { loggedInUser }
              changeLoggedInUser = { changeLoggedInUser }
              doAction = { doAction }
              resizeContentHeight = { [resizeContentHeight] }
            /> : null
          }
          { path === "/login" ?
            <LoginPageContent
              webpageStyle = { webpageStyle }
              loggedInUser = { loggedInUser }
              changeLoggedInUser = { changeLoggedInUser }
              doAction = { doAction }
              resizeContentHeight = { [resizeContentHeight] }
            /> : null
          }
          { path === "/forgotpasswordrequest" ?
            <ForgotPasswordRequestPageContent
              webpageStyle = { webpageStyle }
              loggedInUser = { loggedInUser }
              doAction = { doAction }
              resizeContentHeight = { [resizeContentHeight] }
            /> : null
          }
          { path === "/forgotpasswordreset" ?
            <ForgotPasswordResetPageContent
              webpageStyle = { webpageStyle }
              loggedInUser = { loggedInUser }
              changeLoggedInUser = { changeLoggedInUser }
              doAction = { doAction }
              resizeContentHeight = { [resizeContentHeight] }
            /> : null
          }
          { path === "/user/interestinghorses" ?
            <UserInterestingHorsesPageContent
              webpageStyle = { webpageStyle }
              doAction = { doAction }
              resizeContentHeight = { [resizeContentHeight] }
            /> : null
          }
          { path === "/user/requestedproposals" ?
            <UserRequestedProposalsPageContent
              webpageStyle = { webpageStyle }
              doAction = { doAction }
              resizeContentHeight = { [resizeContentHeight] }
            /> : null
          }
          { path === "/user/userprofile" ?
            <UserProfilePageContent
              webpageStyle = { webpageStyle }
              loggedInUser = { loggedInUser }
              changeLoggedInUser = { changeLoggedInUser }
              doAction = { doAction }
              resizeContentHeight = { [resizeContentHeight] }
            /> : null
          }
          { path === "/admin/horses" ?
            <AdminHorsesPageContent
              webpageStyle = { webpageStyle }
              recentHorseCatalogSettings = { recentHorseCatalogSettings }
              changeHorseCatalogSettings = { changeHorseCatalogSettings }
              doAction = { doAction }
              resizeContentHeight = { [resizeContentHeight] }
            /> : null
          }
          { path === "/admin/users" ?
            <AdminUsersPageContent
              webpageStyle = { webpageStyle }
              loggedInUser = { loggedInUser }
              doAction = { doAction }
              resizeContentHeight = { [resizeContentHeight] }
            /> : null
          }
          { path === "/admin/proposals" ?
            <AdminProposalsPageContent
              webpageStyle = { webpageStyle }
              doAction = { doAction }
              resizeContentHeight = { [resizeContentHeight] }
            /> : null
          }
          { path === "/admin/webdata" ?
            <AdminWebDataPageContent
              webpageStyle = { webpageStyle }
              doAction = { doAction }
              resizeContentHeight = { [resizeContentHeight] }
            /> : null
          }
          { path === "/admin/adminprofile" ?
            <AdminProfilePageContent
              webpageStyle = { webpageStyle }
              loggedInUser = { loggedInUser }
              changeLoggedInUser = { changeLoggedInUser }
              doAction = { doAction }
              resizeContentHeight = { [resizeContentHeight] }
            /> : null
          }
        </div>
      </Box>
    )
  }
}
