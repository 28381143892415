import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Horse } from '../../../../models/HorseProps';
import { Box } from '../../../03_baseComponents/Box';
import { AdminHorsesNavigation } from './AdminHorsesNavigationAndFilteringAndSorting/AdminHorsesNavigation';
import { AdminHorsesFiltering } from './AdminHorsesNavigationAndFilteringAndSorting/AdminHorsesFiltering';
import { AdminHorsesSorting } from './AdminHorsesNavigationAndFilteringAndSorting/AdminHorsesSorting';
import { HorseCatalogSettings, HorseCatalogFilteringSettings, HorseCatalogSortingSettings } from '../../../../models/HorseCatalogSettings';
import { LoggedInUser } from '../../../../models/UserProps';





export interface AdminHorsesNavigationAndFilteringAndSortingProps {
  webpageStyle: WebpageStyleProps,
  adminHorseListPageContentType: string,
  recentHorseCatalogSettings: HorseCatalogSettings,
  changeHorseCatalogSettings: Array<any>,
  changeFilteringOrSortingSettings: Array<any>,
  changeAdminHorseListPageContentType: Array<any>,
  resizeSidebarHeight: Array<any>
}





export const AdminHorsesNavigationAndFilteringAndSorting: FC<AdminHorsesNavigationAndFilteringAndSortingProps> = ({ webpageStyle,
  adminHorseListPageContentType, recentHorseCatalogSettings, changeHorseCatalogSettings, changeFilteringOrSortingSettings,
  changeAdminHorseListPageContentType, resizeSidebarHeight }) => {
  //

  // sidebarHeight: nem túl szép megoldás, meg is kellene változtatni
  const [sidebarHeight, setSidebarHeight] = useState<number>(700);


  const changeHorseCatalogFilterSettings = (newHorseCatalogFilteringSettings: HorseCatalogFilteringSettings) => {
    const newHorseCatalogSettings: HorseCatalogSettings = {
      filteringSettings: newHorseCatalogFilteringSettings,
      sortingSettings: recentHorseCatalogSettings.sortingSettings
    }
    changeHorseCatalogSettings[0](newHorseCatalogSettings);
    changeFilteringOrSortingSettings[0](newHorseCatalogSettings);
  }

  const changeHorseCatalogSortSettings = (newHorseCatalogSortingSettings: HorseCatalogSortingSettings) => {
    const newHorseCatalogSettings: HorseCatalogSettings = {
      filteringSettings: recentHorseCatalogSettings.filteringSettings,
      sortingSettings: newHorseCatalogSortingSettings
    }
    changeHorseCatalogSettings[0](newHorseCatalogSettings);
    changeFilteringOrSortingSettings[0](newHorseCatalogSettings);
  }


  useEffect(() => {
    resizeSidebarHeight[0](sidebarHeight);
  }, []);

  useEffect(() => {
    if(adminHorseListPageContentType === "horseList") {
      resizeSidebarHeight[0](webpageStyle.content.sidebar.adminHorses.navigation.boxTop + webpageStyle.content.sidebar.adminHorses.navigation.boxHeight + 50 + webpageStyle.content.sidebar.adminHorses.sorting.boxHeight);
    } else {
      resizeSidebarHeight[0](webpageStyle.content.sidebar.adminHorses.navigation.boxTop + webpageStyle.content.sidebar.adminHorses.navigation.boxHeight);
    }
  }, [adminHorseListPageContentType]);


  return(
    <div>
      <AdminHorsesNavigation
        webpageStyle = { webpageStyle }
        adminHorseListPageContentType = { adminHorseListPageContentType }
        changeAdminHorseListPageContentType = { changeAdminHorseListPageContentType }
      />
      { adminHorseListPageContentType === "horseList" ?
        <AdminHorsesFiltering
          webpageStyle = { webpageStyle }
          recentHorseCatalogFilteringSettings = { recentHorseCatalogSettings.filteringSettings }
          changeHorseCatalogFilteringSettings = { [changeHorseCatalogFilterSettings] }
        /> : null
      }
      { adminHorseListPageContentType === "horseList" ?
        <AdminHorsesSorting
          webpageStyle = { webpageStyle }
          recentHorseCatalogSortingSettings = { recentHorseCatalogSettings.sortingSettings }
          changeHorseCatalogSortingSettings = { [changeHorseCatalogSortSettings] }
        /> : null
      }
    </div>
  )
}
