import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { LinkUrl } from '../04_subComponents/LinkUrl';
import { PopUpWindow } from '../04_subComponents/PopUpWindow';
import { textWidth } from '../../services/TextWidth';
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { incrementActionsDone } from '../../App';





export interface AdjustableTextDisplayProps {
  text: Array<string>,
  message: Array<string>,
  top: number,
  left: number,
  height: number,
  width: number,
  fontSize: number,
  color: string
}

export interface AdjustableSingleRowTextDisplayProps {
  text: string,
  redirectionUrl: Array<string>,
  message: Array<string>,
  top: number,
  left: number,
  width: number,
  fontSize: number,
  color: string
}

export interface AdjustableMultipleRowTextDisplayProps {
  text: string,
  redirectionUrl: Array<string>,
  message: Array<string>,
  top: number,
  left: number,
  height: number,
  width: number,
  fontSize: number,
  color: string
}





export const AdjustableTextDisplay: FC<AdjustableTextDisplayProps> = ({ text, message, top, left, height, width, fontSize, color }) => {
  //

  // console.log(text);

  const rows: Array<JSX.Element> = [];

  for(let i: number = 0; i < text.length; i++) {
    rows.push(
      <div key = { i }>
        <PopUpWindow
          message = { message }
        >
          <div style = {{ position: "absolute", top: (i * fontSize * 1.3), left: 0 }}>
            { text[i] }
          </div>
        </PopUpWindow>
      </div>
    )
  }

  // console.log(rows.length);

  return(
    // <PopUpWindow
    //   message = { message }
    // >
    //   <div style = {{ position: "absolute", fontSize: fontSize, top: top, left: left, height: height > 0 ? height : Math.floor(fontSize * 1.35), width: width, color: color }}>
    //     { text }
    //   </div>
    // </PopUpWindow>
    <div style = {{ position: "absolute", top: top, left: left, height: height > 0 ? height : Math.floor(fontSize * 1.35), width: width, fontSize: fontSize, color: color }}>
      { rows }
    </div>
  )
}





export const AdjustableSingleRowTextDisplay: FC<AdjustableSingleRowTextDisplayProps> = ({ text, redirectionUrl, message, top, left, width, fontSize, color }) => {
  //

  let history = useHistory();

  return(
    <div
      style = {{ position: "absolute", whiteSpace: "nowrap", top: top, left: left, cursor: redirectionUrl.length === 0 ? "auto" : "pointer" }}
      onClick = { () => {
        if(redirectionUrl.length > 0) {
          incrementActionsDone();
          history.push(redirectionUrl[0]);
        }
      }}
    >
      <AdjustableTextDisplay
        text = { [text] }
        message = { message }
        top = { 0 }
        left = { 0 }
        height = { -1 }
        width = { width }
        fontSize = { fontSize }
        color = { color }
      />
      {/* <LinkUrl
        redirectionUrl = { redirectionUrl }
      >
        <AdjustableTextDisplay
          text = { [text] }
          message = { message }
          top = { 0 }
          left = { 0 }
          height = { -1 }
          width = { width }
          fontSize = { fontSize }
          color = { color }
        />
      </LinkUrl> */}
    </div>
  )
}

export const AdjustableMultipleRowTextDisplay: FC<AdjustableMultipleRowTextDisplayProps> = ({ text, redirectionUrl, message, top, left, height, width, fontSize, color }) => {
  //

  const safetyDistanceFromTheEdge: number = (25 * fontSize) / 10;

  const fontSizeHeightRatio: number = 1.3;

  let textOutput: Array<string> = [];
  let textPart: string = "";

  if(text.length !== 0) {
    for(let i: number = 0; i < text.length; i++) {
      if(text[i].charCodeAt(0) === 10) {
        textOutput.push(textPart);
        // console.log(textOutput);
        textPart = "";
      } else {
        textPart = textPart + text[i];
      }
    }

    if(textPart !== "") {
      textOutput.push(textPart);
    }

    for(let i: number = 0; i < textOutput.length; i++) {
      //
  
      let rowTextWidth: number = textWidth(textOutput[i], fontSize);
  
      if(rowTextWidth + safetyDistanceFromTheEdge > width) {
        //
  
        const wordsAndSpacesInTheRow: Array<string> = [];
        let builtUpWord: string = "";
  
        // console.log(textOutput[i].length);
  
        for(let j: number = 0; j < textOutput[i].length; j++) {
          if(textOutput[i][j] === " ") {
            if(builtUpWord !== "") {
              wordsAndSpacesInTheRow.push(builtUpWord);
              wordsAndSpacesInTheRow.push(" ");
              builtUpWord = "";
            } else {
              wordsAndSpacesInTheRow.push(" ");
            }
          } else {
            builtUpWord = builtUpWord + textOutput[i][j];
          }
        }
  
        if(builtUpWord !== "") {
          wordsAndSpacesInTheRow.push(builtUpWord);
        }
  
        // console.log(wordsAndSpacesInTheRow);
  
        const newRows: Array<Array<string>> = [];
  
        let accumulatedTextWidth: number = 0;
  
        for(let j: number = 0; j < wordsAndSpacesInTheRow.length; j++) {
          //

          // console.log(j);
          // console.log(wordsAndSpacesInTheRow[j]);
          // console.log(accumulatedTextWidth);
  
          accumulatedTextWidth += textWidth(wordsAndSpacesInTheRow[j], fontSize);
  
          if(accumulatedTextWidth + safetyDistanceFromTheEdge > width) {
            //

            // console.log("-----");
  
            // console.log(accumulatedTextWidth);
            // console.log(wordsAndSpacesInTheRow);

            // console.log(wordsAndSpacesInTheRow[j - 2]);
            // console.log(wordsAndSpacesInTheRow[j - 1]);
            // console.log(wordsAndSpacesInTheRow[j]);
  
            if(j > 0) {
              //

              if(wordsAndSpacesInTheRow[j] === " ") {
                wordsAndSpacesInTheRow.splice(j, 1);
                j--;
              } else {
                wordsAndSpacesInTheRow.splice(j - 1, 1);
                j--;
              }
  
              let newRow: Array<string> = [];

              while(j > 0) {
                newRow.push(wordsAndSpacesInTheRow[0]);
                wordsAndSpacesInTheRow.splice(0, 1);
                j--;
              }

              newRows.push(newRow);
              accumulatedTextWidth = 0;

              j--;

              // console.log(newRow);
              // console.log(wordsAndSpacesInTheRow);
            } else {
              // egy elem kitölti a sort, de még lehet több is

              // console.log("***");

              let accumulatedWordWidth: number = 0;

              let overflowingWord: string = "";

              for(let k: number = 0; k < wordsAndSpacesInTheRow[j].length; k++) {
                //

                accumulatedWordWidth += textWidth(wordsAndSpacesInTheRow[j][k], fontSize);

                // console.log(wordsAndSpacesInTheRow[j]);

                if(accumulatedWordWidth + safetyDistanceFromTheEdge > width) {
                  k = wordsAndSpacesInTheRow[j].length;
                  // console.log("-----");
                } else {
                  overflowingWord = overflowingWord + wordsAndSpacesInTheRow[j][k];
                  wordsAndSpacesInTheRow[j] = wordsAndSpacesInTheRow[j].slice(1);
                  // console.log(wordsAndSpacesInTheRow[j]);
                  k--;
                }
              }
              // overflowingWord = overflowingWord + "(...)";
              newRows.push([overflowingWord]);
              wordsAndSpacesInTheRow.splice(0, 1, wordsAndSpacesInTheRow[j]);
              // wordsAndSpacesInTheRow.splice(0, 1);
              accumulatedTextWidth = 0;
              j--;
            }
          } else {
            // lastNewRow = lastNewRow + wordsAndSpacesInTheRow[j];
          }
        }

        if(newRows.length > 0) {
          newRows.push(wordsAndSpacesInTheRow);
          const newRowsStrings: Array<string> = [];

          for(let j: number = 0; j < newRows.length; j++) {
            //

            let newRowString: string = "";

            for(let k: number = 0; k < newRows[j].length; k++) {
              newRowString = newRowString + newRows[j][k];
            }

            newRowsStrings.push(newRowString);
          }

          textOutput.splice(i, 1, newRowsStrings[0]);

          for(let j: number = 1; j < newRowsStrings.length; j++) {
            textOutput.splice((i + j), 0, newRowsStrings[j]);
          }

          // console.log(newRowsStrings);
        }

        // console.log(newRows);
        // console.log(wordsAndSpacesInTheRow);
  
        // const wordsInTheRow: Array<string> = [];
  
        // for(let j: number = 0; j < textOutput[i].length; j++) {
        //   //
        // }
      }
      //

      // console.log(textOutput);
    }

    if((textOutput.length * fontSize * fontSizeHeightRatio) + safetyDistanceFromTheEdge > height) {
      //

      let lastRowIndex: number = -1;

      for(let i: number = 0; i < textOutput.length; i++) {
        if((i * fontSize * fontSizeHeightRatio) + safetyDistanceFromTheEdge > height) {
          i = textOutput.length;
        } else {
          lastRowIndex++;
        }
      }

      if(lastRowIndex === -1) {
        textOutput = [];
      } else {
        if(lastRowIndex < (textOutput.length - 1) ) {
          textOutput[lastRowIndex] = textOutput[lastRowIndex] + " (...)";
          textOutput.splice((lastRowIndex + 1));
        }
      }
    }
  }

  // for(let i: number = 0; i < textOutput.length; i++) {
  //   //

  //   for(let j: number = 0; j < textOutput[i].length; j++) {
  //     //

  //     if(i === 0) {
  //       if(textOutput[i] === " ") {
  //         //

  //         textOutput[i].slice(0, 1);
  //       }
  //     } else {
  //       if(textOutput[i] === textOutput[i - 1] && textOutput[i] === " ") {
  //         //
  //       }
  //     }

      
  //   }
  // }

  let history = useHistory();

  return(
    <div
      style = {{ position: "absolute", whiteSpace: "normal", top: top, left: left, cursor: redirectionUrl.length === 0 ? "auto" : "pointer" }}
      onClick = { () => {
        if(redirectionUrl.length > 0) {
          incrementActionsDone();
          history.push(redirectionUrl[0]);
        }
      }}
    >
      <AdjustableTextDisplay
        text = { textOutput }
        message = { message }
        top = { 0 }
        left = { 0 }
        height = { height }
        width = { width }
        fontSize = { fontSize }
        color = { color }
      />
      {/* <LinkUrl
        redirectionUrl = { redirectionUrl }
      >
        <AdjustableTextDisplay
          text = { textOutput }
          message = { message }
          top = { 0 }
          left = { 0 }
          height = { height }
          width = { width }
          fontSize = { fontSize }
          color = { color }
        />
      </LinkUrl> */}
    </div>
  )
}
