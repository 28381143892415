import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { AdjustableImageDisplay } from '../04_subComponents/MediaDisplay';
import { UploadedFile } from '../04_subComponents/FileInput';





export interface ImageDisplayPrototypeDisplayParameters {
  top: number,
  left: number,
  height: number,
  width: number
}

export interface ImageDisplayChangedDisplayParameters {
  top?: number,
  left?: number,
  height?: number,
  width?: number
}

export interface ImageDisplayProps {
  uploadedImage: Array<UploadedFile>, // at index 0 if we load from file image
  sourceUrl: Array<string>, // at indx 0 if we load from url
  redirectionUrl?: string,
  message?: string,
  prototypeDisplayParameters: ImageDisplayPrototypeDisplayParameters,
  changedDisplayParameters?: ImageDisplayChangedDisplayParameters
}





export const ImageDisplay: FC<ImageDisplayProps> = ({ uploadedImage, sourceUrl, redirectionUrl,
  message, prototypeDisplayParameters, changedDisplayParameters }) => {
  //

  const [top, setTop] = useState<number>(prototypeDisplayParameters.top);
  const [left, setLeft] = useState<number>(prototypeDisplayParameters.left);
  const [height, setHeight] = useState<number>(prototypeDisplayParameters.height);
  const [width, setWidth] = useState<number>(prototypeDisplayParameters.width);


  useEffect(() => {
    if(changedDisplayParameters !== undefined) {
      if(changedDisplayParameters.top !== undefined) {
        setTop(changedDisplayParameters.top);
      }
      if(changedDisplayParameters.left !== undefined) {
        setLeft(changedDisplayParameters.left);
      }
      if(changedDisplayParameters.height !== undefined) {
        setHeight(changedDisplayParameters.height);
      }
      if(changedDisplayParameters.width !== undefined) {
        setWidth(changedDisplayParameters.width);
      }
    }
  }, [changedDisplayParameters]);


  return(
    <AdjustableImageDisplay
      uploadedImage = { uploadedImage }
      sourceUrl = { sourceUrl }
      redirectionUrl = { redirectionUrl !== undefined ? [redirectionUrl] : [] }
      message = { message !== undefined ? [message] : [] }
      top = { top }
      left = { left }
      height = { height }
      width = { width }
    />
  )
}
