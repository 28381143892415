import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { Collection } from '../../../03_baseComponents/Collection';
import { KeyValueDisplay, KeyValueDisplayPrototypeDisplayParameters, KeyValueDisplayChangedDisplayParameters } from '../../../03_baseComponents/KeyValueDisplay';
import { KeyValueInput } from '../../../03_baseComponents/KeyValueInput';
import { Horse, HorseData } from '../../../../models/HorseProps';
import { AdminHorseListElement } from './AdminHorseList/AdminHorseListElement';





export interface AdminHorseListProps {
  webpageStyle: WebpageStyleProps,
  selectedAndSortedHorses: Array<Horse>,
  selectedAndSortedHorsesAreChanged: number,
  changeAdminHorseListPageContentType: Array<any>,
  overwriteDeletedHorse: Array<any>,
  changeSelectedHorseId: Array<any>,
  resizeMainContentHeight: Array<any>
}





export const AdminHorseList: FC<AdminHorseListProps> = ({ webpageStyle, selectedAndSortedHorses,
  selectedAndSortedHorsesAreChanged, changeAdminHorseListPageContentType, overwriteDeletedHorse,
  changeSelectedHorseId, resizeMainContentHeight }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [listElements, setListElements] = useState<Array<JSX.Element>>([]);

  const [selectedHorsesAreChangedInCollection, setSelectedHorsesAreChangedInCollection] = useState<number>(0);


  const [listCollectionReferenceBoxHeight, setListCollectionReferenceBoxHeight] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listCollectionReferenceBoxHeight);

  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listCollectionReferenceBoxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.boxTop,
    left: webpageStyle.content.mainContent.boxLeft,
    height: boxHeight,
    width: webpageStyle.content.mainContent.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.boxOutlineColor
  });

  
  const [listCollectionTop, setListCollectionTop] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listCollectionTop);
  const [listCollectionLeft, setListCollectionLeft] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listCollectionLeft);
  const [listCollectionN, setListCollectionN] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listCollectionNumberOfRows);
  const [listCollectionM, setListCollectionM] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listCollectionNumberOfColumns);
  const [listElementHeight, setListElementHeight] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElementHeight);
  const [listElementWidth, setListElementWidth] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElementWidth);

  const [listPaginationTop, setListPaginationTop] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listPaginationTop);
  const [listPaginationLeft, setListPaginationLeft] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listPaginationLeft);
  const [listPaginationWidth, setListPaginationWidth] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listPaginationWidth);
  const [listPaginationFontSize, setListPaginationFontSize] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listPaginationFontSize);

  const [sumOfSelectedHorsesPrototypeDisplayParameters, setSumOfSelectedHorsesPrototypeDisplayParameters] = useState<KeyValueDisplayPrototypeDisplayParameters>({
    keyTop: webpageStyle.content.mainContent.adminHorseList.sumOfSelectedHorsesKeyTop,
    keyLeft: webpageStyle.content.mainContent.adminHorseList.sumOfSelectedHorsesKeyLeft,
    keyWidth: webpageStyle.content.mainContent.adminHorseList.sumOfSelectedHorsesKeyWidth,
    keyFontSize: webpageStyle.content.mainContent.adminHorseList.sumOfSelectedHorsesKeyFontSize,
    keyColor: webpageStyle.content.mainContent.adminHorseList.sumOfSelectedHorsesKeyColor,
    valueTop: webpageStyle.content.mainContent.adminHorseList.sumOfSelectedHorsesValueTop,
    valueLeft: webpageStyle.content.mainContent.adminHorseList.sumOfSelectedHorsesValueLeft,
    valueWidth: webpageStyle.content.mainContent.adminHorseList.sumOfSelectedHorsesValueWidth,
    valueFontSize: webpageStyle.content.mainContent.adminHorseList.sumOfSelectedHorsesValueFontSize,
    valueColor: webpageStyle.content.mainContent.adminHorseList.sumOfSelectedHorsesValueColor
  });

  const [collectionTypeSingleSelectKeyTop, setCollectionTypeSingleSelectKeyTop] = useState<number>(webpageStyle.content.mainContent.adminHorseList.collectionTypeSingleSelectKeyTop);
  const [collectionTypeSingleSelectKeyLeft, setCollectionTypeSingleSelectKeyLeft] = useState<number>(webpageStyle.content.mainContent.adminHorseList.collectionTypeSingleSelectKeyLeft);
  const [collectionTypeSingleSelectKeyWidth, setCollectionTypeSingleSelectKeyWidth] = useState<number>(webpageStyle.content.mainContent.adminHorseList.collectionTypeSingleSelectKeyWidth);
  const [collectionTypeSingleSelectKeyFontSize, setCollectionTypeSingleSelectKeyFontSize] = useState<number>(webpageStyle.content.mainContent.adminHorseList.collectionTypeSingleSelectKeyFontSize);
  const [collectionTypeSingleSelectKeyColor, setCollectionTypeSingleSelectKeyColor] = useState<string>(webpageStyle.content.mainContent.adminHorseList.collectionTypeSingleSelectKeyColor);
  const [collectionTypeSingleSelectFieldTop, setCollectionTypeSingleSelectFieldTop] = useState<number>(webpageStyle.content.mainContent.adminHorseList.collectionTypeSingleSelectFieldTop);
  const [collectionTypeSingleSelectFieldLeft, setCollectionTypeSingleSelectFieldLeft] = useState<number>(webpageStyle.content.mainContent.adminHorseList.collectionTypeSingleSelectFieldLeft);
  const [collectionTypeSingleSelectFieldHeight, setCollectionTypeSingleSelectFieldHeight] = useState<number>(webpageStyle.content.mainContent.adminHorseList.collectionTypeSingleSelectFieldHeight);
  const [collectionTypeSingleSelectFieldWidth, setCollectionTypeSingleSelectFieldWidth] = useState<number>(webpageStyle.content.mainContent.adminHorseList.collectionTypeSingleSelectFieldWidth);
  const [collectionTypeSingleSelectFieldBackgroundColor, setCollectionTypeSingleSelectFieldBackgroundColor] = useState<string>(webpageStyle.content.mainContent.adminHorseList.collectionTypeSingleSelectFieldBackgroundColor);
  const [collectionTypeSingleSelectValueFontSize, setCollectionTypeSingleSelectValueFontSize] = useState<number>(webpageStyle.content.mainContent.adminHorseList.collectionTypeSingleSelectValueFontSize);
  const [collectionTypeSingleSelectValueColor, setCollectionTypeSingleSelectValueColor] = useState<string>(webpageStyle.content.mainContent.adminHorseList.collectionTypeSingleSelectValueColor);


  const getStarted = () =>{
    //

    // console.log(selectedHorses);

    const temporaryListElements: Array<JSX.Element> = [];
    for(let i: number = 0; i < selectedAndSortedHorses.length; i++) {
      temporaryListElements.push(
        <AdminHorseListElement
          webpageStyle = { webpageStyle }
          initialHorse = { [selectedAndSortedHorses[i]] }
          initialHorseInterestState = { selectedAndSortedHorses[i].interest }
          changeAdminHorseListPageContentType = { changeAdminHorseListPageContentType }
          overwriteDeletedHorse = { overwriteDeletedHorse }
          changeSelectedHorseId = { changeSelectedHorseId }
          key = { selectedAndSortedHorses[i].id.toString() }
        />
      )
    }
    setListElements(temporaryListElements);

    setIsLoading(false);

    setSelectedHorsesAreChangedInCollection(selectedHorsesAreChangedInCollection + 1);
  }


  useEffect(() => {
    resizeMainContentHeight[0](boxHeight);
  }, []);

  useEffect(() => {
    getStarted();
  }, [selectedAndSortedHorsesAreChanged]);


  if(isLoading === false) {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      >
        <div>
          <Collection
            collectionType = { "ListCollection" }
            collectionTop = { listCollectionTop }
            collectionLeft = { listCollectionLeft }
            n = { listCollectionN }
            m = { listCollectionM }
            elementHeight = { listElementHeight }
            elementWidth = { listElementWidth }
            paginationTop = { listPaginationTop }
            paginationLeft = { listPaginationLeft }
            paginationWidth = { listPaginationWidth }
            paginationFontSize = { listPaginationFontSize }
            selectedElementsAreChanged = { selectedHorsesAreChangedInCollection }
          >
            { listElements }
          </Collection>
  
          <KeyValueDisplay
            keyText = { "Number of selected horses" }
            valueText = { selectedAndSortedHorses.length.toString() }
            prototypeDisplayParameters = { sumOfSelectedHorsesPrototypeDisplayParameters }
          />
        </div>
      </Box>
    )
  } else {
    return(
      <div>
        Loading...
      </div>
    )
  }
}
