import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../../03_baseComponents/Box';
import { ImageDisplay } from '../../../../03_baseComponents/ImageDisplay';
import { SingleRowText, SingleRowPrototypeDisplayParameters, SingleRowChangedDisplayParameters } from '../../../../03_baseComponents/SingleRowText';
import { KeyValueDisplay, KeyValueDisplayPrototypeDisplayParameters, KeyValueDisplayChangedDisplayParameters } from '../../../../03_baseComponents/KeyValueDisplay';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../../03_baseComponents/Button';
import { MultipleRowText, MultipleRowPrototypeDisplayParameters, MultipleRowChangedDisplayParameters } from '../../../../03_baseComponents/MultipleRowText';
import { Horse, HorseData } from '../../../../../models/HorseProps';
import { getHorsePageUrl } from '../../../../../services/RedirectionUrl';
import { getHorsePedigreeFromHorseTelex, HorsePedigree } from '../../../../../services/HorseTelexPedigree';
import { patchData, deleteData } from '../../../../../services/API.services';





export interface AdminHorseListElementProps {
  webpageStyle: WebpageStyleProps,
  initialHorse: Array<Horse>,
  initialHorseInterestState: boolean,
  changeAdminHorseListPageContentType: Array<any>,
  overwriteDeletedHorse: Array<any>,
  changeSelectedHorseId: Array<any>
}





export const AdminHorseListElement: FC<AdminHorseListElementProps> = ({ webpageStyle, initialHorse,
  initialHorseInterestState, changeAdminHorseListPageContentType, overwriteDeletedHorse, changeSelectedHorseId }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [horse, setHorse] = useState<Array<Horse>>(initialHorse);
  // const [horseDeleted, setHorseDeleted] = useState<boolean>(false);

  const [origin, setOrigin] = useState<string>("");
  const [pedigree, setPedigree] = useState<string>("N/A - N/A - N/A");

  const [horseInterestState, setHorseInterestState] = useState<boolean>(initialHorseInterestState);
  const [priceCategory, setPriceCategory] = useState<string>("");
  const [breedingCategory, setBreedingCategory] = useState<string>("");

  const [horsePageUrl, setHorsePageUrl] = useState<Array<string>>([]);

  const [imageComponent, setImageComponent] = useState<Array<JSX.Element>>([<div/>]);
  const [imageBoxComponent, setImageBoxComponent] = useState<Array<JSX.Element>>([<div/>]);
  
  const [inAction, setInAction] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string>("");
  const [actionOnwardMessage, setActionOnwardMessage] = useState<string>("");


  const [boxBackgroundColor, setBoxBackgroundColor] = useState<string>(webpageStyle.content.mainContent.adminHorseList.listElement.boxBackgroundColor);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: 0,
    left: 0,
    height: webpageStyle.content.mainContent.adminHorseList.listElement.boxHeight,
    width: webpageStyle.content.mainContent.adminHorseList.listElement.boxWidth,
    backgroundColor: boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.adminHorseList.listElement.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.adminHorseList.listElement.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.adminHorseList.listElement.boxOutlineColor
  });

  
  const [imageBoxTop, setImageBoxTop] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.imageBoxTop);
  const [imageBoxLeft, setImageBoxLeft] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.imageBoxLeft);
  const [imageBoxBackgroundColor, setImageBoxBackgroundColor] = useState<string>(webpageStyle.content.mainContent.adminHorseList.listElement.imageBoxBackgroundColor);
  const [imageBoxHeight, setImageBoxHeight] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.imageBoxHeight);
  const [imageBoxWidth, setImageBoxWidth] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.imageBoxWidth);
  const [imageBoxOutlineStyle, setImageBoxOutlineStyle] = useState<string>(webpageStyle.content.mainContent.adminHorseList.listElement.imageBoxOutlineStyle);
  const [imageBoxOutlineWidth, setImageBoxOutlineWidth] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.imageBoxOutlineWidth);
  const [imageBoxOutlineColor, setImageBoxOutlineColor] = useState<string>(webpageStyle.content.mainContent.adminHorseList.listElement.imageBoxOutlineColor);

  const [imageTop, setImageTop] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.imageTop);
  const [imageLeft, setImageLeft] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.imageLeft);
  const [imageHeight, setImageHeight] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.imageHeight);
  const [imageWidth, setImageWidth] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.imageWidth);

  const [titleTopPrototypeDisplayParameters, setTitleTopPrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.adminHorseList.listElement.titleTop,
    left: webpageStyle.content.mainContent.adminHorseList.listElement.titleLeft,
    width: webpageStyle.content.mainContent.adminHorseList.listElement.titleWidth,
    fontSize: webpageStyle.content.mainContent.adminHorseList.listElement.titleFontSize,
    color: webpageStyle.content.mainContent.adminHorseList.listElement.titleColor
  });

  const [firstDataTop, setFirstDataTop] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.firstDataTop);
  const [dataTopDistance, setDataTopDistance] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.dataTopDistance);

  const [keyValueDisplayPrototypeDisplayParameters, setKeyValueDisplayPrototypeDisplayParameters] = useState<KeyValueDisplayPrototypeDisplayParameters>({
    keyTop: firstDataTop,
    keyLeft: webpageStyle.content.mainContent.adminHorseList.listElement.keyLeft,
    keyWidth: webpageStyle.content.mainContent.adminHorseList.listElement.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.adminHorseList.listElement.keyFontSize,
    keyColor: webpageStyle.content.mainContent.adminHorseList.listElement.keyColor,
    valueTop: firstDataTop,
    valueLeft: webpageStyle.content.mainContent.adminHorseList.listElement.valueLeft,
    valueWidth: webpageStyle.content.mainContent.adminHorseList.listElement.valueWidth,
    valueFontSize: webpageStyle.content.mainContent.adminHorseList.listElement.valueFontSize,
    valueColor: webpageStyle.content.mainContent.adminHorseList.listElement.valueColor
  });

  const [categoryLeft, setCategoryLeft] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.categoryLeft);
  const [categoryWidth, setCategoryWidth] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.categoryWidth);
  const [categoryFontSize, setCategoryFontSize] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.categoryFontSize);
  const [categoryColor, setCategoryColor] = useState<string>(webpageStyle.content.mainContent.adminHorseList.listElement.categoryColor);

  const [firstCategoryTop, setFirstCategoryTop] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.firstCategoryTop);
  const [categoryTopDistance, setCategoryTopDistance] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.categoryTopDistance);

  const [firstActionButtonTop, setFirstActionButtonTop] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.firstActionButtonTop);
  const [actionButtonTopDistance, setActionButtonTopDistance] = useState<number>(webpageStyle.content.mainContent.adminHorseList.listElement.actionButtonTopDistance);

  const [actionButtonPrototypeDisplayParameters, setActionButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.adminHorseList.listElement.firstActionButtonTop,
    left: webpageStyle.content.mainContent.adminHorseList.listElement.actionButtonLeft,
    height: webpageStyle.content.mainContent.adminHorseList.listElement.actionButtonHeight,
    width: webpageStyle.content.mainContent.adminHorseList.listElement.actionButtonWidth,
    backgroundColor: webpageStyle.content.mainContent.adminHorseList.listElement.actionButtonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.adminHorseList.listElement.actionButtonFontSize,
    contentColor: webpageStyle.content.mainContent.adminHorseList.listElement.actionButtonContentColorActive
  });

  const [onwardYesButtonPrototypeDisplayParameters, setOnwardYesButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.adminHorseList.listElement.onwardYesButtonTop,
    left: webpageStyle.content.mainContent.adminHorseList.listElement.onwardYesButtonLeft,
    height: webpageStyle.content.mainContent.adminHorseList.listElement.onwardButtonHeight,
    width: webpageStyle.content.mainContent.adminHorseList.listElement.onwardButtonWidth,
    backgroundColor: webpageStyle.content.mainContent.adminHorseList.listElement.onwardButtonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.adminHorseList.listElement.onwardButtonFontSize,
    contentColor: webpageStyle.content.mainContent.adminHorseList.listElement.onwardButtonContentColorActive
  });

  const [onwardNoButtonPrototypeDisplayParameters, setOnwardNoButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.adminHorseList.listElement.onwardNoButtonTop,
    left: webpageStyle.content.mainContent.adminHorseList.listElement.onwardNoButtonLeft,
    height: webpageStyle.content.mainContent.adminHorseList.listElement.onwardButtonHeight,
    width: webpageStyle.content.mainContent.adminHorseList.listElement.onwardButtonWidth,
    backgroundColor: webpageStyle.content.mainContent.adminHorseList.listElement.onwardButtonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.adminHorseList.listElement.onwardButtonFontSize,
    contentColor: webpageStyle.content.mainContent.adminHorseList.listElement.onwardButtonContentColorActive
  });

  const [onwardMessagePrototypeDisplayParameters, setOnwardMessagePrototypeDisplayParameters] = useState<MultipleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.adminHorseList.listElement.onwardMessageTop,
    left: webpageStyle.content.mainContent.adminHorseList.listElement.onwardMessageLeft,
    height: webpageStyle.content.mainContent.adminHorseList.listElement.onwardMessageHeight,
    width: webpageStyle.content.mainContent.adminHorseList.listElement.onwardMessageWidth,
    fontSize: webpageStyle.content.mainContent.adminHorseList.listElement.onwardMessageFontSize,
    color: webpageStyle.content.mainContent.adminHorseList.listElement.onwardMessageContentColor
  });


  const loadData = async () => {
    const temporaryRedirectionUrl: string = getHorsePageUrl(horse[0].data.id);
    setHorsePageUrl([temporaryRedirectionUrl]);

    if(horse[0].data.mainImageUrl !== null) {
      if(horse[0].data.mainImageUrl.length !== 0) {
        setImageComponent([
          <ImageDisplay
            uploadedImage = { [] }
            sourceUrl = { [horse[0].data.mainImageUrl] }
            redirectionUrl = { temporaryRedirectionUrl }
            prototypeDisplayParameters = { {
              top: imageTop ,
              left: imageLeft,
              height: imageHeight,
              width: imageWidth
            } }
          />
        ])
      }
    }

    setImageBoxComponent([
      <Box
        redirectionUrl = { temporaryRedirectionUrl }
        prototypeDisplayParameters = { {
          top: imageBoxTop,
          left: imageBoxLeft,
          height: imageBoxHeight,
          width: imageBoxWidth,
          backgroundColor: imageBoxBackgroundColor,
          outlineStyle: imageBoxOutlineStyle,
          outlineWidth: imageBoxOutlineWidth,
          outlineColor: imageBoxOutlineColor
        } }
      />
    ])

    setOrigin((horse[0].data.origin + " (" + horse[0].data.horseBreed + ")"));

    let horsePedigree: HorsePedigree | undefined = undefined;

    if(horse[0].data.horseTelexId !== null) {
      horsePedigree = await getHorsePedigreeFromHorseTelex(horse[0].data.horseTelexId);
    }

    if(horsePedigree !== undefined) {
      const fathersName: string = horsePedigree.fathersName.length !== 0 ? horsePedigree.fathersName[0] : "N/A";
      const mothersFathersName: string = horsePedigree.mothersFathersName.length !== 0 ? horsePedigree.mothersFathersName[0] : "N/A";
      const mothersMothersFathersName: string = horsePedigree.mothersMothersFathersName.length !== 0 ? horsePedigree.mothersMothersFathersName[0] : "N/A";

      setPedigree(( fathersName + " - " + mothersFathersName + " - " + mothersMothersFathersName ));
    }

    setPriceCategory((horse[0].data.priceCategory + ' €'));

    if(horse[0].data.breedingCategory !== "no information") {
      setBreedingCategory(horse[0].data.breedingCategory);
    }

    setIsLoading(false);
  }

  const changeHorseInterest = () => {
    //

    // run API ...

    // feltételek ...

    setHorseInterestState(horseInterestState === true ? false : true);
  }

  const activateHorse = async () => {
    const result = await patchData(`/api/horses/single/activate/admin/${ horse[0].id }`, { sg: "sg" }, true);
    // const result: boolean = true;
    if(result === true) {
      let horseWithNewAccessibility: Array<Horse> = horse;
      horseWithNewAccessibility[0].data.accessibility = "public";
      setHorse(horseWithNewAccessibility);
    }
  }

  const deactivateHorse = async () => {
    const result = await patchData(`/api/horses/single/deactivate/admin/${ horse[0].id }`, { sg: "sg" }, true);
    // const result: boolean = true;
    if(result === true) {
      let userWithNewAccessibility: Array<Horse> = horse;
      userWithNewAccessibility[0].data.accessibility = "private";
      setHorse(userWithNewAccessibility);
    }
  }

  const deleteHorse = async () => {
    const result = await deleteData(`/api/horses/single/delete/admin/${ horse[0].id }`, { sg: "sg" }, true);
    // const result: boolean = true;
    if(result === true) {
      let horseWithNewStatus: Array<Horse> = horse;
      horseWithNewStatus[0].data.accessibility = "deleted";
      // setHorseDeleted(true);
      setHorse(horseWithNewStatus);
      overwriteDeletedHorse[0](horse[0].id);
    }
  }


  useEffect(() => {
    loadData();
  }, []);


  if(isLoading === true) {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      >
        <div>
          Horse catalog list element is loading...
        </div>
      </Box>
    )
  } else {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
        changedDisplayParameters = { {
          backgroundColor: horse[0].data.accessibility !== "deleted" ? boxBackgroundColor : "obscured"
        } }
      >
        <div>
          { imageBoxComponent[0] }

          { imageComponent[0] }

          <SingleRowText
            text = { horse[0].data.name }
            redirectionUrl = { horsePageUrl[0] }
            prototypeDisplayParameters = { titleTopPrototypeDisplayParameters }
          />

          <KeyValueDisplay
            keyText = { "Id" }
            valueText = { horse[0].id.toString() }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 0 * dataTopDistance,
              valueTop: firstDataTop + 0 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Stud" }
            valueText = { horse[0].data.stud }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 1 * dataTopDistance,
              valueTop: firstDataTop + 1 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Birth year" }
            valueText = { horse[0].data.birthYear.toString() }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 2 * dataTopDistance,
              valueTop: firstDataTop + 2 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Sex" }
            valueText = { horse[0].data.sex }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 3 * dataTopDistance,
              valueTop: firstDataTop + 3 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Color" }
            valueText = { horse[0].data.color }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 4 * dataTopDistance,
              valueTop: firstDataTop + 4 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Origin" }
            valueText = { origin }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 5 * dataTopDistance,
              valueTop: firstDataTop + 5 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Pedigree" }
            valueText = { pedigree }
            message = { "Father - Mother's Father - Mother's Mother's Father" }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 6 * dataTopDistance,
              valueTop: firstDataTop + 6 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Trading category" }
            valueText = { horse[0].data.tradingCategory }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 7 * dataTopDistance,
              valueTop: firstDataTop + 7 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Price category" }
            valueText = { priceCategory }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 8 * dataTopDistance,
              valueTop: firstDataTop + 8 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Breeding category" }
            valueText = { horse[0].data.breedingCategory }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 9 * dataTopDistance,
              valueTop: firstDataTop + 9 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Status" }
            valueText = { horse[0].data.accessibility }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 10 * dataTopDistance,
              valueTop: firstDataTop + 10 * dataTopDistance
            } }
          />

          { inAction === false ?
            <Button
              buttonText = { "Read" }
              actionFunctions = { [() => {
                changeSelectedHorseId[0]([horse[0].id]);
                changeAdminHorseListPageContentType[0]("readHorseData");
              }] }
              redirectionUrl = { horsePageUrl[0] }
              disabled = { false }
              prototypeDisplayParameters = { actionButtonPrototypeDisplayParameters }
              changedDisplayParameters = { {
                top: firstActionButtonTop + 0 * actionButtonTopDistance
              } }
            /> : null
          }
  
          { inAction === false ?
            <Button
              buttonText = { "Edit" }
              actionFunctions = { [() => {
                changeSelectedHorseId[0]([horse[0].id]);
                changeAdminHorseListPageContentType[0]("editHorseData");
              }] }
              disabled = { false }
              prototypeDisplayParameters = { actionButtonPrototypeDisplayParameters }
              changedDisplayParameters = { {
                top: firstActionButtonTop + 1 * actionButtonTopDistance
              } }
            /> : null
          }
  
          { inAction === false && horse[0].data.accessibility === "public" ?
            <Button
              buttonText = { "Deactivate" }
              actionFunctions = { [() => {
                setInAction(true);
                setActionType("deactivate");
                setActionOnwardMessage("Deactivate?");
              }] } // üzenetet kel megjeleníteni + választás
              disabled = { false }
              prototypeDisplayParameters = { actionButtonPrototypeDisplayParameters }
              changedDisplayParameters = { {
                top: firstActionButtonTop + 2 * actionButtonTopDistance
              } }
            /> : null
          }
  
          { inAction === false && horse[0].data.accessibility === "private" ?
            <Button
              buttonText = { "Activate" }
              actionFunctions = { [() => {
                setInAction(true);
                setActionType("activate");
                setActionOnwardMessage("Activate?");
              }] } // üzenetet kel megjeleníteni + választás
              disabled = { false }
              prototypeDisplayParameters = { actionButtonPrototypeDisplayParameters }
              changedDisplayParameters = { {
                top: firstActionButtonTop + 2 * actionButtonTopDistance
              } }
            /> : null
          }
  
          { inAction === false && horse[0].data.accessibility === "private" ?
            <Button
              buttonText = { "Delete" }
              actionFunctions = { [() => {
                setInAction(true);
                setActionType("delete");
                setActionOnwardMessage("Delete?");
              }] } // üzenetet kel megjeleníteni + választás
              disabled = { false }
              prototypeDisplayParameters = { actionButtonPrototypeDisplayParameters }
              changedDisplayParameters = { {
                top: firstActionButtonTop + 3 * actionButtonTopDistance
              } }
            /> : null
          }
  
          { inAction === true ?
            <MultipleRowText
              text = { actionOnwardMessage }
              prototypeDisplayParameters = { onwardMessagePrototypeDisplayParameters }
            /> : null
          }
  
          { inAction === true ?
            <Button
              buttonText = { "Yes" }
              actionFunctions = { [async () => {
                switch(actionType) {
                  case("activate"):
                    await activateHorse();
                    break;
                  case("deactivate"):
                    await deactivateHorse();
                    break;
                  case("delete"):
                    await deleteHorse();
                    break;
                }
                setInAction(false);
                setActionType("");
                setActionOnwardMessage("");
              }] }
              disabled = { false }
              prototypeDisplayParameters = { onwardYesButtonPrototypeDisplayParameters }
            /> : null
          }
  
          { inAction === true ?
            <Button
              buttonText = { "No" }
              actionFunctions = { [() => {
                setInAction(false);
                setActionType("");
                setActionOnwardMessage("");
              }] }
              disabled = { false }
              prototypeDisplayParameters = { onwardNoButtonPrototypeDisplayParameters }
            /> : null
          }

          { horse[0].data.accessibility === "deleted" ?
            <div>
              This horse is deleted
            </div> : null
          }
        </div>
      </Box>
    )
  }
}
