import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../03_baseComponents/Box';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../03_baseComponents/Button';
import { LoggedInUser } from '../../models/UserProps';





export interface NavigationProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  path: string,
  changeContent: Array<any>,
  doAction: Array<any>
}





export const Navigation: FC<NavigationProps> = ({ webpageStyle, loggedInUser, path, changeContent, doAction }) => {
  //

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.navigation.boxTop + webpageStyle.general.webpageMargin,
    left: webpageStyle.navigation.boxLeft + webpageStyle.general.webpageMargin,
    height: webpageStyle.navigation.boxHeight,
    width: webpageStyle.navigation.boxWidth,
    backgroundColor: webpageStyle.navigation.boxBackgroundColor,
    outlineStyle: webpageStyle.navigation.boxOutlineStyle,
    outlineWidth: webpageStyle.navigation.boxOutlineWidth,
    outlineColor: webpageStyle.navigation.boxOutlineColor
  });


  const [buttonHeight, setButtonHeight] = useState<number>(webpageStyle.navigation.buttonHeight);
  const [buttonWidth, setButtonWidth] = useState<number>(webpageStyle.navigation.buttonWidth);
  const [buttonFontSize, setButtonFontSize] = useState<number>(webpageStyle.navigation.buttonFontSize);
  const [buttonBackgroundColorActive, setButtonBackgroundColorActive] = useState<string>(webpageStyle.navigation.buttonBackgroundColorActive);
  const [buttonContentColorActive, setButtonContentColorActive] = useState<string>(webpageStyle.navigation.buttonContentColorActive);
  const [buttonBackgroundColorHighlighted, setButtonBackgroundColorHighlighted] = useState<string>(webpageStyle.navigation.buttonBackgroundColorHighlighted);
  const [buttonContentColorHighlighted, setButtonContentColorHighlighted] = useState<string>(webpageStyle.navigation.buttonContentColorHighlighted);

  const [horseCatalogButtonPrototypeDisplayParameters, setHorseCatalogButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.navigation.positionA.top,
    left: webpageStyle.navigation.positionA.left,
    height: buttonHeight,
    width: buttonWidth,
    backgroundColor: buttonBackgroundColorActive,
    fontSize: buttonFontSize,
    contentColor: buttonContentColorActive
  });

  const [interestingHorsesButtonPrototypeDisplayParameters, setInterestingHorsesButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.navigation.positionB.top,
    left: webpageStyle.navigation.positionB.left,
    height: buttonHeight,
    width: buttonWidth,
    backgroundColor: buttonBackgroundColorActive,
    fontSize: buttonFontSize,
    contentColor: buttonContentColorActive
  });

  const [requestedProposalsButtonPrototypeDisplayParameters, setRequestedProposalsButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.navigation.positionC.top,
    left: webpageStyle.navigation.positionC.left,
    height: buttonHeight,
    width: buttonWidth,
    backgroundColor: buttonBackgroundColorActive,
    fontSize: buttonFontSize,
    contentColor: buttonContentColorActive
  });

  const [userProfileButtonPrototypeDisplayParameters, setUserProfileButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.navigation.positionD.top,
    left: webpageStyle.navigation.positionD.left,
    height: buttonHeight,
    width: buttonWidth,
    backgroundColor: buttonBackgroundColorActive,
    fontSize: buttonFontSize,
    contentColor: buttonContentColorActive
  });

  const [horseListButtonPrototypeDisplayParameters, setHorseListButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.navigation.positionB.top,
    left: webpageStyle.navigation.positionB.left,
    height: buttonHeight,
    width: buttonWidth,
    backgroundColor: buttonBackgroundColorActive,
    fontSize: buttonFontSize,
    contentColor: buttonContentColorActive
  });

  const [userListButtonPrototypeDisplayParameters, setUserListButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.navigation.positionC.top,
    left: webpageStyle.navigation.positionC.left,
    height: buttonHeight,
    width: buttonWidth,
    backgroundColor: buttonBackgroundColorActive,
    fontSize: buttonFontSize,
    contentColor: buttonContentColorActive
  });

  const [proposalListButtonPrototypeDisplayParameters, setProposalListButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.navigation.positionD.top,
    left: webpageStyle.navigation.positionD.left,
    height: buttonHeight,
    width: buttonWidth,
    backgroundColor: buttonBackgroundColorActive,
    fontSize: buttonFontSize,
    contentColor: buttonContentColorActive
  });

  const [webDataSettingsButtonPrototypeDisplayParameters, setWebDataSettingsButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.navigation.positionE.top,
    left: webpageStyle.navigation.positionE.left,
    height: buttonHeight,
    width: buttonWidth,
    backgroundColor: buttonBackgroundColorActive,
    fontSize: buttonFontSize,
    contentColor: buttonContentColorActive
  });

  const [adminProfileButtonPrototypeDisplayParameters, setAdminProfileButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.navigation.positionF.top,
    left: webpageStyle.navigation.positionF.left,
    height: buttonHeight,
    width: buttonWidth,
    backgroundColor: buttonBackgroundColorActive,
    fontSize: buttonFontSize,
    contentColor: buttonContentColorActive
  });

  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <div>
        { (loggedInUser.role === "visitor" || loggedInUser.role === "customer" || loggedInUser.role === "administrator") ?
          <Button
            buttonText = { "Horse catalog" }
            actionFunctions = { [changeContent[0]] } // ezt ki kell dolgozni
            redirectionUrl = { "/horsecatalog" }
            conditionFunction = { [() => {
              if(path !== "/horsecatalog") {
                return(true);
              } else {
                return(false);
              }
            }] }
            disabled = { false }
            prototypeDisplayParameters = { horseCatalogButtonPrototypeDisplayParameters }
            changedDisplayParameters = { {
              backgroundColor: path !== "/horsecatalog" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
              contentColor: path !== "/horsecatalog" ? buttonContentColorActive : buttonContentColorHighlighted
            } }
          /> : null
        }

        { loggedInUser.role === "customer" ?
          <Button
            buttonText = { "Interesting horses" }
            actionFunctions = { [changeContent[0]] } // ezt ki kell dolgozni
            redirectionUrl = { "/user/interestinghorses" }
            conditionFunction = { [() => {
              if(path !== "/user/interestinghorses") {
                return(true);
              } else {
                return(false);
              }
            }] }
            disabled = { false }
            prototypeDisplayParameters = { interestingHorsesButtonPrototypeDisplayParameters }
            changedDisplayParameters = { {
              backgroundColor: path !== "/user/interestinghorses" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
              contentColor: path !== "/user/interestinghorses" ? buttonContentColorActive : buttonContentColorHighlighted
            } }
          /> : null
        }

        { loggedInUser.role === "customer" ?
          <Button
            buttonText = { "Requested proposals" }
            actionFunctions = { [changeContent[0]] } // ezt ki kell dolgozni
            redirectionUrl = { "/user/requestedproposals" }
            conditionFunction = { [() => {
              if(path !== "/user/requestedproposals") {
                return(true);
              } else {
                return(false);
              }
            }] }
            disabled = { false }
            prototypeDisplayParameters = { requestedProposalsButtonPrototypeDisplayParameters }
            changedDisplayParameters = { {
              backgroundColor: path !== "/user/requestedproposals" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
              contentColor: path !== "/user/requestedproposals" ? buttonContentColorActive : buttonContentColorHighlighted
            } }
          /> : null
        }

        { loggedInUser.role === "customer" ?
          <Button
            buttonText = { "User profile" }
            actionFunctions = { [changeContent[0]] } // ezt ki kell dolgozni
            redirectionUrl = { "/user/userprofile" }
            conditionFunction = { [() => {
              if(path !== "/user/userprofile") {
                return(true);
              } else {
                return(false);
              }
            }] }
            disabled = { false }
            prototypeDisplayParameters = { userProfileButtonPrototypeDisplayParameters }
            changedDisplayParameters = { {
              backgroundColor: path !== "/user/userprofile" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
              contentColor: path !== "/user/userprofile" ? buttonContentColorActive : buttonContentColorHighlighted
            } }
          /> : null
        }

        { loggedInUser.role === "administrator" ?
          <Button
            buttonText = { "Horse list" }
            actionFunctions = { [changeContent[0]] } // ezt ki kell dolgozni
            redirectionUrl = { "/admin/horses" }
            conditionFunction = { [() => {
              if(path !== "/admin/horses") {
                return(true);
              } else {
                return(false);
              }
            }] }
            disabled = { false }
            prototypeDisplayParameters = { horseListButtonPrototypeDisplayParameters }
            changedDisplayParameters = { {
              backgroundColor: path !== "/admin/horses" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
              contentColor: path !== "/admin/horses" ? buttonContentColorActive : buttonContentColorHighlighted
            } }
          /> : null
        }

        { loggedInUser.role === "administrator" ?
          <Button
            buttonText = { "User list" }
            actionFunctions = { [changeContent[0]] } // ezt ki kell dolgozni
            redirectionUrl = { "/admin/users" }
            conditionFunction = { [() => {
              if(path !== "/admin/users") {
                return(true);
              } else {
                return(false);
              }
            }] }
            disabled = { false }
            prototypeDisplayParameters = { userListButtonPrototypeDisplayParameters }
            changedDisplayParameters = { {
              backgroundColor: path !== "/admin/users" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
              contentColor: path !== "/admin/users" ? buttonContentColorActive : buttonContentColorHighlighted
            } }
          /> : null
        }

        { loggedInUser.role === "administrator" ?
          <Button
            buttonText = { "Proposal list" }
            actionFunctions = { [changeContent[0]] } // ezt ki kell dolgozni
            redirectionUrl = { "/admin/proposals" }
            conditionFunction = { [() => {
              if(path !== "/admin/proposals") {
                return(true);
              } else {
                return(false);
              }
            }] }
            disabled = { false }
            prototypeDisplayParameters = { proposalListButtonPrototypeDisplayParameters }
            changedDisplayParameters = { {
              backgroundColor: path !== "/admin/proposals" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
              contentColor: path !== "/admin/proposals" ? buttonContentColorActive : buttonContentColorHighlighted
            } }
          /> : null
        }

        { loggedInUser.role === "administrator" ?
          <Button
            buttonText = { "Web data settings" }
            actionFunctions = { [changeContent[0]] } // ezt ki kell dolgozni
            redirectionUrl = { "/admin/webdata" }
            conditionFunction = { [() => {
              if(path !== "/admin/webdata") {
                return(true);
              } else {
                return(false);
              }
            }] }
            disabled = { false }
            prototypeDisplayParameters = { webDataSettingsButtonPrototypeDisplayParameters }
            changedDisplayParameters = { {
              backgroundColor: path !== "/admin/webdata" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
              contentColor: path !== "/admin/webdata" ? buttonContentColorActive : buttonContentColorHighlighted
            } }
          /> : null
        }

        { loggedInUser.role === "administrator" ?
          <Button
            buttonText = { "Admin profile" }
            actionFunctions = { [changeContent[0]] } // ezt ki kell dolgozni
            redirectionUrl = { "/admin/adminprofile" }
            conditionFunction = { [() => {
              if(path !== "/admin/adminprofile") {
                return(true);
              } else {
                return(false);
              }
            }] }
            disabled = { false }
            prototypeDisplayParameters = { adminProfileButtonPrototypeDisplayParameters }
            changedDisplayParameters = { {
              backgroundColor: path !== "/admin/adminprofile" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
              contentColor: path !== "/admin/adminprofile" ? buttonContentColorActive : buttonContentColorHighlighted
            } }
          /> : null
        }
      </div>
    </Box>
  )
}
