import { ImageOrVideo } from '../components_uj/03_baseComponents/ImageAndVideoUploader/UploadedImageAndVideoElement';
import { AttachedFile } from '../models/AttachedFile';

export function sortImagesAndVideos(initialImages: Array<AttachedFile>, initialVideos: Array<AttachedFile>): Array<ImageOrVideo> {
  //

  const imagesAndVideosResult: Array<ImageOrVideo> = [];

  const images: Array<AttachedFile> = [];
  const videos: Array<AttachedFile> = [];

  for(let i: number = 0; i < initialImages.length; i++) {
    images.push(initialImages[i]);
  }

  for(let i: number = 0; i < initialVideos.length; i++) {
    videos.push(initialVideos[i]);
  }

  for(let i: number = 0; i < (initialImages.length + initialVideos.length); i++) {
    let minSequenceNumber = -1;
    let imageIndexOfMinSequenceNumber: number = -1;
    let videoIndexOfMinSequenceNumber: number = -1;

    for(let j: number = 0; j < images.length; j++) {
      if(minSequenceNumber !== -1) {
        if(images[j].sequenceNumber < minSequenceNumber) {
          minSequenceNumber = images[j].sequenceNumber;
          imageIndexOfMinSequenceNumber = j;
          videoIndexOfMinSequenceNumber = -1;
        }
      } else {
        minSequenceNumber = images[j].sequenceNumber;
        imageIndexOfMinSequenceNumber = j;
      }
    }

    for(let j: number = 0; j < videos.length; j++) {
      if(minSequenceNumber !== -1) {
        if(videos[j].sequenceNumber < minSequenceNumber) {
          minSequenceNumber = videos[j].sequenceNumber;
          videoIndexOfMinSequenceNumber = j;
          imageIndexOfMinSequenceNumber = -1;
        }
      } else {
        minSequenceNumber = videos[j].sequenceNumber;
        videoIndexOfMinSequenceNumber = j;
      }
    }

    if(imageIndexOfMinSequenceNumber !== -1) {
      imagesAndVideosResult.push({
        oldTitle: images[imageIndexOfMinSequenceNumber].title,
        image: [{
          uploadedImage: [],
          sourceUrl: [images[imageIndexOfMinSequenceNumber].url],
          oldTitle: images[imageIndexOfMinSequenceNumber].title,
          title: images[imageIndexOfMinSequenceNumber].title,
          fileExtension: images[imageIndexOfMinSequenceNumber].type,
          id: images[imageIndexOfMinSequenceNumber].id
        }],
        video: []
      });

      images.splice(imageIndexOfMinSequenceNumber, 1);
    }

    if(videoIndexOfMinSequenceNumber !== -1 ) {
      imagesAndVideosResult.push({
        oldTitle: videos[videoIndexOfMinSequenceNumber].title,
        image: [],
        video: [{
          sourceUrl: [videos[videoIndexOfMinSequenceNumber].url],
          oldTitle: videos[videoIndexOfMinSequenceNumber].title,
          title: videos[videoIndexOfMinSequenceNumber].title,
          id: videos[videoIndexOfMinSequenceNumber].id
        }]
      });

      videos.splice(videoIndexOfMinSequenceNumber, 1);
    }
  }

  return(imagesAndVideosResult);
}
