//

import { number } from "prop-types"

export interface WebpageBaseComponentProps {
  top: number,
  left: number,
  height: number,
  width: number,
  backgroundColor: string,
  outlineStyle: string,
  outlineWidth: number,
  outlineColor: string,
  fontSize: number,
  fontOrContentColor: string
}

export interface WebpageStyleProps {
  titleFontSize: number,
  titleFontColor: string,
  textFontSize: number,
  textFontColor: string,
  // fontType: string, // ?
  fieldBackgroundColor: string,
  fieldValueFontSize: number,
  fieldValueFontColor: string,
  backgroundColor: string,
  boxBackgroundColor: string,
  boxOutlineStyle: string,
  boxOutlineWidth: number,
  boxOutlineColor: string,
  buttonFontSize: number,
  buttonBackgroundColorActive: string,
  buttonContentColorActive: string,
  buttonBackgroundColorInactive: string,
  buttonContentColorInactive: string,
  buttonBackgroundColorHighlighted: string,
  buttonContentColorHighlighted: string,
  webpageMargin: number,

  general: {
    titleFontSize: number,
    titleFontColor: string,
    headerFontSize: number,
    headerFontColor: string,
    textFontSize: number,
    textFontColor: string,
    // fontType: string, // ?
    fieldBackgroundColor: string,
    fieldValueFontSize: number,
    fieldValueFontColor: string,
    backgroundColor: string,
    boxBackgroundColor: string,
    boxOutlineStyle: string,
    boxOutlineWidth: number,
    boxOutlineColor: string,
    buttonFontSize: number,
    buttonBackgroundColorActive: string,
    buttonContentColorActive: string,
    buttonBackgroundColorInactive: string,
    buttonContentColorInactive: string,
    buttonBackgroundColorHighlighted: string,
    buttonContentColorHighlighted: string,
    webpageMargin: number,
  },

  logo: {
    boxTop: number,
    boxLeft: number,
    boxHeight: number,
    boxWidth: number,
    boxBackgroundColor: string,
    boxOutlineStyle: string,
    boxOutlineWidth: number,
    boxOutlineColor: string,
    imageTop: number,
    imageLeft: number,
    imageHeight: number,
    imageWidth: number
  },

  header: {
    textFontSize: number,
    textFontColor: string,
    boxTop: number,
    boxLeft: number,
    boxHeight: number,
    boxWidth: number,
    boxBackgroundColor: string,
    boxOutlineStyle: string,
    boxOutlineWidth: number,
    boxOutlineColor: string,
    displayTop: number,
    displayLeft: number,
    displayWidth: number,
    buttonHeight: number,
    buttonWidth: number,
    buttonFontSize: number,
    buttonBackgroundColorActive: string,
    buttonContentColorActive: string,
    buttonBackgroundColorInactive: string,
    buttonContentColorInactive: string,
    buttonBackgroundColorHighlighted: string,
    buttonContentColorHighlighted: string,

    positionA: {
      top: number,
      left: number
    },
    positionB: {
      top: number,
      left: number
    },
    positionC: {
      top: number,
      left: number
    }
  },

  navigation: {
    boxTop: number,
    boxLeft: number,
    boxHeight: number,
    boxWidth: number,
    boxBackgroundColor: string,
    boxOutlineStyle: string,
    boxOutlineWidth: number,
    boxOutlineColor: string,
    buttonHeight: number,
    buttonWidth: number,
    buttonFontSize: number,
    buttonBackgroundColorActive: string,
    buttonContentColorActive: string,
    buttonBackgroundColorInactive: string,
    buttonContentColorInactive: string,
    buttonBackgroundColorHighlighted: string,
    buttonContentColorHighlighted: string,

    positionA: {
      top: number,
      left: number
    },
    positionB: {
      top: number,
      left: number
    },
    positionC: {
      top: number,
      left: number
    },
    positionD: {
      top: number,
      left: number
    },
    positionE: {
      top: number,
      left: number
    },
    positionF: {
      top: number,
      left: number
    }
  },

  content: {
    boxTop: number,
    boxLeft: number,
    boxMinimumHeight: number,
    boxWidth: number,
    boxBackgroundColor: string,
    boxOutlineStyle: string,
    boxOutlineWidth: number,
    boxOutlineColor: string,

    sidebar: {
      navigation: {
        boxLeft: number,
        boxWidth: number,
        boxBackgroundColor: string,
        boxOutlineStyle: string,
        boxOutlineWidth: number,
        boxOutlineColor: string,

        firstButtonTop: number,
        buttonTopDistance: number,

        buttonLeft: number,
        buttonHeight: number,
        buttonWidth: number,
        buttonFontSize: number,
        buttonBackgroundColorActive: string,
        buttonContentColorActive: string,
        buttonBackgroundColorInactive: string,
        buttonContentColorInactive: string,
        buttonBackgroundColorHighlighted: string,
        buttonContentColorHighlighted: string
      },

      filteringAndSorting: {
        boxLeft: number,
        boxWidth: number,
        boxBackgroundColor: string,
        boxOutlineStyle: string,
        boxOutlineWidth: number,
        boxOutlineColor: string,
        
        buttonHeight: number,
        buttonWidth: number,
        buttonFontSize: number,
        buttonBackgroundColorActive: string,
        buttonContentColorActive: string,
        buttonBackgroundColorInactive: string,
        buttonContentColorInactive: string,
        buttonBackgroundColorHighlighted: string,
        buttonContentColorHighlighted: string,

        functionTitleTop: number,
        functionTitleLeft: number,
        functionTitleWidth: number,
        functionTitleFontSize: number,
        functionTitleFontColor: string,

        headerTop: number,
        headerWidth: number,
        headerFontSize: number,
        headerFontColor: string,

        firstValueTop: number,
        valueTopDistance: number,

        keyLeft: number,
        keyWidth: number,
        keyFontSize: number,
        keyFontColor: string,

        fieldLeft: number,
        fieldHeight: number,
        fieldWidth: number,
        fieldBackgroundColor: string,
        valueFontSize: number,
        valueFontColor: string,

        filtering: {
          filterButtonLeft: number,
          resetButtonLeft: number
        },

        sorting: {
          sortButtonLeft: number
        }
      },

      horseCatalog: {
        filtering: {
          boxTop: number,
          boxHeight: number,
          buttonTop: number
        },
        sorting: {
          boxTop: number,
          boxHeight: number,
          buttonTop: number
        }
      },

      adminHorses: {
        navigation: {
          boxTop: number,
          boxHeight: number
        },
        filtering: {
          boxTop: number,
          boxHeight: number,
          buttonTop: number
        },
        sorting: {
          boxTop: number,
          boxHeight: number,
          buttonTop: number
        }
      },

      adminProfile: {
        navigation: {
          boxTop: number,
          boxHeight: number
        }
      },

      adminProposals: {
        navigation: {
          boxTop: number,
          boxHeight: number
        }
      },

      adminUserList: {
        navigation: {
          boxTop: number,
          boxHeight: number
        },
        filtering: {
          boxTop: number,
          boxHeight: number,
          buttonTop: number
        },
        sorting: {
          boxTop: number,
          boxHeight: number,
          buttonTop: number
        }
      },

      adminWebData: {
        navigation: {
          boxTop: number,
          boxHeight: number
        }
      },

      userProfile: {
        navigation: {
          boxTop: number,
          boxHeight: number
        }
      },

      horseProfile: {
        navigation: {
          boxTop: number,
          boxHeight: number
        }
      }
    },

    mainContent: {
      boxTop: number,
      boxLeft: number,
      boxWidth: number,
      boxBackgroundColor: string,
      boxOutlineStyle: string,
      boxOutlineWidth: number,
      boxOutlineColor: string,

      base: {
        boxHeight: number
      },

      informationAndContact: {
        boxHeight: number
      },

      horseCatalogCollection: {
        listCollectionReferenceBoxHeight: number,
        gridCollectionReferenceBoxHeight: number,

        catalogListCollectionTop: number,
        catalogListCollectionLeft: number,
        catalogListCollectionNumberOfRows: number,
        catalogListCollectionNumberOfColumns: number,
        catalogListElementHeight: number,
        catalogListElementWidth: number,

        catalogListPaginationTop: number,
        catalogListPaginationLeft: number,
        catalogListPaginationWidth: number,
        catalogListPaginationFontSize: number,

        catalogGridCollectionTop: number,
        catalogGridCollectionLeft: number,
        catalogGridCollectionNumberOfRows: number,
        catalogGridCollectionNumberOfColumns: number,
        catalogGridElementHeight: number,
        catalogGridElementWidth: number,

        catalogGridPaginationTop: number,
        catalogGridPaginationLeft: number,
        catalogGridPaginationWidth: number,
        catalogGridPaginationFontSize: number,
      
        sumOfSelectedHorsesKeyTop: number,
        sumOfSelectedHorsesKeyLeft: number,
        sumOfSelectedHorsesKeyWidth: number,
        sumOfSelectedHorsesKeyFontSize: number,
        sumOfSelectedHorsesKeyColor: string,
        sumOfSelectedHorsesValueTop: number,
        sumOfSelectedHorsesValueLeft: number,
        sumOfSelectedHorsesValueWidth: number,
        sumOfSelectedHorsesValueFontSize: number,
        sumOfSelectedHorsesValueColor: string,
      
        collectionTypeSingleSelectKeyTop: number,
        collectionTypeSingleSelectKeyLeft: number,
        collectionTypeSingleSelectKeyWidth: number,
        collectionTypeSingleSelectKeyFontSize: number,
        collectionTypeSingleSelectKeyColor: string,
        collectionTypeSingleSelectFieldTop: number,
        collectionTypeSingleSelectFieldLeft: number,
        collectionTypeSingleSelectFieldHeight: number,
        collectionTypeSingleSelectFieldWidth: number,
        collectionTypeSingleSelectFieldBackgroundColor: string,
        collectionTypeSingleSelectValueFontSize: number,
        collectionTypeSingleSelectValueColor: string,

        listElement: {
          boxBackgroundColor: string,
          boxHeight: number,
          boxWidth: number,
          boxOutlineStyle: string,
          boxOutlineWidth: number,
          boxOutlineColor: string,

          imageBoxTop: number,
          imageBoxLeft: number,
          imageBoxBackgroundColor: string,
          imageBoxHeight: number,
          imageBoxWidth: number,
          imageBoxOutlineStyle: string,
          imageBoxOutlineWidth: number,
          imageBoxOutlineColor: string,

          imageTop: number,
          imageLeft: number,
          imageHeight: number,
          imageWidth: number,

          titleTop: number,
          titleLeft: number,
          titleWidth: number,
          titleFontSize: number,
          titleColor: string,

          keyLeft: number,
          keyWidth: number,
          keyFontSize: number,
          keyColor: string,

          valueLeft: number,
          valueWidth: number,
          valueFontSize: number,
          valueColor: string,

          firstDataTop: number,
          dataTopDistance: number,

          interestButtonTop: number,
          interestButtonLeft: number,
          interestButtonSize: number,

          categoryLeft: number,
          categoryWidth: number,
          categoryFontSize: number,
          categoryColor: string,

          firstCategoryTop: number,
          categoryTopDistance: number
        },

        gridElement: {
          boxBackgroundColor: string,
          boxHeight: number,
          boxWidth: number,
          boxOutlineStyle: string,
          boxOutlineWidth: number,
          boxOutlineColor: string,

          imageBoxTop: number,
          imageBoxLeft: number,
          imageBoxBackgroundColor: string,
          imageBoxHeight: number,
          imageBoxWidth: number,
          imageBoxOutlineStyle: string,
          imageBoxOutlineWidth: number,
          imageBoxOutlineColor: string,

          imageTop: number,
          imageLeft: number,
          imageHeight: number,
          imageWidth: number,

          titleTop: number,
          titleLeft: number,
          titleWidth: number,
          titleFontSize: number,
          titleColor: string,

          keyLeft: number,
          keyWidth: number,
          keyFontSize: number,
          keyColor: string,

          valueLeft: number,
          valueWidth: number,
          valueFontSize: number,
          valueColor: string,

          firstDataTop: number,
          dataTopDistance: number,
          connectedDataTopDistance: number,

          interestButtonTop: number,
          interestButtonLeft: number,
          interestButtonSize: number,

          categoryLeft: number,
          categoryWidth: number,
          categoryFontSize: number,
          categoryColor: string,

          firstCategoryTop: number,
          categoryTopDistance: number
        }
      },

      horseProfile: {
        boxHeight: number,

        horseTitle: {
          boxTop: number,
          boxLeft: number,
          boxHeight: number,
          boxWidth: number,
          boxBackgroundColor: string,
          boxOutlineStyle: string,
          boxOutlineWidth: number,
          boxOutlineColor: string,
        
          titleTop: number,
          titleLeft: number,
          titleWidth: number,
          titleFontSize: number,
          titleFontColor: string
        },

        horseImagesAndVideos: {
          boxTop: number,
          boxLeft: number,
          boxHeight: number,
          boxWidth: number,
          boxBackgroundColor: string,
          boxOutlineStyle: string,
          boxOutlineWidth: number,
          boxOutlineColor: string,

          imagesAndVideosTop: number,
          imagesAndVideosLeft: number,
          imagesAndVideosHeight: number,
          imagesAndVideosWidth: number,
          imagesAndVideosPaginationTop: number,
          imagesAndVideosPaginationLeft: number,
          imagesAndVideosPaginationWidth: number,
          imagesAndVideosPaginationFontSize: number,

          imageBoxTop: number,
          imageBoxLeft: number,
          imageBoxHeight: number,
          imageBoxWidth: number,
          imageBoxBackgroundColor: string,
          imageBoxOutlineStyle: string,
          imageBoxOutlineWidth: number,
          imageBoxOutlineColor: string
        },

        horseDataSheet: {
          boxTop: number,
          boxLeft: number,
          boxHeight: number,
          boxWidth: number,
          boxBackgroundColor: string,
          boxOutlineStyle: string,
          boxOutlineWidth: number,
          boxOutlineColor: string,

          firstDataTop: number,
          dataTopDistance: number,
          
          keyLeft: number,
          keyWidth: number,
          keyFontSize: number,
          keyFontColor: string,

          valueLeft: number,
          valueWidth: number,
          valueFontSize: number,
          valueFontColor: string
        },

        horseDescription: {
          boxTop: number,
          boxLeft: number,
          boxHeight: number,
          boxWidth: number,
          boxBackgroundColor: string,
          boxOutlineStyle: string,
          boxOutlineWidth: number,
          boxOutlineColor: string,

          descriptionTop: number,
          descriptionLeft: number,
          descriptionHeight: number,
          descriptionWidth: number,
          descriptionFontSize: number,
          descriptionFontColor: string
        },

        horseInterestPanel: {
          boxTop: number,
          boxLeft: number,
          boxHeight: number,
          boxWidth: number,
          boxBackgroundColor: string,
          boxOutlineStyle: string,
          boxOutlineWidth: number,
          boxOutlineColor: string,

          interestButtonTop: number,
          interestButtonLeft: number,
          interestButtonSize: number
        }
      },

      signUp: {
        boxHeight: number,

        firstFieldTop: number,
        topDistance: number,

        keyLeft: number,
        keyWidth: number,
        keyFontSize: number,
        keyColor: string,

        fieldLeft: number,
        fieldHeight: number,
        fieldWidth: number,
        fieldBackgroundColor: string,
        valueFontSize: number,
        valueColor: string,

        signUpButtonTop: number,
        signUpButtonLeft: number,
        signUpButtonHeight: number,
        signUpButtonWidth: number,
        signUpButtonBackgroundColorActive: string,
        signUpButtonFontSize: number,
        signUpButtonContentColorActive: string,

        errorMessageTop: number,
        errorMessageLeft: number,
        errorMessageHeight: number,
        errorMessageWidth: number,
        errorMessageFontSize: number,
        errorMessageColor: string
      },

      login: {
        boxHeight: number,

        firstFieldTop: number,
        topDistance: number,

        keyLeft: number,
        keyWidth: number,
        keyFontSize: number,
        keyColor: string,

        fieldLeft: number,
        fieldHeight: number,
        fieldWidth: number,
        fieldBackgroundColor: string,
        valueFontSize: number,
        valueColor: string,

        loginButtonTop: number,
        loginButtonLeft: number,
        loginButtonHeight: number,
        loginButtonWidth: number,
        loginButtonBackgroundColorActive: string,
        loginButtonFontSize: number,
        loginButtonContentColorActive: string,

        forgotPasswordButtonTop: number,
        forgotPasswordButtonLeft: number,
        forgotPasswordButtonHeight: number,
        forgotPasswordButtonWidth: number,
        forgotPasswordButtonBackgroundColorActive: string,
        forgotPasswordButtonFontSize: number,
        forgotPasswordButtonContentColorActive: string,

        errorMessageTop: number,
        errorMessageLeft: number,
        errorMessageHeight: number,
        errorMessageWidth: number,
        errorMessageFontSize: number,
        errorMessageColor: string
      },

      forgotPasswordRequest: {
        boxHeight: number,

        firstFieldTop: number,
        topDistance: number,

        keyLeft: number,
        keyWidth: number,
        keyFontSize: number,
        keyColor: string,

        fieldLeft: number,
        fieldHeight: number,
        fieldWidth: number,
        fieldBackgroundColor: string,
        valueFontSize: number,
        valueColor: string,

        forgotPasswordRequestButtonTop: number,
        forgotPasswordRequestButtonLeft: number,
        forgotPasswordRequestButtonHeight: number,
        forgotPasswordRequestButtonWidth: number,
        forgotPasswordRequestButtonBackgroundColorActive: string,
        forgotPasswordRequestButtonFontSize: number,
        forgotPasswordRequestButtonContentColorActive: string,

        errorMessageTop: number,
        errorMessageLeft: number,
        errorMessageHeight: number,
        errorMessageWidth: number,
        errorMessageFontSize: number,
        errorMessageColor: string
      },

      forgotPasswordReset: {
        boxHeight: number,

        firstFieldTop: number,
        topDistance: number,

        keyLeft: number,
        keyWidth: number,
        keyFontSize: number,
        keyColor: string,

        fieldLeft: number,
        fieldHeight: number,
        fieldWidth: number,
        fieldBackgroundColor: string,
        valueFontSize: number,
        valueColor: string,

        changePasswordButtonTop: number,
        changePasswordButtonLeft: number,
        changePasswordButtonHeight: number,
        changePasswordButtonWidth: number,
        changePasswordButtonBackgroundColorActive: string,
        changePasswordButtonFontSize: number,
        changePasswordButtonContentColorActive: string,

        errorMessageTop: number,
        errorMessageLeft: number,
        errorMessageHeight: number,
        errorMessageWidth: number,
        errorMessageFontSize: number,
        errorMessageColor: string
      },

      interestingHorseList: {
        boxHeight: number
      },

      requestedProposalList: {
        boxHeight: number
      },

      readUserData: {
        boxHeight: number,

        firstDataTop: number,
        dataTopDistance: number,

        keyLeft: number,
        keyWidth: number,
        keyFontSize: number,
        keyColor: string,

        valueLeft: number,
        valueWidth: number,
        valueFontSize: number,
        valueColor: string
      },

      editUserData: {
        boxHeight: number,

        firstInputTop: number,
        inputTopDistance: number,

        keyLeft: number,
        keyWidth: number,
        keyFontSize: number,
        keyColor: string,

        fieldLeft: number,
        fieldHeight: number,
        fieldWidth: number,
        fieldBackgroundColor: string,
        valueFontSize: number,
        valueColor: string,

        changingMessageLeft: number,
        changingMessageWidth: number,
        changingMessageFontSize: number,
        changingMessageColor: string,

        saveButtonTop: number,
        saveButtonLeft: number,
        saveButtonHeight: number,
        saveButtonWidth: number,
        saveButtonBackgroundColorActive: string,
        saveButtonBackgroundColorInactive: string,
        saveButtonFontSize: number,
        saveButtonContentColorActive: string,
        saveButtonContentColorInactive: string

        resetButtonTop: number,
        resetButtonLeft: number,
        resetButtonHeight: number,
        resetButtonWidth: number,
        resetButtonBackgroundColorActive: string,
        resetButtonBackgroundColorInactive: string,
        resetButtonFontSize: number,
        resetButtonContentColorActive: string,
        resetButtonContentColorInactive: string,

        errorMessageTop: number,
        errorMessageLeft: number,
        errorMessageHeight: number,
        errorMessageWidth: number,
        errorMessageFontSize: number,
        errorMessageColor: string,

        statusMessageTop: number,
        statusMessageLeft: number,
        statusMessageHeight: number,
        statusMessageWidth: number,
        statusMessageFontSize: number,
        statusMessageColor: string
      },

      changeUserEmail: {
        boxHeight: number,

        firstFieldTop: number,
        topDistance: number,

        keyLeft: number,
        keyWidth: number,
        keyFontSize: number,
        keyColor: string,

        fieldLeft: number,
        fieldHeight: number,
        fieldWidth: number,
        fieldBackgroundColor: string,
        valueFontSize: number,
        valueColor: string,

        changeEmailButtonTop: number,
        changeEmailButtonLeft: number,
        changeEmailButtonHeight: number,
        changeEmailButtonWidth: number,
        changeEmailButtonBackgroundColorActive: string,
        changeEmailButtonFontSize: number,
        changeEmailButtonContentColorActive: string,

        errorMessageTop: number,
        errorMessageLeft: number,
        errorMessageHeight: number,
        errorMessageWidth: number,
        errorMessageFontSize: number,
        errorMessageColor: string,

        statusMessageTop: number,
        statusMessageLeft: number,
        statusMessageHeight: number,
        statusMessageWidth: number,
        statusMessageFontSize: number,
        statusMessageColor: string
      },

      changeUserPassword: {
        boxHeight: number,

        firstFieldTop: number,
        topDistance: number,

        keyLeft: number,
        keyWidth: number,
        keyFontSize: number,
        keyColor: string,

        fieldLeft: number,
        fieldHeight: number,
        fieldWidth: number,
        fieldBackgroundColor: string,
        valueFontSize: number,
        valueColor: string,

        changePasswordButtonTop: number,
        changePasswordButtonLeft: number,
        changePasswordButtonHeight: number,
        changePasswordButtonWidth: number,
        changePasswordButtonBackgroundColorActive: string,
        changePasswordButtonFontSize: number,
        changePasswordButtonContentColorActive: string,

        errorMessageTop: number,
        errorMessageLeft: number,
        errorMessageHeight: number,
        errorMessageWidth: number,
        errorMessageFontSize: number,
        errorMessageColor: string,

        statusMessageTop: number,
        statusMessageLeft: number,
        statusMessageHeight: number,
        statusMessageWidth: number,
        statusMessageFontSize: number,
        statusMessageColor: string
      },

      adminHorseList: {
        listCollectionReferenceBoxHeight: number,

        listCollectionTop: number,
        listCollectionLeft: number,
        listCollectionNumberOfRows: number,
        listCollectionNumberOfColumns: number,
        listElementHeight: number,
        listElementWidth: number,

        listPaginationTop: number,
        listPaginationLeft: number,
        listPaginationWidth: number,
        listPaginationFontSize: number,
      
        sumOfSelectedHorsesKeyTop: number,
        sumOfSelectedHorsesKeyLeft: number,
        sumOfSelectedHorsesKeyWidth: number,
        sumOfSelectedHorsesKeyFontSize: number,
        sumOfSelectedHorsesKeyColor: string,
        sumOfSelectedHorsesValueTop: number,
        sumOfSelectedHorsesValueLeft: number,
        sumOfSelectedHorsesValueWidth: number,
        sumOfSelectedHorsesValueFontSize: number,
        sumOfSelectedHorsesValueColor: string,
      
        collectionTypeSingleSelectKeyTop: number,
        collectionTypeSingleSelectKeyLeft: number,
        collectionTypeSingleSelectKeyWidth: number,
        collectionTypeSingleSelectKeyFontSize: number,
        collectionTypeSingleSelectKeyColor: string,
        collectionTypeSingleSelectFieldTop: number,
        collectionTypeSingleSelectFieldLeft: number,
        collectionTypeSingleSelectFieldHeight: number,
        collectionTypeSingleSelectFieldWidth: number,
        collectionTypeSingleSelectFieldBackgroundColor: string,
        collectionTypeSingleSelectValueFontSize: number,
        collectionTypeSingleSelectValueColor: string,

        listElement: {
          boxHeight: number,
          boxWidth: number,
          boxBackgroundColor: string,
          boxOutlineStyle: string,
          boxOutlineWidth: number,
          boxOutlineColor: string,

          imageBoxTop: number,
          imageBoxLeft: number,
          imageBoxBackgroundColor: string,
          imageBoxHeight: number,
          imageBoxWidth: number,
          imageBoxOutlineStyle: string,
          imageBoxOutlineWidth: number,
          imageBoxOutlineColor: string,

          imageTop: number,
          imageLeft: number,
          imageHeight: number,
          imageWidth: number,

          titleTop: number,
          titleLeft: number,
          titleWidth: number,
          titleFontSize: number,
          titleColor: string,

          keyLeft: number,
          keyWidth: number,
          keyFontSize: number,
          keyColor: string,

          valueLeft: number,
          valueWidth: number,
          valueFontSize: number,
          valueColor: string,

          firstDataTop: number,
          dataTopDistance: number,

          interestButtonTop: number,
          interestButtonLeft: number,
          interestButtonSize: number,

          categoryLeft: number,
          categoryWidth: number,
          categoryFontSize: number,
          categoryColor: string,

          firstCategoryTop: number,
          categoryTopDistance: number,

          firstActionButtonTop: number,
          actionButtonTopDistance: number,

          actionButtonLeft: number,
          actionButtonHeight: number,
          actionButtonWidth: number,
          actionButtonFontSize: number,
          actionButtonBackgroundColorActive: string,
          actionButtonContentColorActive: string,
          actionButtonBackgroundColorInactive: string,
          actionButtonContentColorInactive: string,
          actionButtonBackgroundColorHighlighted: string,
          actionButtonContentColorHighlighted: string,

          onwardYesButtonTop: number,
          onwardYesButtonLeft: number,
          onwardNoButtonTop: number,
          onwardNoButtonLeft: number,

          onwardButtonHeight: number,
          onwardButtonWidth: number,
          onwardButtonFontSize: number,
          onwardButtonBackgroundColorActive: string,
          onwardButtonContentColorActive: string,
          onwardButtonBackgroundColorInactive: string,
          onwardButtonContentColorInactive: string,
          onwardButtonBackgroundColorHighlighted: string,
          onwardButtonContentColorHighlighted: string,

          onwardMessageTop: number,
          onwardMessageLeft: number,
          onwardMessageHeight: number,
          onwardMessageWidth: number,
          onwardMessageFontSize: number,
          onwardMessageContentColor: string
        }
      },

      adminCreateHorse: {
        boxHeight: number,

        dataForm: {
          boxTop: number,
          boxLeft: number,
          boxHeight: number,
          boxWidth: number,
          boxBackgroundColor: string,
          boxOutlineStyle: string,
          boxOutlineWidth: number,
          boxOutlineColor: string,

          firstInputTop: number,
          inputTopDistance: number,

          keyLeft: number,
          keyWidth: number,
          keyFontSize: number,
          keyColor: string,

          fieldLeft: number,
          fieldHeight: number,
          fieldWidth: number,
          fieldBackgroundColor: string,
          valueFontSize: number,
          valueColor: string,

          descriptionFieldHeight: number,

          errorMessageLeft: number,
          errorMessageWidth: number,
          errorMessageFontSize: number,
          errorMessageColor: string,
        },

        uploader: {
          boxTop: number
        },

        imageUploader: {
          boxLeft: number
        },

        videoUploader: {
          boxLeft: number,
          boxMinimumHeight: number,
          boxWidth: number,
          boxBackgroundColor: string,
          boxOutlineStyle: string,
          boxOutlineWidth: number,
          boxOutlineColor: string,
        },

        documentUploader: {
          boxLeft: number,
          boxMinimumHeight: number,
          boxWidth: number,
          boxBackgroundColor: string,
          boxOutlineStyle: string,
          boxOutlineWidth: number,
          boxOutlineColor: string,
        },

        imageAndVideoUploader: {
          boxTop: number,
          boxLeft: number
        },

        submitButton: {
          padding: number,
          buttonTop: number,
          buttonLeft: number,
          buttonHeight: number,
          buttonWidth: number,
          buttonFontSize: number,
          buttonBackgroundColorActive: string,
          buttonContentColorActive: string,
          buttonBackgroundColorInactive: string,
          buttonContentColorInactive: string,
          buttonBackgroundColorHighlighted: string,
          buttonContentColorHighlighted: string,
        },

        mainMessageTop: number,
        mainMessageLeft: number,
        mainMessageHeight: number,
        mainMessageWidth: number,
        mainMessageFontSize: number,

        errorMessageColor: string,
        statusMessageColor: string
      },

      adminEditHorse: {
        boxHeight: number,

        // dataForm: {
        //   boxTop: number,
        //   boxLeft: number,
        //   boxHeight: number,
        //   boxWidth: number,
        //   boxBackgroundColor: string,
        //   boxOutlineStyle: string,
        //   boxOutlineWidth: number,
        //   boxOutlineColor: string,

        //   firstInputTop: number,
        //   inputTopDistance: number,

        //   keyLeft: number,
        //   keyWidth: number,
        //   keyFontSize: number,
        //   keyColor: string,

        //   fieldLeft: number,
        //   fieldHeight: number,
        //   fieldWidth: number,
        //   fieldBackgroundColor: string,
        //   valueFontSize: number,
        //   valueColor: string,

        //   descriptionFieldHeight: number,

        //   errorMessageLeft: number,
        //   errorMessageWidth: number,
        //   errorMessageFontSize: number,
        //   errorMessageColor: string,
        // },

        uploader: {
          boxTop: number
        },

        imageUploader: {
          boxLeft: number
        },

        videoUploader: {
          boxLeft: number,
          boxMinimumHeight: number,
          boxWidth: number,
          boxBackgroundColor: string,
          boxOutlineStyle: string,
          boxOutlineWidth: number,
          boxOutlineColor: string,
        },

        documentUploader: {
          boxLeft: number,
          boxMinimumHeight: number,
          boxWidth: number,
          boxBackgroundColor: string,
          boxOutlineStyle: string,
          boxOutlineWidth: number,
          boxOutlineColor: string,
        },

        imageAndVideoUploader: {
          boxTop: number,
          boxLeft: number
        },

        submitButton: {
          padding: number,
          buttonTop: number,
          buttonLeft: number,
          buttonHeight: number,
          buttonWidth: number,
          buttonFontSize: number,
          buttonBackgroundColorActive: string,
          buttonContentColorActive: string,
          buttonBackgroundColorInactive: string,
          buttonContentColorInactive: string,
          buttonBackgroundColorHighlighted: string,
          buttonContentColorHighlighted: string,
        },

        mainMessageTop: number,
        mainMessageLeft: number,
        mainMessageHeight: number,
        mainMessageWidth: number,
        mainMessageFontSize: number,

        errorMessageColor: string,
        statusMessageColor: string
      },

      adminUserList: {
        boxHeight: number,

        userListCollectionTop: number,
        userListCollectionLeft: number,
        userListCollectionNumberOfRows: number,
        userListCollectionNumberOfColumns: number,
        userListElementHeight: number,
        userListElementWidth: number,

        userListPaginationTop: number,
        userListPaginationLeft: number,
        userListPaginationWidth: number,
        userListPaginationFontSize: number,

        sumOfSelectedUsersKeyTop: number,
        sumOfSelectedUsersKeyLeft: number,
        sumOfSelectedUsersKeyWidth: number,
        sumOfSelectedUsersKeyFontSize: number,
        sumOfSelectedUsersKeyColor: string,
        sumOfSelectedUsersValueTop: number,
        sumOfSelectedUsersValueLeft: number,
        sumOfSelectedUsersValueWidth: number,
        sumOfSelectedUsersValueFontSize: number,
        sumOfSelectedUsersValueColor: string,

        listElement: {
          boxTop: number,
          boxLeft: number,
          boxHeight: number,
          boxWidth: number,
          boxBackgroundColor: string,
          boxOutlineStyle: string,
          boxOutlineWidth: number,
          boxOutlineColor: string,

          keyLeft: number,
          keyWidth: number,
          keyFontSize: number,
          keyColor: string,

          valueLeft: number,
          valueWidth: number,
          valueFontSize: number,
          valueColor: string,

          firstDataTop: number,
          dataTopDistance: number

          firstActionButtonTop: number,
          actionButtonTopDistance: number,

          actionButtonHeight: number,
          actionButtonLeft: number,
          actionButtonWidth: number,
          actionButtonFontSize: number,
          actionButtonBackgroundColorActive: string,
          actionButtonContentColorActive: string,
          actionButtonBackgroundColorInactive: string,
          actionButtonContentColorInactive: string,
          actionButtonBackgroundColorHighlighted: string,
          actionButtonContentColorHighlighted: string,

          onwardYesButtonTop: number,
          onwardYesButtonLeft: number,
          onwardNoButtonTop: number,
          onwardNoButtonLeft: number,

          onwardButtonHeight: number,
          onwardButtonWidth: number,
          onwardButtonFontSize: number,
          onwardButtonBackgroundColorActive: string,
          onwardButtonContentColorActive: string,
          onwardButtonBackgroundColorInactive: string,
          onwardButtonContentColorInactive: string,
          onwardButtonBackgroundColorHighlighted: string,
          onwardButtonContentColorHighlighted: string,

          onwardMessageTop: number,
          onwardMessageLeft: number,
          onwardMessageHeight: number,
          onwardMessageWidth: number,
          onwardMessageFontSize: number,
          onwardMessageContentColor: string
        }
      },

      adminReadUserData: {
        boxHeight: number,

        firstDataTop: number,
        dataTopDistance: number,

        keyLeft: number,
        keyWidth: number,
        keyFontSize: number,
        keyColor: string,

        valueLeft: number,
        valueWidth: number,
        valueFontSize: number,
        valueColor: string
      },

      adminEditUserData: {
        boxHeight: number,

        firstInputTop: number,
        inputTopDistance: number,

        keyLeft: number,
        keyWidth: number,
        keyFontSize: number,
        keyColor: string,

        fieldLeft: number,
        fieldHeight: number,
        fieldWidth: number,
        fieldBackgroundColor: string,
        valueFontSize: number,
        valueColor: string,

        changingMessageLeft: number,
        changingMessageWidth: number,
        changingMessageFontSize: number,
        changingMessageColor: string,

        saveButtonTop: number,
        saveButtonLeft: number,
        saveButtonHeight: number,
        saveButtonWidth: number,
        saveButtonBackgroundColorActive: string,
        saveButtonBackgroundColorInactive: string,
        saveButtonFontSize: number,
        saveButtonContentColorActive: string,
        saveButtonContentColorInactive: string

        resetButtonTop: number,
        resetButtonLeft: number,
        resetButtonHeight: number,
        resetButtonWidth: number,
        resetButtonBackgroundColorActive: string,
        resetButtonBackgroundColorInactive: string,
        resetButtonFontSize: number,
        resetButtonContentColorActive: string,
        resetButtonContentColorInactive: string,

        errorMessageTop: number,
        errorMessageLeft: number,
        errorMessageHeight: number,
        errorMessageWidth: number,
        errorMessageFontSize: number,
        errorMessageColor: string,

        statusMessageTop: number,
        statusMessageLeft: number,
        statusMessageHeight: number,
        statusMessageWidth: number,
        statusMessageFontSize: number,
        statusMessageColor: string
      },

      adminInviteUser: {
        boxHeight: number,

        firstInputTop: number,
        inputTopDistance: number,

        keyLeft: number,
        keyWidth: number,
        keyFontSize: number,
        keyColor: string,

        fieldLeft: number,
        fieldHeight: number,
        fieldWidth: number,
        fieldBackgroundColor: string,
        valueFontSize: number,
        valueColor: string,

        inviteButtonTop: number,
        inviteButtonLeft: number,
        inviteButtonHeight: number,
        inviteButtonWidth: number,
        inviteButtonBackgroundColorActive: string,
        inviteButtonBackgroundColorInactive: string,
        inviteButtonFontSize: number,
        inviteButtonContentColorActive: string,
        inviteButtonContentColorInactive: string

        errorMessageTop: number,
        errorMessageLeft: number,
        errorMessageHeight: number,
        errorMessageWidth: number,
        errorMessageFontSize: number,
        errorMessageColor: string
      },

      adminReadProposal: {
        boxHeight: number
      },

      adminEditProposal: {
        boxHeight: number
      },

      readWebData: {
        boxHeight: number
      },

      editWebData: {
        boxHeight: number
      },

      readStatistics: {
        boxHeight: number
      },

      readAdminData: {
        boxHeight: number
      },

      editAdminData: {
        boxHeight: number
      },

      changeAdminEmail: {
        boxHeight: number
      },

      changeAdminPassword: {
        boxHeight: number
      },
    }
  },
  
  contentBoxMinimumHeight: number,

  footer: {
    textFontSize: number,
    textFontColor: string,
    boxLeft: number,
    boxHeight: number,
    boxWidth: number,
    boxBackgroundColor: string,
    boxOutlineStyle: string,
    boxOutlineWidth: number,
    boxOutlineColor: string
  },

  baseComponent: {
    imageUploader: {
      boxMinimumHeight: number,
      boxWidth: number,
      boxBackgroundColor: string,
      boxOutlineStyle: string,
      boxOutlineWidth: number,
      boxOutlineColor: string,

      functionTitleTop: number,
      functionTitleLeft: number,
      functionTitleWidth: number,
      functionTitleFontSize: number,
      functionTitleColor: string,

      fileUploadTop: number,
      fileUploadLeft: number,

      imageUploaderElementsContainerTop: number,
      imageUploaderElementsContainerLeft: number,
      imageUploaderElementsContainerMinimumHeight: number,

      imageUploaderElement: {
        boxHeight: number,
        boxWidth: number,
        boxBackgroundColor: string,
        boxOutlineStyle: string,
        boxOutlineWidth: number,
        boxOutlineColor: string,

        imageTop: number,
        imageLeft: number,
        imageHeight: number,
        imageWidth: number,

        firstInputTop: number,
        inputTopDistance: number,

        keyLeft: number,
        keyWidth: number,
        keyFontSize: number,
        keyColor: string,

        valueAndFieldLeft: number,
        fieldHeight: number,
        valueAndFieldWidth: number,
        fieldBackgroundColor: string,
        valueFontSize: number,
        valueColor: string,

        extensionLeft: number,
        extensionWidth: number,

        removeButtonTop: number,
        removeButtonLeft: number,
        removeButtonHeight: number,
        removeButtonWidth: number,
        removeButtonFontSize: number,
        removeButtonBackgroundColorActive: string,
        removeButtonContentColorActive: string
      },

      errorMessagePadding: number,
      errorMessageTop: number,
      errorMessageLeft: number,
      errorMessageWidth: number,
      errorMessageFontSize: number,
      errorMessageColor: string
    },

    videoUploader: {
      boxMinimumHeight: number,
      boxWidth: number,
      boxBackgroundColor: string,
      boxOutlineStyle: string,
      boxOutlineWidth: number,
      boxOutlineColor: string,

      functionTitleTop: number,
      functionTitleLeft: number,
      functionTitleWidth: number,
      functionTitleFontSize: number,
      functionTitleColor: string,

      keyValueInputTop: number,
      keyLeft: number,
      keyWidth: number,
      keyFontSize: number,
      keyColor: string,
      fieldLeft: number,
      fieldHeight: number,
      fieldWidth: number,
      fieldBackgroundColor: string,
      valueFontSize: number,
      valueColor: string,

      uploadButtonTop: number,
      uploadButtonLeft: number,
      uploadButtonHeight: number,
      uploadButtonWidth: number,
      uploadButtonBackgroundColorActive: string,
      uploadButtonFontSize: number,
      uploadButtonContentColorActive: string,

      videoUploaderElementsContainerTop: number,
      videoUploaderElementsContainerLeft: number,
      videoUploaderElementsContainerMinimumHeight: number,

      videoUploaderElement: {
        boxHeight: number,
        boxWidth: number,
        boxBackgroundColor: string,
        boxOutlineStyle: string,
        boxOutlineWidth: number,
        boxOutlineColor: string,

        videoTop: number,
        videoLeft: number,
        videoHeight: number,
        videoWidth: number,

        firstInputTop: number,
        inputTopDistance: number,

        keyLeft: number,
        keyWidth: number,
        keyFontSize: number,
        keyColor: string,

        valueAndFieldLeft: number,
        fieldHeight: number,
        valueAndFieldWidth: number,
        fieldBackgroundColor: string,
        valueFontSize: number,
        valueColor: string,

        removeButtonTop: number,
        removeButtonLeft: number,
        removeButtonHeight: number,
        removeButtonWidth: number,
        removeButtonFontSize: number,
        removeButtonBackgroundColorActive: string,
        removeButtonContentColorActive: string,
      },

      errorMessagePadding: number,
      errorMessageTop: number,
      errorMessageLeft: number,
      errorMessageWidth: number,
      errorMessageFontSize: number,
      errorMessageColor: string
    },

    imageAndVideoUploader : {
      boxMinimumHeight: number,
      boxWidth: number,
      boxBackgroundColor: string,
      boxOutlineStyle: string,
      boxOutlineWidth: number,
      boxOutlineColor: string,

      functionTitleTop: number,
      functionTitleLeft: number,
      functionTitleWidth: number,
      functionTitleFontSize: number,
      functionTitleColor: string,

      imageAndVideoInput: {
        boxTop: number,
        boxLeft: number,
        boxHeight: number,
        boxWidth: number,
        boxBackgroundColor: string,
        boxOutlineStyle: string,
        boxOutlineWidth: number,
        boxOutlineColor: string,
        
        modeButtonBackgroundColorActive: string,
        modeButtonContentColorActive: string,
        modeButtonBackgroundColorInactive: string,
        modeButtonContentColorInactive: string,

        imageModeButtonTop: number,
        imageModeButtonLeft: number,
        imageModeButtonHeight: number,
        imageModeButtonWidth: number,
        imageModeButtonFontSize: number,

        videoModeButtonTop: number,
        videoModeButtonLeft: number,
        videoModeButtonHeight: number,
        videoModeButtonWidth: number,
        videoModeButtonFontSize: number,

        fileUploadTop: number,
        fileUploadLeft: number,

        keyValueInputTop: number,
        keyLeft: number,
        keyWidth: number,
        keyFontSize: number,
        keyColor: string,
        fieldLeft: number,
        fieldHeight: number,
        fieldWidth: number,
        fieldBackgroundColor: string,
        valueFontSize: number,
        valueColor: string,
  
        videoUploadButtonTop: number,
        videoUploadButtonLeft: number,
        videoUploadButtonHeight: number,
        videoUploadButtonWidth: number,
        videoUploadButtonBackgroundColorActive: string,
        videoUploadButtonFontSize: number,
        videoUploadButtonContentColorActive: string
      },

      uploadedImageAndVideoElementsContainerTop: number,
      uploadedImageAndVideoElementsContainerLeft: number,
      uploadedImageAndVideoElementsContainerMinimumHeight: number,
      uploadedImageAndVideoElementsContainerWidth: number,
      uploadedImageAndVideoElementsContainerBackgroundColor: string,
      uploadedImageAndVideoElementsContainerOutlineStyle: string,
      uploadedImageAndVideoElementsContainerOutlineWidth: number,
      uploadedImageAndVideoElementsContainerOutlineColor: string,

      uploadedImageAndVideoElement: {
        boxHeight: number,
        boxWidth: number,
        boxBackgroundColor: string,
        boxOutlineStyle: string,
        boxOutlineWidth: number,
        boxOutlineColor: string,

        imageOrVideoTop: number,
        imageOrVideoLeft: number,
        imageOrVideoHeight: number,
        imageOrVideoWidth: number,

        firstInputTop: number,
        inputTopDistance: number,

        keyLeft: number,
        keyWidth: number,
        keyFontSize: number,
        keyColor: string,

        valueAndFieldLeft: number,
        fieldHeight: number,
        valueAndFieldWidth: number,
        fieldBackgroundColor: string,
        valueFontSize: number,
        valueColor: string,

        extensionLeft: number,
        extensionWidth: number,

        removeButtonTop: number,
        removeButtonLeft: number,
        removeButtonHeight: number,
        removeButtonWidth: number,
        removeButtonFontSize: number,
        removeButtonBackgroundColorActive: string,
        removeButtonContentColorActive: string
      },

      errorMessagePadding: number,
      errorMessageTop: number,
      errorMessageLeft: number,
      errorMessageWidth: number,
      errorMessageFontSize: number,
      errorMessageColor: string
    }
  },

  sidebarBoxTop: number,
  sidebarBoxLeft: number,
  sidebarBoxWidth: number,
  sidebarBoxBackgroundColor: string,
  sidebarBoxOutlineStyle: string,
  sidebarBoxOutlineWidth: number,
  sidebarBoxOutlineColor: string,
  sidebarButtonTop: number,
  sidebarButtonTopDistance: number,
  sidebarButtonLeft: number,
  sidebarButtonHeight: number,
  sidebarButtonWidth: number,

  mainContentBoxTop: number,
  mainContentBoxLeft: number,
  mainContentBoxWidth: number,
  mainContentBoxBackgroundColor: string,
  mainContentBoxOutlineStyle: string,
  mainContentBoxOutlineWidth: number,
  mainContentBoxOutlineColor: string,
  
  filteringBoxTop: number,
  filteringBoxHeight: number,
  sortingBoxTop: number,
  sortingBoxHeight: number,
  sidebarFunctionTitleTop: number,
  sidebarFunctionTitleLeft: number,
  sidebarFunctionTitleWidth: number,
  sidebarFunctionTitleFontSize: number,
  sidebarFunctionTitleFontColor: string,
  sidebarHeaderTop: number,
  sidebarHeaderWidth: number,
  sidebarHeaderFontSize: number,
  sidebarHeaderFontColor: string,
  sidebarFirstValueTop: number,
  sidebarValueTopDistance: number,
  sidebarKeyLeft: number,
  sidebarKeyWidth: number,
  sidebarKeyFontSize: number,
  sidebarKeyFontColor: string,
  sidebarValueLeft: number,
  sidebarFieldHeight: number,
  sidebarValueWidth: number,
  sidebarFieldBackgroundColor: string,
  sidebarValueFontSize: number,
  sidebarValueFontColor: string
}

export function getWebpageStyle(styleName: string): WebpageStyleProps {
  let styleResult: WebpageStyleProps = {
    titleFontSize: 18,
    titleFontColor: "black",
    textFontSize: 12,
    textFontColor: "black",
    // fontType: string, // ?
    fieldBackgroundColor: "white",
    fieldValueFontSize: 12,
    fieldValueFontColor: "black",
    backgroundColor: "white",
    boxBackgroundColor: "lightblue",
    boxOutlineStyle: "solid",
    boxOutlineWidth: 2,
    boxOutlineColor: "grey",
    buttonFontSize: 12,
    buttonBackgroundColorActive: "grey",
    buttonContentColorActive: "black",
    buttonBackgroundColorInactive: "lightgrey",
    buttonContentColorInactive: "black",
    buttonBackgroundColorHighlighted: "darkgrey",
    buttonContentColorHighlighted: "black",
    webpageMargin: 25,

    general: {
      titleFontSize: 18,
      titleFontColor: "black",
      headerFontSize: 14,
      headerFontColor: "black",
      textFontSize: 12,
      textFontColor: "black",
      // fontType: string, // ?
      fieldBackgroundColor: "white",
      fieldValueFontSize: 12,
      fieldValueFontColor: "black",
      backgroundColor: "white",
      boxBackgroundColor: "lightblue",
      boxOutlineStyle: "solid",
      boxOutlineWidth: 2,
      boxOutlineColor: "grey",
      buttonFontSize: 12,
      buttonBackgroundColorActive: "grey",
      buttonContentColorActive: "black",
      buttonBackgroundColorInactive: "lightgrey",
      buttonContentColorInactive: "black",
      buttonBackgroundColorHighlighted: "darkgrey",
      buttonContentColorHighlighted: "black",
      webpageMargin: 25,
    },
  
    logo: {
      boxTop: 0,
      boxLeft: 0,
      boxHeight: 300,
      boxWidth: 300,
      boxBackgroundColor: "lightblue",
      boxOutlineStyle: "solid",
      boxOutlineWidth: 2,
      boxOutlineColor: "grey",
      imageTop: 25,
      imageLeft: 25,
      imageHeight: 250,
      imageWidth: 250
    },
  
    header: {
      textFontSize: 12,
      textFontColor: "black",
      boxTop: 0,
      boxLeft: 300,
      boxHeight: 100,
      boxWidth: 900,
      boxBackgroundColor: "lightblue",
      boxOutlineStyle: "solid",
      boxOutlineWidth: 2,
      boxOutlineColor: "grey",
      displayTop: 42,
      displayLeft: 330,
      displayWidth: 240,
      buttonHeight: 40,
      buttonWidth: 240,
      buttonFontSize: 12,
      buttonBackgroundColorActive: "grey",
      buttonContentColorActive: "black",
      buttonBackgroundColorInactive: "lightgrey",
      buttonContentColorInactive: "black",
      buttonBackgroundColorHighlighted: "darkgrey",
      buttonContentColorHighlighted: "black",

      positionA: {
        top: 30,
        left: 30
      },
      positionB: {
        top: 30,
        left: 330
      },
      positionC: {
        top: 30,
        left: 630
      }
    },
  
    navigation: {
      boxTop: 100,
      boxLeft: 300,
      boxHeight: 200,
      boxWidth: 900,
      boxBackgroundColor: "lightblue",
      boxOutlineStyle: "solid",
      boxOutlineWidth: 2,
      boxOutlineColor: "grey",
      buttonHeight: 40,
      buttonWidth: 240,
      buttonFontSize: 12,
      buttonBackgroundColorActive: "grey",
      buttonContentColorActive: "black",
      buttonBackgroundColorInactive: "lightgrey",
      buttonContentColorInactive: "black",
      buttonBackgroundColorHighlighted: "darkgrey",
      buttonContentColorHighlighted: "black",

      positionA: {
        top: 30,
        left: 30
      },
      positionB: {
        top: 30,
        left: 330
      },
      positionC: {
        top: 30,
        left: 630
      },
      positionD: {
        top: 130,
        left: 30
      },
      positionE: {
        top: 130,
        left: 330
      },
      positionF: {
        top: 130,
        left: 630
      }
    },
  
    content: {
      boxTop: 300,
      boxLeft: 0,
      boxMinimumHeight: 500,
      boxWidth: 1200,
      boxBackgroundColor: "lightblue",
      boxOutlineStyle: "solid",
      boxOutlineWidth: 2,
      boxOutlineColor: "grey",

      sidebar: {
        navigation: {
          boxLeft: 0,
          boxWidth: 300,
          boxBackgroundColor: "lightblue",
          boxOutlineStyle: "solid",
          boxOutlineWidth: 2,
          boxOutlineColor: "grey",

          firstButtonTop: 50,
          buttonTopDistance: 60,

          buttonLeft: 50,
          buttonHeight: 40,
          buttonWidth: 200,
          buttonFontSize: 12,
          buttonBackgroundColorActive: "grey",
          buttonContentColorActive: "black",
          buttonBackgroundColorInactive: "lightgrey",
          buttonContentColorInactive: "black",
          buttonBackgroundColorHighlighted: "darkgrey",
          buttonContentColorHighlighted: "black"
        },

        filteringAndSorting: {
          boxLeft: 0,
          boxWidth: 300,
          boxBackgroundColor: "lightblue",
          boxOutlineStyle: "solid",
          boxOutlineWidth: 2,
          boxOutlineColor: "grey",
          
          buttonHeight: 25,
          buttonWidth: 50,
          buttonFontSize: 12,
          buttonBackgroundColorActive: "grey",
          buttonContentColorActive: "black",
          buttonBackgroundColorInactive: "lightgrey",
          buttonContentColorInactive: "black",
          buttonBackgroundColorHighlighted: "darkgrey",
          buttonContentColorHighlighted: "black",
        
          functionTitleTop: 25,
          functionTitleLeft: 25,
          functionTitleWidth: 250,
          functionTitleFontSize: 18,
          functionTitleFontColor: "black",

          headerTop: 75,
          headerWidth: 150,
          headerFontSize: 14,
          headerFontColor: "black",

          firstValueTop: 125,
          valueTopDistance: 40,

          keyLeft: 25,
          keyWidth: 150,
          keyFontSize: 12,
          keyFontColor: "black",
          
          fieldLeft: 150,
          fieldHeight: 25,
          fieldWidth: 125,
          fieldBackgroundColor: "white",
          valueFontSize: 12,
          valueFontColor: "black",

          filtering: {
            filterButtonLeft: 50,
            resetButtonLeft: 150
          },

          sorting: {
            sortButtonLeft: 50
          }
        },

        horseCatalog: {
          filtering: {
            boxTop: 100,
            boxHeight: 600,
            buttonTop: 525
          },
          sorting: {
            boxTop: 750,
            boxHeight: 275,
            buttonTop: 200
          }
        },

        adminHorses: {
          navigation: {
            boxTop: 0,
            boxHeight: 200
          },
          filtering: {
            boxTop: 250,
            boxHeight: 600,
            buttonTop: 525
          },
          sorting: {
            boxTop: 900,
            boxHeight: 275,
            buttonTop: 200
          }
        },
  
        adminProfile: {
          navigation: {
            boxTop: 0,
            boxHeight: 700
          }
        },
  
        adminProposals: {
          navigation: {
            boxTop: 0,
            boxHeight: 700
          }
        },
  
        adminUserList: {
          navigation: {
            boxTop: 0,
            boxHeight: 200
          },
          filtering: {
            boxTop: 250,
            boxHeight: 350,
            buttonTop: 275
          },
          sorting: {
            boxTop: 650,
            boxHeight: 275,
            buttonTop: 200
          }
        },
  
        adminWebData: {
          navigation: {
            boxTop: 0,
            boxHeight: 700
          }
        },
  
        userProfile: {
          navigation: {
            boxTop: 0,
            boxHeight: 700
          }
        },

        horseProfile: {
          navigation: {
            boxTop: 0,
            boxHeight: 200
          }
        }
      },

      mainContent: {
        boxTop: 0,
        boxLeft: 300,
        boxWidth: 900,
        boxBackgroundColor: "lightblue",
        boxOutlineStyle: "solid",
        boxOutlineWidth: 2,
        boxOutlineColor: "grey",
  
        base: {
          boxHeight: 700
        },
  
        informationAndContact: {
          boxHeight: 700
        },

        horseCatalogCollection: {
          listCollectionReferenceBoxHeight: 1800,
          gridCollectionReferenceBoxHeight: 1500,

          catalogListCollectionTop: 100,
          catalogListCollectionLeft: 25,
          catalogListCollectionNumberOfRows: 5,
          catalogListCollectionNumberOfColumns: 1,
          catalogListElementHeight: 300,
          catalogListElementWidth: 850,

          catalogListPaginationTop: 1525,
          catalogListPaginationLeft: 0,
          catalogListPaginationWidth: 850,
          catalogListPaginationFontSize: 20,

          catalogGridCollectionTop: 100,
          catalogGridCollectionLeft: 25,
          catalogGridCollectionNumberOfRows: 3,
          catalogGridCollectionNumberOfColumns: 3,
          catalogGridElementHeight: 400,
          catalogGridElementWidth: 250,

          catalogGridPaginationTop: 1225,
          catalogGridPaginationLeft: 0,
          catalogGridPaginationWidth: 750,
          catalogGridPaginationFontSize: 20,

          sumOfSelectedHorsesKeyTop: 50,
          sumOfSelectedHorsesKeyLeft: 100,
          sumOfSelectedHorsesKeyWidth: 150,
          sumOfSelectedHorsesKeyFontSize: 12,
          sumOfSelectedHorsesKeyColor: "black",
          sumOfSelectedHorsesValueTop: 50,
          sumOfSelectedHorsesValueLeft: 250,
          sumOfSelectedHorsesValueWidth: 50,
          sumOfSelectedHorsesValueFontSize: 12,
          sumOfSelectedHorsesValueColor: "black",

          collectionTypeSingleSelectKeyTop: 50,
          collectionTypeSingleSelectKeyLeft: 300,
          collectionTypeSingleSelectKeyWidth: 100,
          collectionTypeSingleSelectKeyFontSize: 12,
          collectionTypeSingleSelectKeyColor: "black",
          collectionTypeSingleSelectFieldTop: 50,
          collectionTypeSingleSelectFieldLeft: 400,
          collectionTypeSingleSelectFieldHeight: 25,
          collectionTypeSingleSelectFieldWidth: 50,
          collectionTypeSingleSelectFieldBackgroundColor: "white",
          collectionTypeSingleSelectValueFontSize: 12,
          collectionTypeSingleSelectValueColor: "black",

          listElement: {
            boxBackgroundColor: "lightBlue",
            boxHeight: 250,
            boxWidth: 800,
            boxOutlineStyle: "solid",
            boxOutlineWidth: 2,
            boxOutlineColor: "grey",
  
            imageBoxTop: 25,
            imageBoxLeft: 25,
            imageBoxBackgroundColor: "grey",
            imageBoxHeight: 200,
            imageBoxWidth: 200,
            imageBoxOutlineStyle: "solid",
            imageBoxOutlineWidth: 1,
            imageBoxOutlineColor: "black",
  
            imageTop: 25,
            imageLeft: 25,
            imageHeight: 200,
            imageWidth: 200,
  
            titleTop: 25,
            titleLeft: 250,
            titleWidth: 150,
            titleFontSize: 20,
            titleColor: "black",
  
            keyLeft: 250,
            keyWidth: 100,
            keyFontSize: 12,
            keyColor: "black",
  
            valueLeft: 350,
            valueWidth: 150,
            valueFontSize: 12,
            valueColor: "black",
  
            firstDataTop: 75,
            dataTopDistance: 25,
  
            interestButtonTop: 25,
            interestButtonLeft: 650,
            interestButtonSize: 25,
  
            categoryLeft: 625,
            categoryWidth: 100,
            categoryFontSize: 12,
            categoryColor: "black",
  
            firstCategoryTop: 75,
            categoryTopDistance: 25
          },
  
          gridElement: {
            boxBackgroundColor: "lightBlue",
            boxHeight: 350,
            boxWidth: 200,
            boxOutlineStyle: "solid",
            boxOutlineWidth: 2,
            boxOutlineColor: "grey",
  
            imageBoxTop: 50,
            imageBoxLeft: 25,
            imageBoxBackgroundColor: "grey",
            imageBoxHeight: 150,
            imageBoxWidth: 150,
            imageBoxOutlineStyle: "solid",
            imageBoxOutlineWidth: 1,
            imageBoxOutlineColor: "black",
  
            imageTop: 50,
            imageLeft: 25,
            imageHeight: 150,
            imageWidth: 150,
  
            titleTop: 10,
            titleLeft: 25,
            titleWidth: 150,
            titleFontSize: 20,
            titleColor: "black",
  
            keyLeft: 25,
            keyWidth: 100,
            keyFontSize: 12,
            keyColor: "black",
  
            valueLeft: 100,
            valueWidth: 150,
            valueFontSize: 12,
            valueColor: "black",
  
            firstDataTop: 215,
            dataTopDistance: 25,
            connectedDataTopDistance: 15,
  
            interestButtonTop: 10,
            interestButtonLeft: 125,
            interestButtonSize: 25,
  
            categoryLeft: 310,
            categoryWidth: 100,
            categoryFontSize: 12,
            categoryColor: "black",
  
            firstCategoryTop: 25,
            categoryTopDistance: 75
          }
        },
  
        horseProfile: {
          boxHeight: 950,

          horseTitle: {
            boxTop: 25,
            boxLeft: 25,
            boxHeight: 100,
            boxWidth: 400,
            boxBackgroundColor: "lightBlue",
            boxOutlineStyle: "solid",
            boxOutlineWidth: 2,
            boxOutlineColor: "grey",
          
            titleTop: 25,
            titleLeft: 25,
            titleWidth: 250,
            titleFontSize: 40,
            titleFontColor: "black"
          },
  
          horseImagesAndVideos: {
            boxTop: 150,
            boxLeft: 25,
            boxHeight: 450,
            boxWidth: 400,
            boxBackgroundColor: "lightBlue",
            boxOutlineStyle: "solid",
            boxOutlineWidth: 2,
            boxOutlineColor: "grey",
  
            imagesAndVideosTop: 25,
            imagesAndVideosLeft: 25,
            imagesAndVideosHeight: 350,
            imagesAndVideosWidth: 350,
            imagesAndVideosPaginationTop: 375,
            imagesAndVideosPaginationLeft: 0,
            imagesAndVideosPaginationWidth: 350,
            imagesAndVideosPaginationFontSize: 12,
  
            imageBoxTop: 25,
            imageBoxLeft: 25,
            imageBoxHeight: 350,
            imageBoxWidth: 350,
            imageBoxBackgroundColor: "grey",
            imageBoxOutlineStyle: "solid",
            imageBoxOutlineWidth: 0,
            imageBoxOutlineColor: "grey"
          },
  
          horseDataSheet: {
            boxTop: 150,
            boxLeft: 450,
            boxHeight: 450,
            boxWidth: 425,
            boxBackgroundColor: "lightBlue",
            boxOutlineStyle: "solid",
            boxOutlineWidth: 2,
            boxOutlineColor: "grey",
  
            firstDataTop: 25,
            dataTopDistance: 40,
            
            keyLeft: 25,
            keyWidth: 100,
            keyFontSize: 15,
            keyFontColor: "black",
  
            valueLeft: 175,
            valueWidth: 200,
            valueFontSize: 15,
            valueFontColor: "black"
          },
  
          horseDescription: {
            boxTop: 625,
            boxLeft: 25,
            boxHeight: 300,
            boxWidth: 850,
            boxBackgroundColor: "lightBlue",
            boxOutlineStyle: "solid",
            boxOutlineWidth: 2,
            boxOutlineColor: "grey",
  
            descriptionTop: 25,
            descriptionLeft: 25,
            descriptionHeight: 250,
            descriptionWidth: 750,
            descriptionFontSize: 15,
            descriptionFontColor: "black"
          },
  
          horseInterestPanel: {
            boxTop: 25,
            boxLeft: 450,
            boxHeight: 100,
            boxWidth: 425,
            boxBackgroundColor: "lightBlue",
            boxOutlineStyle: "solid",
            boxOutlineWidth: 2,
            boxOutlineColor: "grey",
  
            interestButtonTop: 25,
            interestButtonLeft: 150,
            interestButtonSize: 25
          },
        },
  
        signUp: {
          boxHeight: 700,

          firstFieldTop: 50,
          topDistance: 40,

          keyLeft: 50,
          keyWidth: 150,
          keyFontSize: 12,
          keyColor: "black",

          fieldLeft: 250,
          fieldHeight: 25,
          fieldWidth: 150,
          fieldBackgroundColor: "white",
          valueFontSize: 12,
          valueColor: "black",

          signUpButtonTop: 225,
          signUpButtonLeft: 350,
          signUpButtonHeight: 25,
          signUpButtonWidth: 100,
          signUpButtonBackgroundColorActive: "grey",
          signUpButtonFontSize: 12,
          signUpButtonContentColorActive: "black",

          errorMessageTop: 225,
          errorMessageLeft: 50,
          errorMessageHeight: 50,
          errorMessageWidth: 250,
          errorMessageFontSize: 12,
          errorMessageColor: "red"
        },
  
        login: {
          boxHeight: 700,

          firstFieldTop: 50,
          topDistance: 40,

          keyLeft: 50,
          keyWidth: 150,
          keyFontSize: 12,
          keyColor: "black",

          fieldLeft: 250,
          fieldHeight: 25,
          fieldWidth: 150,
          fieldBackgroundColor: "white",
          valueFontSize: 12,
          valueColor: "black",

          loginButtonTop: 185,
          loginButtonLeft: 325,
          loginButtonHeight: 25,
          loginButtonWidth: 150,
          loginButtonBackgroundColorActive: "grey",
          loginButtonFontSize: 12,
          loginButtonContentColorActive: "black",

          forgotPasswordButtonTop: 225,
          forgotPasswordButtonLeft: 325,
          forgotPasswordButtonHeight: 25,
          forgotPasswordButtonWidth: 150,
          forgotPasswordButtonBackgroundColorActive: "grey",
          forgotPasswordButtonFontSize: 12,
          forgotPasswordButtonContentColorActive: "black",

          errorMessageTop: 185,
          errorMessageLeft: 50,
          errorMessageHeight: 50,
          errorMessageWidth: 250,
          errorMessageFontSize: 12,
          errorMessageColor: "red"
        },
  
        forgotPasswordRequest: {
          boxHeight: 700,

          firstFieldTop: 50,
          topDistance: 40,

          keyLeft: 50,
          keyWidth: 150,
          keyFontSize: 12,
          keyColor: "black",

          fieldLeft: 250,
          fieldHeight: 25,
          fieldWidth: 150,
          fieldBackgroundColor: "white",
          valueFontSize: 12,
          valueColor: "black",

          forgotPasswordRequestButtonTop: 145,
          forgotPasswordRequestButtonLeft: 300,
          forgotPasswordRequestButtonHeight: 25,
          forgotPasswordRequestButtonWidth: 175,
          forgotPasswordRequestButtonBackgroundColorActive: "grey",
          forgotPasswordRequestButtonFontSize: 12,
          forgotPasswordRequestButtonContentColorActive: "black",

          errorMessageTop: 145,
          errorMessageLeft: 50,
          errorMessageHeight: 50,
          errorMessageWidth: 225,
          errorMessageFontSize: 12,
          errorMessageColor: "red"
        },
  
        forgotPasswordReset: {
          boxHeight: 700,

          firstFieldTop: 50,
          topDistance: 40,

          keyLeft: 50,
          keyWidth: 150,
          keyFontSize: 12,
          keyColor: "black",

          fieldLeft: 250,
          fieldHeight: 25,
          fieldWidth: 150,
          fieldBackgroundColor: "white",
          valueFontSize: 12,
          valueColor: "black",

          changePasswordButtonTop: 305,
          changePasswordButtonLeft: 325,
          changePasswordButtonHeight: 25,
          changePasswordButtonWidth: 150,
          changePasswordButtonBackgroundColorActive: "grey",
          changePasswordButtonFontSize: 12,
          changePasswordButtonContentColorActive: "black",

          errorMessageTop: 305,
          errorMessageLeft: 50,
          errorMessageHeight: 50,
          errorMessageWidth: 250,
          errorMessageFontSize: 12,
          errorMessageColor: "red"
        },
  
        interestingHorseList: {
          boxHeight: 700
        },
  
        requestedProposalList: {
          boxHeight: 700
        },
  
        readUserData: {
          boxHeight: 1500,

          firstDataTop: 50,
          dataTopDistance: 40,

          keyLeft: 50,
          keyWidth: 250,
          keyFontSize: 12,
          keyColor: "black",

          valueLeft: 350,
          valueWidth: 250,
          valueFontSize: 12,
          valueColor: "black"
        },
  
        editUserData: {
          boxHeight: 1550,

          firstInputTop: 50,
          inputTopDistance: 40,

          keyLeft: 50,
          keyWidth: 250,
          keyFontSize: 12,
          keyColor: "black",

          fieldLeft: 350,
          fieldHeight: 25,
          fieldWidth: 250,
          fieldBackgroundColor: "white",
          valueFontSize: 12,
          valueColor: "black",

          changingMessageLeft: 650,
          changingMessageWidth: 100,
          changingMessageFontSize: 12,
          changingMessageColor: "black",

          saveButtonTop: 1440,
          saveButtonLeft: 100,
          saveButtonHeight: 25,
          saveButtonWidth: 100,
          saveButtonBackgroundColorActive: "grey",
          saveButtonBackgroundColorInactive: "lightgrey",
          saveButtonFontSize: 12,
          saveButtonContentColorActive: "black",
          saveButtonContentColorInactive: "black",

          resetButtonTop: 1440,
          resetButtonLeft: 400,
          resetButtonHeight: 25,
          resetButtonWidth: 100,
          resetButtonBackgroundColorActive: "grey",
          resetButtonBackgroundColorInactive: "lightgrey",
          resetButtonFontSize: 12,
          resetButtonContentColorActive: "black",
          resetButtonContentColorInactive: "black",

          errorMessageTop: 1365,
          errorMessageLeft: 50,
          errorMessageHeight: 50,
          errorMessageWidth: 250,
          errorMessageFontSize: 12,
          errorMessageColor: "red",

          statusMessageTop: 1365,
          statusMessageLeft: 50,
          statusMessageHeight: 50,
          statusMessageWidth: 250,
          statusMessageFontSize: 12,
          statusMessageColor: "green"
        },
  
        changeUserEmail: {
          boxHeight: 700,

          firstFieldTop: 50,
          topDistance: 40,

          keyLeft: 50,
          keyWidth: 150,
          keyFontSize: 12,
          keyColor: "black",

          fieldLeft: 250,
          fieldHeight: 25,
          fieldWidth: 150,
          fieldBackgroundColor: "white",
          valueFontSize: 12,
          valueColor: "black",

          changeEmailButtonTop: 225,
          changeEmailButtonLeft: 325,
          changeEmailButtonHeight: 25,
          changeEmailButtonWidth: 150,
          changeEmailButtonBackgroundColorActive: "grey",
          changeEmailButtonFontSize: 12,
          changeEmailButtonContentColorActive: "black",

          errorMessageTop: 225,
          errorMessageLeft: 50,
          errorMessageHeight: 50,
          errorMessageWidth: 250,
          errorMessageFontSize: 12,
          errorMessageColor: "red",

          statusMessageTop: 225,
          statusMessageLeft: 50,
          statusMessageHeight: 50,
          statusMessageWidth: 250,
          statusMessageFontSize: 12,
          statusMessageColor: "green"
        },
  
        changeUserPassword: {
          boxHeight: 700,

          firstFieldTop: 50,
          topDistance: 40,

          keyLeft: 50,
          keyWidth: 150,
          keyFontSize: 12,
          keyColor: "black",

          fieldLeft: 250,
          fieldHeight: 25,
          fieldWidth: 150,
          fieldBackgroundColor: "white",
          valueFontSize: 12,
          valueColor: "black",

          changePasswordButtonTop: 265,
          changePasswordButtonLeft: 325,
          changePasswordButtonHeight: 25,
          changePasswordButtonWidth: 150,
          changePasswordButtonBackgroundColorActive: "grey",
          changePasswordButtonFontSize: 12,
          changePasswordButtonContentColorActive: "black",

          errorMessageTop: 265,
          errorMessageLeft: 50,
          errorMessageHeight: 50,
          errorMessageWidth: 250,
          errorMessageFontSize: 12,
          errorMessageColor: "red",

          statusMessageTop: 265,
          statusMessageLeft: 50,
          statusMessageHeight: 50,
          statusMessageWidth: 250,
          statusMessageFontSize: 12,
          statusMessageColor: "green"
        },
  
        adminHorseList: {
          listCollectionReferenceBoxHeight: 1800,

          listCollectionTop: 100,
          listCollectionLeft: 25,
          listCollectionNumberOfRows: 5,
          listCollectionNumberOfColumns: 1,
          listElementHeight: 300,
          listElementWidth: 850,

          listPaginationTop: 1525,
          listPaginationLeft: 0,
          listPaginationWidth: 850,
          listPaginationFontSize: 20,

          sumOfSelectedHorsesKeyTop: 50,
          sumOfSelectedHorsesKeyLeft: 100,
          sumOfSelectedHorsesKeyWidth: 150,
          sumOfSelectedHorsesKeyFontSize: 12,
          sumOfSelectedHorsesKeyColor: "black",
          sumOfSelectedHorsesValueTop: 50,
          sumOfSelectedHorsesValueLeft: 250,
          sumOfSelectedHorsesValueWidth: 50,
          sumOfSelectedHorsesValueFontSize: 12,
          sumOfSelectedHorsesValueColor: "black",

          collectionTypeSingleSelectKeyTop: 50,
          collectionTypeSingleSelectKeyLeft: 300,
          collectionTypeSingleSelectKeyWidth: 100,
          collectionTypeSingleSelectKeyFontSize: 12,
          collectionTypeSingleSelectKeyColor: "black",
          collectionTypeSingleSelectFieldTop: 50,
          collectionTypeSingleSelectFieldLeft: 400,
          collectionTypeSingleSelectFieldHeight: 25,
          collectionTypeSingleSelectFieldWidth: 50,
          collectionTypeSingleSelectFieldBackgroundColor: "white",
          collectionTypeSingleSelectValueFontSize: 12,
          collectionTypeSingleSelectValueColor: "black",

          listElement: {
            boxHeight: 250,
            boxWidth: 800,
            boxBackgroundColor: "lightBlue",
            boxOutlineStyle: "solid",
            boxOutlineWidth: 2,
            boxOutlineColor: "grey",
  
            imageBoxTop: 25,
            imageBoxLeft: 25,
            imageBoxBackgroundColor: "grey",
            imageBoxHeight: 200,
            imageBoxWidth: 200,
            imageBoxOutlineStyle: "solid",
            imageBoxOutlineWidth: 1,
            imageBoxOutlineColor: "black",
  
            imageTop: 25,
            imageLeft: 25,
            imageHeight: 200,
            imageWidth: 200,
  
            titleTop: 25,
            titleLeft: 250,
            titleWidth: 150,
            titleFontSize: 20,
            titleColor: "black",
  
            keyLeft: 250,
            keyWidth: 100,
            keyFontSize: 12,
            keyColor: "black",
  
            valueLeft: 380,
            valueWidth: 150,
            valueFontSize: 12,
            valueColor: "black",
  
            firstDataTop: 55, // 75
            dataTopDistance: 15, // 25
  
            interestButtonTop: 25,
            interestButtonLeft: 650,
            interestButtonSize: 25,
  
            categoryLeft: 625,
            categoryWidth: 100,
            categoryFontSize: 12,
            categoryColor: "black",
  
            firstCategoryTop: 75,
            categoryTopDistance: 25,

            firstActionButtonTop: 75,
            actionButtonTopDistance: 30,

            actionButtonLeft: 600,
            actionButtonHeight: 25,
            actionButtonWidth: 100,
            actionButtonFontSize: 12,
            actionButtonBackgroundColorActive: "grey",
            actionButtonContentColorActive: "black",
            actionButtonBackgroundColorInactive: "lightgrey",
            actionButtonContentColorInactive: "black",
            actionButtonBackgroundColorHighlighted: "darkgrey",
            actionButtonContentColorHighlighted: "black",

            onwardYesButtonTop: 165,
            onwardYesButtonLeft: 600,
            onwardNoButtonTop: 165,
            onwardNoButtonLeft: 700,
  
            onwardButtonHeight: 25,
            onwardButtonWidth: 50,
            onwardButtonFontSize: 12,
            onwardButtonBackgroundColorActive: "grey",
            onwardButtonContentColorActive: "black",
            onwardButtonBackgroundColorInactive: "lightgrey",
            onwardButtonContentColorInactive: "black",
            onwardButtonBackgroundColorHighlighted: "darkgrey",
            onwardButtonContentColorHighlighted: "black",

            onwardMessageTop: 75,
            onwardMessageLeft: 600,
            onwardMessageHeight: 150,
            onwardMessageWidth: 200,
            onwardMessageFontSize: 12,
            onwardMessageContentColor: "black"
          }
        },
  
        adminCreateHorse: {
          boxHeight: 1800, // nem számít, mert úgyis átszámolja

          dataForm: {
            boxTop: 50,
            boxLeft: 50,
            boxHeight: 740,
            boxWidth: 800,
            boxBackgroundColor: "lightblue",
            boxOutlineStyle: "solid",
            boxOutlineWidth: 2,
            boxOutlineColor: "grey",

            firstInputTop: 50,
            inputTopDistance: 40,

            keyLeft: 50,
            keyWidth: 250,
            keyFontSize: 12,
            keyColor: "black",

            fieldLeft: 250,
            fieldHeight: 25,
            fieldWidth: 250,
            fieldBackgroundColor: "white",
            valueFontSize: 12,
            valueColor: "black",

            descriptionFieldHeight: 50,

            errorMessageLeft: 550,
            errorMessageWidth: 250,
            errorMessageFontSize: 12,
            errorMessageColor: "red",
          },

          uploader: {
            boxTop: 840
          },
  
          imageUploader: {
            boxLeft: 50
          },
  
          videoUploader: {
            boxLeft: 325,
            boxMinimumHeight: 250,
            boxWidth: 250,
            boxBackgroundColor: "lightblue",
            boxOutlineStyle: "solid",
            boxOutlineWidth: 2,
            boxOutlineColor: "grey"
          },
  
          documentUploader: {
            boxLeft: 600,
            boxMinimumHeight: 250,
            boxWidth: 250,
            boxBackgroundColor: "lightblue",
            boxOutlineStyle: "solid",
            boxOutlineWidth: 2,
            boxOutlineColor: "grey"
          },

          imageAndVideoUploader: {
            boxTop: 840,
            boxLeft: 50
          },
  
          submitButton: {
            padding: 50,
            buttonTop: 1540, // nem számít, mert úgyis átszámolja
            buttonLeft: 50,
            buttonHeight: 25,
            buttonWidth: 100,
            buttonFontSize: 12,
            buttonBackgroundColorActive: "grey",
            buttonContentColorActive: "black",
            buttonBackgroundColorInactive: "lightgrey",
            buttonContentColorInactive: "black",
            buttonBackgroundColorHighlighted: "darkgrey",
            buttonContentColorHighlighted: "black",
          },

          mainMessageTop: 1540, // nem számít, mert úgyis átszámolja
          mainMessageLeft: 200,
          mainMessageHeight: 25,
          mainMessageWidth: 250,
          mainMessageFontSize: 12,

          errorMessageColor: "red",
          statusMessageColor: "green"
        },
  
        adminEditHorse: {
          boxHeight: 700, // nem számít, mert úgyis átszámolja

          // dataForm: {
          //   boxTop: 50,
          //   boxLeft: 50,
          //   boxHeight: 700,
          //   boxWidth: 800,
          //   boxBackgroundColor: "lightblue",
          //   boxOutlineStyle: "solid",
          //   boxOutlineWidth: 2,
          //   boxOutlineColor: "grey",

          //   firstInputTop: 50,
          //   inputTopDistance: 40,

          //   keyLeft: 50,
          //   keyWidth: 250,
          //   keyFontSize: 12,
          //   keyColor: "black",

          //   fieldLeft: 250,
          //   fieldHeight: 25,
          //   fieldWidth: 250,
          //   fieldBackgroundColor: "white",
          //   valueFontSize: 12,
          //   valueColor: "black",

          //   descriptionFieldHeight: 50,

          //   errorMessageLeft: 550,
          //   errorMessageWidth: 250,
          //   errorMessageFontSize: 12,
          //   errorMessageColor: "red",
          // },

          uploader: {
            boxTop: 840
          },
  
          imageUploader: {
            boxLeft: 50
          },
  
          videoUploader: {
            boxLeft: 325,
            boxMinimumHeight: 250,
            boxWidth: 250,
            boxBackgroundColor: "lightblue",
            boxOutlineStyle: "solid",
            boxOutlineWidth: 2,
            boxOutlineColor: "grey"
          },
  
          documentUploader: {
            boxLeft: 600,
            boxMinimumHeight: 250,
            boxWidth: 250,
            boxBackgroundColor: "lightblue",
            boxOutlineStyle: "solid",
            boxOutlineWidth: 2,
            boxOutlineColor: "grey"
          },

          imageAndVideoUploader: {
            boxTop: 840,
            boxLeft: 50
          },
  
          submitButton: {
            padding: 50,
            buttonTop: 1540, // nem számít, mert úgyis átszámolja
            buttonLeft: 50,
            buttonHeight: 25,
            buttonWidth: 100,
            buttonFontSize: 12,
            buttonBackgroundColorActive: "grey",
            buttonContentColorActive: "black",
            buttonBackgroundColorInactive: "lightgrey",
            buttonContentColorInactive: "black",
            buttonBackgroundColorHighlighted: "darkgrey",
            buttonContentColorHighlighted: "black",
          },

          mainMessageTop: 1540, // nem számít, mert úgyis átszámolja
          mainMessageLeft: 200,
          mainMessageHeight: 25,
          mainMessageWidth: 250,
          mainMessageFontSize: 12,

          errorMessageColor: "red",
          statusMessageColor: "green"
        },
  
        adminUserList: {
          boxHeight:  1300,

          userListCollectionTop: 100,
          userListCollectionLeft: 25,
          userListCollectionNumberOfRows: 5,
          userListCollectionNumberOfColumns: 1,
          userListElementHeight: 200,
          userListElementWidth: 850,
  
          userListPaginationTop: 1025,
          userListPaginationLeft: 0,
          userListPaginationWidth: 850,
          userListPaginationFontSize: 20,
  
          sumOfSelectedUsersKeyTop: 50,
          sumOfSelectedUsersKeyLeft: 100,
          sumOfSelectedUsersKeyWidth: 150,
          sumOfSelectedUsersKeyFontSize: 12,
          sumOfSelectedUsersKeyColor: "black",
          sumOfSelectedUsersValueTop: 50,
          sumOfSelectedUsersValueLeft: 250,
          sumOfSelectedUsersValueWidth: 50,
          sumOfSelectedUsersValueFontSize: 12,
          sumOfSelectedUsersValueColor: "black",

          listElement: {
            boxTop: 0,
            boxLeft: 0,
            boxHeight: 175,
            boxWidth: 800,
            boxBackgroundColor: "lightBlue",
            boxOutlineStyle: "solid",
            boxOutlineWidth: 2,
            boxOutlineColor: "grey",
  
            keyLeft: 25,
            keyWidth: 100,
            keyFontSize: 12,
            keyColor: "black",
  
            valueLeft: 125,
            valueWidth: 150,
            valueFontSize: 12,
            valueColor: "black",
  
            firstDataTop: 25,
            dataTopDistance: 25,

            firstActionButtonTop: 25,
            actionButtonTopDistance: 30,

            actionButtonHeight: 25,
            actionButtonLeft: 450,
            actionButtonWidth: 100,
            actionButtonFontSize: 12,
            actionButtonBackgroundColorActive: "grey",
            actionButtonContentColorActive: "black",
            actionButtonBackgroundColorInactive: "lightgrey",
            actionButtonContentColorInactive: "black",
            actionButtonBackgroundColorHighlighted: "darkgrey",
            actionButtonContentColorHighlighted: "black",

            onwardYesButtonTop: 115,
            onwardYesButtonLeft: 450,
            onwardNoButtonTop: 115,
            onwardNoButtonLeft: 550,
  
            onwardButtonHeight: 25,
            onwardButtonWidth: 50,
            onwardButtonFontSize: 12,
            onwardButtonBackgroundColorActive: "grey",
            onwardButtonContentColorActive: "black",
            onwardButtonBackgroundColorInactive: "lightgrey",
            onwardButtonContentColorInactive: "black",
            onwardButtonBackgroundColorHighlighted: "darkgrey",
            onwardButtonContentColorHighlighted: "black",

            onwardMessageTop: 25,
            onwardMessageLeft: 450,
            onwardMessageHeight: 150,
            onwardMessageWidth: 200,
            onwardMessageFontSize: 12,
            onwardMessageContentColor: "black"
          }
        },

        adminReadUserData: {
          boxHeight: 1580,

          firstDataTop: 50,
          dataTopDistance: 40,

          keyLeft: 50,
          keyWidth: 250,
          keyFontSize: 12,
          keyColor: "black",

          valueLeft: 350,
          valueWidth: 250,
          valueFontSize: 12,
          valueColor: "black"
        },

        adminEditUserData: {
          boxHeight: 1630,

          firstInputTop: 50,
          inputTopDistance: 40,

          keyLeft: 50,
          keyWidth: 250,
          keyFontSize: 12,
          keyColor: "black",

          fieldLeft: 350,
          fieldHeight: 25,
          fieldWidth: 250,
          fieldBackgroundColor: "white",
          valueFontSize: 12,
          valueColor: "black",

          changingMessageLeft: 650,
          changingMessageWidth: 100,
          changingMessageFontSize: 12,
          changingMessageColor: "black",

          saveButtonTop: 1520,
          saveButtonLeft: 100,
          saveButtonHeight: 25,
          saveButtonWidth: 100,
          saveButtonBackgroundColorActive: "grey",
          saveButtonBackgroundColorInactive: "lightgrey",
          saveButtonFontSize: 12,
          saveButtonContentColorActive: "black",
          saveButtonContentColorInactive: "black",

          resetButtonTop: 1520,
          resetButtonLeft: 400,
          resetButtonHeight: 25,
          resetButtonWidth: 100,
          resetButtonBackgroundColorActive: "grey",
          resetButtonBackgroundColorInactive: "lightgrey",
          resetButtonFontSize: 12,
          resetButtonContentColorActive: "black",
          resetButtonContentColorInactive: "black",

          errorMessageTop: 1445,
          errorMessageLeft: 50,
          errorMessageHeight: 50,
          errorMessageWidth: 250,
          errorMessageFontSize: 12,
          errorMessageColor: "red",

          statusMessageTop: 1445,
          statusMessageLeft: 50,
          statusMessageHeight: 50,
          statusMessageWidth: 250,
          statusMessageFontSize: 12,
          statusMessageColor: "green"
        },

        adminInviteUser: {
          boxHeight: 700,
  
          firstInputTop: 50,
          inputTopDistance: 40,

          keyLeft: 50,
          keyWidth: 150,
          keyFontSize: 12,
          keyColor: "black",

          fieldLeft: 250,
          fieldHeight: 25,
          fieldWidth: 150,
          fieldBackgroundColor: "white",
          valueFontSize: 12,
          valueColor: "black",
  
          inviteButtonTop: 225,
          inviteButtonLeft: 350,
          inviteButtonHeight: 25,
          inviteButtonWidth: 100,
          inviteButtonBackgroundColorActive: "grey",
          inviteButtonBackgroundColorInactive: "lightgrey",
          inviteButtonFontSize: 12,
          inviteButtonContentColorActive: "black",
          inviteButtonContentColorInactive: "black",

          errorMessageTop: 225,
          errorMessageLeft: 50,
          errorMessageHeight: 50,
          errorMessageWidth: 250,
          errorMessageFontSize: 12,
          errorMessageColor: "red"
        },
  
        adminReadProposal: {
          boxHeight: 700
        },
  
        adminEditProposal: {
          boxHeight: 700
        },
  
        readWebData: {
          boxHeight: 700
        },
  
        editWebData: {
          boxHeight: 700
        },
  
        readStatistics: {
          boxHeight: 700
        },
  
        readAdminData: {
          boxHeight: 700
        },
  
        editAdminData: {
          boxHeight: 700
        },
  
        changeAdminEmail: {
          boxHeight: 700
        },
  
        changeAdminPassword: {
          boxHeight: 700
        },
      }
    },
  
    contentBoxMinimumHeight: 500,
  
    footer: {
      textFontSize: 12,
      textFontColor: "black",
      boxLeft: 0,
      boxHeight: 300,
      boxWidth: 1200,
      boxBackgroundColor: "lightblue",
      boxOutlineStyle: "solid",
      boxOutlineWidth: 2,
      boxOutlineColor: "grey"
    },

    baseComponent: {
      imageUploader: {
        boxMinimumHeight: 250,
        boxWidth: 250,
        boxBackgroundColor: "lightblue",
        boxOutlineStyle: "solid",
        boxOutlineWidth: 2,
        boxOutlineColor: "grey",

        functionTitleTop: 5,
        functionTitleLeft: 25,
        functionTitleWidth: 100,
        functionTitleFontSize: 12,
        functionTitleColor: "black",

        fileUploadTop: 25,
        fileUploadLeft: 25,

        imageUploaderElementsContainerTop: 100,
        imageUploaderElementsContainerLeft: 0,
        imageUploaderElementsContainerMinimumHeight: 100,

        imageUploaderElement: {
          boxHeight: 200,
          boxWidth: 200,
          boxBackgroundColor: "lightblue",
          boxOutlineStyle: "solid",
          boxOutlineWidth: 2,
          boxOutlineColor: "grey",

          imageTop: 10,
          imageLeft: 10,
          imageHeight: 80,
          imageWidth: 80,

          firstInputTop: 125,
          inputTopDistance: 40,

          keyLeft: 25,
          keyWidth: 250,
          keyFontSize: 10,
          keyColor: "black",

          valueAndFieldLeft: 75,
          fieldHeight: 20,
          valueAndFieldWidth: 50,
          fieldBackgroundColor: "white",
          valueFontSize: 10,
          valueColor: "black",

          extensionLeft: 135,
          extensionWidth: 10,

          removeButtonTop: 25,
          removeButtonLeft: 125,
          removeButtonHeight: 25,
          removeButtonWidth: 50,
          removeButtonFontSize: 12,
          removeButtonBackgroundColorActive: "grey",
          removeButtonContentColorActive: "black"
        },

        errorMessagePadding: 15,
        errorMessageTop: 225,
        errorMessageLeft: 25,
        errorMessageWidth: 100,
        errorMessageFontSize: 12,
        errorMessageColor: "black"
      },

      videoUploader: {
        boxMinimumHeight: 250,
        boxWidth: 250,
        boxBackgroundColor: "lightblue",
        boxOutlineStyle: "solid",
        boxOutlineWidth: 2,
        boxOutlineColor: "grey",

        functionTitleTop: 5,
        functionTitleLeft: 25,
        functionTitleWidth: 100,
        functionTitleFontSize: 12,
        functionTitleColor: "black",

        keyValueInputTop: 25,
        keyLeft: 25,
        keyWidth: 50,
        keyFontSize: 12,
        keyColor: "black",
        fieldLeft: 100,
        fieldHeight: 25,
        fieldWidth: 100,
        fieldBackgroundColor: "white",
        valueFontSize: 12,
        valueColor: "black",
  
        uploadButtonTop: 55,
        uploadButtonLeft: 25,
        uploadButtonHeight: 25,
        uploadButtonWidth: 50,
        uploadButtonFontSize: 12,
        uploadButtonBackgroundColorActive: "grey",
        uploadButtonContentColorActive: "black",

        videoUploaderElementsContainerTop: 100,
        videoUploaderElementsContainerLeft: 0,
        videoUploaderElementsContainerMinimumHeight: 100,

        videoUploaderElement: {
          boxHeight: 200,
          boxWidth: 200,
          boxBackgroundColor: "lightblue",
          boxOutlineStyle: "solid",
          boxOutlineWidth: 2,
          boxOutlineColor: "grey",

          videoTop: 10,
          videoLeft: 10,
          videoHeight: 80,
          videoWidth: 80,

          firstInputTop: 125,
          inputTopDistance: 40,

          keyLeft: 25,
          keyWidth: 250,
          keyFontSize: 10,
          keyColor: "black",

          valueAndFieldLeft: 75,
          fieldHeight: 20,
          valueAndFieldWidth: 50,
          fieldBackgroundColor: "white",
          valueFontSize: 10,
          valueColor: "black",

          removeButtonTop: 25,
          removeButtonLeft: 125,
          removeButtonHeight: 25,
          removeButtonWidth: 50,
          removeButtonFontSize: 12,
          removeButtonBackgroundColorActive: "grey",
          removeButtonContentColorActive: "black",
        },

        errorMessagePadding: 15,
        errorMessageTop: 225,
        errorMessageLeft: 25,
        errorMessageWidth: 100,
        errorMessageFontSize: 12,
        errorMessageColor: "black"
      },

      imageAndVideoUploader : {
        boxMinimumHeight: 500,
        boxWidth: 500,
        boxBackgroundColor: "lightblue",
        boxOutlineStyle: "solid",
        boxOutlineWidth: 2,
        boxOutlineColor: "grey",

        functionTitleTop: 10,
        functionTitleLeft: 25,
        functionTitleWidth: 100,
        functionTitleFontSize: 18,
        functionTitleColor: "black",

        imageAndVideoInput: {
          boxTop: 50,
          boxLeft: 25,
          boxHeight: 200,
          boxWidth: 450,
          boxBackgroundColor: "lightblue",
          boxOutlineStyle: "solid",
          boxOutlineWidth: 2,
          boxOutlineColor: "grey",
        
          modeButtonBackgroundColorActive: "grey",
          modeButtonContentColorActive: "black",
          modeButtonBackgroundColorInactive: "lightgrey",
          modeButtonContentColorInactive: "black",

          imageModeButtonTop: 25,
          imageModeButtonLeft: 25,
          imageModeButtonHeight: 25,
          imageModeButtonWidth: 50,
          imageModeButtonFontSize: 12,
  
          videoModeButtonTop: 25,
          videoModeButtonLeft: 100,
          videoModeButtonHeight: 25,
          videoModeButtonWidth: 50,
          videoModeButtonFontSize: 12,

          fileUploadTop: 100,
          fileUploadLeft: 25,

          keyValueInputTop: 100,
          keyLeft: 25,
          keyWidth: 50,
          keyFontSize: 12,
          keyColor: "black",
          fieldLeft: 100,
          fieldHeight: 25,
          fieldWidth: 250,
          fieldBackgroundColor: "white",
          valueFontSize: 12,
          valueColor: "black",
    
          videoUploadButtonTop: 130,
          videoUploadButtonLeft: 25,
          videoUploadButtonHeight: 25,
          videoUploadButtonWidth: 50,
          videoUploadButtonFontSize: 12,
          videoUploadButtonBackgroundColorActive: "grey",
          videoUploadButtonContentColorActive: "black"
        },

        uploadedImageAndVideoElementsContainerTop: 300,
        uploadedImageAndVideoElementsContainerLeft: 25,
        uploadedImageAndVideoElementsContainerMinimumHeight: 100,
        uploadedImageAndVideoElementsContainerWidth: 450,
        uploadedImageAndVideoElementsContainerBackgroundColor: "lightblue",
        uploadedImageAndVideoElementsContainerOutlineStyle: "solid",
        uploadedImageAndVideoElementsContainerOutlineWidth: 2,
        uploadedImageAndVideoElementsContainerOutlineColor: "grey",
  
        uploadedImageAndVideoElement: {
          boxHeight: 200,
          boxWidth: 450,
          boxBackgroundColor: "lightblue",
          boxOutlineStyle: "solid",
          boxOutlineWidth: 2,
          boxOutlineColor: "grey",

          imageOrVideoTop: 20,
          imageOrVideoLeft: 20,
          imageOrVideoHeight: 160,
          imageOrVideoWidth: 160,

          firstInputTop: 20,
          inputTopDistance: 40,

          keyLeft: 200,
          keyWidth: 250,
          keyFontSize: 10,
          keyColor: "black",

          valueAndFieldLeft: 250,
          fieldHeight: 20,
          valueAndFieldWidth: 50,
          fieldBackgroundColor: "white",
          valueFontSize: 10,
          valueColor: "black",

          extensionLeft: 315,
          extensionWidth: 10,

          removeButtonTop: 155,
          removeButtonLeft: 350,
          removeButtonHeight: 25,
          removeButtonWidth: 50,
          removeButtonFontSize: 12,
          removeButtonBackgroundColorActive: "grey",
          removeButtonContentColorActive: "black"
        },

        errorMessagePadding: 15,
        errorMessageTop: 225,
        errorMessageLeft: 25,
        errorMessageWidth: 100,
        errorMessageFontSize: 12,
        errorMessageColor: "black"
      }
    },
    
    sidebarBoxTop: 0,
    sidebarBoxLeft: 0,
    sidebarBoxWidth: 300,
    sidebarBoxBackgroundColor: "lightblue",
    sidebarBoxOutlineStyle: "solid",
    sidebarBoxOutlineWidth: 2,
    sidebarBoxOutlineColor: "grey",
    sidebarButtonTop: 50,
    sidebarButtonTopDistance: 60,
    sidebarButtonLeft: 25,
    sidebarButtonHeight: 40,
    sidebarButtonWidth: 200,

    mainContentBoxTop: 0,
    mainContentBoxLeft: 300,
    mainContentBoxWidth: 900,
    mainContentBoxBackgroundColor: "lightblue",
    mainContentBoxOutlineStyle: "solid",
    mainContentBoxOutlineWidth: 2,
    mainContentBoxOutlineColor: "grey",

    filteringBoxTop: 100,
    filteringBoxHeight: 600,
    sortingBoxTop: 750,
    sortingBoxHeight: 275,
    sidebarFunctionTitleTop: 25,
    sidebarFunctionTitleLeft: 25,
    sidebarFunctionTitleWidth: 250,
    sidebarFunctionTitleFontSize: 12,
    sidebarFunctionTitleFontColor: "black",
    sidebarHeaderTop: 75,
    sidebarHeaderWidth: 150,
    sidebarHeaderFontSize: 12,
    sidebarHeaderFontColor: "black",
    sidebarFirstValueTop: 125,
    sidebarValueTopDistance: 40,
    sidebarKeyLeft: 25,
    sidebarKeyWidth: 150,
    sidebarKeyFontSize: 12,
    sidebarKeyFontColor: "black",
    sidebarValueLeft: 150,
    sidebarFieldHeight: 25,
    sidebarValueWidth: 125,
    sidebarFieldBackgroundColor: "white",
    sidebarValueFontSize: 12,
    sidebarValueFontColor: "black"
  }

  return(styleResult);
}
