import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../03_baseComponents/Button';





export interface AdminWebDataNavigationProps {
  webpageStyle: WebpageStyleProps,
  adminWebDataPageContentType: string,
  changeAdminWebDataPageContentType: Array<any>,
  resizeSidebarHeight: Array<any>
}





export const AdminWebDataNavigation: FC<AdminWebDataNavigationProps> = ({ webpageStyle, adminWebDataPageContentType, changeAdminWebDataPageContentType, resizeSidebarHeight }) => {
  //

  const [sidebarHeight, setSidebarHeight] = useState<number>(webpageStyle.content.sidebar.adminWebData.navigation.boxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.adminWebData.navigation.boxTop,
    left: webpageStyle.content.sidebar.navigation.boxLeft,
    height: sidebarHeight,
    width: webpageStyle.content.sidebar.navigation.boxWidth,
    backgroundColor: webpageStyle.content.sidebar.navigation.boxBackgroundColor,
    outlineStyle: webpageStyle.content.sidebar.navigation.boxOutlineStyle,
    outlineWidth: webpageStyle.content.sidebar.navigation.boxOutlineWidth,
    outlineColor: webpageStyle.content.sidebar.navigation.boxOutlineColor
  });


  const [firstButtonTop, setFirstButtonTop] = useState<number>(webpageStyle.content.sidebar.navigation.firstButtonTop);
  const [buttonTopDistance, setButtonTopDistance] = useState<number>(webpageStyle.content.sidebar.navigation.buttonTopDistance);

  const [buttonBackgroundColorActive, setButtonBackgroundColorActive] = useState<string>(webpageStyle.content.sidebar.navigation.buttonBackgroundColorActive);
  const [buttonContentColorActive, setButtonContentColorActive] = useState<string>(webpageStyle.content.sidebar.navigation.buttonContentColorActive);
  const [buttonBackgroundColorHighlighted, setButtonBackgroundColorHighlighted] = useState<string>(webpageStyle.content.sidebar.navigation.buttonBackgroundColorHighlighted);
  const [buttonContentColorHighlighted, setButtonContentColorHighlighted] = useState<string>(webpageStyle.content.sidebar.navigation.buttonContentColorHighlighted);

  const [buttonPrototypeDisplayParameters, setButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.navigation.firstButtonTop,
    left: webpageStyle.content.sidebar.navigation.buttonLeft,
    height: webpageStyle.content.sidebar.navigation.buttonHeight,
    width: webpageStyle.content.sidebar.navigation.buttonWidth,
    backgroundColor: buttonBackgroundColorActive,
    fontSize: webpageStyle.content.sidebar.navigation.buttonFontSize,
    contentColor: buttonContentColorActive
  });


  useEffect(() => {
    resizeSidebarHeight[0](sidebarHeight);
  }, []);


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <div>
        <Button
          buttonText = { "Read web data" }
          actionFunctions = { [() => changeAdminWebDataPageContentType[0]("readWebData")] }
          conditionFunction = { [() => {
            if(adminWebDataPageContentType !== "readWebData") {
              return(true);
            } else {
              return(false);
            }
          }] }
          disabled = { false }
          prototypeDisplayParameters = { buttonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            top: firstButtonTop + 0 * buttonTopDistance,
            backgroundColor: adminWebDataPageContentType !== "readWebData" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
            contentColor: adminWebDataPageContentType !== "readWebData" ?  buttonContentColorActive : buttonContentColorHighlighted
          } }
        />

        <Button
          buttonText = { "Edit web data" }
          actionFunctions = { [() => changeAdminWebDataPageContentType[0]("editWebData")] }
          conditionFunction = { [() => {
            if(adminWebDataPageContentType !== "editWebData") {
              return(true);
            } else {
              return(false);
            }
          }] }
          disabled = { false }
          prototypeDisplayParameters = { buttonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            top: firstButtonTop + 1 * buttonTopDistance,
            backgroundColor: adminWebDataPageContentType !== "editWebData" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
            contentColor: adminWebDataPageContentType !== "editWebData" ?  buttonContentColorActive : buttonContentColorHighlighted
          } }
        />

        <Button
          buttonText = { "Read statistics" }
          actionFunctions = { [() => changeAdminWebDataPageContentType[0]("readStatistics")] }
          conditionFunction = { [() => {
            if(adminWebDataPageContentType !== "readStatistics") {
              return(true);
            } else {
              return(false);
            }
          }] }
          disabled = { false }
          prototypeDisplayParameters = { buttonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            top: firstButtonTop + 2 * buttonTopDistance,
            backgroundColor: adminWebDataPageContentType !== "readStatistics" ? buttonBackgroundColorActive : buttonBackgroundColorHighlighted,
            contentColor: adminWebDataPageContentType !== "readStatistics" ?  buttonContentColorActive : buttonContentColorHighlighted
          } }
        />
      </div>
    </Box>
  )
}
