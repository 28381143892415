import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { AdjustableVideoDisplay } from '../04_subComponents/MediaDisplay';





export interface VideoDisplayPrototypeDisplayParameters {
  top: number,
  left: number,
  height: number,
  width: number
}

export interface VideoDisplayChangedDisplayParameters {
  top?: number,
  left?: number,
  height?: number,
  width?: number
}

export interface VideoDisplayProps {
  sourceUrl: string,
  prototypeDisplayParameters: VideoDisplayPrototypeDisplayParameters,
  changedDisplayParameters?: VideoDisplayChangedDisplayParameters
}





export const VideoDisplay: FC<VideoDisplayProps> = ({ sourceUrl, prototypeDisplayParameters, changedDisplayParameters }) => {
  //

  const [top, setTop] = useState<number>(prototypeDisplayParameters.top);
  const [left, setLeft] = useState<number>(prototypeDisplayParameters.left);
  const [height, setHeight] = useState<number>(prototypeDisplayParameters.height);
  const [width, setWidth] = useState<number>(prototypeDisplayParameters.width);


  useEffect(() => {
    if(changedDisplayParameters !== undefined) {
      if(changedDisplayParameters.top !== undefined) {
        setTop(changedDisplayParameters.top);
      }
      if(changedDisplayParameters.left !== undefined) {
        setLeft(changedDisplayParameters.left);
      }
      if(changedDisplayParameters.height !== undefined) {
        setHeight(changedDisplayParameters.height);
      }
      if(changedDisplayParameters.width !== undefined) {
        setWidth(changedDisplayParameters.width);
      }
    }
  }, [changedDisplayParameters]);


  return(
    <AdjustableVideoDisplay
      sourceUrl = { sourceUrl }
      top = { top }
      left = { left }
      height = { height }
      width = { width }
    />
  )
}
