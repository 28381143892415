import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../services/webpageStyle';
import { BrowserRouter, Switch, Route, Redirect, useHistory, useParams } from 'react-router-dom';
import { Horse, HorseData } from '../../../models/HorseProps';
import { getData } from '../../../services/API.services';
import { HorseProfile } from './mainContent/HorseProfile';
import { LoggedInUser } from '../../../models/UserProps';





export interface HorseProfilePageContentProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  search: string,
  doAction: Array<any>,
  resizeContentHeight: Array<any>
}





export const HorseProfilePageContent: FC<HorseProfilePageContentProps> = ({ webpageStyle, loggedInUser, search, doAction, resizeContentHeight }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [horse, setHorse] = useState<Array<Horse>>([]);

  // const [search, setSearch] = useState<string>(useHistory().location.search);


  const [contentHeight, setContentHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);

  const [mainContentHeight, setMainContentHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);
  const [sidebarHeight, setSidebarHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);


  const saveHorse = (horseVariable: Array<Horse>) => {
    setHorse(horseVariable);

    console.log(horseVariable);
  }

  const getHorse = async () => {
    let horseId: string = "";
    if(search.length >= 5 && search[0] === '?' && search[1] === 'i' && search[2] === 'd' && search[3] === '=') {
      if(Number(search.slice(4)) >= 0 || Number(search.slice(4)) < 0) {
        horseId = search.slice(4);
      }
    }
    // let horseId: string = "1";

    if(horseId.length !== 0) {
      let result: any;
      if(loggedInUser.role === "visitor" || loggedInUser.role === "customer") { // a cutomernek kéne csinálni egy újat
        result = await getData(`/api/horses/single/read/visitor/${ horseId }`);
      }
      if(loggedInUser.role === "customer") { // ezt kéne használni a customernek
        //
      }
      if(loggedInUser.role === "administrator" || loggedInUser.role === "account owner") {
        result = await getData(`/api/horses/single/read/admin/${ horseId }`, true);
      }
      if( !result || ( result as { status: number, message: string } )?.status >= 400 ) {
        if(result !== undefined) {
          if(result.message !== undefined) {
            console.log(result.message);
            setIsLoading(false);
          }
        }
      } else {
        //

        // console.log(result);

        if(result.length !== 0) {
          saveHorse(result);
        }

        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }

  const resizeMainContentHeight = (newMainContentHeight: number) => {
    setMainContentHeight(newMainContentHeight);
  }

  const resizeSidebarHeight = (newSidebarHeight: number) => {
    setSidebarHeight(newSidebarHeight);
  }


  useEffect(() => {
    getHorse();
  }, []);

  useEffect(() => {
    setContentHeight(Math.max(mainContentHeight, sidebarHeight));
    resizeContentHeight[0](Math.max(mainContentHeight, sidebarHeight));
  }, [mainContentHeight, sidebarHeight]);


  // console.log(search);


  return(
    <div>
      { isLoading === false ?
        <HorseProfile
          webpageStyle = { webpageStyle }
          loggedInUser = { loggedInUser }
          horse = { horse }
          resizeMainContentHeight = { [resizeMainContentHeight] }
        /> : null
      }
    </div>
  )
}
