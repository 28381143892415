import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { AdjustableIcon } from '../04_subComponents/Icon';





export interface InterestButtonPrototypeDisplayParameters {
  top: number,
  left: number,
  size: number
}

export interface InterestButtonBoxChangedDisplayParameters {
  top?: number,
  left?: number,
  size?: number
}

export interface InterestButtonProps {
  value: boolean,
  userId: number,
  horseId: number,
  functions: Array<any>,
  prototypeDisplayParameters: InterestButtonPrototypeDisplayParameters,
  changedDisplayParameters?: InterestButtonBoxChangedDisplayParameters
}





export const InterestButton: FC<InterestButtonProps> = ({ value, userId, horseId, functions,
  prototypeDisplayParameters, changedDisplayParameters }) => {
  //

  const [top, setTop] = useState<number>(prototypeDisplayParameters.top);
  const [left, setLeft] = useState<number>(prototypeDisplayParameters.left);
  const [size, setSize] = useState<number>(prototypeDisplayParameters.size);


  const addInterest = () => {
    if(value === false) {
      console.log(`addInterest ${userId} ${horseId}`);
      // run API connector function: addHorseInterest(userId, horseId)

      // wait for it, and if the API request was successful, run functions[0](true)
      const toBeContinue: boolean = true;

      if(toBeContinue === true) {
        functions[0](true);
      }
    }
  }

  const removeInterest = () => {
    if(value === true) {
      console.log(`removeInterest ${userId} ${horseId}`);
      // run API connector function: removeHorseInterest(userId, horseId)

      // wait for it, and if the API request was successful, run functions[0](false)
      const toBeContinue: boolean = true;

      if(toBeContinue === true) {
        functions[0](false);
      }
    }
  }


  useEffect(() => {
    if(changedDisplayParameters !== undefined) {
      if(changedDisplayParameters.top !== undefined) {
        setTop(changedDisplayParameters.top);
      }
      if(changedDisplayParameters.left !== undefined) {
        setLeft(changedDisplayParameters.left);
      }
      if(changedDisplayParameters.size !== undefined) {
        setSize(changedDisplayParameters.size);
      }
    }
  }, [changedDisplayParameters]);


  if(value === true) {
    return(
      <AdjustableIcon
        iconType = { "StarIcon" }
        message = { [] }
        onClickFunctions = { [removeInterest] }
        top = { top }
        left = { left }
        size = { size }
        color = { "black" }
      />
    )
  } else {
    return(
      <AdjustableIcon
        iconType = { "StarBorderIcon" }
        message = { [] }
        onClickFunctions = { [addInterest] }
        top = { top }
        left = { left }
        size = { size }
        color = { "black" }
      />
    )
  }

  
}
