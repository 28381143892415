import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { KeyValueInput, KeyValueInputPrototypeDisplayParameters, KeyValueInputChangedDisplayParameters } from '../../../03_baseComponents/KeyValueInput';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../03_baseComponents/Button';
import { emailInputTest, numberOfCharactersGreaterThanOrEqual, equalCondition } from '../../../../services/Conditions';
import { SingleRowText } from '../../../03_baseComponents/SingleRowText';
import { MultipleRowText, MultipleRowPrototypeDisplayParameters, MultipleRowChangedDisplayParameters } from '../../../03_baseComponents/MultipleRowText';
import { LoginRequest, ForgotPasswordResetRequest, UserFromToken } from '../../../../models/UserProps';
import { postData } from "../../../../services/API.services";
import jwtDecode from "jwt-decode";
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { resetActionsDone, passwordLength } from '../../../../App';





export interface ForgotPasswordResetProps {
  webpageStyle: WebpageStyleProps,
  changeLoggedInUser: Array<any>,
  resizeMainContentHeight: Array<any>
}





export const ForgotPasswordReset: FC<ForgotPasswordResetProps> = ({ webpageStyle, changeLoggedInUser, resizeMainContentHeight }) => {
  //

  // const [passwordLength, setPasswordLength] = useState<Array<number>>([8]);

  const [email, setEmail] = useState<Array<string>>([]);
  const [temporaryPassword, setTemporaryPassword] = useState<Array<string>>([]);

  const [newPassword, setNewPassword] = useState<Array<string>>([]);
  const [confirmNewPassword, setConfirmNewPassword] = useState<Array<string>>([]);

  const [errorMessage, setErrorMessage] = useState<string>("");

  
  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.mainContent.forgotPasswordReset.boxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.boxTop,
    left: webpageStyle.content.mainContent.boxLeft,
    height: boxHeight,
    width: webpageStyle.content.mainContent.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.boxOutlineColor
  });


  const [firstFieldTop, setFirstFieldTop] = useState<number>(webpageStyle.content.mainContent.forgotPasswordReset.firstFieldTop);
  const [topDistance, setTopDistance] = useState<number>(webpageStyle.content.mainContent.forgotPasswordReset.topDistance);

  const [keyValueInputPrototypeDisplayParameters, setKeyValueInputPrototypeDisplayParameters] = useState<KeyValueInputPrototypeDisplayParameters>({
    keyTop: firstFieldTop,
    keyLeft: webpageStyle.content.mainContent.forgotPasswordReset.keyLeft,
    keyWidth: webpageStyle.content.mainContent.forgotPasswordReset.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.forgotPasswordReset.keyFontSize,
    keyColor: webpageStyle.content.mainContent.forgotPasswordReset.keyColor,
    fieldTop: firstFieldTop,
    fieldLeft: webpageStyle.content.mainContent.forgotPasswordReset.fieldLeft,
    fieldHeight: webpageStyle.content.mainContent.forgotPasswordReset.fieldHeight,
    fieldWidth: webpageStyle.content.mainContent.forgotPasswordReset.fieldWidth,
    fieldBackgroundColor: webpageStyle.content.mainContent.forgotPasswordReset.fieldBackgroundColor,
    valueFontSize: webpageStyle.content.mainContent.forgotPasswordReset.valueFontSize,
    valueColor: webpageStyle.content.mainContent.forgotPasswordReset.valueColor,
  });

  const [changePasswordButtonPrototypeDisplayParameters, setChangePasswordButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.forgotPasswordReset.changePasswordButtonTop,
    left: webpageStyle.content.mainContent.forgotPasswordReset.changePasswordButtonLeft,
    height: webpageStyle.content.mainContent.forgotPasswordReset.changePasswordButtonHeight,
    width: webpageStyle.content.mainContent.forgotPasswordReset.changePasswordButtonWidth,
    backgroundColor: webpageStyle.content.mainContent.forgotPasswordReset.changePasswordButtonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.forgotPasswordReset.changePasswordButtonFontSize,
    contentColor: webpageStyle.content.mainContent.forgotPasswordReset.changePasswordButtonContentColorActive
  });

  const [errorMessagePrototypeDisplayParameters, setErrorMessagePrototypeDisplayParameters] = useState<MultipleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.forgotPasswordReset.errorMessageTop,
    left: webpageStyle.content.mainContent.forgotPasswordReset.errorMessageLeft,
    height: webpageStyle.content.mainContent.forgotPasswordReset.errorMessageHeight,
    width: webpageStyle.content.mainContent.forgotPasswordReset.errorMessageWidth,
    fontSize: webpageStyle.content.mainContent.forgotPasswordReset.errorMessageFontSize,
    color: webpageStyle.content.mainContent.forgotPasswordReset.errorMessageColor
  });


  let history = useHistory();

  const changePassword = async () => {
    //

    const test: boolean = testConditions();

    if(test === true) {
      const forgotPasswordResetRequest: ForgotPasswordResetRequest = {
        email: email[0],
        temporaryPassword: temporaryPassword[0],
        newPassword: newPassword[0]
      }
      const forgotPasswordResetResult = await postData(`/api/users/single/forgotpasswordreset`, forgotPasswordResetRequest);
      if (!forgotPasswordResetResult || (forgotPasswordResetResult as { status: number, message: string })?.status >= 400) {
        setErrorMessage(forgotPasswordResetResult.message);
      } else {
        const loginUserRequest: LoginRequest = {
          email: email[0],
          password: newPassword[0]
        }
        const loginResult = await postData(`/api/users/single/login`, loginUserRequest);
        if (!loginResult || (loginResult as { status: number, message: string })?.status >= 400) {
          setErrorMessage(loginResult.message);
        } else {
          resetActionsDone();
          localStorage.setItem("user", JSON.stringify(loginResult.token));
          setErrorMessage("");
          let userFromToken: UserFromToken = jwtDecode<UserFromToken>(loginResult.token as string);
          changeLoggedInUser[0](userFromToken.id, userFromToken.email, userFromToken.role);
          history.push("/");
        }
      }

      // + login
    }
  }

  const testConditions = (): boolean => {
    //

    const testEmail = emailInputTest(email);
    
    const testPasswordLength = numberOfCharactersGreaterThanOrEqual(newPassword, passwordLength);
    const testPasswordsEquality = equalCondition(newPassword, confirmNewPassword);

    if(testEmail.length !== 0) {
      if(testEmail[0] !== true) {
        setErrorMessage("Not valid email address");
        return(false);
      }
    }

    if(temporaryPassword.length === 0) {
      setErrorMessage("No temporary password entered");
        return(false);
    }

    if(testPasswordLength.length !== 0) {
      if(testPasswordLength[0] !== true) {
        setErrorMessage(`The new password length is less than ${ passwordLength }`);
        return(false);
      }
    }

    if(testPasswordsEquality.length !== 0) {
      if(testPasswordsEquality[0] !== true) {
        setErrorMessage("The new password doesn't equal the confirm new password");
        return(false);
      }
    }

    setErrorMessage("");
    return(true);
  }


  useEffect(() => {
    resizeMainContentHeight[0](boxHeight);
  }, []);


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <KeyValueInput
        keyText = { "Email" }
        initialValueString = { [] }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { [] }
        inputType = { "EmailInput" }
        modificationFunctions = { [setEmail, () => { setErrorMessage("") }] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstFieldTop + 0 * topDistance,
          fieldTop: firstFieldTop + 0 * topDistance
        } }
      />

      <KeyValueInput
        keyText = { "Temporary password" }
        initialValueString = { [] }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { [] }
        inputType = { "PasswordInputPrimer" }
        modificationFunctions = { [setTemporaryPassword, () => { setErrorMessage("") }] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstFieldTop + 1 * topDistance,
          fieldTop: firstFieldTop + 1 * topDistance
        } }
      />

      <KeyValueInput
        keyText = { "New password" }
        initialValueString = { [] }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { [] }
        inputType = { "PasswordInputPrimer" }
        modificationFunctions = { [setNewPassword, () => { setErrorMessage("") }] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstFieldTop + 3 * topDistance,
          fieldTop: firstFieldTop + 3 * topDistance
        } }
      />

      <KeyValueInput
        keyText = { "Confirm new password" }
        initialValueString = { confirmNewPassword }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { [] }
        inputType = { "PasswordInputSecunder" }
        modificationFunctions = { [setConfirmNewPassword, () => { setErrorMessage("") }] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstFieldTop + 4 * topDistance,
          fieldTop: firstFieldTop + 4 * topDistance
        } }
      />

      <Button
        buttonText = { "Change password" }
        actionFunctions = { [changePassword] }
        disabled = { false }
        prototypeDisplayParameters = { changePasswordButtonPrototypeDisplayParameters }
      />

      {/* <SingleRowText
        text = { errorMessage }
        redirectionUrl = { [] }
        message = { [] }
        top = { errorMessageTop }
        left = { errorMessageLeft }
        width = { errorMessageWidth }
        fontSize = { errorMessageFontSize }
        color = { errorMessageColor }
      /> */}

      <MultipleRowText
        text = { errorMessage }
        prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
      />
    </Box>
  )
}
