import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { AdjustableSingleRowTextDisplay } from '../04_subComponents/TextDisplay';
import { AdjustableSingleRowTextInput, AdjustableMultipleRowTextInput, AdjustableSingleSelectInput,
  AdjustablePasswordInput, AdjustableDateInput, AdjustableIntegerInput, AdjustableNumberInput, AdjustableCheckboxInput
} from '../04_subComponents/FormInput';
import { AdjustableButton } from '../04_subComponents/Button';
import { AdjustableIcon } from '../04_subComponents/Icon';
import { equalCondition, notEqualCondition, blankCondition, notBlankCondition,
  greaterThanCondition, lessThanCondition, greaterThanOrEqualCondition, lessThanOrEqualCondition,
  containCondition, notContainCondition, partOfCondition, notPartOfCondition,
  numberOfCharactersGreaterThanOrEqual, numberOfCharactersLessThanOrEqual, emailInputTest } from '../../services/Conditions';





export interface SingleRowTextInputProps {
  initialValue: Array<string>,
  modificationFunction: Array<any>,
  disabled: boolean,
  fieldTop: number,
  fieldLeft: number,
  fieldHeight: number,
  fieldWidth: number,
  fieldBackgroundColor: string,
  valueFontSize: number,
  valueColor: string,
}

export interface MultipleRowTextInputProps {
  initialValue: Array<string>,
  modificationFunction: Array<any>,
  disabled: boolean,
  fieldTop: number,
  fieldLeft: number,
  fieldHeight: number,
  fieldWidth: number,
  fieldBackgroundColor: string,
  valueFontSize: number,
  valueColor: string,
}

export interface SingleSelectInputProps {
  initialValue: Array<string>,
  options: Array<string>,
  modificationFunction: Array<any>,
  disabled: boolean,
  fieldTop: number,
  fieldLeft: number,
  fieldHeight: number,
  fieldWidth: number,
  fieldBackgroundColor: string,
  valueFontSize: number,
  valueColor: string,
}

export interface PasswordInputProps {
  initialValue: Array<string>,
  modificationFunction: Array<any>,
  disabled: boolean,
  fieldTop: number,
  fieldLeft: number,
  fieldHeight: number,
  fieldWidth: number,
  fieldBackgroundColor: string,
  valueFontSize: number,
  valueColor: string,
}

export interface EmailInputProps {
  initialValue: Array<string>,
  modificationFunction: Array<any>,
  disabled: boolean,
  fieldTop: number,
  fieldLeft: number,
  fieldHeight: number,
  fieldWidth: number,
  fieldBackgroundColor: string,
  valueFontSize: number,
  valueColor: string,
}

export interface UrlInputProps {
  initialValue: Array<string>,
  modificationFunction: Array<any>,
  disabled: boolean,
  fieldTop: number,
  fieldLeft: number,
  fieldHeight: number,
  fieldWidth: number,
  fieldBackgroundColor: string,
  valueFontSize: number,
  valueColor: string,
}

export interface DateInputProps {
  initialValue: Array<string>,
  modificationFunction: Array<any>,
  disabled: boolean,
  fieldTop: number,
  fieldLeft: number,
  fieldHeight: number,
  fieldWidth: number,
  fieldBackgroundColor: string,
  valueFontSize: number,
  valueColor: string,
}

export interface NumberInputProps {
  initialValue: Array<number>,
  modificationFunction: Array<any>,
  disabled: boolean,
  fieldTop: number,
  fieldLeft: number,
  fieldHeight: number,
  fieldWidth: number,
  fieldBackgroundColor: string,
  valueFontSize: number,
  valueColor: string,
}

export interface CheckboxInputProps {
  initialValue: Array<boolean>,
  modificationFunction: Array<any>,
  disabled: boolean,
  fieldTop: number,
  fieldLeft: number,
  fieldHeight: number,
  fieldWidth: number,
  fieldBackgroundColor: string,
  valueFontSize: number,
  valueColor: string,
}

export interface ProperInputIndicatorProps {
  properInputDisplay: boolean,
  valueString: Array<string>,
  valueNumber: Array<number>,
  valueBoolean: Array<boolean>,
  inputType: string, // input típusok: SingleRowTextInput, MultipleRowTextInput, SingleSelectInput, NumberInput, PasswordInput, EmailInput, UrlInput, Checkbox
  conditions: Array<ConditionProps>,
  conditionTestFunctions: Array<any>, // if the output is true -> do the actionFunction, if false -> do nothing, if no conditionFunction -> do the actionFunction, például ellenőrizni, hogy van-e már ilyen e-mail, vagy ilyesmi
  conditionResultFunctions: Array<any>, // run this function with the result of the conditions
  properInputIndicatorTop?: number,
  properInputIndicatorLeft?: number,
  properInputIndicatorSize?: number
}

export interface ConditionProps {
  conditionType: string,
  conditionArgument: Array<string | number | boolean>,
  errorMessage: Array<string>
}

export interface KeyValueInputPrototypeDisplayParameters {
  keyTop: number,
  keyLeft: number,
  keyWidth: number,
  keyFontSize: number,
  keyColor: string,
  fieldTop: number,
  fieldLeft: number,
  fieldHeight: number,
  fieldWidth: number,
  fieldBackgroundColor: string,
  valueFontSize: number,
  valueColor: string,
}

export interface KeyValueInputChangedDisplayParameters {
  keyTop?: number,
  keyLeft?: number,
  keyWidth?: number,
  keyFontSize?: number,
  keyColor?: string,
  fieldTop?: number,
  fieldLeft?: number,
  fieldHeight?: number,
  fieldWidth?: number,
  fieldBackgroundColor?: string,
  valueFontSize?: number,
  valueColor?: string,
}

export interface KeyValueInputProps {
  keyText: string,
  initialValueString: Array<string>,
  initialValueNumber: Array<number>,
  initialValueBoolean: Array<boolean>,
  optionsString: Array<string>,
  inputType: string, // input típusok: SingleRowTextInput, MultipleRowTextInput, SingleSelectInput, NumberInput, PasswordInput, EmailInput, UrlInput, Checkbox
  modificationFunctions?: Array<any>,
  properInputDisplay?: boolean,
  conditions?: Array<ConditionProps>,
  conditionTestFunctions?: Array<any>, // if the output is true -> do the actionFunction, if false -> do nothing, if no conditionFunction -> do the actionFunction
  conditionResultFunctions?: Array<any>, // run this function with the result of the conditions
  hidden?: Array<boolean>,
  disabled: boolean,
  prototypeDisplayParameters: KeyValueInputPrototypeDisplayParameters,
  changedDisplayParameters?: KeyValueInputChangedDisplayParameters,
  properInputIndicatorTop?: Array<number>,
  properInputIndicatorLeft?: Array<number>,
  properInputIndicatorSize?: Array<number>
}





const SingleRowTextInput: FC<SingleRowTextInputProps> = ({ initialValue, modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor }) => {
  const [value, setValue] = useState<Array<string>>(initialValue);
  const [inputFieldComponent, setInputFieldComponent] = useState<Array<JSX.Element>>([]);
  const [keyValueInputIsLoading, setKeyValueInputIsLoading] = useState<boolean>(true);

  // nem teljesen tökéletesen van megcsinálva
  // talán a getInputFieldComponentFromValue-nak nem kellene minden egyes value változtatásnál lefutni
  // inkább egy szintel ez alatt kellene a frissítést csinálni, viszont azt nem tudtam implementálni

  const stringModificationFunction = (v: string) => {
    if(v.length > 0) {
      modificationFunction[0]([v]);
      setValue([v]);
    } else {
      modificationFunction[0]([]);
      setValue([]);
    }
  }

  const getInputFieldComponentFromInitialValue = () => {
    setValue(initialValue);
  }

  const getInputFieldComponentFromValue = () => {
    const temporaryInputFieldComponent: Array<JSX.Element> = [];
    temporaryInputFieldComponent.push(
      <AdjustableSingleRowTextInput
        value = { value.length === 1 ? value[0] : "" }
        modificationFunction = { [stringModificationFunction] }
        disabled = { disabled }
        top = { fieldTop }
        left = { fieldLeft }
        height = { fieldHeight }
        width = { fieldWidth }
        backgroundColor = { fieldBackgroundColor }
        fontSize = { valueFontSize }
        color = { valueColor }
      />
    );
    setInputFieldComponent(temporaryInputFieldComponent);
    setKeyValueInputIsLoading(false);
  }

  useEffect(() => {
    getInputFieldComponentFromInitialValue();
  }, [initialValue[0]]);

  useEffect(() => {
    getInputFieldComponentFromValue();
  }, [value[0], modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor]);

  // return (
  //   <AdjustableSingleRowTextInput
  //     value = { value.length === 1 ? value[0] : "" }
  //     modificationFunction = { [stringModificationFunction] }
  //     disabled = { disabled }
  //     top = { fieldTop }
  //     left = { fieldLeft }
  //     height = { fieldHeight }
  //     width = { fieldWidth }
  //     backgroundColor = { fieldBackgroundColor }
  //     fontSize = { valueFontSize }
  //     color = { valueColor }
  //   />
  // )

  if(keyValueInputIsLoading === true) {
    return(
      <div style = {{ position: "absolute", top: fieldTop, left: fieldLeft }}>
        The input field is loading...
      </div>
    )
  } else {
    return (
      inputFieldComponent[0]
    )
  }
}

const MultipleRowTextInput: FC<MultipleRowTextInputProps> = ({ initialValue, modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor }) => {
  const [value, setValue] = useState<Array<string>>(initialValue);

  const [inputFieldComponent, setInputFieldComponent] = useState<Array<JSX.Element>>([]);
  const [keyValueInputIsLoading, setKeyValueInputIsLoading] = useState<boolean>(true);

    const stringModificationFunction = (v: string) => {
    if(v.length > 0) {
      modificationFunction[0]([v]);
      setValue([v]);
    } else {
      modificationFunction[0]([]);
      setValue([]);
    }
  }

  const getInputFieldComponentFromInitialValue = () => {
    setValue(initialValue);
  }

  const getInputFieldComponentFromValue = () => {
    const temporaryInputFieldComponent: Array<JSX.Element> = [];
    temporaryInputFieldComponent.push(
      <AdjustableMultipleRowTextInput
        value = { value.length === 1 ? value[0] : "" }
        modificationFunction = { [stringModificationFunction] }
        disabled = { disabled }
        top = { fieldTop }
        left = { fieldLeft }
        height = { fieldHeight }
        width = { fieldWidth }
        backgroundColor = { fieldBackgroundColor }
        fontSize = { valueFontSize }
        color = { valueColor }
      />
    );
    setInputFieldComponent(temporaryInputFieldComponent);
    setKeyValueInputIsLoading(false);
  }

  useEffect(() => {
    getInputFieldComponentFromInitialValue();
  }, [initialValue[0]]);

  useEffect(() => {
    getInputFieldComponentFromValue();
  }, [value[0], modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor]);

  if(keyValueInputIsLoading === true) {
    return(
      <div style = {{ position: "absolute", top: fieldTop, left: fieldLeft }}>
        The input field is loading...
      </div>
    )
  } else {
    return (
      inputFieldComponent[0]
    )
  }

  // return (
  //   <AdjustableMultipleRowTextInput
  //     value = { value.length === 1 ? value[0] : "" }
  //     modificationFunction = { [stringModificationFunction] }
  //     disabled = { disabled }
  //     top = { fieldTop }
  //     left = { fieldLeft }
  //     height = { fieldHeight }
  //     width = { fieldWidth }
  //     backgroundColor = { fieldBackgroundColor }
  //     fontSize = { valueFontSize }
  //     color = { valueColor }
  //   />
  // )
}

const SingleSelectInput: FC<SingleSelectInputProps> = ({ initialValue, options, modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor }) => {
  const [value, setValue] = useState<Array<string>>([]);
  const [inputFieldComponent, setInputFieldComponent] = useState<Array<JSX.Element>>([]);
  const [keyValueInputIsLoading, setKeyValueInputIsLoading] = useState<boolean>(true);

  const stringModificationFunction = (v: string) => {
    if(v.length > 0) {
      modificationFunction[0]([v]);
      setValue([v]);
    } else {
      modificationFunction[0]([]);
      setValue([]);
    }
  }
  
  const getInputFieldComponentFromInitialValue = () => {
    setValue(initialValue);
  }

  const getInputFieldComponentFromValue = () => {
    const temporaryInputFieldComponent: Array<JSX.Element> = [];
    temporaryInputFieldComponent.push(
      <AdjustableSingleSelectInput
        value = { value.length === 1 ? value[0] : "" }
        options = { options }
        modificationFunction = { [stringModificationFunction] }
        disabled = { disabled }
        top = { fieldTop }
        left = { fieldLeft }
        height = { fieldHeight }
        width = { fieldWidth }
        backgroundColor = { fieldBackgroundColor }
        fontSize = { valueFontSize }
        color = { valueColor }
      />
    );
    setInputFieldComponent(temporaryInputFieldComponent);
    setKeyValueInputIsLoading(false);
  }

  useEffect(() => {
    getInputFieldComponentFromInitialValue();
  }, [initialValue[0]]);

  useEffect(() => {
    getInputFieldComponentFromValue();
  }, [value[0], options, modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor]);

  if(keyValueInputIsLoading === true) {
    return(
      <div style = {{ position: "absolute", top: fieldTop, left: fieldLeft }}>
        The input field is loading...
      </div>
    )
  } else {
    return (
      inputFieldComponent[0]
    )
  }

  // return (
  //   <AdjustableSingleSelectInput
  //     value = { value.length === 1 ? value[0] : "" }
  //     options = { options }
  //     modificationFunction = { [stringModificationFunction] }
  //     disabled = { disabled }
  //     top = { fieldTop }
  //     left = { fieldLeft }
  //     height = { fieldHeight }
  //     width = { fieldWidth }
  //     backgroundColor = { fieldBackgroundColor }
  //     fontSize = { valueFontSize }
  //     color = { valueColor }
  //   />
  // )
}

const PasswordInputPrimer: FC<PasswordInputProps> = ({ initialValue, modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor }) => {
  const [value, setValue] = useState<Array<string>>(initialValue);
  const [hidden, setHidden] = useState<boolean>(true);
  const [inputFieldComponent, setInputFieldComponent] = useState<Array<JSX.Element>>([]);
  const [keyValueInputIsLoading, setKeyValueInputIsLoading] = useState<boolean>(true);

  const reverseHidden = () => {
    // console.log("*");
    setHidden(hidden === true ? false : true);
  }

  const stringModificationFunction = (v: string) => {
    if(v.length > 0) {
      modificationFunction[0]([v]);
      setValue([v]);
    } else {
      modificationFunction[0]([]);
      setValue([]);
    }
  }

  const getInputFieldComponentFromInitialValue = () => {
    setValue(initialValue);
  }

  const getInputFieldComponentFromValue = () => {
    const temporaryInputFieldComponent: Array<JSX.Element> = [];
    temporaryInputFieldComponent.push(
      <div>
        <AdjustablePasswordInput
          value = { value.length === 1 ? value[0] : "" }
          modificationFunction = { [stringModificationFunction] }
          hidden = { hidden }
          disabled = { disabled }
          top = { fieldTop }
          left = { fieldLeft }
          height = { fieldHeight }
          width = { fieldWidth }
          backgroundColor = { fieldBackgroundColor }
          fontSize = { valueFontSize }
          color = { valueColor }
        />
        <AdjustableIcon
          iconType = { hidden === true ? "VisibilityIcon" : "VisibilityOffIcon" }
          message = { [] }
          onClickFunctions = { [reverseHidden] }
          top = { fieldTop }
          left = { (fieldLeft + fieldWidth) }
          size = { (fieldHeight / 2) }
          color = { "black" }
        />
      </div>
    );
    setInputFieldComponent(temporaryInputFieldComponent);
    setKeyValueInputIsLoading(false);
  }

  useEffect(() => {
    getInputFieldComponentFromInitialValue();
  }, [initialValue[0]]);

  useEffect(() => {
    getInputFieldComponentFromValue();
  }, [value[0], hidden, modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor]);

  if(keyValueInputIsLoading === true) {
    return(
      <div style = {{ position: "absolute", top: fieldTop, left: fieldLeft }}>
        The input field is loading...
      </div>
    )
  } else {
    return (
      inputFieldComponent[0]
    )
  }

  // return (
  //   <div>
  //     <AdjustablePasswordInput
  //       value = { value.length === 1 ? value[0] : "" }
  //       modificationFunction = { [stringModificationFunction] }
  //       hidden = { hidden }
  //       disabled = { disabled }
  //       top = { fieldTop }
  //       left = { fieldLeft }
  //       height = { fieldHeight }
  //       width = { fieldWidth }
  //       backgroundColor = { fieldBackgroundColor }
  //       fontSize = { valueFontSize }
  //       color = { valueColor }
  //     />
  //     <AdjustableIcon
  //       iconType = { hidden === true ? "VisibilityIcon" : "VisibilityOffIcon" }
  //       message = { [] }
  //       onClickFunction = { [reverseHidden] }
  //       top = { fieldTop }
  //       left = { (fieldLeft + fieldWidth) }
  //       size = { (fieldHeight / 2) }
  //       color = { "black" }
  //     />
  //   </div>
  // )
}

const PasswordInputSecunder: FC<PasswordInputProps> = ({ initialValue, modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor }) => {
  const [value, setValue] = useState<Array<string>>(initialValue);
  const [inputFieldComponent, setInputFieldComponent] = useState<Array<JSX.Element>>([]);
  const [keyValueInputIsLoading, setKeyValueInputIsLoading] = useState<boolean>(true);

  const stringModificationFunction = (v: string) => {
    if(v.length > 0) {
      modificationFunction[0]([v]);
      setValue([v]);
    } else {
      modificationFunction[0]([]);
      setValue([]);
    }
  }

  const getInputFieldComponentFromInitialValue = () => {
    setValue(initialValue);
  }

  const getInputFieldComponentFromValue = () => {
    const temporaryInputFieldComponent: Array<JSX.Element> = [];
    temporaryInputFieldComponent.push(
      <AdjustablePasswordInput
        value = { value.length === 1 ? value[0] : "" }
        modificationFunction = { [stringModificationFunction] }
        hidden = { true }
        disabled = { disabled }
        top = { fieldTop }
        left = { fieldLeft }
        height = { fieldHeight }
        width = { fieldWidth }
        backgroundColor = { fieldBackgroundColor }
        fontSize = { valueFontSize }
        color = { valueColor }
      />
    );
    setInputFieldComponent(temporaryInputFieldComponent);
    setKeyValueInputIsLoading(false);
  }

  useEffect(() => {
    getInputFieldComponentFromInitialValue();
  }, [initialValue[0]]);

  useEffect(() => {
    getInputFieldComponentFromValue();
  }, [value[0], modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor]);

  if(keyValueInputIsLoading === true) {
    return(
      <div style = {{ position: "absolute", top: fieldTop, left: fieldLeft }}>
        The input field is loading...
      </div>
    )
  } else {
    return (
      inputFieldComponent[0]
    )
  }

  // return (
  //   <div>
  //     <AdjustablePasswordInput
  //       value = { value.length === 1 ? value[0] : "" }
  //       modificationFunction = { [stringModificationFunction] }
  //       hidden = { true }
  //       disabled = { disabled }
  //       top = { fieldTop }
  //       left = { fieldLeft }
  //       height = { fieldHeight }
  //       width = { fieldWidth }
  //       backgroundColor = { fieldBackgroundColor }
  //       fontSize = { valueFontSize }
  //       color = { valueColor }
  //     />
  //   </div>
  // )
}

const EmailInput: FC<EmailInputProps> = ({ initialValue, modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor }) => {
  const [value, setValue] = useState<Array<string>>(initialValue);
  const [inputFieldComponent, setInputFieldComponent] = useState<Array<JSX.Element>>([]);
  const [keyValueInputIsLoading, setKeyValueInputIsLoading] = useState<boolean>(true);

  const stringModificationFunction = (v: string) => {
    if(v.length > 0) {
      modificationFunction[0]([v]);
      setValue([v]);
    } else {
      modificationFunction[0]([]);
      setValue([]);
    }
  }

  const getInputFieldComponentFromInitialValue = () => {
    setValue(initialValue);
  }

  const getInputFieldComponentFromValue = () => {
    const temporaryInputFieldComponent: Array<JSX.Element> = [];
    temporaryInputFieldComponent.push(
      <AdjustableSingleRowTextInput
        value = { value.length === 1 ? value[0] : "" }
        modificationFunction = { [stringModificationFunction] }
        disabled = { disabled }
        top = { fieldTop }
        left = { fieldLeft }
        height = { fieldHeight }
        width = { fieldWidth }
        backgroundColor = { fieldBackgroundColor }
        fontSize = { valueFontSize }
        color = { valueColor }
      />
    );
    setInputFieldComponent(temporaryInputFieldComponent);
    setKeyValueInputIsLoading(false);
  }

  useEffect(() => {
    getInputFieldComponentFromInitialValue();
  }, [initialValue[0]]);

  useEffect(() => {
    getInputFieldComponentFromValue();
  }, [value[0], modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor]);

  if(keyValueInputIsLoading === true) {
    return(
      <div style = {{ position: "absolute", top: fieldTop, left: fieldLeft }}>
        The input field is loading...
      </div>
    )
  } else {
    return (
      inputFieldComponent[0]
    )
  }

  // return (
  //   <AdjustableSingleRowTextInput
  //     value = { value.length === 1 ? value[0] : "" }
  //     modificationFunction = { [stringModificationFunction] }
  //     disabled = { disabled }
  //     top = { fieldTop }
  //     left = { fieldLeft }
  //     height = { fieldHeight }
  //     width = { fieldWidth }
  //     backgroundColor = { fieldBackgroundColor }
  //     fontSize = { valueFontSize }
  //     color = { valueColor }
  //   />
  // )
}

const UrlInput: FC<UrlInputProps> = ({ initialValue, modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor }) => {
  const [value, setValue] = useState<Array<string>>(initialValue);
  const [inputFieldComponent, setInputFieldComponent] = useState<Array<JSX.Element>>([]);
  const [keyValueInputIsLoading, setKeyValueInputIsLoading] = useState<boolean>(true);

  const stringModificationFunction = (v: string) => {
    if(v.length > 0) {
      modificationFunction[0]([v]);
      setValue([v]);
    } else {
      modificationFunction[0]([]);
      setValue([]);
    }
  }

  const getInputFieldComponentFromInitialValue = () => {
    setValue(initialValue);
  }

  const getInputFieldComponentFromValue = () => {
    const temporaryInputFieldComponent: Array<JSX.Element> = [];
    temporaryInputFieldComponent.push(
      <AdjustableSingleRowTextInput
        value = { value.length === 1 ? value[0] : "" }
        modificationFunction = { [stringModificationFunction] }
        disabled = { disabled }
        top = { fieldTop }
        left = { fieldLeft }
        height = { fieldHeight }
        width = { fieldWidth }
        backgroundColor = { fieldBackgroundColor }
        fontSize = { valueFontSize }
        color = { valueColor }
      />
    );
    setInputFieldComponent(temporaryInputFieldComponent);
    setKeyValueInputIsLoading(false);
  }

  useEffect(() => {
    getInputFieldComponentFromInitialValue();
  }, [initialValue[0]]);

  useEffect(() => {
    getInputFieldComponentFromValue();
  }, [value[0], modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor]);

  if(keyValueInputIsLoading === true) {
    return(
      <div style = {{ position: "absolute", top: fieldTop, left: fieldLeft }}>
        The input field is loading...
      </div>
    )
  } else {
    return (
      inputFieldComponent[0]
    )
  }

  // return (
  //   <AdjustableSingleRowTextInput
  //     value = { value.length === 1 ? value[0] : "" }
  //     modificationFunction = { [stringModificationFunction] }
  //     disabled = { disabled }
  //     top = { fieldTop }
  //     left = { fieldLeft }
  //     height = { fieldHeight }
  //     width = { fieldWidth }
  //     backgroundColor = { fieldBackgroundColor }
  //     fontSize = { valueFontSize }
  //     color = { valueColor }
  //   />
  // )
}

const DateInput: FC<DateInputProps> = ({ initialValue, modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor }) => {
  const [value, setValue] = useState<Array<string>>(initialValue);
  const [inputFieldComponent, setInputFieldComponent] = useState<Array<JSX.Element>>([]);
  const [keyValueInputIsLoading, setKeyValueInputIsLoading] = useState<boolean>(true);

  const stringModificationFunction = (v: string) => {
    if(v.length > 0) {
      modificationFunction[0]([v]);
      setValue([v]);
    } else {
      modificationFunction[0]([]);
      setValue([]);
    }
  }

  const getInputFieldComponentFromInitialValue = () => {
    setValue(initialValue);
  }

  const getInputFieldComponentFromValue = () => {
    const temporaryInputFieldComponent: Array<JSX.Element> = [];
    temporaryInputFieldComponent.push(
      <AdjustableDateInput
        value = { value.length === 1 ? value[0] : "" }
        modificationFunction = { [stringModificationFunction] }
        disabled = { disabled }
        top = { fieldTop }
        left = { fieldLeft }
        height = { fieldHeight }
        width = { fieldWidth }
        backgroundColor = { fieldBackgroundColor }
        fontSize = { valueFontSize }
        color = { valueColor }
      />
    );
    setInputFieldComponent(temporaryInputFieldComponent);
    setKeyValueInputIsLoading(false);
  }

  useEffect(() => {
    getInputFieldComponentFromInitialValue();
  }, [initialValue[0]]);

  useEffect(() => {
    getInputFieldComponentFromValue();
  }, [value[0], modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor]);

  if(keyValueInputIsLoading === true) {
    return(
      <div style = {{ position: "absolute", top: fieldTop, left: fieldLeft }}>
        The input field is loading...
      </div>
    )
  } else {
    return (
      inputFieldComponent[0]
    )
  }

  // return (
  //   <AdjustableDateInput
  //     value = { value.length === 1 ? value[0] : "" }
  //     modificationFunction = { [stringModificationFunction] }
  //     disabled = { disabled }
  //     top = { fieldTop }
  //     left = { fieldLeft }
  //     height = { fieldHeight }
  //     width = { fieldWidth }
  //     backgroundColor = { fieldBackgroundColor }
  //     fontSize = { valueFontSize }
  //     color = { valueColor }
  //   />
  // )
}

const IntegerInput: FC<NumberInputProps> = ({ initialValue, modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor }) => {
  const [initialValueIsLoading, setInitialValueIsLoading] = useState<boolean>(true);
  const [value, setValue] = useState<Array<number>>([]);
  const [valueImage, setValueImage] = useState<string>("");
  const [inputFieldComponent, setInputFieldComponent] = useState<Array<JSX.Element>>([]);
  const [keyValueInputIsLoading, setKeyValueInputIsLoading] = useState<boolean>(true);

  const numberModificationFunction = (v: Array<number>, vImage: string) => {
    modificationFunction[0](v, vImage);
    setValue(v);
    setValueImage(vImage);
  }

  // const getInitialValue = () => {
  //   console.log("*");
  //   if(initialValue.length !== 0) {
  //     setValueImage(initialValue[0].toString());
  //   } else {
  //     setValueImage("");
  //   }
  //   setInitialValueIsLoading(false);
  // }

  const getInputFieldComponentFromInitialValue = () => {
    setValueImage(initialValue.toString());
  }

  const getInputFieldComponentFromValue = () => {
    const temporaryInputFieldComponent: Array<JSX.Element> = [];
    // console.log(valueImage);
    temporaryInputFieldComponent.push(
      <AdjustableIntegerInput
        valueImage = { valueImage }
        modificationFunction = { [numberModificationFunction] }
        disabled = { disabled }
        top = { fieldTop }
        left = { fieldLeft }
        height = { fieldHeight }
        width = { fieldWidth }
        backgroundColor = { fieldBackgroundColor }
        fontSize = { valueFontSize }
        color = { valueColor }
      />
    );
    setInputFieldComponent(temporaryInputFieldComponent);
    setKeyValueInputIsLoading(false);
  }

  useEffect(() => {
    getInputFieldComponentFromInitialValue();
  }, [initialValue[0]]);

  useEffect(() => {
    getInputFieldComponentFromValue();
  }, [valueImage, modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor]);

  if(keyValueInputIsLoading === true) {
    return(
      <div style = {{ position: "absolute", top: fieldTop, left: fieldLeft }}>
        The input field is loading...
      </div>
    )
  } else {
    return (
      inputFieldComponent[0]
    )
  }

  // if(keyValueInputIsLoading === true) {
  //   return(
  //     <div style = {{ position: "absolute", top: fieldTop, left: fieldLeft }}>
  //       The input field input is loading...
  //     </div>
  //   )
  // } else {
  //   return (
  //     <AdjustableIntegerInput
  //       valueImage = { valueImage }
  //       modificationFunction = { [numberModificationFunction] }
  //       disabled = { disabled }
  //       top = { fieldTop }
  //       left = { fieldLeft }
  //       height = { fieldHeight }
  //       width = { fieldWidth }
  //       backgroundColor = { fieldBackgroundColor }
  //       fontSize = { valueFontSize }
  //       color = { valueColor }
  //     />
  //   )
  // }
}

const NumberInput: FC<NumberInputProps> = ({ initialValue, modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor }) => {
  // const [initialValueIsLoading, setInitialValueIsLoading] = useState<boolean>(true);
  const [value, setValue] = useState<Array<number>>([]);
  const [valueImage, setValueImage] = useState<string>("");
  const [inputFieldComponent, setInputFieldComponent] = useState<Array<JSX.Element>>([]);
  const [keyValueInputIsLoading, setKeyValueInputIsLoading] = useState<boolean>(true);

  const numberModificationFunction = (v: Array<number>, vImage: string) => {
    if(v.length !== 0 || vImage.length === 0 ) {
      modificationFunction[0](v, vImage);
    }
    setValue(v);
    setValueImage(vImage);
  }

  // const getInitialValue = () => {
  //   if(initialValue.length !== 0) {
  //     setValueImage(initialValue[0].toString());
  //   } else {
  //     setValueImage("");
  //   }
  //   // setInitialValueIsLoading(false);
  // }

  const getInputFieldComponentFromInitialValue = () => {
    setValueImage(initialValue.toString());
  }

  const getInputFieldComponentFromValue = () => {
    const temporaryInputFieldComponent: Array<JSX.Element> = [];
    temporaryInputFieldComponent.push(
      <AdjustableNumberInput
        valueImage = { valueImage }
        modificationFunction = { [numberModificationFunction] }
        disabled = { disabled }
        top = { fieldTop }
        left = { fieldLeft }
        height = { fieldHeight }
        width = { fieldWidth }
        backgroundColor = { fieldBackgroundColor }
        fontSize = { valueFontSize }
        color = { valueColor }
      />
    );
    setInputFieldComponent(temporaryInputFieldComponent);
    setKeyValueInputIsLoading(false);
  }

  useEffect(() => {
    getInputFieldComponentFromInitialValue();
  }, [initialValue[0]]);

  useEffect(() => {
    getInputFieldComponentFromValue();
  }, [valueImage, modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor]);

  if(keyValueInputIsLoading === true) {
    return(
      <div style = {{ position: "absolute", top: fieldTop, left: fieldLeft }}>
        The input field is loading...
      </div>
    )
  } else {
    return (
      inputFieldComponent[0]
    )
  }

  // if(initialValueIsLoading === true) {
  //   return(
  //     <div style = {{ position: "absolute", top: fieldTop, left: fieldLeft }}>
  //       The input field input is loading...
  //     </div>
  //   )
  // } else {
  //   return (
  //     <AdjustableNumberInput
  //       valueImage = { valueImage }
  //       modificationFunction = { [numberModificationFunction] }
  //       disabled = { disabled }
  //       top = { fieldTop }
  //       left = { fieldLeft }
  //       height = { fieldHeight }
  //       width = { fieldWidth }
  //       backgroundColor = { fieldBackgroundColor }
  //       fontSize = { valueFontSize }
  //       color = { valueColor }
  //     />
  //   )
  // }
}

const CheckboxInput: FC<CheckboxInputProps> = ({ initialValue, modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor }) => {
  const [value, setValue] = useState<Array<boolean>>(initialValue);
  const [inputFieldComponent, setInputFieldComponent] = useState<Array<JSX.Element>>([]);
  const [keyValueInputIsLoading, setKeyValueInputIsLoading] = useState<boolean>(true);

  const booleanModificationFunction = (v: boolean) => {
    modificationFunction[0]([v]);
    setValue([v]);
  }

  // const getInputFieldComponent = () => {
  //   const temporaryInputFieldComponent: Array<JSX.Element> = [];
  //   temporaryInputFieldComponent.push(
  //     <AdjustableCheckboxInput
  //       value = { value.length !== 0 ? value[0] : false }
  //       modificationFunction = { [booleanModificationFunction] }
  //       disabled = { disabled }
  //       top = { fieldTop }
  //       left = { fieldLeft }
  //       size = { fieldHeight }
  //     />
  //   );
  //   setInputFieldComponent(temporaryInputFieldComponent);
  //   setKeyValueInputIsLoading(false);
  // }

  const getInputFieldComponentFromInitialValue = () => {
    setValue(initialValue);
  }

  const getInputFieldComponentFromValue = () => {
    const temporaryInputFieldComponent: Array<JSX.Element> = [];
    temporaryInputFieldComponent.push(
      <AdjustableCheckboxInput
        value = { value.length !== 0 ? value[0] : false }
        modificationFunction = { [booleanModificationFunction] }
        disabled = { disabled }
        top = { fieldTop }
        left = { fieldLeft }
        size = { fieldHeight }
      />
    );
    setInputFieldComponent(temporaryInputFieldComponent);
    setKeyValueInputIsLoading(false);
  }

  useEffect(() => {
    getInputFieldComponentFromInitialValue();
  }, [initialValue[0]]);

  useEffect(() => {
    getInputFieldComponentFromValue();
  }, [value[0], modificationFunction, disabled, fieldTop, fieldLeft, fieldHeight]);

  if(keyValueInputIsLoading === true) {
    return(
      <div style = {{ position: "absolute", top: fieldTop, left: fieldLeft }}>
        The input field is loading...
      </div>
    )
  } else {
    return (
      inputFieldComponent[0]
    )
  }

  // return (
  //   <AdjustableCheckboxInput
  //     value = { value.length !== 0 ? value[0] : false }
  //     modificationFunction = { [booleanModificationFunction] }
  //     disabled = { disabled }
  //     top = { fieldTop }
  //     left = { fieldLeft }
  //     size = { fieldHeight }
  //   />
  // )
}

// function ProperInputIndicatorResults(conditions: ConditionsProps): ConditionsResults { // ha esetleg nem működne jó a ProperInputIndicator
//   //

//   return();
// }

const ProperInputIndicator: FC<ProperInputIndicatorProps> = ({ properInputDisplay, valueString, valueNumber, valueBoolean,
  inputType,  conditions, conditionTestFunctions, conditionResultFunctions,
  properInputIndicatorTop, properInputIndicatorLeft, properInputIndicatorSize }) => {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProper, setIsProper] = useState<boolean>(true);
  const [message, setMessage] = useState<Array<string>>([]);

  const getConditionResults = () => {
    //

    let totalErrors: number = 0;
    let errorMessageArray: Array<string> = [];

    let conditionValue: Array<string | number | boolean> = [];
    if(inputType === "SingleRowTextInput" || inputType === "MultipleRowTextInput" || inputType === "SingleSelectInput" || inputType === "PasswordInput" || inputType === "PasswordInputPrimer" || inputType === "PasswordInputSecunder" || inputType === "EmailInput" || inputType === "UrlInput" || inputType === "DateInput") {
      conditionValue = valueString;
    }
    if(inputType === "IntegerInput" || inputType === "NumberInput") {
      conditionValue = valueNumber;
    }
    if(inputType === "CheckboxInput") {
      conditionValue = valueBoolean;
    }

    if(inputType === "EmailInput") {
      // let emailTest = containCondition(conditionValue, ['@']);
      let emailTest = emailInputTest(valueString);
      if(emailTest.length !== 0) {
        if(emailTest[0] === false) { // emailTest !== [false]
          totalErrors = totalErrors + 1;
          errorMessageArray.push("Invalid email address");
        }
      }
    }

    for(let i: number = 0; i < conditions.length; i++) {
      let conditionArgument: Array<string | number | boolean> = [];
      if(conditions[i].conditionArgument.length !== 0) {
        conditionArgument = conditions[i].conditionArgument;
      }

      let conditionResult: Array<boolean> = [];

      switch(conditions[i].conditionType) {
        case("equalCondition"):
          conditionResult = equalCondition(conditionValue, conditionArgument);
          break;
        case("notEqualCondition"):
          conditionResult = notEqualCondition(conditionValue, conditionArgument);
          break;
        case("blankCondition"):
          conditionResult = blankCondition(conditionValue);
          break;
        case("notBlankCondition"):
          conditionResult = notBlankCondition(conditionValue);
          break;
        case("greaterThanCondition"):
          conditionResult = greaterThanCondition(conditionValue, conditionArgument);
          break;
        case("lessThanCondition"):
          conditionResult = lessThanCondition(conditionValue, conditionArgument);
          break;
        case("greaterThanOrEqualCondition"):
          conditionResult = greaterThanOrEqualCondition(conditionValue, conditionArgument);
          break;
        case("lessThanOrEqualCondition"):
          conditionResult = lessThanOrEqualCondition(conditionValue, conditionArgument);
          break;
        case("containCondition"):
          conditionResult = containCondition(conditionValue, conditionArgument);
          break;
        case("notContainCondition"):
          conditionResult = notContainCondition(conditionValue, conditionArgument);
          break;
        case("partOfCondition"):
          conditionResult = partOfCondition(conditionValue, conditionArgument);
          break;
        case("notPartOfCondition"):
          conditionResult = notPartOfCondition(conditionValue, conditionArgument);
          break;
        case("numberOfCharactersGreaterThanOrEqual"):
          conditionResult = numberOfCharactersGreaterThanOrEqual(conditionValue, conditionArgument);
          break;
        case("numberOfCharactersLessThanOrEqual"):
          conditionResult = numberOfCharactersLessThanOrEqual(conditionValue, conditionArgument);
          break;
      }
      if(conditionResult.length !== 0) {
        if(conditionResult[0] === false) {
          totalErrors = totalErrors + 1;
          if(conditions[i].errorMessage.length !== 0) {
            errorMessageArray.push(conditions[i].errorMessage[0]);
          }
        }
      }
    }

    for(let i: number = 0; i < conditionTestFunctions.length; i++) {
      //

      const conditionTestFunctionErrorMessage: Array<string> = conditionTestFunctions[i]();

      if(conditionTestFunctionErrorMessage.length !== 0) {
        totalErrors = totalErrors + 1;
        errorMessageArray.push(conditionTestFunctionErrorMessage[0]);
      }
    }

    let errorMessage: string = "";

    if(errorMessageArray.length !== 0) {
      errorMessage = errorMessageArray[0];

      for(let i: number = 1; i < errorMessageArray.length; i++) {
        errorMessage = errorMessage + "; " + errorMessageArray[i];
      }
    }

    // lefuttatja a conditionResultFunctions függvényeket ???

    for(let i: number = 0; i < conditionResultFunctions.length; i++) {
      conditionResultFunctions[i](errorMessageArray.length === 0 ? true : false)
    }

    if(errorMessage !== "") {
      setMessage([errorMessage]);
    } else {
      setMessage([]);
    }
    if(totalErrors !== 0) {
      setIsProper(false);
    } else {
      setIsProper(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getConditionResults();
  }, [valueString, valueNumber, valueBoolean]);

  if(properInputDisplay === true) {
    if(isLoading === true) {
      return(
        <div
          style = {{ position: "absolute", top: properInputIndicatorTop, left: properInputIndicatorLeft }}
        />
      )
    } else {
      if(isProper === true) {
        return(
          <div
            style = {{ position: "absolute", top: properInputIndicatorTop, left: properInputIndicatorLeft }}
          />
        )
      } else {
        return(
          <AdjustableIcon
          iconType = { "ErrorOutlineIcon" }
          message = { message }
          onClickFunctions = { [] }
          top = { properInputIndicatorTop !== undefined ? properInputIndicatorTop : 0 }
          left = { properInputIndicatorLeft !== undefined ? properInputIndicatorLeft : 0 }
          size = { properInputIndicatorSize !== undefined ? properInputIndicatorSize : 0 }
          color = { "red" }
        />
        )
        
      }
    }
  } else {
    return(
      <div
        style = {{ position: "absolute", top: properInputIndicatorTop, left: properInputIndicatorLeft }}
      />
    )
  }
}

export const KeyValueInput: FC<KeyValueInputProps> = ({ keyText, initialValueString, initialValueNumber, initialValueBoolean,
  optionsString, inputType, modificationFunctions, properInputDisplay, conditions, conditionTestFunctions, conditionResultFunctions,
  hidden, disabled, prototypeDisplayParameters, changedDisplayParameters,
  properInputIndicatorTop, properInputIndicatorLeft, properInputIndicatorSize }) => {
  const [keyValueInputIsLoading, setKeyValueInputIsLoading] = useState<boolean>(true);
  const [inputFieldComponent, setInputFieldComponent] = useState<Array<JSX.Element>>([]);

  const [valueString, setValueString] = useState<Array<string>>(initialValueString);
  const [valueNumber, setValueNumber] = useState<Array<number>>(initialValueNumber);
  const [valueBoolean, setValueBoolean] = useState<Array<boolean>>(initialValueBoolean);

  const [keyTop, setKeyTop] = useState<number>(prototypeDisplayParameters.keyTop);
  const [keyLeft, setKeyLeft] = useState<number>(prototypeDisplayParameters.keyLeft);
  const [keyWidth, setKeyWidth] = useState<number>(prototypeDisplayParameters.keyWidth);
  const [keyFontSize, setKeyFontSize] = useState<number>(prototypeDisplayParameters.keyFontSize);
  const [keyColor, setKeyColor] = useState<string>(prototypeDisplayParameters.keyColor);
  const [fieldTop, setFieldTop] = useState<number>(prototypeDisplayParameters.fieldTop);
  const [fieldLeft, setFieldLeft] = useState<number>(prototypeDisplayParameters.fieldLeft);
  const [fieldHeight, setFieldHeight] = useState<number>(prototypeDisplayParameters.fieldHeight);
  const [fieldWidth, setFieldWidth] = useState<number>(prototypeDisplayParameters.fieldWidth);
  const [fieldBackgroundColor, setFieldBackgroundColor] = useState<string>(prototypeDisplayParameters.fieldBackgroundColor);
  const [valueFontSize, setValueFontSize] = useState<number>(prototypeDisplayParameters.valueFontSize);
  const [valueColor, setValueColor] = useState<string>(prototypeDisplayParameters.valueColor);
  

  const stringModificationFunction = (v: Array<string>) => {
    setValueString(v);
    // if(modificationFunction.length > 0) {
    //   modificationFunction[0](v);
    // }
    if(modificationFunctions !== undefined) {
      for(let i: number = 0; i < modificationFunctions.length; i++) {
        modificationFunctions[i](v);
      }
    }
  }

  const numberModificationFunction = (v: Array<number>) => {
    setValueNumber(v);
    // if(modificationFunction.length > 0) {
    //   modificationFunction[0](v);
    // }
    if(modificationFunctions !== undefined) {
      for(let i: number = 0; i < modificationFunctions.length; i++) {
        modificationFunctions[i](v);
      }
    }
  }

  const booleanModificationFunction = (v: Array<boolean>) => {
    setValueBoolean(v);
    // if(modificationFunction.length > 0) {
    //   modificationFunction[0](v);
    // }
    if(modificationFunctions !== undefined) {
      for(let i: number = 0; i < modificationFunctions.length; i++) {
        modificationFunctions[i](v);
      }
    }
  }

  const getInputFieldComponent = () => {
    const temporaryInputFieldComponent: Array<JSX.Element> = [];

    switch(inputType) {
      case("SingleRowTextInput"):
        temporaryInputFieldComponent.push(
          <SingleRowTextInput
            initialValue = { initialValueString }
            modificationFunction = { [stringModificationFunction] }
            disabled = { disabled }
            fieldTop = { fieldTop }
            fieldLeft = { fieldLeft }
            fieldHeight = { fieldHeight }
            fieldWidth = { fieldWidth }
            fieldBackgroundColor = { fieldBackgroundColor }
            valueFontSize = { valueFontSize }
            valueColor = { valueColor }
          />
        );
        break;
      case("MultipleRowTextInput"):
        temporaryInputFieldComponent.push(
          <MultipleRowTextInput
            initialValue = { initialValueString }
            modificationFunction = { [stringModificationFunction] }
            disabled = { disabled }
            fieldTop = { fieldTop }
            fieldLeft = { fieldLeft }
            fieldHeight = { fieldHeight }
            fieldWidth = { fieldWidth }
            fieldBackgroundColor = { fieldBackgroundColor }
            valueFontSize = { valueFontSize }
            valueColor = { valueColor }
          />
        );
        break;
      case("SingleSelectInput"):
        temporaryInputFieldComponent.push(
          <SingleSelectInput
            initialValue = { initialValueString }
            options = { optionsString }
            modificationFunction = { [stringModificationFunction] }
            disabled = { disabled }
            fieldTop = { fieldTop }
            fieldLeft = { fieldLeft }
            fieldHeight = { fieldHeight }
            fieldWidth = { fieldWidth }
            fieldBackgroundColor = { fieldBackgroundColor }
            valueFontSize = { valueFontSize }
            valueColor = { valueColor }
          />
        );
        break;
      case("PasswordInputPrimer"):
        temporaryInputFieldComponent.push(
          <PasswordInputPrimer
            initialValue = { initialValueString }
            modificationFunction = { [stringModificationFunction] }
            disabled = { disabled }
            fieldTop = { fieldTop }
            fieldLeft = { fieldLeft }
            fieldHeight = { fieldHeight }
            fieldWidth = { fieldWidth }
            fieldBackgroundColor = { fieldBackgroundColor }
            valueFontSize = { valueFontSize }
            valueColor = { valueColor }
          />
        );
        break;
      case("PasswordInputSecunder"):
        temporaryInputFieldComponent.push(
          <PasswordInputSecunder
            initialValue = { initialValueString }
            modificationFunction = { [stringModificationFunction] }
            disabled = { disabled }
            fieldTop = { fieldTop }
            fieldLeft = { fieldLeft }
            fieldHeight = { fieldHeight }
            fieldWidth = { fieldWidth }
            fieldBackgroundColor = { fieldBackgroundColor }
            valueFontSize = { valueFontSize }
            valueColor = { valueColor }
          />
        );
        break;
      case("EmailInput"):
        temporaryInputFieldComponent.push(
          <EmailInput
            initialValue = { initialValueString }
            modificationFunction = { [stringModificationFunction] }
            disabled = { disabled }
            fieldTop = { fieldTop }
            fieldLeft = { fieldLeft }
            fieldHeight = { fieldHeight }
            fieldWidth = { fieldWidth }
            fieldBackgroundColor = { fieldBackgroundColor }
            valueFontSize = { valueFontSize }
            valueColor = { valueColor }
          />
        );
        break;
      case("UrlInput"):
        temporaryInputFieldComponent.push(
          <UrlInput
            initialValue = { initialValueString }
            modificationFunction = { [stringModificationFunction] }
            disabled = { disabled }
            fieldTop = { fieldTop }
            fieldLeft = { fieldLeft }
            fieldHeight = { fieldHeight }
            fieldWidth = { fieldWidth }
            fieldBackgroundColor = { fieldBackgroundColor }
            valueFontSize = { valueFontSize }
            valueColor = { valueColor }
          />
        );
        break;
      case("DateInput"):
        temporaryInputFieldComponent.push(
          <DateInput
            initialValue = { initialValueString }
            modificationFunction = { [stringModificationFunction] }
            disabled = { disabled }
            fieldTop = { fieldTop }
            fieldLeft = { fieldLeft }
            fieldHeight = { fieldHeight }
            fieldWidth = { fieldWidth }
            fieldBackgroundColor = { fieldBackgroundColor }
            valueFontSize = { valueFontSize }
            valueColor = { valueColor }
          />
        );
        break;
      case("IntegerInput"):
        temporaryInputFieldComponent.push(
          <IntegerInput
            initialValue = { initialValueNumber }
            modificationFunction = { [numberModificationFunction] }
            disabled = { disabled }
            fieldTop = { fieldTop }
            fieldLeft = { fieldLeft }
            fieldHeight = { fieldHeight }
            fieldWidth = { fieldWidth }
            fieldBackgroundColor = { fieldBackgroundColor }
            valueFontSize = { valueFontSize }
            valueColor = { valueColor }
          />
        );
        break;
      case("NumberInput"):
        temporaryInputFieldComponent.push(
          <NumberInput
            initialValue = { initialValueNumber }
            modificationFunction = { [numberModificationFunction] }
            disabled = { disabled }
            fieldTop = { fieldTop }
            fieldLeft = { fieldLeft }
            fieldHeight = { fieldHeight }
            fieldWidth = { fieldWidth }
            fieldBackgroundColor = { fieldBackgroundColor }
            valueFontSize = { valueFontSize }
            valueColor = { valueColor }
          />
        );
        break;
      case("CheckboxInput"):
        temporaryInputFieldComponent.push(
          <CheckboxInput
            initialValue = { initialValueBoolean }
            modificationFunction = { [booleanModificationFunction] }
            disabled = { disabled }
            fieldTop = { fieldTop }
            fieldLeft = { fieldLeft }
            fieldHeight = { fieldHeight }
            fieldWidth = { fieldWidth }
            fieldBackgroundColor = { fieldBackgroundColor }
            valueFontSize = { valueFontSize }
            valueColor = { valueColor }
          />
        );
        break;
      default:
        temporaryInputFieldComponent.push(
          <div style = {{ position: "absolute", top: fieldTop, left: fieldLeft  }}>
            No such input type
          </div>
        );
        break;
    }
    setInputFieldComponent(temporaryInputFieldComponent);
    setKeyValueInputIsLoading(false);
  }


  useEffect(() => {
    getInputFieldComponent();
  }, [initialValueString[0], initialValueNumber[0], initialValueBoolean[0], keyTop, keyLeft, keyWidth, keyFontSize, keyColor, fieldTop, fieldLeft, fieldHeight, fieldWidth, fieldBackgroundColor, valueFontSize, valueColor]);

  useEffect(() => {
    if(changedDisplayParameters !== undefined) {
      if(changedDisplayParameters.keyTop !== undefined) {
        setKeyTop(changedDisplayParameters.keyTop);
      }
      if(changedDisplayParameters.keyLeft !== undefined) {
        setKeyLeft(changedDisplayParameters.keyLeft);
      }
      if(changedDisplayParameters.keyWidth !== undefined) {
        setKeyWidth(changedDisplayParameters.keyWidth);
      }
      if(changedDisplayParameters.keyFontSize !== undefined) {
        setKeyFontSize(changedDisplayParameters.keyFontSize);
      }
      if(changedDisplayParameters.keyColor !== undefined) {
        setKeyColor(changedDisplayParameters.keyColor);
      }
      if(changedDisplayParameters.fieldTop !== undefined) {
        setFieldTop(changedDisplayParameters.fieldTop);
      }
      if(changedDisplayParameters.fieldLeft !== undefined) {
        setFieldLeft(changedDisplayParameters.fieldLeft);
      }
      if(changedDisplayParameters.fieldHeight !== undefined) {
        setFieldHeight(changedDisplayParameters.fieldHeight);
      }
      if(changedDisplayParameters.fieldWidth !== undefined) {
        setFieldWidth(changedDisplayParameters.fieldWidth);
      }
      if(changedDisplayParameters.fieldBackgroundColor !== undefined) {
        setFieldBackgroundColor(changedDisplayParameters.fieldBackgroundColor);
      }
      if(changedDisplayParameters.valueFontSize !== undefined) {
        setValueFontSize(changedDisplayParameters.valueFontSize);
      }
      if(changedDisplayParameters.valueColor !== undefined) {
        setValueColor(changedDisplayParameters.valueColor);
      }
    }
  }, [changedDisplayParameters]);


  if(keyValueInputIsLoading === true) {
    return(
      <div style = {{ position: "absolute", top: keyTop, left: keyLeft }}>
        The key value input is loading...
      </div>
    )
  } else {
    return (
      <div>
        {
          (keyText.length !== 0) ?
          (<AdjustableSingleRowTextDisplay
            text = { ( keyText + ':' ) }
            redirectionUrl = { [] }
            message = { [] }
            top = { keyTop }
            left = { keyLeft }
            width = { keyWidth }
            fontSize = { keyFontSize }
            color = { keyColor }
          />) : (<div/>)
        }
        { inputFieldComponent[0] }
        { properInputDisplay === true ? (
          <ProperInputIndicator
            properInputDisplay = { properInputDisplay }
            valueString = { valueString }
            valueNumber = { valueNumber }
            valueBoolean = { valueBoolean }
            inputType = { inputType }
            conditions = { conditions !== undefined ? conditions : [] }
            conditionTestFunctions = { conditionTestFunctions !== undefined ? conditionTestFunctions : [] }
            conditionResultFunctions = { conditionResultFunctions !== undefined ? conditionResultFunctions : [] }
            properInputIndicatorTop = { properInputIndicatorTop !== undefined ? properInputIndicatorTop[0] : 0 }
            properInputIndicatorLeft = { properInputIndicatorLeft !== undefined ? properInputIndicatorLeft[0] : 0 }
            properInputIndicatorSize = { properInputIndicatorSize !== undefined ? properInputIndicatorSize[0] : 0 }
          />) : <div/>
        }
      </div>
    )
  }
}
