import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { AdjustableCollection } from '../04_subComponents/Collection';
import { AdjustableImageDisplay, AdjustableVideoDisplay } from '../04_subComponents/MediaDisplay';
import { ImageOrVideo } from './ImageAndVideoUploader/UploadedImageAndVideoElement';





export interface ImagesAndVideosProps {
  imagesAndVideos: Array<ImageOrVideo>,
  collectionTop: number,
  collectionLeft: number,
  elementHeight: number,
  elementWidth: number,
  paginationTop: number,
  paginationLeft: number,
  paginationWidth: number,
  paginationFontSize: number
}





export const ImagesAndVideos: FC<ImagesAndVideosProps> = ({ imagesAndVideos, collectionTop, collectionLeft, elementHeight, elementWidth, paginationTop, paginationLeft, paginationWidth, paginationFontSize }) => {
  const [paginationIsLoading, setPaginationIsLoading] = useState<boolean>(true);

  const [paginationSingleElements, setPaginationSingleElements] = useState<JSX.Element[]>([]);

  const getPaginationSingleElements = () => {
    const temporaryPaginationSingleElements: JSX.Element[] = [];
    for(let i: number = 0; i < imagesAndVideos.length; i++) {
      if(imagesAndVideos[i].image.length !== 0) {
        temporaryPaginationSingleElements.push(
          <AdjustableImageDisplay
            uploadedImage = { [] }
            sourceUrl = { [imagesAndVideos[i].image[0].sourceUrl[0]] }
            redirectionUrl = { [] }
            message = { [] }
            top = { 0 }
            left = { 0 }
            height = { elementHeight }
            width = { elementWidth }
            key = { i }
          />
        )
      } else {
        if(imagesAndVideos[i].video.length !== 0) {
          temporaryPaginationSingleElements.push(
            <AdjustableVideoDisplay
              sourceUrl = { imagesAndVideos[i].video[0].sourceUrl[0] }
              top = { 0 }
              left = { 0 }
              height = { elementHeight }
              width = { elementWidth }
              key = { i }
            />
          )
        } else {
          temporaryPaginationSingleElements.push(
            <div>
              Wrong ImagesAndVideos type
            </div>
          )
        }
      }
    }
    setPaginationSingleElements(temporaryPaginationSingleElements);
    setPaginationIsLoading(false);
  }

  useEffect(() => {
    getPaginationSingleElements();
  }, []);


  if(paginationIsLoading === true) {
    return(
      <div>
        Pagination is loading
      </div>
    )
  } else {
    return(
      <AdjustableCollection
        collectionType = { "FilmCollection" }
        collectionTop = { collectionTop }
        collectionLeft = { collectionLeft }
        n = { 1 }
        m = { 1 }
        elementHeight = { elementHeight }
        elementWidth = { elementWidth }
        paginationTop = { paginationTop }
        paginationLeft = { paginationLeft }
        paginationWidth = { paginationWidth }
        paginationFontSize = { paginationFontSize }
        selectedElementsAreChanged = { 0 }
      >
        { paginationSingleElements }
      </AdjustableCollection>
    )
  }
}
