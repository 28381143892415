import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../../03_baseComponents/Box';
import { SingleRowText, SingleRowPrototypeDisplayParameters, SingleRowChangedDisplayParameters } from '../../../../03_baseComponents/SingleRowText';
import { KeyValueInput, KeyValueInputPrototypeDisplayParameters, KeyValueInputChangedDisplayParameters } from '../../../../03_baseComponents/KeyValueInput';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../../03_baseComponents/Button';
import { UserListSettings, UserListFilteringSettings, UserListSortingSettings } from '../../../../../models/UserListSettings';





export interface AdminUsersFilteringProps {
  webpageStyle: WebpageStyleProps,
  recentUserListFilteringSettings: UserListFilteringSettings,
  changeUserListFilteringSettings: Array<any>
}





export const AdminUsersFiltering: FC<AdminUsersFilteringProps> = ({ webpageStyle, recentUserListFilteringSettings,
  changeUserListFilteringSettings }) => {
  //

  const [filterable, setFilterable] = useState<boolean>(false);
  const [resettable, setResettable] = useState<boolean>((recentUserListFilteringSettings.keyWords.length !== 0 ||
    recentUserListFilteringSettings.role.length !== 0 || recentUserListFilteringSettings.status.length !== 0) ? true : false);

  const [recentlyReset, setRecentlyReset] = useState<boolean>(false);

  const [roleOptions, setRoleOptions] = useState<Array<string>>([" ", "customer", "administrator", "account owner"]);
  const [statusOptions, setStatusOptions] = useState<Array<string>>([" ", "active", "deactivated"]);
  

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.adminUserList.filtering.boxTop,
    left: webpageStyle.content.sidebar.filteringAndSorting.boxLeft,
    height: webpageStyle.content.sidebar.adminUserList.filtering.boxHeight,
    width: webpageStyle.content.sidebar.filteringAndSorting.boxWidth,
    backgroundColor: webpageStyle.content.sidebar.filteringAndSorting.boxBackgroundColor,
    outlineStyle: webpageStyle.content.sidebar.filteringAndSorting.boxOutlineStyle,
    outlineWidth: webpageStyle.content.sidebar.filteringAndSorting.boxOutlineWidth,
    outlineColor: webpageStyle.content.sidebar.filteringAndSorting.boxOutlineColor
  });


  const [functionTitleDisplayParameters, setFunctionTitleDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.filteringAndSorting.functionTitleTop,
    left: webpageStyle.content.sidebar.filteringAndSorting.functionTitleLeft,
    width: webpageStyle.content.sidebar.filteringAndSorting.functionTitleWidth,
    fontSize: webpageStyle.content.sidebar.filteringAndSorting.functionTitleFontSize,
    color: webpageStyle.content.sidebar.filteringAndSorting.functionTitleFontColor
  });

  const [headerPrototypeDisplayParameters, setHeaderPrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.filteringAndSorting.headerTop,
    left: 0,
    width: webpageStyle.content.sidebar.filteringAndSorting.headerWidth,
    fontSize: webpageStyle.content.sidebar.filteringAndSorting.headerFontSize,
    color: webpageStyle.content.sidebar.filteringAndSorting.headerFontColor
  });

  const [firstInputTop, setFirstInputTop] = useState<number>(webpageStyle.content.sidebar.filteringAndSorting.firstValueTop);
  const [inputTopDistance, setInputTopDistance] = useState<number>(webpageStyle.content.sidebar.filteringAndSorting.valueTopDistance);

  const [keyLeft, setKeyLeft] = useState<number>(webpageStyle.content.sidebar.filteringAndSorting.keyLeft);
  const [keyWidth, setKeyWidth] = useState<number>();
  const [keyFontSize, setKeyFontSize] = useState<number>();
  const [keyFontColor, setKeyFontColor] = useState<string>();

  const [fieldLeft, setFieldLeft] = useState<number>(webpageStyle.content.sidebar.filteringAndSorting.fieldLeft);
  const [fieldHeight, setFieldHeight] = useState<number>();
  const [fieldWidth, setFieldWidth] = useState<number>();
  const [fieldBackgroundColor, setFieldBackgroundColor] = useState<string>();
  const [valueFontSize, setValueFontSize] = useState<number>();
  const [valueFontColor, setValueFontColor] = useState<string>();

  const [keyValueInputPrototypeDisplayParameters, setKeyValueInputPrototypeDisplayParameters] = useState<KeyValueInputPrototypeDisplayParameters>({
    keyTop: firstInputTop,
    keyLeft: keyLeft,
    keyWidth: webpageStyle.content.sidebar.filteringAndSorting.keyWidth,
    keyFontSize: webpageStyle.content.sidebar.filteringAndSorting.keyFontSize,
    keyColor: webpageStyle.content.sidebar.filteringAndSorting.keyFontColor,
    fieldTop: firstInputTop,
    fieldLeft: fieldLeft,
    fieldHeight: webpageStyle.content.sidebar.filteringAndSorting.fieldHeight,
    fieldWidth: webpageStyle.content.sidebar.filteringAndSorting.fieldWidth,
    fieldBackgroundColor: webpageStyle.content.sidebar.filteringAndSorting.fieldBackgroundColor,
    valueFontSize: webpageStyle.content.sidebar.filteringAndSorting.valueFontSize,
    valueColor: webpageStyle.content.sidebar.filteringAndSorting.valueFontColor,
  });

  const [filterButtonBackgroundColorActive, setFilterButtonBackgroundColorActive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonBackgroundColorActive);
  const [filterButtonBackgroundColorInactive, setFilterButtonBackgroundColorInactive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonBackgroundColorInactive);
  const [filterButtonContentColorActive, setFilterButtonContentColorActive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonContentColorActive);
  const [filterButtonContentColorInactive, setFilterButtonContentColorInactive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonContentColorInactive);

  const [filterButtonPrototypeDisplayParameters, setFilterButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.adminUserList.filtering.buttonTop,
    left: webpageStyle.content.sidebar.filteringAndSorting.filtering.filterButtonLeft,
    height: webpageStyle.content.sidebar.filteringAndSorting.buttonHeight,
    width: webpageStyle.content.sidebar.filteringAndSorting.buttonWidth,
    backgroundColor: filterButtonBackgroundColorActive,
    fontSize: webpageStyle.content.sidebar.filteringAndSorting.buttonFontSize,
    contentColor: filterButtonContentColorActive
  });

  const [resetButtonBackgroundColorActive, setResetButtonBackgroundColorActive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonBackgroundColorActive);
  const [resetButtonBackgroundColorInactive, setResetButtonBackgroundColorInactive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonBackgroundColorInactive);
  const [resetButtonContentColorActive, setResetButtonContentColorActive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonContentColorActive);
  const [resetButtonContentColorInactive, setResetButtonContentColorInactive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonContentColorInactive);

  const [resetButtonPrototypeDisplayParameters, setResetButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.adminUserList.filtering.buttonTop,
    left: webpageStyle.content.sidebar.filteringAndSorting.filtering.resetButtonLeft,
    height: webpageStyle.content.sidebar.filteringAndSorting.buttonHeight,
    width: webpageStyle.content.sidebar.filteringAndSorting.buttonWidth,
    backgroundColor: resetButtonBackgroundColorActive,
    fontSize: webpageStyle.content.sidebar.filteringAndSorting.buttonFontSize,
    contentColor: resetButtonContentColorActive
  });

  const [keyWords, setKeyWords] = useState<Array<string>>(recentUserListFilteringSettings.keyWords);
  const [role, setRole] = useState<Array<string>>(recentUserListFilteringSettings.role);
  const [status, setStatus] = useState<Array<string>>(recentUserListFilteringSettings.status);


  function changeKeyWordsValue(newParameter: Array<string>): void {
    if(newParameter.length !== 0) {
      let temporaryKeyWords = newParameter[0].split(" ");
      for(let i: number = 0; i < temporaryKeyWords.length; i++) {
        if(temporaryKeyWords[i] === "") {
          temporaryKeyWords.splice(i, 1);
          i--;
        }
      }
      setKeyWords(temporaryKeyWords);
    } else {
      setKeyWords([]);
    }
  }

  const changeRoleValue = (newParameter: Array<string>) => {
    if(newParameter.length !== 0) {
      if(newParameter[0] !== " ") {
        setRole(newParameter);
      } else {
        setRole([]);
      }
    }
  }

  const changeStatusValue = (newParameter: Array<string>) => {
    if(newParameter.length !== 0) {
      if(newParameter[0] !== " ") {
        setStatus(newParameter);
      } else {
        setStatus([]);
      }
    }
  }

  function getNewParameter(newParameter: Array<string> | Array<number> | Array<boolean>): void {
    setFilterable(true);
    setResettable(true);
  }

  function doFilter(): void {
    //

    changeUserListFilteringSettings[0]({
      keyWords: keyWords,
      role: role,
      status: status
    });
  }

  function doReset(): void {
    //

    setFilterable(false);
    setResettable(false);
    setRecentlyReset(true);

    setKeyWords([]);
    setRole([]);
    setStatus([]);

    changeUserListFilteringSettings[0]({
      keyWords: [],
      role: [],
      status: []
    });
  }

  //

  if(recentlyReset === false) {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      >
        <SingleRowText
          text = { "Filtering" }
          prototypeDisplayParameters = { functionTitleDisplayParameters }
        />

        <SingleRowText
          text = { "Argument" }
          prototypeDisplayParameters = { headerPrototypeDisplayParameters }
          changedDisplayParameters = { {
            left: keyLeft
          } }
        />

        <SingleRowText
          text = { "Input" }
          prototypeDisplayParameters = { headerPrototypeDisplayParameters }
          changedDisplayParameters = { {
            left: keyLeft
          } }
        />

        <KeyValueInput
          keyText = { "Fast filtering" }
          initialValueString = { keyWords }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [changeKeyWordsValue, getNewParameter] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 0 * inputTopDistance,
            fieldTop: firstInputTop + 0 * inputTopDistance
          } }
        />

        <KeyValueInput
          keyText = { "User role" }
          initialValueString = { role }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { roleOptions }
          inputType = { "SingleSelectInput" }
          modificationFunctions = { [changeRoleValue, getNewParameter] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 1 * inputTopDistance,
            fieldTop: firstInputTop + 1 * inputTopDistance
          } }
        />

        <KeyValueInput
          keyText = { "User status" }
          initialValueString = { status }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { statusOptions }
          inputType = { "SingleSelectInput" }
          modificationFunctions = { [changeStatusValue, getNewParameter] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 2 * inputTopDistance,
            fieldTop: firstInputTop + 2 * inputTopDistance
          } }
        />

        <Button
          buttonText = { "Filter" }
          actionFunctions = { [() => doFilter()] }
          disabled = { filterable ? false : true }
          prototypeDisplayParameters = { filterButtonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            backgroundColor: filterable ? filterButtonBackgroundColorActive : filterButtonBackgroundColorInactive,
            contentColor: filterable ? filterButtonContentColorActive : filterButtonContentColorInactive
          } }
        />

        <Button
          buttonText = { "Reset" }
          actionFunctions = { [() => doReset()] } // !!!!!
          disabled = { resettable ? false : true }
          prototypeDisplayParameters = { resetButtonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            backgroundColor: resettable ? resetButtonBackgroundColorActive : resetButtonBackgroundColorInactive,
            contentColor: resettable ? resetButtonContentColorActive : resetButtonContentColorInactive
          } }
        />
      </Box>
    )
  } else {
    setTimeout(() => setRecentlyReset(false), 100);
    return(
      <div>
        Loading...
      </div>
    )
  }
}
