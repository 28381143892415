import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { KeyValueInput, KeyValueInputPrototypeDisplayParameters, KeyValueInputChangedDisplayParameters } from '../../../03_baseComponents/KeyValueInput';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../03_baseComponents/Button';
import { emailInputTest, numberOfCharactersGreaterThanOrEqual, equalCondition } from '../../../../services/Conditions';
import { SingleRowText } from '../../../03_baseComponents/SingleRowText';
import { MultipleRowText, MultipleRowPrototypeDisplayParameters, MultipleRowChangedDisplayParameters } from '../../../03_baseComponents/MultipleRowText';
import { LoggedInUser } from '../../../../models/UserProps';
import { LoginRequest, ChangeEmailRequest, UserFromToken } from '../../../../models/UserProps';
import { postData, patchData } from "../../../../services/API.services";
import jwtDecode from "jwt-decode";
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { resetActionsDone } from '../../../../App';





export interface ChangeUserEmailProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  changeLoggedInUser: Array<any>,
  readUserData: Array<any>,
  resizeMainContentHeight: Array<any>
}





export const ChangeUserEmail: FC<ChangeUserEmailProps> = ({ webpageStyle, loggedInUser, changeLoggedInUser, readUserData, resizeMainContentHeight }) => {
  //

  const [newEmail, setNewEmail] = useState<Array<string>>([loggedInUser.email]);
  const [password, setPassword] = useState<Array<string>>([]);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [statusMessage, setStatusMessage] = useState<string>("");

  
  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.mainContent.changeUserEmail.boxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.boxTop,
    left: webpageStyle.content.mainContent.boxLeft,
    height: boxHeight,
    width: webpageStyle.content.mainContent.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.boxOutlineColor
  });


  const [firstFieldTop, setFirstFieldTop] = useState<number>(webpageStyle.content.mainContent.changeUserEmail.firstFieldTop);
  const [topDistance, setTopDistance] = useState<number>(webpageStyle.content.mainContent.changeUserEmail.topDistance);

  const [keyValueInputPrototypeDisplayParameters, setKeyValueInputPrototypeDisplayParameters] = useState<KeyValueInputPrototypeDisplayParameters>({
    keyTop: firstFieldTop,
    keyLeft: webpageStyle.content.mainContent.changeUserEmail.keyLeft,
    keyWidth: webpageStyle.content.mainContent.changeUserEmail.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.changeUserEmail.keyFontSize,
    keyColor: webpageStyle.content.mainContent.changeUserEmail.keyColor,
    fieldTop: firstFieldTop,
    fieldLeft: webpageStyle.content.mainContent.changeUserEmail.fieldLeft,
    fieldHeight: webpageStyle.content.mainContent.changeUserEmail.fieldHeight,
    fieldWidth: webpageStyle.content.mainContent.changeUserEmail.fieldWidth,
    fieldBackgroundColor: webpageStyle.content.mainContent.changeUserEmail.fieldBackgroundColor,
    valueFontSize: webpageStyle.content.mainContent.changeUserEmail.valueFontSize,
    valueColor: webpageStyle.content.mainContent.changeUserEmail.valueColor,
  });

  const [changeEmailButtonPrototypeDisplayParameters, setChangeEmailButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.changeUserEmail.changeEmailButtonTop,
    left: webpageStyle.content.mainContent.changeUserEmail.changeEmailButtonLeft,
    height: webpageStyle.content.mainContent.changeUserEmail.changeEmailButtonHeight,
    width: webpageStyle.content.mainContent.changeUserEmail.changeEmailButtonWidth,
    backgroundColor: webpageStyle.content.mainContent.changeUserEmail.changeEmailButtonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.changeUserEmail.changeEmailButtonFontSize,
    contentColor: webpageStyle.content.mainContent.changeUserEmail.changeEmailButtonContentColorActive
  });

  const [errorMessagePrototypeDisplayParameters, setErrorMessagePrototypeDisplayParameters] = useState<MultipleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.changeUserEmail.errorMessageTop,
    left: webpageStyle.content.mainContent.changeUserEmail.errorMessageLeft,
    height: webpageStyle.content.mainContent.changeUserEmail.errorMessageHeight,
    width: webpageStyle.content.mainContent.changeUserEmail.errorMessageWidth,
    fontSize: webpageStyle.content.mainContent.changeUserEmail.errorMessageFontSize,
    color: webpageStyle.content.mainContent.changeUserEmail.errorMessageColor
  });

  const [statusMessageTop, setStatusMessageTop] = useState<number>();
  const [statusMessageLeft, setStatusMessageLeft] = useState<number>();
  const [statusMessageHeight, setStatusMessageHeight] = useState<number>();
  const [statusMessageWidth, setStatusMessageWidth] = useState<number>();
  const [statusMessageFontSize, setStatusMessageFontSize] = useState<number>();
  const [statusMessageColor, setStatusMessageColor] = useState<string>();

  const [statusMessagePrototypeDisplayParameters, setStatusMessagePrototypeDisplayParameters] = useState<MultipleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.changeUserEmail.statusMessageTop,
    left: webpageStyle.content.mainContent.changeUserEmail.statusMessageLeft,
    height: webpageStyle.content.mainContent.changeUserEmail.statusMessageHeight,
    width: webpageStyle.content.mainContent.changeUserEmail.statusMessageWidth,
    fontSize: webpageStyle.content.mainContent.changeUserEmail.statusMessageFontSize,
    color: webpageStyle.content.mainContent.changeUserEmail.statusMessageColor
  });


  let history = useHistory();

  const changeEmail = async () => {
    //

    const test: boolean = testConditions();

    if(test === true) {
      const changeEmailRequest: ChangeEmailRequest = {
        newEmail: newEmail[0],
        password: password[0]
      }
      const changeEmailResult = await patchData('/api/users/single/changeemail', changeEmailRequest, true);
      if (!changeEmailResult || (changeEmailResult as { status: number, message: string })?.status >= 400) {
        setErrorMessage(changeEmailResult.message);
      } else {
        let loginUserRequest: LoginRequest = {
          email: newEmail[0],
          password: password[0]
        }
        const loginResult = await postData(`/api/users/single/login`, loginUserRequest);
        if (!loginResult || (loginResult as { status: number, message: string })?.status >= 400) {
          setErrorMessage(loginResult.message);
        } else {
          resetActionsDone();
          localStorage.setItem("user", JSON.stringify(loginResult.token));
          setErrorMessage("");
          setStatusMessage("Email has been changed");
          setPassword([""]);

          readUserData[0]();
          changeLoggedInUser[0](loggedInUser.id, newEmail, loggedInUser.role);
        }
      }
    }
  }

  const testConditions = (): boolean => {
    //
    
    const testEmail = emailInputTest(newEmail);

    if(newEmail.length === 0) {
      setErrorMessage("No new email entered");
        return(false);
    }

    if(testEmail.length !== 0) {
      if(testEmail[0] !== true) {
        setErrorMessage("Not valid email address");
        return(false);
      }
    }

    if(password.length === 0) {
      setErrorMessage("No password entered");
        return(false);
    }

    setErrorMessage("");
    return(true);
  }


  useEffect(() => {
    resizeMainContentHeight[0](boxHeight);
  }, []);


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <KeyValueInput
        keyText = { "New email" }
        initialValueString = { [] }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { [] }
        inputType = { "EmailInput" }
        modificationFunctions = { [setNewEmail, () => {
          setErrorMessage("");
          setStatusMessage("");
        }] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstFieldTop + 0 * topDistance,
          fieldTop: firstFieldTop + 0 * topDistance
        } }
      />

      <KeyValueInput
        keyText = { "Password" }
        initialValueString = { password }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { [] }
        inputType = { "PasswordInputPrimer" }
        modificationFunctions = { [setPassword, () => {
          setErrorMessage("");
          setStatusMessage("");
        }] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstFieldTop + 2 * topDistance,
          fieldTop: firstFieldTop + 2 * topDistance
        } }
      />

      <Button
        buttonText = { "Change email" }
        actionFunctions = { [changeEmail] }
        disabled = { false }
        prototypeDisplayParameters = { changeEmailButtonPrototypeDisplayParameters }
      />

      {/* <SingleRowText
        text = { errorMessage }
        redirectionUrl = { [] }
        message = { [] }
        top = { errorMessageTop }
        left = { errorMessageLeft }
        width = { errorMessageWidth }
        fontSize = { errorMessageFontSize }
        color = { errorMessageColor }
      /> */}

      { errorMessage.length !== 0 ?
        <MultipleRowText
          text = { errorMessage }
          prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
        /> : <div/>
      }
      
      { statusMessage.length !== 0 ?
        <MultipleRowText
          text = { statusMessage }
          prototypeDisplayParameters = { statusMessagePrototypeDisplayParameters }
        /> : <div/>
      }
    </Box>
  )
}
