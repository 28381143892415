import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../services/webpageStyle';
import { UserData } from '../../../models/UserProps';
import { getData } from '../../../services/API.services';
import { UserProfileNavigation } from './sidebar/UserProfileNavigation';
import { ReadUserData } from './mainContent/ReadUserData';
import { EditUserData } from './mainContent/EditUserData';
import { ChangeUserEmail } from './mainContent/ChangeUserEmail';
import { ChangeUserPassword } from './mainContent/ChangeUserPassword';
import { LoggedInUser } from '../../../models/UserProps';





export interface UserProfilePageContentProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  changeLoggedInUser: Array<any>,
  doAction: Array<any>,
  resizeContentHeight: Array<any>
}





export const UserProfilePageContent: FC<UserProfilePageContentProps> = ({ webpageStyle, loggedInUser,
  changeLoggedInUser, doAction, resizeContentHeight }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [userProfilePageContentType, setUserProfilePageContentType] = useState<string>("readUserData");

  const [userData, setUserData] = useState<Array<UserData>>([]);

  const [userPageNavigationTop, setUserPageNavigationTop] = useState<number>(100);
  const [userPageNavigationLeft, setUserPageNavigationLeft] = useState<number>(100);

  const [userPageContentTop, setUserPageContentTop] = useState<number>(100);
  const [userPageContentLeft, setUserPageContentLeft] = useState<number>(400);


  const [contentHeight, setContentHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);

  const [mainContentHeight, setMainContentHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);
  const [sidebarHeight, setSidebarHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);


  const resizeMainContentHeight = (newMainContentHeight: number) => {
    setMainContentHeight(newMainContentHeight);
  }

  const resizeSidebarHeight = (newSidebarHeight: number) => {
    setSidebarHeight(newSidebarHeight);
  }

  const saveUserData = (userVariable: Array<UserData>) => {
    setUserData(userVariable);
  }

  const readUserData = async () => {
    if(loggedInUser.id !== 0) {
      const result = await getData(`/api/users/single/read`, true);
      if( !result || ( result as { status: number, message: string } )?.status >= 400 ) {
        if(result !== undefined) {
          if(result.message !== undefined) {
            console.log(result.message);
            setIsLoading(false);
          }
        }
      } else {
        //

        // console.log(result);

        if(result.length !== 0) {
          saveUserData(result);
        }

        setIsLoading(false);
      }
    }
  }

  const changeUserProfilePageContent = (typeOf: string) => {
    if(typeOf === "readUserData" || typeOf === "changeUserData" || typeOf === "changeUserEmail" || typeOf === "changeUserPassword" || typeOf === "readInterestingHorses" || typeOf === "readRequestedProposals") {
      setUserProfilePageContentType(typeOf);
    } else {
      if(typeOf === "logout") {
        // logout...
      }
    }
  }


  useEffect(() => {
    setContentHeight(Math.max(mainContentHeight, sidebarHeight));
    resizeContentHeight[0](Math.max(mainContentHeight, sidebarHeight));
  }, [mainContentHeight, sidebarHeight]);

  useEffect(() => {
    readUserData();
  }, []);


  return(
    <div>
      <UserProfileNavigation
        webpageStyle = { webpageStyle }
        userProfilePageContentType = { userProfilePageContentType }
        changeUserProfilePageContentType = { [setUserProfilePageContentType] }
        resizeSidebarHeight = { [resizeSidebarHeight] }
      />

      
      { (isLoading === false && userProfilePageContentType === "readUserData") ?
        <ReadUserData
          webpageStyle = { webpageStyle }
          userData = { userData }
          resizeMainContentHeight = { [resizeMainContentHeight] }
        /> : null
      }

      { (isLoading === false && userProfilePageContentType === "editUserData") ?
        <EditUserData
          webpageStyle = { webpageStyle }
          loggedInUser = { loggedInUser }
          userData = { userData }
          readUserData = { [readUserData] }
          resizeMainContentHeight = { [resizeMainContentHeight] }
        /> : null
      }

      { (isLoading === false && userProfilePageContentType === "changeUserEmail") ?
        <ChangeUserEmail
          webpageStyle = { webpageStyle }
          loggedInUser = { loggedInUser }
          changeLoggedInUser = { changeLoggedInUser }
          readUserData = { [readUserData] }
          resizeMainContentHeight = { [resizeMainContentHeight] }
        /> : null
      }

      { (isLoading === false && userProfilePageContentType === "changeUserPassword") ?
        <ChangeUserPassword
          webpageStyle = { webpageStyle }
          loggedInUser = { loggedInUser }
          resizeMainContentHeight = { [resizeMainContentHeight] }
        /> : null
      }
    </div>
  )
}
