import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../../03_baseComponents/Box';
import { KeyValueDisplay, KeyValueDisplayPrototypeDisplayParameters, KeyValueDisplayChangedDisplayParameters } from '../../../../03_baseComponents/KeyValueDisplay';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../../03_baseComponents/Button';
import { MultipleRowText, MultipleRowPrototypeDisplayParameters, MultipleRowChangedDisplayParameters } from '../../../../03_baseComponents/MultipleRowText';
import { UserData } from '../../../../../models/UserProps';
import { LoggedInUser } from '../../../../../models/UserProps';
import { patchData, deleteData } from '../../../../../services/API.services';





export interface AdminUserListElementProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  initialUser: Array<UserData>,
  changeAdminUserListPageContentType: Array<any>,
  overwriteDeletedUser: Array<any>,
  changeSelectedUserId: Array<any>
}





export const AdminUserListElement: FC<AdminUserListElementProps> = ({ webpageStyle, loggedInUser,
  initialUser, changeAdminUserListPageContentType, overwriteDeletedUser, changeSelectedUserId }) => {
  //

  const [inAction, setInAction] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string>("");
  const [actionOnwardMessage, setActionOnwardMessage] = useState<string>("");

  const [user, setUser] = useState<Array<UserData>>(initialUser);
  // const [userDeleted, setUserDeleted] = useState<boolean>(initialUser[0].status);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.adminUserList.listElement.boxTop,
    left: webpageStyle.content.mainContent.adminUserList.listElement.boxLeft,
    height: webpageStyle.content.mainContent.adminUserList.listElement.boxHeight,
    width: webpageStyle.content.mainContent.adminUserList.listElement.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.adminUserList.listElement.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.adminUserList.listElement.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.adminUserList.listElement.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.adminUserList.listElement.boxOutlineColor
  });

  const [firstDataTop, setFirstDataTop] = useState<number>(webpageStyle.content.mainContent.adminUserList.listElement.firstDataTop);
  const [dataTopDistance, setDataTopDistance] = useState<number>(webpageStyle.content.mainContent.adminUserList.listElement.dataTopDistance);

  const [keyValueDisplayPrototypeDisplayParameters, setKeyValueDisplayPrototypeDisplayParameters] = useState<KeyValueDisplayPrototypeDisplayParameters>({
    keyTop: firstDataTop,
    keyLeft: webpageStyle.content.mainContent.adminUserList.listElement.keyLeft,
    keyWidth: webpageStyle.content.mainContent.adminUserList.listElement.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.adminUserList.listElement.keyFontSize,
    keyColor: webpageStyle.content.mainContent.adminUserList.listElement.keyColor,
    valueTop: firstDataTop,
    valueLeft: webpageStyle.content.mainContent.adminUserList.listElement.valueLeft,
    valueWidth: webpageStyle.content.mainContent.adminUserList.listElement.valueWidth,
    valueFontSize: webpageStyle.content.mainContent.adminUserList.listElement.valueFontSize,
    valueColor: webpageStyle.content.mainContent.adminUserList.listElement.valueColor
  });

  const [firstActionButtonTop, setFirstActionButtonTop] = useState<number>(webpageStyle.content.mainContent.adminUserList.listElement.firstActionButtonTop);
  const [actionButtonTopDistance, setActionButtonTopDistance] = useState<number>(webpageStyle.content.mainContent.adminUserList.listElement.actionButtonTopDistance);

  const [actionButtonBackgroundColorActive, setActionButtonBackgroundColorActive] = useState<string>(webpageStyle.content.mainContent.adminUserList.listElement.actionButtonBackgroundColorActive);
  const [actionButtonContentColorActive, setActionButtonContentColorActive] = useState<string>(webpageStyle.content.mainContent.adminUserList.listElement.actionButtonContentColorActive);
  const [actionButtonBackgroundColorInactive, setActionButtonBackgroundColorInactive] = useState<string>(webpageStyle.content.mainContent.adminUserList.listElement.actionButtonBackgroundColorInactive);
  const [actionButtonContentColoInactive, setActionButtonContentColorInactive] = useState<string>(webpageStyle.content.mainContent.adminUserList.listElement.actionButtonContentColorInactive);

  const [actionButtonPrototypeDisplayParameters, setActionButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.adminUserList.listElement.firstActionButtonTop,
    left: webpageStyle.content.mainContent.adminUserList.listElement.actionButtonLeft,
    height: webpageStyle.content.mainContent.adminUserList.listElement.actionButtonHeight,
    width: webpageStyle.content.mainContent.adminUserList.listElement.actionButtonWidth,
    backgroundColor: actionButtonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.adminUserList.listElement.actionButtonFontSize,
    contentColor: actionButtonContentColorActive
  });

  const [onwardYesButtonPrototypeDisplayParameters, setOnwardYesButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.adminUserList.listElement.onwardYesButtonTop,
    left: webpageStyle.content.mainContent.adminUserList.listElement.onwardYesButtonLeft,
    height: webpageStyle.content.mainContent.adminUserList.listElement.onwardButtonHeight,
    width: webpageStyle.content.mainContent.adminUserList.listElement.onwardButtonWidth,
    backgroundColor: webpageStyle.content.mainContent.adminUserList.listElement.onwardButtonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.adminUserList.listElement.onwardButtonFontSize,
    contentColor: webpageStyle.content.mainContent.adminUserList.listElement.onwardButtonContentColorActive
  });

  const [onwardNoButtonPrototypeDisplayParameters, setOnwardNoButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.adminUserList.listElement.onwardNoButtonTop,
    left: webpageStyle.content.mainContent.adminUserList.listElement.onwardNoButtonLeft,
    height: webpageStyle.content.mainContent.adminUserList.listElement.onwardButtonHeight,
    width: webpageStyle.content.mainContent.adminUserList.listElement.onwardButtonWidth,
    backgroundColor: webpageStyle.content.mainContent.adminUserList.listElement.onwardButtonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.adminUserList.listElement.onwardButtonFontSize,
    contentColor: webpageStyle.content.mainContent.adminUserList.listElement.onwardButtonContentColorActive
  });

  const [onwardMessagePrototypeDisplayParameters, setOnwardMessagePrototypeDisplayParameters] = useState<MultipleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.adminUserList.listElement.onwardMessageTop,
    left: webpageStyle.content.mainContent.adminUserList.listElement.onwardMessageLeft,
    height: webpageStyle.content.mainContent.adminUserList.listElement.onwardMessageHeight,
    width: webpageStyle.content.mainContent.adminUserList.listElement.onwardMessageWidth,
    fontSize: webpageStyle.content.mainContent.adminUserList.listElement.onwardMessageFontSize,
    color: webpageStyle.content.mainContent.adminUserList.listElement.onwardMessageContentColor
  });


  const activateUser = async () => {
    const result = await patchData(`/api/users/single/activate/admin/${ user[0].id }`, { sg: "sg" }, true);
    if(result === true) {
      let userWithNewStatus: Array<UserData> = user;
      userWithNewStatus[0].status = "active";
      setUser(userWithNewStatus);
    }
  }

  const deactivateUser = async () => {
    const result = await patchData(`/api/users/single/deactivate/admin/${ user[0].id }`, { sg: "sg" }, true);
    if(result === true) {
      let userWithNewStatus: Array<UserData> = user;
      userWithNewStatus[0].status = "deactivated";
      setUser(userWithNewStatus);
    }
  }

  const deleteUser = async () => {
    const result = await deleteData(`/api/users/single/delete/admin/${ user[0].id }`, { sg: "sg" }, true);
    if(result === true) {
      let userWithNewStatus: Array<UserData> = user;
      userWithNewStatus[0].status = "deleted";
      // setUserDeleted(true);
      setUser(userWithNewStatus);
      overwriteDeletedUser[0](user[0].id);
    }
  }


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <div>
        <KeyValueDisplay
          keyText = { "Id" }
          valueText = { user[0].id.toString() }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 0 * dataTopDistance,
            valueTop: firstDataTop + 0 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Name" }
          valueText = { user[0].name }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 1 * dataTopDistance,
            valueTop: firstDataTop + 1 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Email" }
          valueText = { user[0].email }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 2 * dataTopDistance,
            valueTop: firstDataTop + 2 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Role" }
          valueText = { user[0].role }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 3 * dataTopDistance,
            valueTop: firstDataTop + 3 * dataTopDistance
          } }
        />

        <KeyValueDisplay
          keyText = { "Status" }
          valueText = { user[0].status }
          prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstDataTop + 4 * dataTopDistance,
            valueTop: firstDataTop + 4 * dataTopDistance
          } }
        />

        { inAction === false ?
          <Button
            buttonText = { "Read" }
            actionFunctions = { [() => {
              changeSelectedUserId[0]([user[0].id]);
              changeAdminUserListPageContentType[0]("readUserData");
            }] }
            disabled = { false }
            prototypeDisplayParameters = { actionButtonPrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstActionButtonTop + 0 * actionButtonTopDistance
            } }
          /> : null
        }

        { inAction === false ?
          <Button
            buttonText = { "Edit" }
            actionFunctions = { [() => {
              changeSelectedUserId[0]([user[0].id]);
              changeAdminUserListPageContentType[0]("editUserData");
            }] }
            disabled = { false }
            prototypeDisplayParameters = { actionButtonPrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstActionButtonTop + 1 * actionButtonTopDistance
            } }
          /> : null
        }

        { inAction === false && user[0].status === "active" ?
          <Button
            buttonText = { "Deactivate" }
            actionFunctions = { [() => {
              setInAction(true);
              setActionType("deactivate");
              setActionOnwardMessage("Deactivate?");
            }] } // üzenetet kel megjeleníteni + választás
            disabled = { loggedInUser.id === user[0].id }
            prototypeDisplayParameters = { actionButtonPrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstActionButtonTop + 2 * actionButtonTopDistance,
              backgroundColor: loggedInUser.id !== user[0].id? actionButtonBackgroundColorActive : actionButtonBackgroundColorInactive,
              contentColor: loggedInUser.id !== user[0].id? actionButtonContentColorActive : actionButtonContentColoInactive
            } }
          /> : null
        }

        { inAction === false && user[0].status === "deactivated" ?
          <Button
            buttonText = { "Activate" }
            actionFunctions = { [() => {
              setInAction(true);
              setActionType("activate");
              setActionOnwardMessage("Activate?");
            }] } // üzenetet kel megjeleníteni + választás
            disabled = { loggedInUser.id === user[0].id }
            prototypeDisplayParameters = { actionButtonPrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstActionButtonTop + 2 * actionButtonTopDistance,
              backgroundColor: loggedInUser.id !== user[0].id? actionButtonBackgroundColorActive : actionButtonBackgroundColorInactive,
              contentColor: loggedInUser.id !== user[0].id? actionButtonContentColorActive : actionButtonContentColoInactive
            } }
          /> : null
        }

        { inAction === false && (user[0].status === "deactivated" || user[0].status === "invited") ?
          <Button
            buttonText = { "Delete" }
            actionFunctions = { [() => {
              setInAction(true);
              setActionType("delete");
              setActionOnwardMessage("Delete?");
            }] } // üzenetet kel megjeleníteni + választás
            disabled = { loggedInUser.id === user[0].id }
            prototypeDisplayParameters = { actionButtonPrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstActionButtonTop + 3 * actionButtonTopDistance,
              backgroundColor: loggedInUser.id !== user[0].id? actionButtonBackgroundColorActive : actionButtonBackgroundColorInactive,
              contentColor: loggedInUser.id !== user[0].id? actionButtonContentColorActive : actionButtonContentColoInactive
            } }
          /> : null
        }

        { inAction === true ?
          <MultipleRowText
            text = { actionOnwardMessage }
            prototypeDisplayParameters = { onwardMessagePrototypeDisplayParameters }
          /> : null
        }

        { inAction === true ?
          <Button
            buttonText = { "Yes" }
            actionFunctions = { [async () => {
              switch(actionType) {
                case("activate"):
                  await activateUser();
                  break;
                case("deactivate"):
                  await deactivateUser();
                  break;
                case("delete"):
                  await deleteUser();
                  break;
              }
              setInAction(false);
              setActionType("");
              setActionOnwardMessage("");
            }] }
            disabled = { false }
            prototypeDisplayParameters = { onwardYesButtonPrototypeDisplayParameters }
          /> : null
        }

        { inAction === true ?
          <Button
            buttonText = { "No" }
            actionFunctions = { [() => {
              setInAction(false);
              setActionType("");
              setActionOnwardMessage("");
            }] }
            disabled = { false }
            prototypeDisplayParameters = { onwardNoButtonPrototypeDisplayParameters }
          /> : null
        }

        { user[0].status === "deleted" ?
          <div>
            This user is deleted
          </div> : null
        }
      </div>
    </Box>
  )
}
