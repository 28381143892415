import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../../03_baseComponents/Box';
import { SingleRowText, SingleRowPrototypeDisplayParameters, SingleRowChangedDisplayParameters } from '../../../../03_baseComponents/SingleRowText';
import { KeyValueInput, KeyValueInputPrototypeDisplayParameters, KeyValueInputChangedDisplayParameters } from '../../../../03_baseComponents/KeyValueInput';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../../03_baseComponents/Button';
import { HorseCatalogFilteringSettings } from '../../../../../models/HorseCatalogSettings';
import { LoggedInUser } from '../../../../../models/UserProps';





export interface HorseCatalogFilteringProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  recentHorseCatalogFilteringSettings: HorseCatalogFilteringSettings,
  changeHorseCatalogFilteringSettings: Array<any>
}





export const HorseCatalogFiltering: FC<HorseCatalogFilteringProps> = ({ webpageStyle, loggedInUser,
  recentHorseCatalogFilteringSettings, changeHorseCatalogFilteringSettings }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [filterable, setFilterable] = useState<boolean>(false);
  const [resettable, setResettable] = useState<boolean>((recentHorseCatalogFilteringSettings.keyWords.length !== 0 ||
    recentHorseCatalogFilteringSettings.name.length !== 0 || recentHorseCatalogFilteringSettings.sex.length !== 0 ||
    recentHorseCatalogFilteringSettings.birthYear.length !== 0 || recentHorseCatalogFilteringSettings.color.length !== 0 ||
    recentHorseCatalogFilteringSettings.stud.length !== 0 || recentHorseCatalogFilteringSettings.origin.length !== 0 ||
    recentHorseCatalogFilteringSettings.horseBreed.length !== 0 ||
    recentHorseCatalogFilteringSettings.tradingCategory.length !== 0) ? true : false);

  const [recentlyReset, setRecentlyReset] = useState<boolean>(false);

  const [numberOfFiltering, setNumberOfFiltering] = useState<number>(0);
  

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.horseCatalog.filtering.boxTop,
    left: webpageStyle.content.sidebar.filteringAndSorting.boxLeft,
    height: webpageStyle.content.sidebar.horseCatalog.filtering.boxHeight,
    width: webpageStyle.content.sidebar.filteringAndSorting.boxWidth,
    backgroundColor: webpageStyle.content.sidebar.filteringAndSorting.boxBackgroundColor,
    outlineStyle: webpageStyle.content.sidebar.filteringAndSorting.boxOutlineStyle,
    outlineWidth: webpageStyle.content.sidebar.filteringAndSorting.boxOutlineWidth,
    outlineColor: webpageStyle.content.sidebar.filteringAndSorting.boxOutlineColor
  });


  const [functionTitlePrototypeDisplayParameters, setFunctionTitlePrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.filteringAndSorting.functionTitleTop,
    left: webpageStyle.content.sidebar.filteringAndSorting.functionTitleLeft,
    width: webpageStyle.content.sidebar.filteringAndSorting.functionTitleWidth,
    fontSize: webpageStyle.content.sidebar.filteringAndSorting.functionTitleFontSize,
    color: webpageStyle.content.sidebar.filteringAndSorting.functionTitleFontColor
  });

  const [headerPrototypeDisplayParameters, setHeaderPrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.filteringAndSorting.headerTop,
    left: 0,
    width: webpageStyle.content.sidebar.filteringAndSorting.headerWidth,
    fontSize: webpageStyle.content.sidebar.filteringAndSorting.headerFontSize,
    color: webpageStyle.content.sidebar.filteringAndSorting.headerFontColor
  });

  const [firstInputTop, setFirstInputTop] = useState<number>(webpageStyle.content.sidebar.filteringAndSorting.firstValueTop);
  const [inputTopDistance, setInputTopDistance] = useState<number>(webpageStyle.content.sidebar.filteringAndSorting.valueTopDistance);

  const [keyLeft, setKeyLeft] = useState<number>(webpageStyle.content.sidebar.filteringAndSorting.keyLeft);

  const [fieldLeft, setFieldLeft] = useState<number>(webpageStyle.content.sidebar.filteringAndSorting.fieldLeft);

  const [keyValueInputPrototypeDisplayParameters, setKeyValueInputPrototypeDisplayParameters] = useState<KeyValueInputPrototypeDisplayParameters>({
    keyTop: firstInputTop,
    keyLeft: keyLeft,
    keyWidth: webpageStyle.content.sidebar.filteringAndSorting.keyWidth,
    keyFontSize: webpageStyle.content.sidebar.filteringAndSorting.keyFontSize,
    keyColor: webpageStyle.content.sidebar.filteringAndSorting.keyFontColor,
    fieldTop: firstInputTop,
    fieldLeft: fieldLeft,
    fieldHeight: webpageStyle.content.sidebar.filteringAndSorting.fieldHeight,
    fieldWidth: webpageStyle.content.sidebar.filteringAndSorting.fieldWidth,
    fieldBackgroundColor: webpageStyle.content.sidebar.filteringAndSorting.fieldBackgroundColor,
    valueFontSize: webpageStyle.content.sidebar.filteringAndSorting.valueFontSize,
    valueColor: webpageStyle.content.sidebar.filteringAndSorting.valueFontColor,
  });

  const [filterButtonBackgroundColorActive, setFilterButtonBackgroundColorActive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonBackgroundColorActive);
  const [filterButtonBackgroundColorInactive, setFilterButtonBackgroundColorInactive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonBackgroundColorInactive);
  const [filterButtonContentColorActive, setFilterButtonContentColorActive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonContentColorActive);
  const [filterButtonContentColorInactive, setFilterButtonContentColorInactive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonContentColorInactive);

  const [filterButtonPrototypeDisplayParameters, setFilterButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.horseCatalog.filtering.buttonTop,
    left: webpageStyle.content.sidebar.filteringAndSorting.filtering.filterButtonLeft,
    height: webpageStyle.content.sidebar.filteringAndSorting.buttonHeight,
    width: webpageStyle.content.sidebar.filteringAndSorting.buttonWidth,
    backgroundColor: filterButtonBackgroundColorActive,
    fontSize: webpageStyle.content.sidebar.filteringAndSorting.buttonFontSize,
    contentColor: filterButtonContentColorActive
  });

  const [resetButtonBackgroundColorActive, setResetButtonBackgroundColorActive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonBackgroundColorActive);
  const [resetButtonBackgroundColorInactive, setResetButtonBackgroundColorInactive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonBackgroundColorInactive);
  const [resetButtonContentColorActive, setResetButtonContentColorActive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonContentColorActive);
  const [resetButtonContentColorInactive, setResetButtonContentColorInactive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonContentColorInactive);

  const [resetButtonPrototypeDisplayParameters, setResetButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.horseCatalog.filtering.buttonTop,
    left: webpageStyle.content.sidebar.filteringAndSorting.filtering.resetButtonLeft,
    height: webpageStyle.content.sidebar.filteringAndSorting.buttonHeight,
    width: webpageStyle.content.sidebar.filteringAndSorting.buttonWidth,
    backgroundColor: resetButtonBackgroundColorActive,
    fontSize: webpageStyle.content.sidebar.filteringAndSorting.buttonFontSize,
    contentColor: resetButtonContentColorActive
  });

  const [keyWords, setKeyWords] = useState<Array<string>>(recentHorseCatalogFilteringSettings.keyWords);
  const [name, setName] = useState<Array<string>>(recentHorseCatalogFilteringSettings.name);
  const [sex, setSex] = useState<Array<string>>(recentHorseCatalogFilteringSettings.sex);
  const [birthYear, setBirthYear] = useState<Array<number>>(recentHorseCatalogFilteringSettings.birthYear);
  const [color, setColor] = useState<Array<string>>(recentHorseCatalogFilteringSettings.color);
  const [stud, setStud] = useState<Array<string>>(recentHorseCatalogFilteringSettings.stud);
  const [origin, setOrigin] = useState<Array<string>>(recentHorseCatalogFilteringSettings.origin);
  const [horseBreed, setHorseBreed] = useState<Array<string>>(recentHorseCatalogFilteringSettings.horseBreed);
  const [tradingCategory, setTradingCategory] = useState<Array<string>>(recentHorseCatalogFilteringSettings.tradingCategory);
  // const [accessibility, setAccessibility] = useState<Array<string>>(loggedInUserType === "administrator" ? recentHorseCatalogFilterSettings.accessibility : ["public"]);

  const [sexOptions, setSexOptions] = useState<Array<string>>([" ", "stallion", "mare", "gelding"]);
  const [colorOptions, setColorOptions] = useState<Array<string>>([" ", "sorrel", "dapple grey", "bay", "blue dun", "grey", "flaxen chestnut", "skew bald", "blue eyed cream", "black", "dun", "liver chestnut", "red roan", "palomino", "appaloosa", "pie bald", "buckskin"]);
  const [tradingCategoryOptions, setTradingCategoryOptions] = useState<Array<string>>([" ", "for sale", "sold", "not for sale", "owned by others"]);
  const [accessibilityOptions, setAccessibilityOptions] = useState<Array<string>>([" ", "public", "private"]);

  
  function changeKeyWordsValue(newParameter: Array<string>): void {
    if(newParameter.length !== 0) {
      let temporaryKeyWords = newParameter[0].split(" ");
      for(let i: number = 0; i < temporaryKeyWords.length; i++) {
        if(temporaryKeyWords[i] === "") {
          temporaryKeyWords.splice(i, 1);
          i--;
        }
      }
      setKeyWords(temporaryKeyWords);
    } else {
      setKeyWords([]);
    }
  }

  // console.log(keyWords);

  const changeSexValue = (newParameter: Array<string>) => {
    if(newParameter.length !== 0) {
      if(newParameter[0] !== " ") {
        setSex(newParameter);
      } else {
        setSex([]);
      }
    }
  }

  function changeColorValue(newParameter: Array<string>): void {
    if(newParameter.length !== 0) {
      if(newParameter[0] !== " ") {
        setColor(newParameter);
      } else {
        setColor([]);
      }
    }
  }

  function changeTradingCategoryValue(newParameter: Array<string>): void {
    if(newParameter.length !== 0) {
      if(newParameter[0] !== " ") {
        setTradingCategory(newParameter);
      } else {
        setTradingCategory([]);
      }
    }
  }

  // function changeAccessibilityValue(newParameter: Array<string>): void {
  //   if(newParameter.length !== 0) {
  //     if(newParameter[0] !== " ") {
  //       setAccessibility(newParameter);
  //     } else {
  //       setAccessibility([]);
  //     }
  //   }
  // }

  function getNewParameter(newParameter: Array<string> | Array<number> | Array<boolean>): void {
    setFilterable(true);
    setResettable(true);
  }

  function doFilter(): void {
    //

    changeHorseCatalogFilteringSettings[0]({
      keyWords: keyWords,
      name: name,
      sex: sex,
      birthYear: birthYear,
      color: color,
      stud: stud,
      origin: origin,
      horseBreed: horseBreed,
      tradingCategory: tradingCategory,
      // accessibility: accessibility
    });
  }

  function doReset(): void {
    //

    setFilterable(false);
    setResettable(false);
    setRecentlyReset(true);

    setKeyWords([]);
    setName([]);
    setSex([]);
    setBirthYear([]);
    setColor([]);
    setStud([]);
    setOrigin([]);
    setHorseBreed([]);
    setTradingCategory([]);
    // setAccessibility(["public"]);

    changeHorseCatalogFilteringSettings[0]({
      keyWords: [],
      name: [],
      sex: [],
      birthYear: [],
      color: [],
      stud: [],
      origin: [],
      horseBreed: [],
      tradingCategory: [],
      // accessibility: ["public"]
    });
  }


  if(recentlyReset === false) {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      >
        <SingleRowText
          text = { "Filtering" }
          prototypeDisplayParameters = { functionTitlePrototypeDisplayParameters }
        />

        <SingleRowText
          text = { "Argument" }
          prototypeDisplayParameters = { headerPrototypeDisplayParameters }
          changedDisplayParameters = { {
            left: keyLeft
          } }
        />

        <SingleRowText
          text = { "Input" }
          prototypeDisplayParameters = { headerPrototypeDisplayParameters }
          changedDisplayParameters = { {
            left: fieldLeft
          } }
        />

        <KeyValueInput
          keyText = { "Fast filtering" }
          initialValueString = { keyWords }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [changeKeyWordsValue, getNewParameter] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 0 * inputTopDistance,
            fieldTop: firstInputTop + 0 * inputTopDistance
          } }
        />

        <KeyValueInput
          keyText = { "Horse name" }
          initialValueString = { name }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setName, getNewParameter] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 1 * inputTopDistance,
            fieldTop: firstInputTop + 1 * inputTopDistance
          } }
        />

        <KeyValueInput
          keyText = { "Horse sex" }
          initialValueString = { sex }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { sexOptions }
          inputType = { "SingleSelectInput" }
          modificationFunctions = { [changeSexValue, getNewParameter] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 2 * inputTopDistance,
            fieldTop: firstInputTop + 2 * inputTopDistance
          } }
        />

        <KeyValueInput
          keyText = { "Horse birth year" }
          initialValueString = { [] }
          initialValueNumber = { birthYear }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "NumberInput" }
          modificationFunctions = { [setBirthYear, getNewParameter] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 3 * inputTopDistance,
            fieldTop: firstInputTop + 3 * inputTopDistance
          } }
        />

        <KeyValueInput
          keyText = { "Horse color" }
          initialValueString = { color }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { colorOptions }
          inputType = { "SingleSelectInput" }
          modificationFunctions = { [changeColorValue, getNewParameter] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 4 * inputTopDistance,
            fieldTop: firstInputTop + 4 * inputTopDistance
          } }
        />

        <KeyValueInput
          keyText = { "Horse stud" }
          initialValueString = { stud }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setStud, getNewParameter] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 5 * inputTopDistance,
            fieldTop: firstInputTop + 5 * inputTopDistance
          } }
        />

        <KeyValueInput
          keyText = { "Horse origin" }
          initialValueString = { origin }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setOrigin, getNewParameter] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 6 * inputTopDistance,
            fieldTop: firstInputTop + 6 * inputTopDistance
          } }
        />

        <KeyValueInput
          keyText = { "Horse horse breed" }
          initialValueString = { horseBreed }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { [] }
          inputType = { "SingleRowTextInput" }
          modificationFunctions = { [setHorseBreed, getNewParameter] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 7 * inputTopDistance,
            fieldTop: firstInputTop + 7 * inputTopDistance
          } }
        />

        <KeyValueInput
          keyText = { "Trading category" }
          initialValueString = { tradingCategory }
          initialValueNumber = { [] }
          initialValueBoolean = { [] }
          optionsString = { tradingCategoryOptions }
          inputType = { "SingleSelectInput" }
          modificationFunctions = { [changeTradingCategoryValue, getNewParameter] }
          properInputDisplay = { false }
          disabled = { false }
          prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
          changedDisplayParameters = { {
            keyTop: firstInputTop + 8 * inputTopDistance,
            fieldTop: firstInputTop + 8 * inputTopDistance
          } }
        />

        <Button
          buttonText = { "Filter" }
          actionFunctions = { [() => doFilter()] }
          disabled = { filterable ? false : true }
          prototypeDisplayParameters = { filterButtonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            backgroundColor: filterable ? filterButtonBackgroundColorActive : filterButtonBackgroundColorInactive,
            contentColor: filterable ? filterButtonContentColorActive : filterButtonContentColorInactive
          } }
        />

        <Button
          buttonText = { "Reset" }
          actionFunctions = { [() => doReset()] } // !!!!!
          disabled = { resettable ? false : true }
          prototypeDisplayParameters = { resetButtonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            backgroundColor: resettable ? resetButtonBackgroundColorActive : resetButtonBackgroundColorInactive,
            contentColor: resettable ? resetButtonContentColorActive : resetButtonContentColorInactive
          } }
        />
      </Box>
    )
  } else {
    setTimeout(() => setRecentlyReset(false), 100);
    return(
      <div>
        Loading...
      </div>
    )
  }
}
