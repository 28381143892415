import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { LinkUrl } from '../04_subComponents/LinkUrl';
import { PopUpWindow } from '../04_subComponents/PopUpWindow';
import YouTube, { YouTubeProps } from 'react-youtube';
import { UploadedFile } from './FileInput';
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { incrementActionsDone } from '../../App';





export interface ImageDisplayProps {
  uploadedImage: Array<UploadedFile>,
  sourceUrl: Array<string>,
  redirectionUrl: Array<string>,
  message: Array<string>,
  top: number,
	left: number,
	height: number,
	width: number
}

export interface VideoDisplayProps {
  sourceUrl: string
  top: number,
	left: number,
	height: number,
	width: number
}





function Base64ToImage(base64img: string, file: Array<UploadedFile>, maxWidth: number, maxHeight: number) {
  var originalImage = new Image();
  // originalImage.onload = function() {
  //     // callback(originalImage);
  // };
  originalImage.src = base64img;

  setTimeout(() => {
    const originalWidth: number = originalImage.width;
    const originalHeight: number = originalImage.height;
    let targetWidth: number;
    let targetHeight: number;
    if(originalWidth <= maxWidth && originalHeight <= maxHeight) {
      // console.log("*");
      targetWidth = originalWidth;
      targetHeight = originalHeight;
    } else {
      if(originalWidth === originalHeight) {
        // console.log("**");
        targetWidth = maxWidth;
        targetHeight = maxHeight;
      } else {
        if( originalWidth < originalHeight) {
          // console.log("***");
          targetHeight = maxHeight;
          targetWidth = Math.ceil(originalWidth * (targetHeight / originalHeight));
        } else {
          // console.log("****");
          targetWidth = maxWidth;
          targetHeight = Math.ceil(originalHeight * (targetWidth / originalWidth));
        }
      }
    }

    const image = new Image(targetWidth, targetHeight);
    // console.log("*");
    image.src = base64img;
    // console.log(maxWidth);
    // console.log(maxHeight);
    // console.log(originalWidth);
    // console.log(originalHeight);
    // console.log(targetWidth);
    // console.log(targetHeight);
    // console.log(image.width);
    // console.log(image.height);

    image.onload = function() {
      // callback(image);
      // console.log("**");
      if(document.getElementById(file[0].title + '.' + file[0].fileExtension)!.firstElementChild === null) {
        document.getElementById(file[0].title + '.' + file[0].fileExtension)!.appendChild(image);
        // console.log("***");
      }
      // console.log(document.getElementById(file[0].title + '.' + file[0].fileExtension)!.firstElementChild);
      if(document.getElementById(file[0].title + '.' + file[0].fileExtension)!.firstElementChild === null) {
        document.getElementById(file[0].title + '.' + file[0].fileExtension)!.appendChild(image);
        // console.log("****");
      }
    };
  }, 100);
  
  // document.getElementById(uploadedImages[0].oldName + '.' + uploadedImages[0].fileExtension)!.appendChild(image);
}

export const AdjustableImageDisplay: FC<ImageDisplayProps> = ({uploadedImage, sourceUrl, redirectionUrl, message, top, left, height, width}) => {
  const [imageIsLoading, setImageIsLoading] = useState<boolean>(true);

  let history = useHistory();

  if(uploadedImage.length !== 0) {
    if(imageIsLoading === true) {
      const fileExtension: string = uploadedImage[0].fileExtension;
      // console.log(fileExtension);
      if(fileExtension === "png" || fileExtension === "PNG") {
        Base64ToImage(("data:image/png;base64," + uploadedImage[0].content), uploadedImage, height, width);
      }
      if(fileExtension === "jpg" || fileExtension === "JPG") {
        Base64ToImage(("data:image/jpg;base64," + uploadedImage[0].content), uploadedImage, height, width);
      }
      setImageIsLoading(false);
    }

    if(imageIsLoading === true) {
      return(
        <div style = {{ position: "absolute", top: top, left: left }}>
          Image is loading...
        </div>
      )
    } else {
      return(
        <div
          style = {{ position: "absolute", top: top, left: left, height: height, width: width, display: "flex", flexDirection: "column", cursor: redirectionUrl.length === 0 ? "auto" : "pointer" }}
          onClick = { () => {
            if(redirectionUrl.length > 0) {
              incrementActionsDone();
              history.push(redirectionUrl[0]);
            }
          }}
        >
          <PopUpWindow
            message = { message }
          >
            <div id={uploadedImage[0].title + '.' + uploadedImage[0].fileExtension} className="uploadedImageVisualization"></div>
            {/* <LinkUrl
              redirectionUrl = { redirectionUrl }
            >
              <div id={uploadedImage[0].oldName + '.' + uploadedImage[0].fileExtension} className="uploadedImageVisualization"></div>
            </LinkUrl> */}
          </PopUpWindow>
        </div>
      )
    }
  }

  if(sourceUrl.length !== 0) {
    return(
      <div
        style= {{ position: "absolute", top: top, left: left, height: height, width: width, display: "flex", flexDirection: "column", cursor: redirectionUrl.length === 0 ? "auto" : "pointer" }}
        onClick = { () => {
          if(redirectionUrl.length > 0) {
            incrementActionsDone();
            history.push(redirectionUrl[0]);
          }
        }}
      >
        <PopUpWindow
          message = { message }
        >
          <div style = {{ height: height, width: width, display: "flex", flexDirection: "column" }}>
            <img style= {{ position: "relative", marginTop: "auto", marginBottom: "auto", marginRight: "auto", marginLeft: "auto", maxHeight: height, maxWidth: width }} src={ sourceUrl[0] }></img>
          </div>
          {/* <LinkUrl
            redirectionUrl = { redirectionUrl }
          >
            <div style = {{ height: height, width: width, display: "flex", flexDirection: "column" }}>
              <img style= {{ position: "relative", marginTop: "auto", marginBottom: "auto", marginRight: "auto", marginLeft: "auto", maxHeight: height, maxWidth: width }} src={ sourceUrl[0] }></img>
            </div>
          </LinkUrl> */}
        </PopUpWindow>
      </div>
    )
  }

  return(
    <div style = {{ position: "absolute", top: top, left: left }}>
      No image
    </div>
  )
}

export const AdjustableVideoDisplay: FC<VideoDisplayProps> = ({sourceUrl, top, left, height, width}) => {
  const [videoIsLoading, setVideoIsLoading] = useState<boolean>(true);

  let youtubeId1: string[] = sourceUrl.split("https://www.youtube.com/watch?v=");
  let youtubeId2: string[] = sourceUrl.split("https://www.youtube.com/embed/");
  let youtubeId3: string[] = sourceUrl.split("https://youtu.be/");
  let youtubeId: string = "";

  if(youtubeId1.length === 2) {
    youtubeId = youtubeId1[1];
  } else {
    if(youtubeId2.length === 2) {
      youtubeId = youtubeId2[1];
    } else {
      if(youtubeId3.length === 2) {
        youtubeId = youtubeId3[1];
      }
    }
  }

  if(youtubeId !== "") {
    if(videoIsLoading === false) {
      const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
      }
    
      const opts: YouTubeProps['opts'] = {
        height: height,
        width: width,
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        },
      };
    
      return (
        <div style = {{ position: "absolute", top: top, left: left }}>
          <YouTube videoId={youtubeId} opts={opts} onReady={onPlayerReady} />
        </div>
      );
    } else {
      setTimeout(() => {setVideoIsLoading(false)}, 100);
      return(
        <div style = {{ position: "absolute", top: top, left: left }}>
          <p>Video is loading...</p>
        </div>
      )
    }
  }

  return(
    <div style = {{ position: "absolute", top: top, left: left }}>
      <p>Wrong youtube url</p>
    </div>
  )
}