import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { KeyValueInput, KeyValueInputPrototypeDisplayParameters, KeyValueInputChangedDisplayParameters } from '../../../03_baseComponents/KeyValueInput';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../03_baseComponents/Button';
import { emailInputTest, numberOfCharactersGreaterThanOrEqual, equalCondition } from '../../../../services/Conditions';
import { SingleRowText } from '../../../03_baseComponents/SingleRowText';
import { MultipleRowText, MultipleRowPrototypeDisplayParameters, MultipleRowChangedDisplayParameters } from '../../../03_baseComponents/MultipleRowText';
import { LoggedInUser } from '../../../../models/UserProps';
import { LoginRequest, ChangePasswordRequest, UserFromToken } from '../../../../models/UserProps';
import { postData, patchData } from "../../../../services/API.services";
import jwtDecode from "jwt-decode";
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { resetActionsDone, passwordLength } from '../../../../App';





export interface ChangeUserPasswordProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  resizeMainContentHeight: Array<any>
}





export const ChangeUserPassword: FC<ChangeUserPasswordProps> = ({ webpageStyle, loggedInUser, resizeMainContentHeight }) => {
  //

  // const [passwordLength, setPasswordLength] = useState<Array<number>>([8]);

  const [newPassword, setNewPassword] = useState<Array<string>>([]);
  const [confirmNewPassword, setConfirmNewPassword] = useState<Array<string>>([]);
  const [oldPassword, setOldPassword] = useState<Array<string>>([]);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [statusMessage, setStatusMessage] = useState<string>("");

  
  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.mainContent.changeUserPassword.boxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.boxTop,
    left: webpageStyle.content.mainContent.boxLeft,
    height: boxHeight,
    width: webpageStyle.content.mainContent.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.boxOutlineColor
  });


  const [firstFieldTop, setFirstFieldTop] = useState<number>(webpageStyle.content.mainContent.changeUserPassword.firstFieldTop);
  const [topDistance, setTopDistance] = useState<number>(webpageStyle.content.mainContent.changeUserPassword.topDistance);

  const [keyValueInputPrototypeDisplayParameters, setKeyValueInputPrototypeDisplayParameters] = useState<KeyValueInputPrototypeDisplayParameters>({
    keyTop: firstFieldTop,
    keyLeft: webpageStyle.content.mainContent.changeUserPassword.keyLeft,
    keyWidth: webpageStyle.content.mainContent.changeUserPassword.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.changeUserPassword.keyFontSize,
    keyColor: webpageStyle.content.mainContent.changeUserPassword.keyColor,
    fieldTop: firstFieldTop,
    fieldLeft: webpageStyle.content.mainContent.changeUserPassword.fieldLeft,
    fieldHeight: webpageStyle.content.mainContent.changeUserPassword.fieldHeight,
    fieldWidth: webpageStyle.content.mainContent.changeUserPassword.fieldWidth,
    fieldBackgroundColor: webpageStyle.content.mainContent.changeUserPassword.fieldBackgroundColor,
    valueFontSize: webpageStyle.content.mainContent.changeUserPassword.valueFontSize,
    valueColor: webpageStyle.content.mainContent.changeUserPassword.valueColor,
  });

  const [changePasswordButtonPrototypeDisplayParameters, setChangePasswordButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.changeUserPassword.changePasswordButtonTop,
    left: webpageStyle.content.mainContent.changeUserPassword.changePasswordButtonLeft,
    height: webpageStyle.content.mainContent.changeUserPassword.changePasswordButtonHeight,
    width: webpageStyle.content.mainContent.changeUserPassword.changePasswordButtonWidth,
    backgroundColor: webpageStyle.content.mainContent.changeUserPassword.changePasswordButtonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.changeUserPassword.changePasswordButtonFontSize,
    contentColor: webpageStyle.content.mainContent.changeUserPassword.changePasswordButtonContentColorActive
  });

  const [errorMessagePrototypeDisplayParameters, setErrorMessagePrototypeDisplayParameters] = useState<MultipleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.changeUserPassword.errorMessageTop,
    left: webpageStyle.content.mainContent.changeUserPassword.errorMessageLeft,
    height: webpageStyle.content.mainContent.changeUserPassword.errorMessageHeight,
    width: webpageStyle.content.mainContent.changeUserPassword.errorMessageWidth,
    fontSize: webpageStyle.content.mainContent.changeUserPassword.errorMessageFontSize,
    color: webpageStyle.content.mainContent.changeUserPassword.errorMessageColor
  });

  const [statusMessagePrototypeDisplayParameters, setStatusMessagePrototypeDisplayParameters] = useState<MultipleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.changeUserPassword.statusMessageTop,
    left: webpageStyle.content.mainContent.changeUserPassword.statusMessageLeft,
    height: webpageStyle.content.mainContent.changeUserPassword.statusMessageHeight,
    width: webpageStyle.content.mainContent.changeUserPassword.statusMessageWidth,
    fontSize: webpageStyle.content.mainContent.changeUserPassword.statusMessageFontSize,
    color: webpageStyle.content.mainContent.changeUserPassword.statusMessageColor
  });


  let history = useHistory();

  const changePassword = async () => {
    //

    const test: boolean = testConditions();

    if(test === true) {
      const changePasswordRequest: ChangePasswordRequest = {
        oldPassword: oldPassword[0],
        newPassword: newPassword[0]
      }
      const changePasswordResult = await patchData('/api/users/single/changepassword', changePasswordRequest, true);
      if (!changePasswordResult || (changePasswordResult as { status: number, message: string })?.status >= 400) {
        setErrorMessage(changePasswordResult.message);
      } else {
        let loginUserRequest: LoginRequest = {
          email: loggedInUser.email,
          password: newPassword[0]
        }
        const loginResult = await postData(`/api/users/single/login`, loginUserRequest);
        if (!loginResult || (loginResult as { status: number, message: string })?.status >= 400) {
          setErrorMessage(loginResult.message);
        } else {
          resetActionsDone();
          localStorage.setItem("user", JSON.stringify(loginResult.token));
          setErrorMessage("");
          setStatusMessage("Password has been changed");
          setNewPassword([""]);
          setConfirmNewPassword([""]);
          setOldPassword([""]);
        }
      }
    }
  }

  const testConditions = (): boolean => {
    //
    
    const testPasswordLength = numberOfCharactersGreaterThanOrEqual(newPassword, passwordLength);
    const testPasswordsEquality = equalCondition(newPassword, confirmNewPassword);

    if(oldPassword.length === 0) {
      setErrorMessage("No old password entered");
        return(false);
    }

    if(testPasswordLength.length !== 0) {
      if(testPasswordLength[0] !== true) {
        setErrorMessage(`The new password length is less than ${ passwordLength }`);
        return(false);
      }
    }

    if(testPasswordsEquality.length !== 0) {
      if(testPasswordsEquality[0] !== true) {
        setErrorMessage("The new password doesn't equal the confirm new password");
        return(false);
      }
    }

    setErrorMessage("");
    return(true);
  }


  useEffect(() => {
    resizeMainContentHeight[0](boxHeight);
  }, []);


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <KeyValueInput
        keyText = { "New password" }
        initialValueString = { newPassword }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { [] }
        inputType = { "PasswordInputPrimer" }
        modificationFunctions = { [setNewPassword, () => {
          setErrorMessage("");
          setStatusMessage("");
        }] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstFieldTop + 0 * topDistance,
          fieldTop: firstFieldTop + 0 * topDistance
        } }
      />

      <KeyValueInput
        keyText = { "Confirm new password" }
        initialValueString = { confirmNewPassword }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { [] }
        inputType = { "PasswordInputSecunder" }
        modificationFunctions = { [setConfirmNewPassword, () => {
          setErrorMessage("");
          setStatusMessage("");
        }] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstFieldTop + 1 * topDistance,
          fieldTop: firstFieldTop + 1 * topDistance
        } }
      />

      <KeyValueInput
        keyText = { "Old password" }
        initialValueString = { oldPassword }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { [] }
        inputType = { "PasswordInputPrimer" }
        modificationFunctions = { [setOldPassword, () => {
          setErrorMessage("");
          setStatusMessage("");
        }] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { keyValueInputPrototypeDisplayParameters }
        changedDisplayParameters = { {
          keyTop: firstFieldTop + 3 * topDistance,
          fieldTop: firstFieldTop + 3 * topDistance
        } }
      />

      <Button
        buttonText = { "Change password" }
        actionFunctions = { [changePassword] }
        disabled = { false }
        prototypeDisplayParameters = { changePasswordButtonPrototypeDisplayParameters }
      />

      {/* <SingleRowText
        text = { errorMessage }
        redirectionUrl = { [] }
        message = { [] }
        top = { errorMessageTop }
        left = { errorMessageLeft }
        width = { errorMessageWidth }
        fontSize = { errorMessageFontSize }
        color = { errorMessageColor }
      /> */}

      { errorMessage.length !== 0 ?
        <MultipleRowText
          text = { errorMessage }
          prototypeDisplayParameters = { errorMessagePrototypeDisplayParameters }
        /> : <div/>
      }
      
      { statusMessage.length !== 0 ?
        <MultipleRowText
          text = { statusMessage }
          prototypeDisplayParameters = { statusMessagePrototypeDisplayParameters }
        /> : <div/>
      }
    </Box>
  )
}
