import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { AdjustableCollection } from '../04_subComponents/Collection';

export interface PaginationProps {
  collectionType: string, // SingleElementPagination, ListPagination, GridPagination
  collectionTop: number,
  collectionLeft: number,
  n: number, // height
  m: number, // width
  elementHeight: number,
  elementWidth: number,
  paginationTop: number,
  paginationLeft: number,
  paginationWidth: number,
  paginationFontSize: number,
  selectedElementsAreChanged: number,
  children?: Array<JSX.Element>
}

export const Collection: FC<PaginationProps> = ({ collectionType, collectionTop, collectionLeft, n, m, elementHeight, elementWidth, paginationTop, paginationLeft, paginationWidth, paginationFontSize, selectedElementsAreChanged, children }) => {
  return(
    <AdjustableCollection
      collectionType = { collectionType }
      collectionTop = { collectionTop }
      collectionLeft = { collectionLeft }
      n = { n }
      m = { m }
      elementHeight = { elementHeight }
      elementWidth = { elementWidth }
      paginationTop = { paginationTop }
      paginationLeft = { paginationLeft }
      paginationWidth = { paginationWidth }
      paginationFontSize = { paginationFontSize }
      children = { children }
      selectedElementsAreChanged = { selectedElementsAreChanged }
    />
  )
}
