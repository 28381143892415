import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../03_baseComponents/Box';
import { ImageDisplay, ImageDisplayPrototypeDisplayParameters, ImageDisplayChangedDisplayParameters } from '../03_baseComponents/ImageDisplay';





export interface LogoProps {
  webpageStyle: WebpageStyleProps,
  path: string,
  doAction: Array<any>
}





export const Logo: FC<LogoProps> = ({ webpageStyle, path, doAction }) => {
  //

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.logo.boxTop + webpageStyle.general.webpageMargin,
    left: webpageStyle.logo.boxLeft + webpageStyle.general.webpageMargin,
    height: webpageStyle.logo.boxHeight,
    width: webpageStyle.logo.boxWidth,
    backgroundColor: webpageStyle.logo.boxBackgroundColor,
    outlineStyle: webpageStyle.logo.boxOutlineStyle,
    outlineWidth: webpageStyle.logo.boxOutlineWidth,
    outlineColor: webpageStyle.logo.boxOutlineColor
  });


  const [logoImageDisplayPrototypeDisplayParameters, setLogoImageDisplayPrototypeDisplayParameters] = useState<ImageDisplayPrototypeDisplayParameters>({
    top: webpageStyle.logo.imageTop,
    left: webpageStyle.logo.imageLeft,
    height: webpageStyle.logo.imageHeight,
    width: webpageStyle.logo.imageWidth
  });


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <div>
        <ImageDisplay
          uploadedImage = { [] }
          sourceUrl = { ["https://www.senseequine.com/wp-content/uploads/2018/01/26232833_1644067092319527_7625958385140513752_o-1.jpg"] }
          redirectionUrl = { path !== "/" ? "/" : undefined }
          prototypeDisplayParameters = { logoImageDisplayPrototypeDisplayParameters }
        />
      </div>
    </Box>
  )
}
