import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../../03_baseComponents/Box';
import { KeyValueInput, KeyValueInputPrototypeDisplayParameters, KeyValueInputChangedDisplayParameters } from '../../../../03_baseComponents/KeyValueInput';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../../03_baseComponents/Button';
import { SingleRowText, SingleRowPrototypeDisplayParameters, SingleRowChangedDisplayParameters } from '../../../../03_baseComponents/SingleRowText';
import { UserListSettings, UserListFilteringSettings, UserListSortingSettings } from '../../../../../models/UserListSettings';





export interface AdminUsersSortingProps {
  webpageStyle: WebpageStyleProps,
  changeHorseCatalogSortingSettings: Array<any>
}





export const AdminUsersSorting: FC<AdminUsersSortingProps> = ({ webpageStyle,
  changeHorseCatalogSortingSettings }) => {
  //

  const [sortable, setSortable] = useState<boolean>(false);
  

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.adminUserList.sorting.boxTop,
    left: webpageStyle.content.sidebar.filteringAndSorting.boxLeft,
    height: webpageStyle.content.sidebar.adminUserList.sorting.boxHeight,
    width: webpageStyle.content.sidebar.filteringAndSorting.boxWidth,
    backgroundColor: webpageStyle.content.sidebar.filteringAndSorting.boxBackgroundColor,
    outlineStyle: webpageStyle.content.sidebar.filteringAndSorting.boxOutlineStyle,
    outlineWidth: webpageStyle.content.sidebar.filteringAndSorting.boxOutlineWidth,
    outlineColor: webpageStyle.content.sidebar.filteringAndSorting.boxOutlineColor
  });


  const [functionTitlePrototypeDisplayParameters, setFunctionTitlePrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.filteringAndSorting.functionTitleTop,
    left: webpageStyle.content.sidebar.filteringAndSorting.functionTitleLeft,
    width: webpageStyle.content.sidebar.filteringAndSorting.functionTitleWidth,
    fontSize: webpageStyle.content.sidebar.filteringAndSorting.functionTitleFontSize,
    color: webpageStyle.content.sidebar.filteringAndSorting.functionTitleFontColor
  });

  const [headerPrototypeDisplayParameters, setHeaderPrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.filteringAndSorting.headerTop,
    left: 0,
    width: webpageStyle.content.sidebar.filteringAndSorting.headerWidth,
    fontSize: webpageStyle.content.sidebar.filteringAndSorting.headerFontSize,
    color: webpageStyle.content.sidebar.filteringAndSorting.headerFontColor
  });

  const [sortingArgumentFieldLeft, setSortingArgumentFieldLeft] = useState<number>(webpageStyle.content.sidebar.filteringAndSorting.keyLeft);

  const [sortingArgumentPrototypeDisplayParameters, setSortingArgumentPrototypeDisplayParameters] = useState<KeyValueInputPrototypeDisplayParameters>({
    keyTop: webpageStyle.content.sidebar.filteringAndSorting.firstValueTop,
    keyLeft: sortingArgumentFieldLeft,
    keyWidth: webpageStyle.content.sidebar.filteringAndSorting.fieldWidth,
    keyFontSize: webpageStyle.content.sidebar.filteringAndSorting.valueFontSize,
    keyColor: webpageStyle.content.sidebar.filteringAndSorting.valueFontColor,
    fieldTop: webpageStyle.content.sidebar.filteringAndSorting.firstValueTop,
    fieldLeft: sortingArgumentFieldLeft,
    fieldHeight: webpageStyle.content.sidebar.filteringAndSorting.fieldHeight,
    fieldWidth: webpageStyle.content.sidebar.filteringAndSorting.fieldWidth,
    fieldBackgroundColor: webpageStyle.content.sidebar.filteringAndSorting.fieldBackgroundColor,
    valueFontSize: webpageStyle.content.sidebar.filteringAndSorting.valueFontSize,
    valueColor: webpageStyle.content.sidebar.filteringAndSorting.valueFontColor,
  });

  const [sortingOrderFieldLeft, setSortingOrderFieldLeft] = useState<number>(webpageStyle.content.sidebar.filteringAndSorting.fieldLeft);

  const [sortingOrderPrototypeDisplayParameters, setSortingOrderPrototypeDisplayParameters] = useState<KeyValueInputPrototypeDisplayParameters>({
    keyTop: webpageStyle.content.sidebar.filteringAndSorting.firstValueTop,
    keyLeft: sortingOrderFieldLeft,
    keyWidth: webpageStyle.content.sidebar.filteringAndSorting.fieldWidth,
    keyFontSize: webpageStyle.content.sidebar.filteringAndSorting.valueFontSize,
    keyColor: webpageStyle.content.sidebar.filteringAndSorting.valueFontColor,
    fieldTop: webpageStyle.content.sidebar.filteringAndSorting.firstValueTop,
    fieldLeft: sortingOrderFieldLeft,
    fieldHeight: webpageStyle.content.sidebar.filteringAndSorting.fieldHeight,
    fieldWidth: webpageStyle.content.sidebar.filteringAndSorting.fieldWidth,
    fieldBackgroundColor: webpageStyle.content.sidebar.filteringAndSorting.fieldBackgroundColor,
    valueFontSize: webpageStyle.content.sidebar.filteringAndSorting.valueFontSize,
    valueColor: webpageStyle.content.sidebar.filteringAndSorting.valueFontColor,
  });

  const [sortButtonBackgroundColorActive, setSortButtonBackgroundColorActive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonBackgroundColorActive);
  const [sortButtonBackgroundColorInactive, setSortButtonBackgroundColorInactive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonBackgroundColorInactive);
  const [sortButtonContentColorActive, setSortButtonContentColorActive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonContentColorActive);
  const [sortButtonContentColorInactive, setSortButtonContentColoInactive] = useState<string>(webpageStyle.content.sidebar.filteringAndSorting.buttonContentColorInactive);

  const [sortButtonPrototypeDisplayParameters, setSortButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.sidebar.adminUserList.sorting.buttonTop,
    left: webpageStyle.content.sidebar.filteringAndSorting.sorting.sortButtonLeft,
    height: webpageStyle.content.sidebar.filteringAndSorting.buttonHeight,
    width: webpageStyle.content.sidebar.filteringAndSorting.buttonWidth,
    backgroundColor: sortButtonBackgroundColorActive,
    fontSize: webpageStyle.content.sidebar.filteringAndSorting.buttonFontSize,
    contentColor: sortButtonContentColorActive
  });

  const [sortingArgumentOptions, setSortingArgumentOptions] = useState<Array<string>>(["Id", "Name", "Email"]);
  const [sortingOrderOptions, setSortingOptions] = useState<Array<string>>(["Ascending", "Descending"]);

  const [sortingArgument, setSortingArgument] = useState<Array<string>>(["Id"]);
  const [sortingOrder, setSortingOrder] = useState<Array<string>>(["Ascending"]);

  const [sortingArguments, setSortingArguments] = useState<Array<string>>(["Id"]);
  const [sortingOrders, setSortingOrders] = useState<Array<string>>(["Ascending"]);


  function getNewParameter(newParameter: Array<string> | Array<number> | Array<boolean>): void {
    setSortable(true);
  }

  function doSort(): void {
    //

    let newSortingArguments: Array<string> = sortingArguments.reverse();
    let newSortingOrders: Array<string> = sortingOrders.reverse();

    let indexOfRepeating: number = newSortingArguments.indexOf(sortingArgument[0]);

    if(indexOfRepeating !== -1) {
      newSortingArguments.splice(indexOfRepeating, 1);
      newSortingOrders.splice(indexOfRepeating, 1);
    }

    newSortingArguments.push(sortingArgument[0]);
    newSortingArguments = newSortingArguments.reverse();

    newSortingOrders.push(sortingOrder[0]);
    newSortingOrders = newSortingOrders.reverse();

    if(newSortingArguments.length > 3) {
      newSortingArguments.pop();
      newSortingOrders.pop();
    }

    // console.log(newSortingArguments);
    // console.log(newSortingOrders);

    setSortingArguments(newSortingArguments);
    setSortingOrders(newSortingOrders);

    const newUserListSortingSettings: UserListSortingSettings = {
      arguments: newSortingArguments,
      orders: newSortingOrders
    }

    changeHorseCatalogSortingSettings[0](newUserListSortingSettings);

    setSortable(false);
  }


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
    >
      <SingleRowText
        text = { "Sorting" }
        prototypeDisplayParameters = { functionTitlePrototypeDisplayParameters }
      />

      <SingleRowText
        text = { "Argument" }
        prototypeDisplayParameters = { headerPrototypeDisplayParameters }
        changedDisplayParameters = { {
          left: sortingArgumentFieldLeft
        } }
      />

      <SingleRowText
        text = { "Order" }
        prototypeDisplayParameters = { headerPrototypeDisplayParameters }
        changedDisplayParameters = { {
          left: sortingOrderFieldLeft
        } }
      />

      <KeyValueInput
        keyText = { "" }
        initialValueString = { sortingArgument }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { sortingArgumentOptions }
        inputType = { "SingleSelectInput" }
        modificationFunctions = { [setSortingArgument, getNewParameter] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { sortingArgumentPrototypeDisplayParameters }
      />

      <KeyValueInput
        keyText = { "" }
        initialValueString = { sortingOrder }
        initialValueNumber = { [] }
        initialValueBoolean = { [] }
        optionsString = { sortingOrderOptions }
        inputType = { "SingleSelectInput" }
        modificationFunctions = { [setSortingOrder, getNewParameter] }
        properInputDisplay = { false }
        disabled = { false }
        prototypeDisplayParameters = { sortingOrderPrototypeDisplayParameters }
      />

      <Button
        buttonText = { "Sort" }
        actionFunctions = { [doSort] }
        disabled = { sortable ? false : true }
        prototypeDisplayParameters = { sortButtonPrototypeDisplayParameters }
        changedDisplayParameters = { {
          backgroundColor: sortable ? sortButtonBackgroundColorActive : sortButtonBackgroundColorInactive,
          contentColor: sortable ? sortButtonContentColorActive : sortButtonContentColorInactive
        } }
      />
    </Box>
  )
}
