import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../03_baseComponents/Box';





export interface FooterProps {
  webpageStyle: WebpageStyleProps,
  footerBoxTop: number,
  doAction: Array<any>
}





export const Footer: FC<FooterProps> = ({ webpageStyle, footerBoxTop, doAction }) => {
  //

  const [boxTop, setBoxTop] = useState<number>(footerBoxTop + webpageStyle.general.webpageMargin);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: footerBoxTop + webpageStyle.general.webpageMargin,
    left: webpageStyle.footer.boxLeft + webpageStyle.general.webpageMargin,
    height: webpageStyle.footer.boxHeight,
    width: webpageStyle.footer.boxWidth,
    backgroundColor: webpageStyle.footer.boxBackgroundColor,
    outlineStyle: webpageStyle.footer.boxOutlineStyle,
    outlineWidth: webpageStyle.footer.boxOutlineWidth,
    outlineColor: webpageStyle.footer.boxOutlineColor
  });


  useEffect(() => {
    setBoxTop(footerBoxTop);
  }, [footerBoxTop]);


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      changedDisplayParameters = { {
        top: boxTop
      } }
    >
      <div>
        Footer
      </div>
    </Box>
  )
}
