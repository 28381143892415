import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../services/webpageStyle';
import { ForgotPasswordRequest } from './mainContent/ForgotPasswordRequest';
import { LoggedInUser } from '../../../models/UserProps';





export interface ForgotPasswordRequestPageContentProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  doAction: Array<any>,
  resizeContentHeight: Array<any>
}





export const ForgotPasswordRequestPageContent: FC<ForgotPasswordRequestPageContentProps> = ({ webpageStyle,
  loggedInUser, doAction, resizeContentHeight }) => {
  //

  const [contentHeight, setContentHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);

  const [mainContentHeight, setMainContentHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);
  const [sidebarHeight, setSidebarHeight] = useState<number>(webpageStyle.contentBoxMinimumHeight);

  const resizeMainContentHeight = (newMainContentHeight: number) => {
    setMainContentHeight(newMainContentHeight);
  }

  const resizeSidebarHeight = (newSidebarHeight: number) => {
    setSidebarHeight(newSidebarHeight);
  }

  useEffect(() => {
    setContentHeight(Math.max(mainContentHeight, sidebarHeight));
    resizeContentHeight[0](Math.max(mainContentHeight, sidebarHeight));
  }, [mainContentHeight, sidebarHeight]);

  return(
    <div>
      { loggedInUser.role === "visitor" ?
        <ForgotPasswordRequest
          webpageStyle = { webpageStyle }
          resizeMainContentHeight = { [resizeMainContentHeight] }
        /> : null
      }
    </div>
  )
}
