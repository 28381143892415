import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { AdminCreateAndEditHorseDataForm, DataFormData } from './AdminCreateHorse/AdminCreateAndEditHorseDataForm';
import { AdminCreateHorseImageUploader } from './AdminCreateHorse/AdminCreateHorseImageUploader';
import { AdminCreateHorseVideoUploader } from './AdminCreateHorse/AdminCreateHorseVideoUploader';
import { AdminCreateHorseDocumentUploader } from './AdminCreateHorse/AdminCreateHorseDocumentUploader';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../03_baseComponents/Button';
import { SingleRowText, SingleRowPrototypeDisplayParameters, SingleRowChangedDisplayParameters } from '../../../03_baseComponents/SingleRowText';
import { ImageUploader } from '../../../03_baseComponents/ImageUploader';
import { Image } from '../../../03_baseComponents/ImageUploader/UploadedImageElement';
import { VideoUploader } from '../../../03_baseComponents/VideoUploader';
import { Video } from '../../../03_baseComponents/VideoUploader/UploadedVideoElement';
import { ImageAndVideoUploader } from '../../../03_baseComponents/ImageAndVideoUploader';
import { ImageOrVideo } from '../../../03_baseComponents/ImageAndVideoUploader/UploadedImageAndVideoElement';
import { Horse, HorseData } from '../../../../models/HorseProps';
import { getData } from '../../../../services/API.services';
import { sortImagesAndVideos } from '../../../../services/SortImagesAndVideos';





export interface AdminEditHorseProps {
  webpageStyle: WebpageStyleProps,
  selectedHorseId: Array<number>,
  resizeMainContentHeight: Array<any>
}





export const AdminEditHorse: FC<AdminEditHorseProps> = ({ webpageStyle, selectedHorseId, resizeMainContentHeight }) => {
  //

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [horseId, setHorseId] = useState<Array<string>>([]);
  const [name, setName] = useState<Array<string>>([]);
  const [stud, setStud] = useState<Array<string>>([]);
  const [birthYear, setBirthYear] = useState<Array<number>>([]);
  const [sex, setSex] = useState<Array<string>>([]);
  const [origin, setOrigin] = useState<Array<string>>([]);
  const [horseBreed, setHorseBreed] = useState<Array<string>>([]);
  const [color, setColor] = useState<Array<string>>([]);
  const [description, setDescription] = useState<Array<string>>([]);
  const [horseTelexId, setHorseTelexId] = useState<Array<string>>([]);
  const [dijugratasId, setDijugratasId] = useState<Array<string>>([]);
  const [tradingCategory, setTradingCategory] = useState<Array<string>>([]);
  const [priceCategory, setPriceCategory] = useState<Array<string>>([]);
  const [breedingCategory, setBreedingCategory] = useState<Array<string>>([]);
  const [accessibility, setAccessibility] = useState<Array<string>>([]);

  const [images, setImages] = useState<Array<Image>>([]);

  const [videos, setVideos] = useState<Array<Video>>([]);

  const [initialImagesAndVideos, setInitialImagesAndVideos] = useState<Array<ImageOrVideo>>([]);
  const [imagesAndVideos, setImagesAndVideos] = useState<Array<ImageOrVideo>>([]);

  const [dataForm, setDataForm] = useState<Array<DataFormData>>([]);


  const [nameErrorMessage, setNameErrorMessage] = useState<Array<string>>([]);
  const [studErrorMessage, setStudErrorMessage] = useState<Array<string>>([]);
  const [birthYearErrorMessage, setBirthYearErrorMessage] = useState<Array<string>>([]);
  const [sexErrorMessage, setSexErrorMessage] = useState<Array<string>>([]);
  const [originErrorMessage, setOriginErrorMessage] = useState<Array<string>>([]);
  const [horseBreedErrorMessage, setHorseBreedErrorMessage] = useState<Array<string>>([]);
  const [colorErrorMessage, setColorErrorMessage] = useState<Array<string>>([]);
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState<Array<string>>([]);
  const [horseTelexIdErrorMessage, setHorseTelexIdErrorMessage] = useState<Array<string>>([]);
  const [dijugratasIdErrorMessage, setDijugratasIdErrorMessage] = useState<Array<string>>([]);
  const [tradingCategoryErrorMessage, setTradingCategoryErrorMessage] = useState<Array<string>>([]);
  const [priceCategoryErrorMessage, setPriceCategoryErrorMessage] = useState<Array<string>>([]);
  const [breedingCategoryErrorMessage, setBreedingCategoryErrorMessage] = useState<Array<string>>([]);
  const [accessibilityErrorMessage, setAccessibilityErrorMessage] = useState<Array<string>>([]);

  const [imageUploaderErrorMessage, setImageUploaderErrorMessage] = useState<Array<string>>([]);
  const [videoUploaderErrorMessage, setVideoUploaderErrorMessage] = useState<Array<string>>([]);

  const [imageAndVideoUploaderErrorMessage, setImageAndVideoUploaderErrorMessage] = useState<Array<string>>([]);

  const [mainErrorMessage, setMainErrorMessage] = useState<Array<string>>([]);
  const [mainStatusMessage, setMainStatusMessage] = useState<Array<string>>([]);

  const [showErrorMessages, setShowErrorMessages] = useState<boolean>(false);

  
  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.mainContent.adminEditHorse.boxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.boxTop,
    left: webpageStyle.content.mainContent.boxLeft,
    height: boxHeight,
    width: webpageStyle.content.mainContent.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.boxOutlineColor
  });

  const [submitButtonTop, setSubmitButtonTop] = useState<number>(webpageStyle.content.mainContent.adminEditHorse.submitButton.buttonTop);

  const [submitButtonPrototypeDisplayParameters, setSubmitButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: submitButtonTop,
    left: webpageStyle.content.mainContent.adminEditHorse.submitButton.buttonLeft,
    height: webpageStyle.content.mainContent.adminEditHorse.submitButton.buttonHeight,
    width: webpageStyle.content.mainContent.adminEditHorse.submitButton.buttonWidth,
    backgroundColor: webpageStyle.content.mainContent.adminEditHorse.submitButton.buttonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.adminEditHorse.submitButton.buttonFontSize,
    contentColor: webpageStyle.content.mainContent.adminEditHorse.submitButton.buttonContentColorActive
  });

  // const [uploaderTop, setUploaderTop] = useState<number>(webpageStyle.content.mainContent.adminEditHorse.uploader.boxTop);

  // const [imageUploaderLeft, setImageUploaderLeft] = useState<number>(webpageStyle.content.mainContent.adminEditHorse.imageUploader.boxLeft);
  // const [videoUploaderLeft, setVideoUploaderLeft] = useState<number>(webpageStyle.content.mainContent.adminEditHorse.videoUploader.boxLeft);

  // const [imageUploaderHeight, setImageUploaderHeight] = useState<number>(webpageStyle.baseComponent.imageUploader.boxMinimumHeight);
  // const [videoUploaderHeight, setVideoUploaderHeight] = useState<number>(webpageStyle.content.mainContent.adminEditHorse.videoUploader.boxMinimumHeight);
  // const [documentUploaderHeight, setDocumentUploaderHeight] = useState<number>(webpageStyle.content.mainContent.adminEditHorse.documentUploader.boxMinimumHeight);

  const [imageAndVideoUploaderTop, setImageAndVideoUploaderTop] = useState<number>(webpageStyle.content.mainContent.adminEditHorse.imageAndVideoUploader.boxTop);
  const [imageAndVideoUploaderLeft, setImageAndVideoUploaderLeft] = useState<number>(webpageStyle.content.mainContent.adminEditHorse.imageAndVideoUploader.boxLeft);
  
  const [imageAndVideoUploaderHeight, setImageAndVideoUploaderHeight] = useState<number>(webpageStyle.baseComponent.imageAndVideoUploader.boxMinimumHeight);

  const [mainMessageTop, setMainMessageTop] = useState<number>(webpageStyle.content.mainContent.adminEditHorse.mainMessageTop);

  const [errorMessageSingleRowPrototypeDisplayParameters, setErrorMessageSingleRowPrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: mainMessageTop,
    left: webpageStyle.content.mainContent.adminEditHorse.mainMessageLeft,
    width: webpageStyle.content.mainContent.adminEditHorse.mainMessageWidth,
    fontSize: webpageStyle.content.mainContent.adminEditHorse.mainMessageFontSize,
    color: webpageStyle.content.mainContent.adminEditHorse.errorMessageColor
  });

  const [statusMessageSingleRowPrototypeDisplayParameters, setStatusMessageSingleRowPrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: mainMessageTop,
    left: webpageStyle.content.mainContent.adminEditHorse.mainMessageLeft,
    width: webpageStyle.content.mainContent.adminEditHorse.mainMessageWidth,
    fontSize: webpageStyle.content.mainContent.adminEditHorse.mainMessageFontSize,
    color: webpageStyle.content.mainContent.adminEditHorse.statusMessageColor
  });


  const saveHorse = (horseVariable: Array<Horse>) => {
    // setHorse(horseVariable);

    //

    setHorseId([horseVariable[0].id.toString()]);
    setName([horseVariable[0].data.name]);
    setStud([horseVariable[0].data.stud]);
    setBirthYear([horseVariable[0].data.birthYear]);
    setSex([horseVariable[0].data.sex]);
    setOrigin([horseVariable[0].data.origin]);
    setHorseBreed([horseVariable[0].data.horseBreed]);
    setColor([horseVariable[0].data.color]);
    setDescription([horseVariable[0].data.description]);
    setHorseTelexId(horseVariable[0].data.horseTelexId !== null ? [horseVariable[0].data.horseTelexId.toString()] : []);
    setDijugratasId(horseVariable[0].data.dijugratasId !== null ? [horseVariable[0].data.dijugratasId.toString()] : []);
    setTradingCategory([horseVariable[0].data.tradingCategory]);
    setPriceCategory([horseVariable[0].data.priceCategory]);
    setBreedingCategory([horseVariable[0].data.breedingCategory]);
    setAccessibility([horseVariable[0].data.accessibility]);

    setInitialImagesAndVideos(sortImagesAndVideos(horseVariable[0].images, horseVariable[0].videos));
    setImagesAndVideos(sortImagesAndVideos(horseVariable[0].images, horseVariable[0].videos));

    setDataForm([{
      horseId: [horseVariable[0].id],
      name: [horseVariable[0].data.name],
      stud: [horseVariable[0].data.stud],
      birthYear: [horseVariable[0].data.birthYear],
      sex: [horseVariable[0].data.sex],
      origin: [horseVariable[0].data.origin],
      horseBreed: [horseVariable[0].data.horseBreed],
      color: [horseVariable[0].data.color],
      description: [horseVariable[0].data.description],
      horseTelexId: [horseVariable[0].data.horseTelexId],
      dijugratasId: [horseVariable[0].data.dijugratasId],
      tradingCategory: [horseVariable[0].data.tradingCategory],
      priceCategory: [horseVariable[0].data.priceCategory],
      breedingCategory: [horseVariable[0].data.breedingCategory],
      accessibility: [horseVariable[0].data.accessibility]
    }]);

    setIsLoading(false);

    console.log(horseVariable);
  }

  const getHorse = async () => {
    // let horseId: string = "1";

    if(selectedHorseId.length !== 0) {
      // let result: any;
      // if(loggedInUser.role === "administrator" || loggedInUser.role === "account owner") {
      //   result = await getData(`/api/horses/single/read/admin/${ horseId }`, true);
      // }

      let result: any = await getData(`/api/horses/single/read/admin/${ selectedHorseId[0].toString() }`, true);
      if( !result || ( result as { status: number, message: string } )?.status >= 400 ) {
        if(result !== undefined) {
          if(result.message !== undefined) {
            console.log(result.message);
            // setIsLoading(false);
          }
        }
      } else {
        //

        // console.log(result);

        if(result.length !== 0) {
          saveHorse(result);
        }

        // setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }

  const updateDataForm = (modifiedValueType: string, newValue: any, newErrorMessage: Array<string>) => {
    // console.log(modifiedValueType, newValue, newErrorMessage);
    switch(modifiedValueType) {
      case("name"):
        setName(newValue);
        setNameErrorMessage(newErrorMessage);
        break;
      case("stud"):
        setStud(newValue);
        setStudErrorMessage(newErrorMessage);
        break;
      case("birthYear"):
        setBirthYear(newValue);
        setBirthYearErrorMessage(newErrorMessage);
        break;
      case("sex"):
        setSex(newValue);
        setSexErrorMessage(newErrorMessage);
        break;
      case("origin"):
        setOrigin(newValue);
        setOriginErrorMessage(newErrorMessage);
        break;
      case("horseBreed"):
        setHorseBreed(newValue);
        setHorseBreedErrorMessage(newErrorMessage);
        break;
      case("color"):
        setColor(newValue);
        setColorErrorMessage(newErrorMessage);
        break;
      case("description"):
        setDescription(newValue);
        setDescriptionErrorMessage(newErrorMessage);
        break;
      case("horseTelexId"):
        setHorseTelexId(newValue);
        setHorseTelexIdErrorMessage(newErrorMessage);
        break;
      case("dijugratasId"):
        setDijugratasId(newValue);
        setDijugratasIdErrorMessage(newErrorMessage);
        break;
      case("tradingCategory"):
        setTradingCategory(newValue);
        setTradingCategoryErrorMessage(newErrorMessage);
        break;
      case("priceCategory"):
        setPriceCategory(newValue);
        setPriceCategoryErrorMessage(newErrorMessage);
        break;
      case("breedingCategory"):
        setBreedingCategory(newValue);
        setBreedingCategoryErrorMessage(newErrorMessage);
        break;
      case("accessibility"):
        setAccessibility(newValue);
        setAccessibilityErrorMessage(newErrorMessage);
        break;
    }
    setMainStatusMessage([]);
    setMainErrorMessage([]);
  }

  const updateImages = (updatedImages: Array<Image>, updatedImageUploaderErrorMessage: Array<string>) => {
    setImages(updatedImages);
    setImageUploaderErrorMessage(updatedImageUploaderErrorMessage);
    setMainStatusMessage([]);
    setMainErrorMessage([]);
  }

  const updateVideos = (updatedVideos: Array<Video>, updatedVideoUploaderErrorMessage: Array<string>) => {
    setVideos(updatedVideos);
    setVideoUploaderErrorMessage(updatedVideoUploaderErrorMessage);
    setMainStatusMessage([]);
    setMainErrorMessage([]);
  }

  const updateImagesAndVideos = (updatedImagesAndVideos: Array<ImageOrVideo>, updatedImageAndVideoUploaderErrorMessage: Array<string>) => {
    setImagesAndVideos(updatedImagesAndVideos);
    setImageAndVideoUploaderErrorMessage(updatedImageAndVideoUploaderErrorMessage);
    setMainStatusMessage([]);
    setMainErrorMessage([]);
  }

  const submitButton = () => {
    //

    const test: boolean = testForm();

    if(test === true) {
      //

      setMainStatusMessage(["Everything is OK"]);
    } else {
      setMainErrorMessage(["Please check the inputs"]);
      setShowErrorMessages(true);
    }
  }

  const testForm = (): boolean => {
    if(nameErrorMessage.length !== 0) {
      return(false);
    }
    if(studErrorMessage.length !== 0) {
      return(false);
    }
    if(birthYearErrorMessage.length !== 0) {
      return(false);
    }
    if(sexErrorMessage.length !== 0) {
      return(false);
    }
    if(originErrorMessage.length !== 0) {
      return(false);
    }
    if(horseBreedErrorMessage.length !== 0) {
      return(false);
    }
    if(colorErrorMessage.length !== 0) {
      return(false);
    }
    if(descriptionErrorMessage.length !== 0) {
      return(false);
    }
    if(horseTelexIdErrorMessage.length !== 0) {
      return(false);
    }
    if(dijugratasIdErrorMessage.length !== 0) {
      return(false);
    }
    if(tradingCategoryErrorMessage.length !== 0) {
      return(false);
    }
    if(priceCategoryErrorMessage.length !== 0) {
      return(false);
    }
    if(breedingCategoryErrorMessage.length !== 0) {
      return(false);
    }
    if(accessibilityErrorMessage.length !== 0) {
      return(false);
    }
    if(imageUploaderErrorMessage.length !== 0) {
      return(false); // ennél majd több kell itt
    }
    if(videoUploaderErrorMessage.length !== 0) {
      return(false); // ennél majd több kell itt
    }
    return(true);
  }

  // const resizeImageUploaderHeight = (newImageUploaderHeight: number) => {
  //   setImageUploaderHeight(newImageUploaderHeight);
  //   const newMainContentHeight: number = webpageStyle.content.mainContent.adminEditHorse.uploader.boxTop + Math.max(newImageUploaderHeight, videoUploaderHeight, documentUploaderHeight) + webpageStyle.content.mainContent.adminEditHorse.submitButton.padding + webpageStyle.content.mainContent.adminEditHorse.submitButton.buttonHeight + webpageStyle.content.mainContent.adminEditHorse.submitButton.padding;
  //   resizeMainContentHeight[0](newMainContentHeight);
  // }

  // const resizeVideoUploaderHeight = (newVideoUploaderHeight: number) => {
  //   setVideoUploaderHeight(newVideoUploaderHeight);
  //   const newMainContentHeight: number = webpageStyle.content.mainContent.adminEditHorse.uploader.boxTop + Math.max(imageUploaderHeight, newVideoUploaderHeight, documentUploaderHeight) + webpageStyle.content.mainContent.adminEditHorse.submitButton.padding + webpageStyle.content.mainContent.adminEditHorse.submitButton.buttonHeight + webpageStyle.content.mainContent.adminEditHorse.submitButton.padding;
  //   resizeMainContentHeight[0](newMainContentHeight);
  // }

  const resizeImageAndVideoUploaderHeight = (newImageAndVideoUploaderHeight: number) => {
    setImageAndVideoUploaderHeight(newImageAndVideoUploaderHeight);
    const newMainContentHeight: number = webpageStyle.content.mainContent.adminEditHorse.imageAndVideoUploader.boxTop + newImageAndVideoUploaderHeight + webpageStyle.content.mainContent.adminEditHorse.submitButton.padding + webpageStyle.content.mainContent.adminEditHorse.submitButton.buttonHeight + webpageStyle.content.mainContent.adminEditHorse.submitButton.padding;
    resizeMainContentHeight[0](newMainContentHeight);
  }


  useEffect(() => {
    // const newMainContentHeight: number = webpageStyle.content.mainContent.adminEditHorse.uploader.boxTop + Math.max(imageUploaderHeight, videoUploaderHeight, documentUploaderHeight) + webpageStyle.content.mainContent.adminEditHorse.submitButton.padding + webpageStyle.content.mainContent.adminEditHorse.submitButton.buttonHeight + webpageStyle.content.mainContent.adminEditHorse.submitButton.padding;
    // const newSubmitButtonTop: number = webpageStyle.content.mainContent.adminEditHorse.uploader.boxTop + Math.max(imageUploaderHeight, videoUploaderHeight, documentUploaderHeight) + webpageStyle.content.mainContent.adminEditHorse.submitButton.padding;
    // const newMainMessageTop: number = webpageStyle.content.mainContent.adminEditHorse.uploader.boxTop + Math.max(imageUploaderHeight, videoUploaderHeight, documentUploaderHeight) + webpageStyle.content.mainContent.adminEditHorse.submitButton.padding;

    const newMainContentHeight: number = webpageStyle.content.mainContent.adminEditHorse.imageAndVideoUploader.boxTop + imageAndVideoUploaderHeight + webpageStyle.content.mainContent.adminEditHorse.submitButton.padding + webpageStyle.content.mainContent.adminEditHorse.submitButton.buttonHeight + webpageStyle.content.mainContent.adminEditHorse.submitButton.padding;
    const newSubmitButtonTop: number = webpageStyle.content.mainContent.adminEditHorse.imageAndVideoUploader.boxTop + imageAndVideoUploaderHeight + webpageStyle.content.mainContent.adminEditHorse.submitButton.padding;
    const newMainMessageTop: number = webpageStyle.content.mainContent.adminEditHorse.imageAndVideoUploader.boxTop + imageAndVideoUploaderHeight + webpageStyle.content.mainContent.adminEditHorse.submitButton.padding;
    setBoxHeight(newMainContentHeight);
    setSubmitButtonTop(newSubmitButtonTop);
    setMainMessageTop(newMainMessageTop);
    resizeMainContentHeight[0](newMainContentHeight);

    getHorse();
  }, [imageAndVideoUploaderHeight]);


  if(isLoading === true) {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
        changedDisplayParameters = { {
          height: boxHeight
        } }
      >
        <div>
          Horse data is loading...
        </div>
      </Box>
    )
  } else {
    if(dataForm.length !== 0) {
      return(
        <Box
          prototypeDisplayParameters = { boxPrototypeDisplayParameters }
          changedDisplayParameters = { {
            height: boxHeight
          } }
        >
          <div>
            <AdminCreateAndEditHorseDataForm
              webpageStyle = { webpageStyle }
              existingData = { dataForm[0] }
              updateDataForm = { [updateDataForm] }
              showErrorMessages = { showErrorMessages }
            />
    
            <ImageAndVideoUploader
              webpageStyle = { webpageStyle }
              top = { imageAndVideoUploaderTop }
              left = { imageAndVideoUploaderLeft }
              resizeImageAndVideoUploaderHeight = { [resizeImageAndVideoUploaderHeight] }
              initialImagesAndVideos = { imagesAndVideos }
              updateImagesAndVideos = { [updateImagesAndVideos] }
              showErrorMessage = { showErrorMessages }
            />
    
            <Button
              buttonText = { "Submit" }
              actionFunctions = { [submitButton] }
              disabled = { false } // módosítani kell
              prototypeDisplayParameters = { submitButtonPrototypeDisplayParameters }
              changedDisplayParameters = { {
                top: submitButtonTop
              } }
            />
    
            { mainErrorMessage.length !== 0 ?
              <SingleRowText
                text = { mainErrorMessage.length !== 0 ? mainErrorMessage[0] : "" }
                prototypeDisplayParameters = { errorMessageSingleRowPrototypeDisplayParameters }
                changedDisplayParameters = { {
                  top: mainMessageTop
                } }
              /> : null
            }
    
            { mainStatusMessage.length !== 0 ?
              <SingleRowText
                text = { mainStatusMessage.length !== 0 ? mainStatusMessage[0] : "" }
                prototypeDisplayParameters = { statusMessageSingleRowPrototypeDisplayParameters }
                changedDisplayParameters = { {
                  top: mainMessageTop
                } }
              /> : null
            }
          </div>
        </Box>
      )
    } else {
      return(
        <Box
          prototypeDisplayParameters = { boxPrototypeDisplayParameters }
          changedDisplayParameters = { {
            height: boxHeight
          } }
        >
          <div>
            Something went wrong.
          </div>
        </Box>
      )
    }
  }
}
