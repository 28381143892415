import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../03_baseComponents/Box';
import { AdminCreateAndEditHorseDataForm } from './AdminCreateHorse/AdminCreateAndEditHorseDataForm';
import { AdminCreateHorseImageUploader } from './AdminCreateHorse/AdminCreateHorseImageUploader';
import { AdminCreateHorseVideoUploader } from './AdminCreateHorse/AdminCreateHorseVideoUploader';
import { AdminCreateHorseDocumentUploader } from './AdminCreateHorse/AdminCreateHorseDocumentUploader';
import { Button, ButtonPrototypeDisplayParameters, ButtonChangedDisplayParameters } from '../../../03_baseComponents/Button';
import { SingleRowText, SingleRowPrototypeDisplayParameters, SingleRowChangedDisplayParameters } from '../../../03_baseComponents/SingleRowText';
import { ImageUploader } from '../../../03_baseComponents/ImageUploader';
import { Image } from '../../../03_baseComponents/ImageUploader/UploadedImageElement';
import { VideoUploader } from '../../../03_baseComponents/VideoUploader';
import { Video } from '../../../03_baseComponents/VideoUploader/UploadedVideoElement';
import { ImageAndVideoUploader } from '../../../03_baseComponents/ImageAndVideoUploader';
import { ImageOrVideo } from '../../../03_baseComponents/ImageAndVideoUploader/UploadedImageAndVideoElement';





export interface AdminCreateHorseProps {
  webpageStyle: WebpageStyleProps,
  resizeMainContentHeight: Array<any>
}





export const AdminCreateHorse: FC<AdminCreateHorseProps> = ({ webpageStyle, resizeMainContentHeight }) => {
  //

  const [name, setName] = useState<Array<string>>([]);
  const [stud, setStud] = useState<Array<string>>([]);
  const [birthYear, setBirthYear] = useState<Array<number>>([]);
  const [sex, setSex] = useState<Array<string>>([]);
  const [origin, setOrigin] = useState<Array<string>>([]);
  const [horseBreed, setHorseBreed] = useState<Array<string>>([]);
  const [color, setColor] = useState<Array<string>>([]);
  const [description, setDescription] = useState<Array<string>>([]);
  const [horseTelexId, setHorseTelexId] = useState<Array<string>>([]);
  const [dijugratasId, setDijugratasId] = useState<Array<string>>([]);
  const [tradingCategory, setTradingCategory] = useState<Array<string>>([]);
  const [priceCategory, setPriceCategory] = useState<Array<string>>([]);
  const [breedingCategory, setBreedingCategory] = useState<Array<string>>([]);
  const [accessibility, setAccessibility] = useState<Array<string>>([]);

  const [images, setImages] = useState<Array<Image>>([]);

  const [videos, setVideos] = useState<Array<Video>>([]);

  const [imagesAndVideos, setImagesAndVideos] = useState<Array<ImageOrVideo>>([]);


  const [nameErrorMessage, setNameErrorMessage] = useState<Array<string>>([]);
  const [studErrorMessage, setStudErrorMessage] = useState<Array<string>>([]);
  const [birthYearErrorMessage, setBirthYearErrorMessage] = useState<Array<string>>([]);
  const [sexErrorMessage, setSexErrorMessage] = useState<Array<string>>([]);
  const [originErrorMessage, setOriginErrorMessage] = useState<Array<string>>([]);
  const [horseBreedErrorMessage, setHorseBreedErrorMessage] = useState<Array<string>>([]);
  const [colorErrorMessage, setColorErrorMessage] = useState<Array<string>>([]);
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState<Array<string>>([]);
  const [horseTelexIdErrorMessage, setHorseTelexIdErrorMessage] = useState<Array<string>>([]);
  const [dijugratasIdErrorMessage, setDijugratasIdErrorMessage] = useState<Array<string>>([]);
  const [tradingCategoryErrorMessage, setTradingCategoryErrorMessage] = useState<Array<string>>([]);
  const [priceCategoryErrorMessage, setPriceCategoryErrorMessage] = useState<Array<string>>([]);
  const [breedingCategoryErrorMessage, setBreedingCategoryErrorMessage] = useState<Array<string>>([]);
  const [accessibilityErrorMessage, setAccessibilityErrorMessage] = useState<Array<string>>([]);

  const [imageUploaderErrorMessage, setImageUploaderErrorMessage] = useState<Array<string>>([]);
  const [videoUploaderErrorMessage, setVideoUploaderErrorMessage] = useState<Array<string>>([]);

  const [imageAndVideoUploaderErrorMessage, setImageAndVideoUploaderErrorMessage] = useState<Array<string>>([]);

  const [mainErrorMessage, setMainErrorMessage] = useState<Array<string>>([]);
  const [mainStatusMessage, setMainStatusMessage] = useState<Array<string>>([]);

  const [showErrorMessages, setShowErrorMessages] = useState<boolean>(false);

  
  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.mainContent.adminCreateHorse.boxHeight);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.boxTop,
    left: webpageStyle.content.mainContent.boxLeft,
    height: boxHeight,
    width: webpageStyle.content.mainContent.boxWidth,
    backgroundColor: webpageStyle.content.mainContent.boxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.boxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.boxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.boxOutlineColor
  });

  const [submitButtonTop, setSubmitButtonTop] = useState<number>(webpageStyle.content.mainContent.adminCreateHorse.submitButton.buttonTop);

  const [submitButtonPrototypeDisplayParameters, setSubmitButtonPrototypeDisplayParameters] = useState<ButtonPrototypeDisplayParameters>({
    top: submitButtonTop,
    left: webpageStyle.content.mainContent.adminCreateHorse.submitButton.buttonLeft,
    height: webpageStyle.content.mainContent.adminCreateHorse.submitButton.buttonHeight,
    width: webpageStyle.content.mainContent.adminCreateHorse.submitButton.buttonWidth,
    backgroundColor: webpageStyle.content.mainContent.adminCreateHorse.submitButton.buttonBackgroundColorActive,
    fontSize: webpageStyle.content.mainContent.adminCreateHorse.submitButton.buttonFontSize,
    contentColor: webpageStyle.content.mainContent.adminCreateHorse.submitButton.buttonContentColorActive
  });

  // const [uploaderTop, setUploaderTop] = useState<number>(webpageStyle.content.mainContent.adminCreateHorse.uploader.boxTop);

  // const [imageUploaderLeft, setImageUploaderLeft] = useState<number>(webpageStyle.content.mainContent.adminCreateHorse.imageUploader.boxLeft);
  // const [videoUploaderLeft, setVideoUploaderLeft] = useState<number>(webpageStyle.content.mainContent.adminCreateHorse.videoUploader.boxLeft);

  // const [imageUploaderHeight, setImageUploaderHeight] = useState<number>(webpageStyle.baseComponent.imageUploader.boxMinimumHeight);
  // const [videoUploaderHeight, setVideoUploaderHeight] = useState<number>(webpageStyle.content.mainContent.adminCreateHorse.videoUploader.boxMinimumHeight);
  // const [documentUploaderHeight, setDocumentUploaderHeight] = useState<number>(webpageStyle.content.mainContent.adminCreateHorse.documentUploader.boxMinimumHeight);

  const [imageAndVideoUploaderTop, setImageAndVideoUploaderTop] = useState<number>(webpageStyle.content.mainContent.adminCreateHorse.imageAndVideoUploader.boxTop);
  const [imageAndVideoUploaderLeft, setImageAndVideoUploaderLeft] = useState<number>(webpageStyle.content.mainContent.adminCreateHorse.imageAndVideoUploader.boxLeft);
  
  const [imageAndVideoUploaderHeight, setImageAndVideoUploaderHeight] = useState<number>(webpageStyle.baseComponent.imageAndVideoUploader.boxMinimumHeight);

  const [mainMessageTop, setMainMessageTop] = useState<number>(webpageStyle.content.mainContent.adminCreateHorse.mainMessageTop);

  const [errorMessageSingleRowPrototypeDisplayParameters, setErrorMessageSingleRowPrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: mainMessageTop,
    left: webpageStyle.content.mainContent.adminCreateHorse.mainMessageLeft,
    width: webpageStyle.content.mainContent.adminCreateHorse.mainMessageWidth,
    fontSize: webpageStyle.content.mainContent.adminCreateHorse.mainMessageFontSize,
    color: webpageStyle.content.mainContent.adminCreateHorse.errorMessageColor
  });

  const [statusMessageSingleRowPrototypeDisplayParameters, setStatusMessageSingleRowPrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: mainMessageTop,
    left: webpageStyle.content.mainContent.adminCreateHorse.mainMessageLeft,
    width: webpageStyle.content.mainContent.adminCreateHorse.mainMessageWidth,
    fontSize: webpageStyle.content.mainContent.adminCreateHorse.mainMessageFontSize,
    color: webpageStyle.content.mainContent.adminCreateHorse.statusMessageColor
  });


  const updateDataForm = (modifiedValueType: string, newValue: any, newErrorMessage: Array<string>) => {
    // console.log(modifiedValueType, newValue, newErrorMessage);
    switch(modifiedValueType) {
      case("name"):
        setName(newValue);
        setNameErrorMessage(newErrorMessage);
        break;
      case("stud"):
        setStud(newValue);
        setStudErrorMessage(newErrorMessage);
        break;
      case("birthYear"):
        setBirthYear(newValue);
        setBirthYearErrorMessage(newErrorMessage);
        break;
      case("sex"):
        setSex(newValue);
        setSexErrorMessage(newErrorMessage);
        break;
      case("origin"):
        setOrigin(newValue);
        setOriginErrorMessage(newErrorMessage);
        break;
      case("horseBreed"):
        setHorseBreed(newValue);
        setHorseBreedErrorMessage(newErrorMessage);
        break;
      case("color"):
        setColor(newValue);
        setColorErrorMessage(newErrorMessage);
        break;
      case("description"):
        setDescription(newValue);
        setDescriptionErrorMessage(newErrorMessage);
        break;
      case("horseTelexId"):
        setHorseTelexId(newValue);
        setHorseTelexIdErrorMessage(newErrorMessage);
        break;
      case("dijugratasId"):
        setDijugratasId(newValue);
        setDijugratasIdErrorMessage(newErrorMessage);
        break;
      case("tradingCategory"):
        setTradingCategory(newValue);
        setTradingCategoryErrorMessage(newErrorMessage);
        break;
      case("priceCategory"):
        setPriceCategory(newValue);
        setPriceCategoryErrorMessage(newErrorMessage);
        break;
      case("breedingCategory"):
        setBreedingCategory(newValue);
        setBreedingCategoryErrorMessage(newErrorMessage);
        break;
      case("accessibility"):
        setAccessibility(newValue);
        setAccessibilityErrorMessage(newErrorMessage);
        break;
    }
    setMainStatusMessage([]);
    setMainErrorMessage([]);
  }

  const updateImages = (updatedImages: Array<Image>, updatedImageUploaderErrorMessage: Array<string>) => {
    setImages(updatedImages);
    setImageUploaderErrorMessage(updatedImageUploaderErrorMessage);
    setMainStatusMessage([]);
    setMainErrorMessage([]);
  }

  const updateVideos = (updatedVideos: Array<Video>, updatedVideoUploaderErrorMessage: Array<string>) => {
    setVideos(updatedVideos);
    setVideoUploaderErrorMessage(updatedVideoUploaderErrorMessage);
    setMainStatusMessage([]);
    setMainErrorMessage([]);
  }

  const updateImagesAndVideos = (updatedImagesAndVideos: Array<ImageOrVideo>, updatedImageAndVideoUploaderErrorMessage: Array<string>) => {
    setImagesAndVideos(updatedImagesAndVideos);
    setImageAndVideoUploaderErrorMessage(updatedImageAndVideoUploaderErrorMessage);
    setMainStatusMessage([]);
    setMainErrorMessage([]);
  }

  const submitButton = () => {
    //

    const test: boolean = testForm();

    if(test === true) {
      //

      setMainStatusMessage(["Everything is OK"]);
    } else {
      setMainErrorMessage(["Please check the inputs"]);
      setShowErrorMessages(true);
    }
  }

  const testForm = (): boolean => {
    if(nameErrorMessage.length !== 0) {
      return(false);
    }
    if(studErrorMessage.length !== 0) {
      return(false);
    }
    if(birthYearErrorMessage.length !== 0) {
      return(false);
    }
    if(sexErrorMessage.length !== 0) {
      return(false);
    }
    if(originErrorMessage.length !== 0) {
      return(false);
    }
    if(horseBreedErrorMessage.length !== 0) {
      return(false);
    }
    if(colorErrorMessage.length !== 0) {
      return(false);
    }
    if(descriptionErrorMessage.length !== 0) {
      return(false);
    }
    if(horseTelexIdErrorMessage.length !== 0) {
      return(false);
    }
    if(dijugratasIdErrorMessage.length !== 0) {
      return(false);
    }
    if(tradingCategoryErrorMessage.length !== 0) {
      return(false);
    }
    if(priceCategoryErrorMessage.length !== 0) {
      return(false);
    }
    if(breedingCategoryErrorMessage.length !== 0) {
      return(false);
    }
    if(accessibilityErrorMessage.length !== 0) {
      return(false);
    }
    if(imageUploaderErrorMessage.length !== 0) {
      return(false); // ennél majd több kell itt
    }
    if(videoUploaderErrorMessage.length !== 0) {
      return(false); // ennél majd több kell itt
    }
    return(true);
  }

  // const resizeImageUploaderHeight = (newImageUploaderHeight: number) => {
  //   setImageUploaderHeight(newImageUploaderHeight);
  //   const newMainContentHeight: number = webpageStyle.content.mainContent.adminCreateHorse.uploader.boxTop + Math.max(newImageUploaderHeight, videoUploaderHeight, documentUploaderHeight) + webpageStyle.content.mainContent.adminCreateHorse.submitButton.padding + webpageStyle.content.mainContent.adminCreateHorse.submitButton.buttonHeight + webpageStyle.content.mainContent.adminCreateHorse.submitButton.padding;
  //   resizeMainContentHeight[0](newMainContentHeight);
  // }

  // const resizeVideoUploaderHeight = (newVideoUploaderHeight: number) => {
  //   setVideoUploaderHeight(newVideoUploaderHeight);
  //   const newMainContentHeight: number = webpageStyle.content.mainContent.adminCreateHorse.uploader.boxTop + Math.max(imageUploaderHeight, newVideoUploaderHeight, documentUploaderHeight) + webpageStyle.content.mainContent.adminCreateHorse.submitButton.padding + webpageStyle.content.mainContent.adminCreateHorse.submitButton.buttonHeight + webpageStyle.content.mainContent.adminCreateHorse.submitButton.padding;
  //   resizeMainContentHeight[0](newMainContentHeight);
  // }

  const resizeImageAndVideoUploaderHeight = (newImageAndVideoUploaderHeight: number) => {
    setImageAndVideoUploaderHeight(newImageAndVideoUploaderHeight);
    const newMainContentHeight: number = webpageStyle.content.mainContent.adminCreateHorse.imageAndVideoUploader.boxTop + newImageAndVideoUploaderHeight + webpageStyle.content.mainContent.adminCreateHorse.submitButton.padding + webpageStyle.content.mainContent.adminCreateHorse.submitButton.buttonHeight + webpageStyle.content.mainContent.adminCreateHorse.submitButton.padding;
    resizeMainContentHeight[0](newMainContentHeight);
  }


  useEffect(() => {
    // const newMainContentHeight: number = webpageStyle.content.mainContent.adminCreateHorse.uploader.boxTop + Math.max(imageUploaderHeight, videoUploaderHeight, documentUploaderHeight) + webpageStyle.content.mainContent.adminCreateHorse.submitButton.padding + webpageStyle.content.mainContent.adminCreateHorse.submitButton.buttonHeight + webpageStyle.content.mainContent.adminCreateHorse.submitButton.padding;
    // const newSubmitButtonTop: number = webpageStyle.content.mainContent.adminCreateHorse.uploader.boxTop + Math.max(imageUploaderHeight, videoUploaderHeight, documentUploaderHeight) + webpageStyle.content.mainContent.adminCreateHorse.submitButton.padding;
    // const newMainMessageTop: number = webpageStyle.content.mainContent.adminCreateHorse.uploader.boxTop + Math.max(imageUploaderHeight, videoUploaderHeight, documentUploaderHeight) + webpageStyle.content.mainContent.adminCreateHorse.submitButton.padding;

    const newMainContentHeight: number = webpageStyle.content.mainContent.adminCreateHorse.imageAndVideoUploader.boxTop + imageAndVideoUploaderHeight + webpageStyle.content.mainContent.adminCreateHorse.submitButton.padding + webpageStyle.content.mainContent.adminCreateHorse.submitButton.buttonHeight + webpageStyle.content.mainContent.adminCreateHorse.submitButton.padding;
    const newSubmitButtonTop: number = webpageStyle.content.mainContent.adminCreateHorse.imageAndVideoUploader.boxTop + imageAndVideoUploaderHeight + webpageStyle.content.mainContent.adminCreateHorse.submitButton.padding;
    const newMainMessageTop: number = webpageStyle.content.mainContent.adminCreateHorse.imageAndVideoUploader.boxTop + imageAndVideoUploaderHeight + webpageStyle.content.mainContent.adminCreateHorse.submitButton.padding;
    setBoxHeight(newMainContentHeight);
    setSubmitButtonTop(newSubmitButtonTop);
    setMainMessageTop(newMainMessageTop);
    resizeMainContentHeight[0](newMainContentHeight);
  }, [imageAndVideoUploaderHeight]);


  return(
    <Box
      prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      changedDisplayParameters = { {
        height: boxHeight
      } }
    >
      <div>
        <AdminCreateAndEditHorseDataForm
          webpageStyle = { webpageStyle }
          updateDataForm = { [updateDataForm] }
          showErrorMessages = { showErrorMessages }
        />

        {/* <AdminCreateHorseImageUploader
          webpageStyle = { webpageStyle }
          updateUploadedImages = { [] }
        /> */}

        {/* <ImageUploader
          webpageStyle = { webpageStyle }
          top = { uploaderTop }
          left = { imageUploaderLeft }
          resizeImageUploaderHeight = { [resizeImageUploaderHeight] }
          initialImages = { images }
          updateImages = { [updateImages] }
          showErrorMessage = { showErrorMessages }
        />

        <VideoUploader
          webpageStyle = { webpageStyle }
          top = { uploaderTop }
          left = { videoUploaderLeft }
          resizeVideoUploaderHeight = { [resizeVideoUploaderHeight] }
          initialVideos = { videos }
          updateVideos = { [updateVideos] }
          showErrorMessage = { showErrorMessages }
        />

        <AdminCreateHorseDocumentUploader
          webpageStyle = { webpageStyle }
          updateUploadedDocuments = { [] }
        /> */}

        <ImageAndVideoUploader
          webpageStyle = { webpageStyle }
          top = { imageAndVideoUploaderTop }
          left = { imageAndVideoUploaderLeft }
          resizeImageAndVideoUploaderHeight = { [resizeImageAndVideoUploaderHeight] }
          initialImagesAndVideos = { [] }
          updateImagesAndVideos = { [updateImagesAndVideos] }
          showErrorMessage = { showErrorMessages }
        />

        <Button
          buttonText = { "Submit" }
          actionFunctions = { [submitButton] }
          disabled = { false } // módosítani kell
          prototypeDisplayParameters = { submitButtonPrototypeDisplayParameters }
          changedDisplayParameters = { {
            top: submitButtonTop
          } }
        />

        { mainErrorMessage.length !== 0 ?
          <SingleRowText
            text = { mainErrorMessage.length !== 0 ? mainErrorMessage[0] : "" }
            prototypeDisplayParameters = { errorMessageSingleRowPrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: mainMessageTop
            } }
          /> : null
        }

        { mainStatusMessage.length !== 0 ?
          <SingleRowText
            text = { mainStatusMessage.length !== 0 ? mainStatusMessage[0] : "" }
            prototypeDisplayParameters = { statusMessageSingleRowPrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: mainMessageTop
            } }
          /> : null
        }
      </div>
    </Box>
  )
}
