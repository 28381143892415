import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { WebpageStyleProps } from '../../../../../services/webpageStyle';
import { Box, BoxPrototypeDisplayParameters, BoxChangedDisplayParameters } from '../../../../03_baseComponents/Box';
import { ImageDisplay, ImageDisplayPrototypeDisplayParameters, ImageDisplayChangedDisplayParameters } from '../../../../03_baseComponents/ImageDisplay';
import { SingleRowText, SingleRowPrototypeDisplayParameters, SingleRowChangedDisplayParameters } from '../../../../03_baseComponents/SingleRowText';
import { KeyValueDisplay, KeyValueDisplayPrototypeDisplayParameters, KeyValueDisplayChangedDisplayParameters } from '../../../../03_baseComponents/KeyValueDisplay';
import { Horse, HorseData } from '../../../../../models/HorseProps';
import { InterestButton, InterestButtonPrototypeDisplayParameters, InterestButtonBoxChangedDisplayParameters } from '../../../../03_baseComponents/InterestButton';
import { getHorsePageUrl } from '../../../../../services/RedirectionUrl';
import { getHorsePedigreeFromHorseTelex, HorsePedigree } from '../../../../../services/HorseTelexPedigree';
import { LoggedInUser } from '../../../../../models/UserProps';






export interface HorseCatalogListElementProps {
  webpageStyle: WebpageStyleProps,
  loggedInUser: LoggedInUser,
  horse: Array<Horse>,
  initialHorseInterestState: boolean,
  interestFunction: Array<any>
}





export const HorseCatalogListElement: FC<HorseCatalogListElementProps> = ({ webpageStyle, loggedInUser, horse,
  initialHorseInterestState, interestFunction }) => {
    
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [origin, setOrigin] = useState<string>("");
  const [pedigree, setPedigree] = useState<string>("N/A - N/A - N/A");

  const [horseInterestState, setHorseInterestState] = useState<boolean>(initialHorseInterestState);
  const [priceCategory, setPriceCategory] = useState<string>("");
  const [breedingCategory, setBreedingCategory] = useState<string>("");

  const [horsePageUrl, setHorsePageUrl] = useState<Array<string>>([]);

  const [imageComponent, setImageComponent] = useState<Array<JSX.Element>>([<div/>]);
  const [imageBoxComponent, setImageBoxComponent] = useState<Array<JSX.Element>>([<div/>]);


  const [boxTop, setBoxTop] = useState<number>(0);
  const [boxLeft, setBoxLeft] = useState<number>(0);
  const [boxBackgroundColor, setBoxBackgroundColor] = useState<string>(webpageStyle.content.mainContent.horseCatalogCollection.listElement.boxBackgroundColor);
  const [boxHeight, setBoxHeight] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.listElement.boxHeight);
  const [boxWidth, setBoxWidth] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.listElement.boxWidth);
  const [boxOutlineStyle, setBoxOutlineStyle] = useState<string>(webpageStyle.content.mainContent.horseCatalogCollection.listElement.boxOutlineStyle);
  const [boxOutlineWidth, setBoxOutlineWidth] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.listElement.boxOutlineWidth);
  const [boxOutlineColor, setBoxOutlineColor] = useState<string>(webpageStyle.content.mainContent.horseCatalogCollection.listElement.boxOutlineColor);

  const [boxPrototypeDisplayParameters, setBoxDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: boxTop,
    left: boxLeft,
    height: boxHeight,
    width: boxWidth,
    backgroundColor: boxBackgroundColor,
    outlineStyle: boxOutlineStyle,
    outlineWidth: boxOutlineWidth,
    outlineColor: boxOutlineColor
  });


  const [imageBoxPrototypeDisplayParameters, setImageBoxPrototypeDisplayParameters] = useState<BoxPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.horseCatalogCollection.listElement.imageBoxTop,
    left: webpageStyle.content.mainContent.horseCatalogCollection.listElement.imageBoxLeft,
    height: webpageStyle.content.mainContent.horseCatalogCollection.listElement.imageBoxHeight,
    width: webpageStyle.content.mainContent.horseCatalogCollection.listElement.imageBoxWidth,
    backgroundColor: webpageStyle.content.mainContent.horseCatalogCollection.listElement.imageBoxBackgroundColor,
    outlineStyle: webpageStyle.content.mainContent.horseCatalogCollection.listElement.imageBoxOutlineStyle,
    outlineWidth: webpageStyle.content.mainContent.horseCatalogCollection.listElement.imageBoxOutlineWidth,
    outlineColor: webpageStyle.content.mainContent.horseCatalogCollection.listElement.imageBoxOutlineColor,
  });

  const [imagePrototypeDisplayParameters, setImagePrototypeDisplayParameters] = useState<ImageDisplayPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.horseCatalogCollection.listElement.imageTop,
    left: webpageStyle.content.mainContent.horseCatalogCollection.listElement.imageLeft,
    height: webpageStyle.content.mainContent.horseCatalogCollection.listElement.imageHeight,
    width: webpageStyle.content.mainContent.horseCatalogCollection.listElement.imageWidth
  });

  const [titlePrototypeDisplayParameters, setTitlePrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.horseCatalogCollection.listElement.titleTop,
    left: webpageStyle.content.mainContent.horseCatalogCollection.listElement.titleLeft,
    width: webpageStyle.content.mainContent.horseCatalogCollection.listElement.titleWidth,
    fontSize: webpageStyle.content.mainContent.horseCatalogCollection.listElement.titleFontSize,
    color: webpageStyle.content.mainContent.horseCatalogCollection.listElement.titleColor
  });

  const [firstDataTop, setFirstDataTop] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.listElement.firstDataTop);
  const [dataTopDistance, setDataTopDistance] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.listElement.dataTopDistance);

  const [keyValueDisplayPrototypeDisplayParameters, setKeyValueDisplayPrototypeDisplayParameters] = useState<KeyValueDisplayPrototypeDisplayParameters>({
    keyTop: firstDataTop,
    keyLeft: webpageStyle.content.mainContent.horseCatalogCollection.listElement.keyLeft,
    keyWidth: webpageStyle.content.mainContent.horseCatalogCollection.listElement.keyWidth,
    keyFontSize: webpageStyle.content.mainContent.horseCatalogCollection.listElement.keyFontSize,
    keyColor: webpageStyle.content.mainContent.horseCatalogCollection.listElement.keyColor,
    valueTop: firstDataTop,
    valueLeft: webpageStyle.content.mainContent.horseCatalogCollection.listElement.valueLeft,
    valueWidth: webpageStyle.content.mainContent.horseCatalogCollection.listElement.valueWidth,
    valueFontSize: webpageStyle.content.mainContent.horseCatalogCollection.listElement.valueFontSize,
    valueColor: webpageStyle.content.mainContent.horseCatalogCollection.listElement.valueColor
  });

  const [interestButtonPrototypeDisplayParameters, setInterestButtonPrototypeDisplayParameters] = useState<InterestButtonPrototypeDisplayParameters>({
    top: webpageStyle.content.mainContent.horseCatalogCollection.listElement.interestButtonTop,
    left: webpageStyle.content.mainContent.horseCatalogCollection.listElement.interestButtonLeft,
    size: webpageStyle.content.mainContent.horseCatalogCollection.listElement.interestButtonSize
  });

  const [categoryLeft, setCategoryLeft] = useState<number>();
  const [categoryWidth, setCategoryWidth] = useState<number>();
  const [categoryFontSize, setCategoryFontSize] = useState<number>();
  const [categoryColor, setCategoryColor] = useState<string>();

  const [categoryPrototypeDisplayParameters, setCategoryPrototypeDisplayParameters] = useState<SingleRowPrototypeDisplayParameters>({
    top: firstDataTop,
    left: webpageStyle.content.mainContent.horseCatalogCollection.listElement.categoryLeft,
    width: webpageStyle.content.mainContent.horseCatalogCollection.listElement.categoryWidth,
    fontSize: webpageStyle.content.mainContent.horseCatalogCollection.listElement.categoryFontSize,
    color: webpageStyle.content.mainContent.horseCatalogCollection.listElement.categoryColor
  });

  const [firstCategoryTop, setFirstCategoryTop] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.listElement.firstCategoryTop);
  const [categoryTopDistance, setCategoryTopDistance] = useState<number>(webpageStyle.content.mainContent.horseCatalogCollection.listElement.categoryTopDistance);


  const loadData = async () => {
    const temporaryRedirectionUrl: string = getHorsePageUrl(horse[0].data.id);
    setHorsePageUrl([temporaryRedirectionUrl]);

    if(horse[0].data.mainImageUrl !== null) {
      if(horse[0].data.mainImageUrl.length !== 0) {
        setImageComponent([
          <ImageDisplay
            uploadedImage = { [] }
            sourceUrl = { [horse[0].data.mainImageUrl] }
            redirectionUrl = { temporaryRedirectionUrl }
            prototypeDisplayParameters = { imagePrototypeDisplayParameters }
          />
        ])
      }
    }

    setImageBoxComponent([
      <Box
        redirectionUrl = { temporaryRedirectionUrl }
        prototypeDisplayParameters = { imageBoxPrototypeDisplayParameters }
      />
    ])

    setOrigin((horse[0].data.origin + " (" + horse[0].data.horseBreed + ")"));

    let horsePedigree: HorsePedigree | undefined = undefined;

    if(horse[0].data.horseTelexId !== null) {
      horsePedigree = await getHorsePedigreeFromHorseTelex(horse[0].data.horseTelexId);
    }

    if(horsePedigree !== undefined) {
      const fathersName: string = horsePedigree.fathersName.length !== 0 ? horsePedigree.fathersName[0] : "N/A";
      const mothersFathersName: string = horsePedigree.mothersFathersName.length !== 0 ? horsePedigree.mothersFathersName[0] : "N/A";
      const mothersMothersFathersName: string = horsePedigree.mothersMothersFathersName.length !== 0 ? horsePedigree.mothersMothersFathersName[0] : "N/A";

      setPedigree(( fathersName + " - " + mothersFathersName + " - " + mothersMothersFathersName ));
    }

    setPriceCategory((horse[0].data.priceCategory + ' €'));

    if(horse[0].data.breedingCategory !== "no information") {
      setBreedingCategory(horse[0].data.breedingCategory);
    }

    setIsLoading(false);
  }

  const changeHorseInterest = () => {
    //

    // run API ...

    // feltételek ...

    setHorseInterestState(horseInterestState === true ? false : true);
  }


  useEffect(() => {
    loadData();
  }, []);
  

  if(isLoading === true) {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      >
        <div>
          Horse catalog list element is loading...
        </div>
      </Box>
    )
  } else {
    return(
      <Box
        prototypeDisplayParameters = { boxPrototypeDisplayParameters }
      >
        <div>
          { imageBoxComponent[0] }

          { imageComponent[0] }

          <SingleRowText
            text = { horse[0].data.name }
            redirectionUrl = { horsePageUrl[0] }
            prototypeDisplayParameters = { titlePrototypeDisplayParameters }
          />

          <KeyValueDisplay
            keyText = { "Stud" }
            valueText = { horse[0].data.stud }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 0 * dataTopDistance,
              valueTop: firstDataTop + 0 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Birth year" }
            valueText = { horse[0].data.birthYear.toString() }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 1 * dataTopDistance,
              valueTop: firstDataTop + 1 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Sex" }
            valueText = { horse[0].data.sex }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 2 * dataTopDistance,
              valueTop: firstDataTop + 2 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Color" }
            valueText = { horse[0].data.color }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 3 * dataTopDistance,
              valueTop: firstDataTop + 3 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Origin" }
            valueText = { origin }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 4 * dataTopDistance,
              valueTop: firstDataTop + 4 * dataTopDistance
            } }
          />

          <KeyValueDisplay
            keyText = { "Pedigree" }
            valueText = { pedigree }
            message = { "Father - Mother's Father - Mother's Mother's Father" }
            prototypeDisplayParameters = { keyValueDisplayPrototypeDisplayParameters }
            changedDisplayParameters = { {
              keyTop: firstDataTop + 5 * dataTopDistance,
              valueTop: firstDataTop + 5 * dataTopDistance
            } }
          />

          { loggedInUser.role !== "administrator" && loggedInUser.role !== "account owner" ?
            <InterestButton
              value = { horseInterestState }
              userId = { -1 }
              horseId = { horse[0].data.id }
              functions = { [changeHorseInterest] }
              prototypeDisplayParameters = { interestButtonPrototypeDisplayParameters }
            /> : null
          }

          <SingleRowText
            text = { horse[0].data.tradingCategory }
            prototypeDisplayParameters = { categoryPrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstCategoryTop + 0 * categoryTopDistance
            } }
          />
          {/* <KeyValueDisplay
            keyText = { "Trading category" }
            valueText = { horse.tradingCategory }
            redirectionUrl = { [] }
            message = { [] }
            keyTop = { 100 }
            keyLeft = { 600 }
            keyWidth = { 150 }
            keyFontSize = { keyValueFontSize }
            keyColor = { fontColor }
            valueTop = { 100 }
            valueLeft = { 750 }
            valueWidth = { 150 }
            valueFontSize = { keyValueFontSize }
            valueColor = { fontColor }
          /> */}

          <SingleRowText
            text = { priceCategory }
            prototypeDisplayParameters = { categoryPrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstCategoryTop + 1 * categoryTopDistance
            } }
          />
          {/* <KeyValueDisplay
            keyText = { "Price category" }
            valueText = { horse.priceCategory }
            redirectionUrl = { [] }
            message = { [] }
            keyTop = { 125 }
            keyLeft = { 600 }
            keyWidth = { 150 }
            keyFontSize = { keyValueFontSize }
            keyColor = { fontColor }
            valueTop = { 125 }
            valueLeft = { 750 }
            valueWidth = { 150 }
            valueFontSize = { keyValueFontSize }
            valueColor = { fontColor }
          /> */}

          <SingleRowText
            text = { breedingCategory }
            prototypeDisplayParameters = { categoryPrototypeDisplayParameters }
            changedDisplayParameters = { {
              top: firstCategoryTop + 3 * categoryTopDistance
            } }
          />
          {/* // breeding category
          <KeyValueDisplay
            keyText = { "Breeding category" }
            valueText = { horse.breedingCategory }
            redirectionUrl = { [] }
            message = { [] }
            keyTop = { 150 }
            keyLeft = { 600 }
            keyWidth = { 150 }
            keyFontSize = { keyValueFontSize }
            keyColor = { fontColor }
            valueTop = { 150 }
            valueLeft = { 750 }
            valueWidth = { 150 }
            valueFontSize = { keyValueFontSize }
            valueColor = { fontColor }
          /> */}

        </div>
      </Box>
    )
  }
}
