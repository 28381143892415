import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { AdjustableSingleRowTextDisplay } from '../04_subComponents/TextDisplay';





export interface KeyValueDisplayPrototypeDisplayParameters {
  keyTop: number,
  keyLeft: number,
  keyWidth: number,
  keyFontSize: number,
  keyColor: string,
  valueTop: number,
  valueLeft: number,
  valueWidth: number,
  valueFontSize: number,
  valueColor: string
}

export interface KeyValueDisplayChangedDisplayParameters {
  keyTop?: number,
  keyLeft?: number,
  keyWidth?: number,
  keyFontSize?: number,
  keyColor?: string,
  valueTop?: number,
  valueLeft?: number,
  valueWidth?: number,
  valueFontSize?: number,
  valueColor?: string
}

export interface KeyValueDisplayProps {
  keyText: string,
  valueText: string,
  redirectionUrl?: string,
  message?: string,
  prototypeDisplayParameters: KeyValueDisplayPrototypeDisplayParameters,
  changedDisplayParameters?: KeyValueDisplayChangedDisplayParameters
}





export const KeyValueDisplay: FC<KeyValueDisplayProps> = ({ keyText, valueText, redirectionUrl, message,
  prototypeDisplayParameters, changedDisplayParameters }) => {
  //

  const [keyTop, setKeyTop] = useState<number>(prototypeDisplayParameters.keyTop);
  const [keyLeft, setKeyLeft] = useState<number>(prototypeDisplayParameters.keyLeft);
  const [keyWidth, setKeyWidth] = useState<number>(prototypeDisplayParameters.keyWidth);
  const [keyFontSize, setKeyFontSize] = useState<number>(prototypeDisplayParameters.keyFontSize);
  const [keyColor, setKeyColor] = useState<string>(prototypeDisplayParameters.keyColor);
  const [valueTop, setValueTop] = useState<number>(prototypeDisplayParameters.valueTop);
  const [valueLeft, setValueLeft] = useState<number>(prototypeDisplayParameters.valueLeft);
  const [valueWidth, setValueWidth] = useState<number>(prototypeDisplayParameters.valueWidth);
  const [valueFontSize, setValueFontSize] = useState<number>(prototypeDisplayParameters.valueFontSize);
  const [valueColor, setValueColor] = useState<string>(prototypeDisplayParameters.valueColor);


  useEffect(() => {
    if(changedDisplayParameters !== undefined) {
      if(changedDisplayParameters.keyTop !== undefined) {
        setKeyTop(changedDisplayParameters.keyTop);
      }
      if(changedDisplayParameters.keyLeft !== undefined) {
        setKeyLeft(changedDisplayParameters.keyLeft);
      }
      if(changedDisplayParameters.keyWidth !== undefined) {
        setKeyWidth(changedDisplayParameters.keyWidth);
      }
      if(changedDisplayParameters.keyFontSize !== undefined) {
        setKeyFontSize(changedDisplayParameters.keyFontSize);
      }
      if(changedDisplayParameters.keyColor !== undefined) {
        setKeyColor(changedDisplayParameters.keyColor);
      }
      if(changedDisplayParameters.valueTop !== undefined) {
        setValueTop(changedDisplayParameters.valueTop);
      }
      if(changedDisplayParameters.valueLeft !== undefined) {
        setValueLeft(changedDisplayParameters.valueLeft);
      }
      if(changedDisplayParameters.valueWidth !== undefined) {
        setValueWidth(changedDisplayParameters.valueWidth);
      }
      if(changedDisplayParameters.valueFontSize !== undefined) {
        setValueFontSize(changedDisplayParameters.valueFontSize);
      }
      if(changedDisplayParameters.valueColor !== undefined) {
        setValueColor(changedDisplayParameters.valueColor);
      }
    }
  }, [changedDisplayParameters]);


  return(
    <div>
      <AdjustableSingleRowTextDisplay
        text = { keyText + ':' }
        redirectionUrl = { [] }
        message = { [] }
        top = { keyTop }
        left = { keyLeft }
        width = { keyWidth }
        fontSize = { keyFontSize }
        color = { keyColor }
      />
      <AdjustableSingleRowTextDisplay
        text = { valueText }
        redirectionUrl = { redirectionUrl !== undefined ? [redirectionUrl] : [] }
        message = { message !== undefined ? [message] : [] }
        top = { valueTop }
        left = { valueLeft }
        width = { valueWidth }
        fontSize = { valueFontSize }
        color = { valueColor }
      />
    </div>
  )
}
